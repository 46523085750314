export class PathGraph {
    constructor (edges) {
        this.graph = {};
        this.path = edges.map(edge => edge.path);
        edges.forEach((edge, idx) => {
            if (!this.graph[edge.from]) {
                this.graph[edge.from] = []
            }
            this.graph[edge.from].push({
                node: edge.to,
                edge: idx,
                cost: edge.time,
            });
            if (edge.bidirection) {
                if (!this.graph[edge.to]) {
                    this.graph[edge.to] = []
                }
                this.graph[edge.to].push({
                    node: edge.from,
                    edge: idx,
                    cost: edge.time,
                });
            }
        });
        this.stop_orders = Object.keys(this.graph)
        .filter(key => !isNaN(key))
        .sort((a,b) => Number(a) - Number(b));

        const pivot_edge = edges.find(edge => !isNaN(edge.from) && !isNaN(edge.to));
        this.pivot_stop = [pivot_edge.from, pivot_edge.to];
    }
    getPath(start, end, ableCircle = false) {
        if (ableCircle && start === end) {
            let new_end = this.pivot_stop[0];
            if (start === new_end) {
                new_end = this.pivot_stop[1];
            }
            const p_1 = this.getPathFunction(start, new_end);
            const p_2 = this.getPathFunction(new_end, start);
            return {
                path: [...p_1.path, ...p_2.path],
                time: p_1.time + p_2.time
            }
        }
        else {
            return this.getPathFunction(start, end);
        }
    }
    getPathFunction(start, end) {
        const queue = [{cur: start, cost: 0}];
        const storage = {};
        storage[start] = {node: start, edge: -1};
        while(queue.length > 0) {
            const top = queue.shift();
            if (top === undefined) {
                continue;
            }
            const {cur, cost} = top;
            if (cur == end || cur === undefined) {
                continue;
            }
            for (let to of this.graph[cur]) {
                if (storage[to.node]) {
                    continue;
                }
                const new_cost = cost + to.cost;
                storage[to.node] = {node: cur, edge: to.edge, cost: new_cost};
                queue.push({cur: to.node, cost: new_cost});
            }
        }
        const result = [];
        const time = storage[end].cost;
        let cursor = end;
        while(storage[cursor].node !== cursor) {
            result.push(storage[cursor].edge)
            cursor = storage[cursor].node;
        }
        return {
            path: result.map(edge => this.path[edge]),
            time: time
        };
    }
}