import React, { Component } from 'react';
import { getInfoPage } from "../../Component/Common.js";

class InfoAirport extends Component {
    render() {
        return (<div>
            {getInfoPage("제주공항")}
        </div>
        );
    }
}
export default InfoAirport;