import React, { Children } from "react";
import { FaBus } from "react-icons/fa";
import { RiArrowRightFill } from "react-icons/ri";

const SelectStop = ({
    children,
    color="blue",
    on = false,
    arrow = false,
    busIcon = true,
    onClick,
    clickable = true,
}) => {

    return (
        <div className="direction wrapper">
            {arrow ? <RiArrowRightFill className="arrow-icon"/> : null }
            <div className={`direction ${color} ${on ? 'on' : 'off'} ${clickable ? 'clickable' : ''}`}
                onClick={onClick}
            >
                {busIcon ? <FaBus className={`bus-icon`}/> : <img src="/place.svg" />}
                <span className="text"> {Children.toArray(children)}</span>
            </div>
        </div>
    )
}

export default SelectStop;