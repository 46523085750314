import React, { useEffect, useRef, useState, createContext, useLayoutEffect, useCallback, Children } from "react";
import { toLatLng } from "./Util";

const KAKAO_SCRIPT_ID = "kakao_script"

export const KakaoMapContext = createContext({
    map: null,
    loaded: false,
    is_drag: false,
})

const KakaoMap = ({
    className = '',
    width=0, height=0,
    style = {},
    children,
    
    center,
    level = 5,
    bounds = undefined,

    blockCenterChange=false,

    onCenterChange,
    onZoomStart,
    onZoomChanged,
    onBoundsChanged,
    onClick,
    onDoubleClick,
    onRightClick,
    onMouseMove,
    onDragStart,
    onDrag,
    onDragEnd,
    onIdle,
    onTilesLoaded,
    onMapTypeIdChanged,
    onTrigger = [],
    ...props
}) => {

    const [map, setMap] = useState(null);
    const [geocoder, setGeocoder] = useState(null);
    const map_container = useRef(null);
    const [loaded, setLoaded] = useState(false);
    const [kakaoLoaded, setKakaoLoaded] = useState(false);
    const [scriptLoaded, setScriptLoaded] = useState(document.getElementById(KAKAO_SCRIPT_ID)?.loaded);

    const [currentWidth, setCurrentWidth] = useState(width);
    const [currentHeight, setCurrentHeight] = useState(height);

    const [isDrag, setisDrag] = useState(false);

    const onTriggerEvent = useCallback((map, callback) => {
        return function (data) {
            callback(data, map);
        }
    }, []);

    const onEvent = useCallback((map, func) => {
        if(map && typeof(func) === 'function') {
            return function (event) {
                func(map, event);
            }
        }
    }, []);

    useLayoutEffect(() => {
        if (!scriptLoaded) {
            console.log("create kakao script")
            const script = document.createElement("script");
            
            script.async = true;
            script.src =
            "//dapi.kakao.com/v2/maps/sdk.js?appkey=44e205bb2875a6334dd7602d2e5c004d&autoload=false&libraries=services";
            script.id = KAKAO_SCRIPT_ID;
            document.head.appendChild(script);
            script.onload = () => {
                script.loaded = true;
                setScriptLoaded(true);
                window.kakao.maps.load(() => {
                    setKakaoLoaded(true);
                });
            }
        }
        else {
            window.kakao.maps.load(() => {
                console.log("kakao.maps loaded")
                setKakaoLoaded(true);
            });
        }
    }, []);

    useLayoutEffect(() => {
        if (scriptLoaded && kakaoLoaded && map_container.current) {
            const options = {
                center: toLatLng([33.25335474788911, 126.50898282140602]),
                level: 6
            };
            const map_class = new window.kakao.maps.Map(map_container.current, options);
            map_class.relayout();
            const geocoder_class = new window.kakao.maps.services.Geocoder();
            setMap(map_class);
            setGeocoder(geocoder_class);
            setLoaded(true);
        }
    },[scriptLoaded, kakaoLoaded]);


    useLayoutEffect(() => {
        if (map && center && !blockCenterChange) {
            let latlng = toLatLng(center);
            if (!!!latlng) {
                console.error("Invalid center value");
                return;
            }
            let current_center = map.getCenter();
            const is_same = latlng.getLat() === current_center.getLat() && latlng.getLng() === current_center.getLng();
            if (!is_same) {
                map.setCenter(latlng);
            }
        }
    }, [map, center, blockCenterChange]);

    useLayoutEffect(() => {
        if (map && level > -1) {
            const options = {
                animate: undefined,
                anchor: map.getCenter(),
            }
            map.setLevel(level, options);
        }
    }, [map, level]);

    useLayoutEffect(() => {
        if (map && bounds !== undefined) {
            let latlng_bounds;
            let padding = [];
            if (Array.isArray(bounds)) {
                latlng_bounds = new window.kakao.maps.LatLngBounds(...bounds.map(point => toLatLng(point)));
            }
            else if (typeof bounds === 'object' && bounds !== null) {
                if (bounds.hasOwnProperty('bound') && !!bounds.bound && Array.isArray(bounds.bound)) {
                    latlng_bounds = new window.kakao.maps.LatLngBounds(...bounds.bound.map(point => toLatLng(point)));
                }
                else if (bounds.hasOwnProperty('ne') || bounds.hasOwnProperty('sw')) {
                    latlng_bounds = new window.kakao.maps.LatLngBounds(toLatLng(bounds.sw), toLatLng(bounds.ne));
                }
                
                if (bounds.hasOwnProperty('padding')) {
                    padding = bounds.padding;
                }
                else {
                    padding = [bounds.paddingTop, bounds.paddingRight, bounds.paddingBottom, bounds.paddingLeft];
                }
                
            }
            if (latlng_bounds) {
                map.setBounds(latlng_bounds, ...padding);
            }
        }
    }, [map, bounds]);

    useLayoutEffect(() => {
        if (!map || !onTrigger.length) {
            return;
        }

        onTrigger.forEach(trigger_obj => {
            window.kakao.maps.event.addListener(map, trigger_obj.type, onTriggerEvent(map, trigger_obj.callback));
        });
        return () => {
            onTrigger.forEach(trigger_obj => {
                window.kakao.maps.event.removeListener(map, trigger_obj.type, onTriggerEvent(map, trigger_obj.callback));
            });
        }
    }, [map, onTrigger, onTriggerEvent]);

    useEffect(() => {
        if (map_container.current && map && currentHeight && currentWidth) {
            if (map_container.current.offsetWidth === currentWidth && map_container.current.offsetHeight === currentHeight) {
                return;
            }
            else {
                map.relayout();
                setCurrentWidth(map_container.current.offsetWidth);
                setCurrentHeight(map_container.current.offsetHeight);
            }
        }
    });
    useEffect(() => {
        if (map) {
            map.relayout();
            setCurrentWidth(width);
            setCurrentHeight(height);
        }
    },[width, height]);


    useEffect(() => {
        if (map && window.kakao && typeof(onClick) === 'function') {
            console.log("click event is changed");
            const click_func = onEvent(map, onClick);
            window.kakao.maps.event.addListener(map, 'click', click_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'click', click_func);
            }
        }
        return;
    }, [map, onEvent, onClick]);
    useEffect(() => {
        if (map && window.kakao && typeof(onCenterChange) === 'function') {
            console.log("center_changed event is changed");
            const center_changed_func = onEvent(map, onCenterChange);
            window.kakao.maps.event.addListener(map, 'center_changed', center_changed_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'center_changed', center_changed_func);
            }
        }
        return;
    }, [map, onEvent, onCenterChange]);
    useEffect(() => {
        if (map && window.kakao && typeof(onZoomStart) === 'function') {
            console.log("zoom_start event is changed");
            const zoom_start_func = onEvent(map, onZoomStart);
            window.kakao.maps.event.addListener(map, 'zoom_start', zoom_start_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'zoom_start', zoom_start_func);
            }
        }
        return;
    }, [map, onEvent, onZoomStart]);
    useEffect(() => {
        if (map && window.kakao && typeof(onZoomChanged) === 'function') {
            console.log("zoom_changed event is changed");
            const zoom_changed_func = onEvent(map, onZoomChanged);
            window.kakao.maps.event.addListener(map, 'zoom_changed', zoom_changed_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'zoom_changed', zoom_changed_func);
            }
        }
        return;
    }, [map, onEvent, onZoomChanged]);
    useEffect(() => {
        if (map && window.kakao && typeof(onBoundsChanged) === 'function') {
            console.log("bounds_changed event is changed");
            const bounds_changed_func = onEvent(map, onBoundsChanged);
            window.kakao.maps.event.addListener(map, 'bounds_changed', bounds_changed_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'bounds_changed', bounds_changed_func);
            }
        }
        return;
    }, [map, onEvent, onBoundsChanged]);
    useEffect(() => {
        if (map && window.kakao && typeof(onDoubleClick) === 'function') {
            console.log("double_click event is changed");
            const double_click_func = onEvent(map, onDoubleClick);
            window.kakao.maps.event.addListener(map, 'dbclick', double_click_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'dbclick', double_click_func);
            }
        }
        return;
    }, [map, onEvent, onDoubleClick]);
    useEffect(() => {
        if (map && window.kakao && typeof(onRightClick) === 'function') {
            console.log("right_click event is changed");
            const right_click_func = onEvent(map, onRightClick);
            window.kakao.maps.event.addListener(map, 'rightclick', right_click_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'rightclick', right_click_func);
            }
        }
        return;
    }, [map, onEvent, onRightClick]);
    useEffect(() => {
        if (map && window.kakao && typeof(onMouseMove) === 'function') {
            console.log("mouse_move event is changed");
            const mouse_move_func = onEvent(map, onMouseMove);
            window.kakao.maps.event.addListener(map, 'mousemove', mouse_move_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'mousemove', mouse_move_func);
            }
        }
        return;
    }, [map, onEvent, onMouseMove]);
    useEffect(() => {
        if (map && window.kakao && typeof(onDragStart) === 'function') {
            console.log("drag_start event is changed");
            const drag_start_func = onEvent(map, onDragStart);
            window.kakao.maps.event.addListener(map, 'dragstart', drag_start_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'dragstart', drag_start_func);
            }
        }
        return;
    }, [map, onEvent, onDragStart]);
    useEffect(() => {
        if (map && window.kakao && typeof(onDrag) === 'function') {
            const drag_func = onEvent(map, onDrag);
            window.kakao.maps.event.addListener(map, 'drag', drag_func);
            return () => {
                console.log("destroy onDrag Event")
                window.kakao.maps.event.removeListener(map, 'drag', drag_func);
            }
        }
        return;
    }, [map, onEvent, onDrag]);
    useEffect(() => {
        if (map && window.kakao && typeof(onDragEnd) === 'function') {
            const drag_end_func = onEvent(map, onDragEnd);
            window.kakao.maps.event.addListener(map, 'dragend', drag_end_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'dragend', drag_end_func);
            }
        }
        return;
    }, [map, onEvent, onDragEnd]);
    useEffect(() => {
        if (map && window.kakao && typeof(onIdle) === 'function') {
            console.log("idle event is changed");
            const idle_func = onEvent(map, onIdle);
            window.kakao.maps.event.addListener(map, 'idle', idle_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'idle', idle_func);
            }
        }
        return;
    }, [map, onEvent, onIdle]);
    useEffect(() => {
        if (map && window.kakao && typeof(onTilesLoaded) === 'function') {
            console.log("tile_loaded event is changed");
            const tile_loaded_func = onEvent(map, onTilesLoaded);
            window.kakao.maps.event.addListener(map, 'tilesloaded', tile_loaded_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'tilesloaded', tile_loaded_func);
            }
        }
        return;
    }, [map, onEvent, onTilesLoaded]);
    useEffect(() => {
        if (map && window.kakao && typeof(onMapTypeIdChanged) === 'function') {
            console.log("map_type_id_changed event is changed");
            const map_type_id_changed_func = onEvent(map, onMapTypeIdChanged);
            window.kakao.maps.event.addListener(map, 'maptypeid_changed', map_type_id_changed_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'maptypeid_changed', map_type_id_changed_func);
            }
        }
        return;
    }, [map, onEvent, onMapTypeIdChanged]);
    useEffect(() => {
        if (map && window.kakao) {
            const on_drag_func = () => setisDrag(true);
            const off_drag_func = () => setisDrag(false);
            window.kakao.maps.event.addListener(map, 'dragstart', on_drag_func);
            window.kakao.maps.event.addListener(map, 'dragend', off_drag_func);
            return () => {
                window.kakao.maps.event.removeListener(map, 'dragstart', on_drag_func);
                window.kakao.maps.event.removeListener(map, 'dragend', off_drag_func);
            }
        }
    }, [map]);

    return (
        <>
        <div 
            id="map-container"
            className={className}
            ref={map_container}
            style={{
                // width,
                // height,
                ...style
            }}
        />
        <KakaoMapContext.Provider value={{map: map, loaded: loaded, is_drag: isDrag}}>
            {Children.toArray(children)}
        </KakaoMapContext.Provider>
        </>
    )

};

export default KakaoMap;