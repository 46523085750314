import React, { Children, useCallback } from "react";
import { Button } from "reactstrap";

export const UnavailablePage = ({
    history,
    url="/tamra",
    children,
    black,
    disable = false,
    ...props
}) => {
    const goMainPage = useCallback(() => {
        history.push(url);
    }, [history]);

    return <div className={`font-SUIT tamra-complete-page ${black ? 'black' : ''}`}>
        <div className="title">
            {Children.toArray(children)}
        </div>
        <div className="image-box">
            <img
                src="/ioniq_front_view.png"  
            />
            <div className="bars">
                <div className="bar bright"/>
                <div className="bar"/>
                <div className="bar bright"/>
                <div className="bar"/>
            </div>
        </div>
        {disable ? null
        :   <Button
                onClick={goMainPage}
            >
                메인 화면으로
            </Button>
        }
    </div>
}