import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Button } from "reactstrap";
import { AiOutlineLeft } from "react-icons/ai";
import { usePosition, useGetCarState, useInterval } from "../../Hooks/Hooks";
import { KakaoMap, KakaoMarker, KakaoPolyline } from "../../Component/Map/Kakao.js";
import { TamraPassengerForm } from "../Tamra/TamraPassengerForm";
import KakaoCustomOverlay from "../../Component/Map/KakaoCustomOverlay";
import LocationButton from "../../Component/LocationButton";
import SelectStop from "../../Component/SelectStop";
import { PathGraph } from "../Tamra/TamraShuttlePath";
import { Snackbar } from "@material-ui/core";

const UNDEFINED_DATE = {year: -1, month: -1, date: -1};
const UNDEFINED_TIME = {hour: -1, min: -1};
const UNDEFINED_CARS = [];

const INIT_CENTER = [33.511045030318414, 126.48122531110278];
const INIT_BOUNDS = {
    bound : [
      {lng: 127.25622227147937, lat: 36.49748574064419},
      {lng: 127.27290914557533, lat: 36.507181211338235}, 
    ],
    padding: [0, 0]
};
const CAR_MAX_PASSENGER = 4;
const CAN_SHARE_A_CAR = true;

const LOCATION = 'sangsang';
const SERVICE = 'carpool';

const SangsangShuttle = ({ history, location }) => {
    const position = usePosition(true);

    const [mode, setMode] = useState(CAN_SHARE_A_CAR ? 0 : 1);
    // 0: select passenger count
    // 1: select start stop, select end stop,
    // else: show form

    const [stops, setStops] = useState([]);
    const [stopsFlag, setStopsFlag] = useState(false);
    const [startStop, setStartStop] = useState(-1);
    const [endStop, setEndStop] = useState(-1);

    const [selectedDay, setSelectedDay] = useState(UNDEFINED_DATE);
    const [selectedTime, setSelectedTime] = useState(UNDEFINED_TIME);
    const [availableCarsCount, setAvailableCarsCount] = useState(UNDEFINED_CARS.length);

    const [center, setCenter] = useState(INIT_CENTER);
    const [bounds, setBounds] = useState(INIT_BOUNDS);

    const [shuttlePath, setShuttlePath] = useState([]);
    const [shuttleGraph, setShuttleGraph] = useState();

    const [passengerCount, setPassengerCount]= useState(1)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [carInfo, getCarState] = useGetCarState(2000, LOCATION, SERVICE);
    const carNames = Object.keys(carInfo)
    const [selectedCarNames, setSelectedCarNames] = useState([])

    const storeAction = useCallback(({mode, startStop, endStop, selectedDay, selectedTime}) => {
        history.push(history.location.pathname, {
            mode: mode,
            startStop: startStop,
            endStop: endStop,
            selectedDay: selectedDay,
            selectedTime: selectedTime,
        });
    }, [history]);
    const replaceAction = useCallback(({mode, startStop, endStop, selectedDay, selectedTime}) => {
        history.replace(history.location.pathname, {
            mode: mode,
            startStop: startStop,
            endStop: endStop,
            selectedDay: selectedDay,
            selectedTime: selectedTime,
        });
    }, [history]);

    const goBackCB = useCallback((new_location) => {
        if (!new_location.state) {
            return;
        }
        const {
            mode: _mode,
            startStop: _startStop = -1,
            endStop: _endStop = -1,
            selectedDay: _selectedDay = UNDEFINED_DATE,
            selectedTime: _selectedTime = UNDEFINED_TIME,
        } = new_location.state;

        setMode(_mode);
        setStartStop(_startStop);
        setEndStop(_endStop);
        setSelectedDay(_selectedDay);
        setSelectedDay(_selectedTime);
    }, [history, storeAction]);

    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const onClickgoBackBtn = useCallback(() => {
        if (mode === 2) {
            setMode(1)
            return
        }
        if (mode !== 1) {
            goBack();
            return;
        }
        if (CAN_SHARE_A_CAR && startStop < 0 && endStop < 0) {
            setMode(0)
            return
        }
        if (mode === 1 && startStop >= 0 ) {
            setStartStop(-1);
            setEndStop(-1);
        }
    }, [goBack, mode, startStop, endStop]);

    const getMaxAvailableSeatFromStartToEnd = useCallback((_carStops, _startStopID, _endStopID) => {
        const _startStop = _carStops.find((stop) => stop.id === _startStopID)?.order;
        const endStopOrder = _carStops.find((stop) => stop.id === _endStopID)?.order;
        console.assert(_startStop && endStopOrder, `can not found startStopOrder(${_startStop}) or endStopOrder(${endStopOrder})`)
        const includesStops = _carStops.filter((currentStop) =>
            _startStop < endStopOrder
                ? _startStop <= currentStop.order &&
                  currentStop.order < endStopOrder
                : _startStop <= currentStop.order ||
                  currentStop.order < endStopOrder
        );

        const maxAvailableSeatFromStartToEnd = Math.min(
            ...includesStops.map((stop) => stop.left_seats)
        );
        return maxAvailableSeatFromStartToEnd;
    }, []);
    
    const getEstimatedSelectedCar = useCallback(async () => {
        if (startStop === -1 || endStop === -1) {
            setSelectedCarNames((prev) => {
                if (prev.length === 0) return prev
                return []
            })
            return
        }
        const _selectedCarNames = await axios
            .get(`/api/${LOCATION}/${SERVICE}/get-estimated-selected-car`, {
                params: {
                    start_stop_id: stops[startStop].id,
                    end_stop_id: stops[endStop].id,
                    passenger_num: CAN_SHARE_A_CAR ? passengerCount : CAR_MAX_PASSENGER,
                },
            })
            .then((res) => res.data)
            .catch(() => []);
        setSelectedCarNames((prev) => {
            if (prev.length !== _selectedCarNames.length) return _selectedCarNames
            const isSame = prev.every((selectedCarName) => _selectedCarNames.includes(selectedCarName))
            if (isSame) return prev
            return _selectedCarNames
        })
        return _selectedCarNames
    }, [stops, startStop, endStop, passengerCount]);

    const onClickStopConfirm = useCallback(() => {
        if (selectedCarNames.length === 0) {
            window.alert('현재 배정 가능한 차량이 없습니다.\n새로고침 하여 다시 확인 바랍니다.')
            return;
        }
        let isNextTimeCar = false;
        const selectedCarInfo = carInfo[selectedCarNames[0]]
        const currentStopIdx = selectedCarInfo?.stops.findIndex(
            (stop) => stop.id === selectedCarInfo.current_shuttle_stop_id
        );
        const selectedStartStopIdx = selectedCarInfo?.stops.findIndex(
            (stop) => stop.id === stops[startStop].id
        );
        if (selectedStartStopIdx >= currentStopIdx) isNextTimeCar = true;
        if (isNextTimeCar && !window.confirm('예상 배정 차량은 한 바퀴를 돌고나서 탑승 가능한 차량입니다. 그래도 호출을 계속하시겠습니까?')) {
            setEndStop(-1)
            setStartStop(-1)
            return;
        }
        setMode(2);
        storeAction({
            mode: 2,
            startStop: startStop,
            endStop: endStop,
            selectedDay: selectedDay,
            selectedTime: selectedTime,
        });
        getEstimatedSelectedCar()
    }, [mode, startStop, endStop, selectedDay, selectedTime, storeAction, selectedCarNames, getEstimatedSelectedCar, stops]);

    const onClickStopMarker = useCallback(async (marker, idx) => {
        if (mode !== 1) {
            return;
        }
        let start_stop = startStop, end_stop = endStop;

        if (startStop < 0) {
            start_stop = idx;
        }
        else if (endStop < 0) {
            end_stop = idx;
        }
        else if (endStop >=0) {
            if (idx === endStop) {
                end_stop = -1;
            }
            else {
                end_stop = idx;
            }
        }
        if (start_stop > -1 && end_stop > -1) {
            getEstimatedSelectedCar()
        }
        replaceAction({
            mode: 0, startStop: start_stop, endStop: end_stop,
        });
        setStartStop(start_stop);
        setEndStop(end_stop);
    }, [mode, startStop, endStop, replaceAction, getEstimatedSelectedCar]);

    const onClickStopDescription = useCallback((isStart) => {
        if (isStart) {
            if (startStop < 0) {
                return null;
            }
            return () => {
                setStartStop(-1);
            }
        }
        else {
            if (endStop < 0) {
                return null;
            }
            return () => {
                setEndStop(-1);
            }
        }
    }, [startStop, endStop]);
    
    const onSubmit = useCallback(async (passengers, passenger_num) => {
        const start_stop = stops[startStop];
        const end_stop = stops[endStop];
        const { status, data, request } = await axios.post(`/api/${LOCATION}/${SERVICE}/call_request`,{
            passengers,
            passenger_num,
            startStop: start_stop.id,
            endStop: end_stop.id,
        }).catch((err) => ({...err.response, data: {id: undefined, carName: ''}}))

        const { id } = data
        if (id) {
            history.push({
                pathname: `/${LOCATION}/${SERVICE}/confirm`,
                search: `id=${id}&representative=true`,
            });
        } else if (status === 409) {
            const { duplicated_passengers } = JSON.parse(request.response);
            alert(`이미 동일한 서비스를 이용중입니다. 중복 이용 번호: ${JSON.stringify(duplicated_passengers)}`);
        } else if (status !== 200) { // todo: status에 따라 다른 피드백 작성
            alert("차량 현황에 변경사항이 있습니다. 새로고침 후 재호출 바랍니다.");
        }
    }, [selectedDay, selectedTime, stops, startStop, endStop, history]);

    const onClickLocationButton = useCallback((e) => {
        if (position.latitude && position.longitude) {
            setCenter(position);
        }
    }, [position]);

    const selectedText = useMemo(() => {
        if (mode === 0) {
            return <div>탑승 인원 선택 (1 ~ {CAR_MAX_PASSENGER})</div>
        }
        if (mode < 2) {
            if (startStop < 0) {
                return <div><b>출발</b>할 정류소를 선택해주세요.</div>;
            }
            return <div><b>도착</b>할 정류소를 선택해주세요.</div>;
        }
        return <div>경로 확인</div>;
    },[startStop, mode]);

    const selectStops = useMemo(() => {
        return (
            <div className="tamra-footer" >
                <div className="title">
                    <GoBackIcon 
                        onClick={onClickgoBackBtn}
                    />
                    {selectedText}
                </div>
                <ShuttleSelectStop 
                    startStop={startStop}
                    endStop={endStop}
                    stops={stops}
                    onClickStart={mode === 1 ? onClickStopDescription(true) : null}
                    onClickEnd={mode === 1 ? onClickStopDescription(false) : null}
                />
                <div className="button-line">
                    <Button 
                        className="btn"
                        onClick={onClickStopConfirm}
                        disabled={startStop < 0 || endStop < 0}
                        >
                        확인
                    </Button>
                </div>
            </div>
        )
    }, [mode, selectedText, stops, startStop, endStop, onClickStopConfirm, onClickStopDescription]);

    const stopMarkers = useMemo(() => {
        return stops.map((stop, idx) => {
            let opacity = 1
            if (startStop === -1 && stop.canStart === false) opacity = 0.5
            else if (endStop === -1 && stop.canEnd === false) opacity = 0.5
            return (
                <div key={`${stop.name}-${stop.description}`}>
                    <KakaoMarker
                        position={stop.location}
                        title={stop.description}
                        image={{
                            src: [startStop, endStop].includes(idx)
                                ? "/bus_station=focused.svg"
                                : "/bus_station=default.svg",
                            width: [startStop, endStop].includes(idx) ? 48 : 40,
                            height: [startStop, endStop].includes(idx)
                                ? 56
                                : 40,
                        }}
                        zIndex={[startStop, endStop].includes(idx) ? 10 : 1}
                        clickable={
                            startStop === -1 ? stop.canStart : stop.canEnd
                        }
                        onClick={(marker) => onClickStopMarker(marker, idx)}
                        opacity={opacity}
                    />
                </div>
            );
        });
    }, [stops, startStop, endStop, onClickStopMarker, passengerCount]);

    const locationButton = useMemo(() => {
        return <LocationButton 
            className="tamra-location-button"
            onClick={onClickLocationButton}
        />
    }, [onClickLocationButton]);

    const SelectContainer = useMemo(() => {
        switch (mode) {
            case 0: {
                return (
                    <div className="tamra-footer-wrapper bottom relative no-zoom">
                        {locationButton}
                        <div className="tamra-footer">
                            <div className="title">
                                <GoBackIcon onClick={onClickgoBackBtn} />
                                {selectedText}
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    width: "100%",
                                }}
                            >
                                <Button
                                    onClick={() =>
                                        setPassengerCount((prev) => prev - 1)
                                    }
                                    disabled={passengerCount <= 1}
                                >
                                    -
                                </Button>
                                <div
                                    style={{
                                        fontSize: "1.2rem",
                                        width: "10%",
                                        borderBottom: "1px solid black",
                                        textAlign: "center",
                                    }}
                                >
                                    {passengerCount}
                                </div>
                                <Button
                                    onClick={() =>
                                        setPassengerCount((prev) => prev + 1)
                                    }
                                    disabled={
                                        passengerCount >= CAR_MAX_PASSENGER
                                    }
                                >
                                    +
                                </Button>
                                <Button
                                    style={{ width: "50%" }}
                                    color="primary"
                                    onClick={() => setMode(1)}
                                >
                                    확인
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            }
            case 1: {
                return <div
                    className="tamra-footer-wrapper bottom relative"
                >
                    {locationButton}
                    {selectStops}
                </div>;
            }
            default: {
                return <div className="tamra-footer relative additional-padding">
                    {locationButton}
                    <div className="title">
                        <GoBackIcon 
                            onClick={onClickgoBackBtn}
                        />
                        {selectedText}
                    </div>
                    <ShuttleSelectStop 
                        startStop={startStop}
                        endStop={endStop}
                        stops={stops}
                    />
                    <div className="line-divider"/> 
                    <TamraPassengerForm 
                        startLocation={stops[startStop]}
                        endLocation={stops[endStop]}
                        reservation={false}
                        max_passenger={passengerCount}
                        initialPassengerNumber={passengerCount}
                        onSubmit={onSubmit}
                        getEstimatedSelectedCar={getEstimatedSelectedCar}
                    />
                </div>
            }
        }
    }, [mode, locationButton, selectedText, onClickgoBackBtn, passengerCount, selectStops, stops, startStop, endStop, onSubmit, getEstimatedSelectedCar]);

    useEffect(() => {
        if (!stopsFlag) return;
        if (!CAN_SHARE_A_CAR) return;
        const carsStops = carNames.map((carName) => carInfo[carName].stops);
        const setCanStart = () => {
            setStops((prev) => prev.map((departureStop) => {
                const { id } = departureStop;
                let canStart = false;
                if (endStop === -1) {
                    prev.forEach((destinationStop) => {
                        carsStops.forEach((carStops) => {
                            const carMaxAvailableSeat =  carStops.length > 0 ? getMaxAvailableSeatFromStartToEnd(carStops, id, destinationStop.id) : 0;
                            if (passengerCount <= carMaxAvailableSeat) canStart = true;
                        })
                    });
                } else {
                    carsStops.forEach((carStops) => {
                        const carMaxAvailableSeat = carStops.length > 0 ? getMaxAvailableSeatFromStartToEnd(carStops, id, stops[endStop].id) : 0;
                        if (passengerCount <= carMaxAvailableSeat) canStart = true;
                    });
                }                
                return {...departureStop, canStart, canEnd: undefined};
            }))
        }
        const setCanEnd = () => {
            setStops((prev) => prev.map((destinationStop) => {
                const { id } = destinationStop;
                let canEnd = false;
                carsStops.forEach((carStops) => {
                    const carMaxAvailableSeat = carStops.length > 0 ? getMaxAvailableSeatFromStartToEnd(carStops, prev[startStop].id, id) : 0;
                    if (passengerCount <= carMaxAvailableSeat) canEnd = true;
                });
                return {...destinationStop, canEnd, canStart: undefined};
            }))
        }
        
        if (startStop === -1) setCanStart();
        else if (startStop !== -1 && endStop === -1) setCanEnd();
    }, [carInfo, passengerCount, startStop, endStop, availableCarsCount, getMaxAvailableSeatFromStartToEnd, stopsFlag]);
    
    useEffect(() => {
        async function wrapper() {
            const [{value: shuttle_stops}, {value: shuttle_path}] = await Promise.allSettled([
                axios.get(`/api/${LOCATION}/${SERVICE}/get-stops`)
                .then(res => res.data).catch(err => {
                    console.error(err);
                    return [];
                }),
                // TODO
                axios.get(`/api/${LOCATION}/${SERVICE}/get-path`)
                .then(({data}) => data).catch(err => {
                    console.error(err);
                    return [];
                }),
            ]);

            setStops(shuttle_stops);
            setStopsFlag(shuttle_stops.length > 0);
            if (shuttle_path.length > 0){
                const path_edges_info = shuttle_path.map(path => {
                    const {
                        from, to, bidirection, time
                    } = path.json_data;
                    return {
                        path: path.geometry.flat(),
                        from, to, bidirection, time
                    }
                });
                
                const graph = new PathGraph(path_edges_info);
                setShuttleGraph(graph);
                setShuttlePath(graph.path);
            } else {
                alert("경로를 불러오는데 실패했습니다.");
            }
        }
        wrapper();
    }, []);

    useInterval(getEstimatedSelectedCar, 5 * 1000)

    useEffect(() => {
        let _availableCarsCount = carNames.filter((carName) => carInfo[carName].call_available).length;
        setAvailableCarsCount((prev) => {
            if (prev === _availableCarsCount) return prev;
            return _availableCarsCount;
        })
    }, [carNames]);

    useEffect(() => {
        const unlisten = history.listen((new_location, action) => {
            if (action === 'POP') {
                goBackCB(new_location);
            }
        });
        return () => {
            unlisten();
        }
    }, [history, goBackCB]);

    useEffect(() => {
        if (shuttleGraph) {
            if (!(startStop < 0 || endStop < 0)) {
                const {
                    path, time
                } = shuttleGraph.getPath(stops[startStop].order, stops[endStop].order, true);
                setShuttlePath(path);
            }
            else {
                setShuttlePath(shuttleGraph.path);
            }
        }
    }, [stops, startStop, endStop, shuttleGraph]);

    useEffect(() => {
        if (mode > 1) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }, [mode]);

    useEffect(() => {
        if (selectedCarNames.length) {
            setSnackbarMessage('예상 배정 차량이 재설정 되었습니다.');
        } else if (startStop !== -1 && endStop !== -1) {
            window.alert('현재 배정 가능한 차량이 없습니다. 잠시 후 다시 호출해주세요.');
            setStartStop(-1);
            setEndStop(-1);
            setMode(0);
        }
    }, [selectedCarNames]);

    useEffect(() => {
        getEstimatedSelectedCar();
    }, [startStop, endStop]);

    const backdrop = <div style={{width: '100%', height: '100%', backgroundColor: 'black', opacity: 0.4, position: 'absolute', zIndex: 10}}></div>

    return <div className="font-SUIT tamra-kakao-map-wrapper flex">
        <KakaoMap
            className="tamra-kakao-map"
            center={center}
            bounds={bounds}
        >
            {mode === 0 && backdrop}
            {mode !== 2 ? 
            <>
                {stopMarkers}
            </>
            : <>
                <KakaoMarker 
                    position={stops[startStop]?.location}
                    image={{
                        src: startStop === endStop
                            ? '/get_on_off=blue.svg'
                            : '/get_on=blue.svg',
                        width: 48,
                        height: 48,
                        options: {
                            offset: [24, 40]
                        }
                    }}
                />
                {startStop !== endStop &&<KakaoMarker 
                    position={stops[endStop]?.location}
                    image={{
                        src: '/get_off=blue.svg',
                        width: 48,
                        height: 48,
                        options: {
                            offset: [24, 40]
                        }
                    }}
                />}
            </>}
            <KakaoCustomOverlay
                   position={position}
                   contentID="mylocation"
            >
                <img
                    src="/mylocation=direction.svg" 
                    style={{transform: `rotate(${90 - (position.heading ?? 90)}deg)`}}    
                />
            </KakaoCustomOverlay>
            {carNames.map((carName) => 
                carInfo[carName].stops.find((stop) => stop.left_seats >= passengerCount) &&
                    <KakaoCustomOverlay
                        key={carName}
                        position={carInfo[carName].car_location}
                        contentID="carIcon"
                    >
                        <img
                            src="/car_icon=mini.png"
                            style={{
                                transform: `
                                    ${selectedCarNames.includes(carName) ? 'scale(1.5)' : ''}
                                    rotate(${180 - (carInfo[carName].car_heading ?? 90)}deg)
                                `,
                                opacity: selectedCarNames.length > 0 && !selectedCarNames.includes(carName) ? 0.6 : 1,
                            }}    
                        />
                    </KakaoCustomOverlay>
            )}
            <KakaoPolyline
                path={shuttlePath}
                strokeColor="#1261FB"
                strokeWeight={5}
            />
        </KakaoMap>
        {SelectContainer}
        <Snackbar
            open={!!snackbarMessage}
            autoHideDuration={5000}
            onClose={()=> setSnackbarMessage('')}
            message={snackbarMessage}
            action={<Button onClick={() => setSnackbarMessage('')}>닫기</Button>}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        />
    </div>
};

export default withRouter(SangsangShuttle);

const ShuttleSelectStop = ({
    startStop,
    endStop,
    stops = [],
    onClickStart,
    onClickEnd
}) => {

    return (
        <>
            <SelectStop
                on={startStop >= 0}
                onClick={onClickStart}
            >
                {startStop < 0 ? "출발할 정류소" : stops[startStop]?.description}
            </SelectStop>
            <SelectStop
                on={endStop >= 0}
                arrow={true}
                onClick={onClickEnd}
            >
                {endStop < 0 ? "도착할 정류소" : stops[endStop]?.description}
            </SelectStop>
        </>
    )
}

const GoBackIcon = ({
    onClick= ()=>{}
}) => {
    return <AiOutlineLeft 
        className="tamra-go-back-icon"
        onClick={onClick}
    />
}