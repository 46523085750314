import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getSeogwipoBg, getSeogwipoName } from "./SeogwipoCommon.js";
import { IoIosArrowForward } from 'react-icons/io';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/SeogwipoMain.css';
import ToastMsg from '../../Component/ToastMsg.js';

const NOTIFY_TOAST_ID = 'notify-toast';
class SeogwipoMain extends Component {


    componentDidMount() {
        const shown_date = new Date(localStorage.getItem(NOTIFY_TOAST_ID));
        if (new Date().getDate() !== shown_date.getDate()) { // 1day
            }
            toast.info(<ToastMsg >
                본 서비스는 <b>2022/12/31</b> 부로 <b>종료</b> 예정입니다.
                <br/>
                라이드플럭스의 다른 서비스에도 많은 관심과 이용 부탁드립니다.
                <br/>
                감사합니다.
            </ToastMsg>, {
                onClose: () => {
                    localStorage.setItem(NOTIFY_TOAST_ID, new Date().toLocaleDateString());
                }
            });
    }
    
    render() {
        return (
            <>
            <ToastContainer
                style={{minWidth: 'fit-content'}}
                position='top-right'
                hideProgressBar={true}
                autoClose={5000}
                pauseOnFocusLoss
            />
            <div className="main flex">
                {getSeogwipoBg()}
                <div className="Seogwipo-Main-top-wrapper">
                    <div className="Seogwipo-Main-box">
                        <div className="Seogwipo-logo-box">
                            <img className="RF-Logo" src="/RF_LOGO_BLUE.png" alt="/RF_LOGO_WHITE.png"/>
                            <img id="Logo-Cross"src="/logo_cross.svg" alt="/favico.ico"/>
                            <img className="JDC-Logo" src="/jdc_logo.png" alt="/jdc_logo.svg"/>
                        </div>
                        <br />
                        {getSeogwipoName()}
                        <br />
                        <div className="service-introduction" onClick={()=> window.open("https://bit.ly/rideflux_seogwipo",'_blank')}>
                            서비스 소개 
                            <IoIosArrowForward className="arrow-forward" />
                        </div>
                    </div>
                </div>

                <div className="Seogwipo-Main-round-box">
                    <div className="register-box-wrapper">
                        <div className="register-info">
                            서비스 이용을 위해 사전 탑승 정보 등록이 필요합니다. 
                            <br/>
                            (최초 1회 진행)
                        </div>
                        <div className="register-box" onClick={()=> this.props.history.push("seogwipo/register")}>
                            탑승 정보 등록하기
                            <IoIosArrowForward className="arrow-forward register-icon" />
                        </div>
                    </div>
                    <div className= "Seogwipo-Main-service" id="shuttle"
                        onClick={(e)=>this.props.history.push("seogwipo/shuttle")}
                        >
                        <div className ="text">
                        고정노선형 <br />운행 차량 확인하기 
                        </div>
                        <img src="/pacifica_front_view.png" alt="/favico.ico"/>
                    </div>
                    <div className= "Seogwipo-Main-service" id="/floating"
                        onClick={(e)=>this.props.history.push("seogwipo/floating")}
                        >
                        <div className ="text">
                        자유노선형 (구역형)<br />차량 호출하기 
                        </div>
                        <img src="/ioniq_front_view.png" alt="/favico.ico"/>
                    </div>
                    <div className="Rideflux-Inc-bottom">
                            © RideFlux Inc.
                    </div>
                </div>
                <div style={{bottom:0}}/>
            </div>
            </>
        );
    }
}
export default withRouter(SeogwipoMain);
