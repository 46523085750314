import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { 
    Form, FormGroup, Input, Modal, Label, Button, 
    ModalBody, ModalFooter, ModalHeader,
    FormText, CustomInput, Collapse
} from 'reactstrap'
import axios from "axios";
import { getSeogwipoBg } from "./SeogwipoCommon.js"
import { agreementShortText, agreementText, isUnderAge } from "../../Component/Common.js"
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';

const translateToKR = {
    name: "이름",
    birthDate: "생년월일",
    phoneNumber: "휴대번호",
    address: "거주 지역",
    purpose: "탑승 목적",
    howToKnow: "유입 경로",
    isRelated: "관련 업종 종사 여부",
    relatedJob: "직장명",
}
const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
const vw = window.innerWidth / 100;
const date_regex = new RegExp("[0-9]{4}-[0-9]{2}-[0-9]{2}");

const SeogwipoRegister = props => {
    const {
        history,
    } = props;
    const initial_personal_info = {
        name: {necessary: true, value: undefined},
        birthDate: {necessary: true, value: undefined},
        phoneNumber: {necessary: true, value: undefined},
        address: {necessary: true, value: undefined},
        purpose: {necessary: true, value: {}},
        howToKnow: {necessary: false, value: undefined},
        isRelated: {necessary: true, value: undefined},
        relatedJob: {necessary: true, value: undefined},
    };

    const [submitBtn, setSubmtBtn] = useState(false);
    const [invalidReason, setInvalidReason] = useState([]);
    const [personalInfo, setPersonalInfo] = useState(initial_personal_info);
    const [agreementList, setAgreementList] = useState([false, false]);

    const submitForm = useCallback(async () => {
        if (!!invalidReason.length) {
            alert(`[${invalidReason.map(reason => translateToKR[reason]).join(', ')}] 란을 채워주시기 바랍니다.`);
            return;
        }
        if (!submitBtn) {
            alert("동의서를 확인해주세요");
            return;
        }
        const _personal_info = personalInfo;
        const register_result = await axios.post("/api/seogwipo/user/register-user",{
            name: _personal_info.name.value,
            phone: _personal_info.phoneNumber.value,
            birthdate: _personal_info.birthDate.value,
            address: _personal_info.address.value,
            purpose: Object.values(_personal_info.purpose.value).join(', '),
            related_job: _personal_info.relatedJob.value,
            know_path: _personal_info.howToKnow.value,
        }).then(res => {
            return res.data;
        }).catch(err => {
            console.error(err);
            alert("제출에 실패했습니다.");
            return {
                success: false,
            };
        });
        if (register_result.success) {
            alert("등록되었습니다.");
            history.push("/seogwipo");
        }
        else {
            console.error(register_result.msg);
            const error_num = register_result.msg[0];
            if (error_num === 1062) {
                alert("이미 등록된 고객(휴대번호)입니다.\n추가 탑승 동의 없이 서비스를 이용하실 수 있습니다.");
            }
        }
    },[history, invalidReason, personalInfo, submitBtn]);
    useEffect(() => {
        let agreement = true;
        for (const agree of agreementList) {
            if (!agree) {
                agreement = false;
                break;
            }            
        }
        setSubmtBtn(agreement);
    },[agreementList]);
    useEffect(() => {
        let invalid_reason = [];
        for (const key in personalInfo) {
            const {
                necessary, value
            } = personalInfo[key];
            if (necessary) {
                if (Array.isArray(value)) {
                    if (!!!value.length)
                        invalid_reason.push(key);
                }
                else if (typeof(value) === 'object' && !!value) {
                    if (!!!Object.keys(value).length)
                        invalid_reason.push(key);
                }
                else {
                    if (!!!value)
                        invalid_reason.push(key);
                }
            }
        }
        setInvalidReason(invalid_reason);
    },[personalInfo]);

    return <div className="main" >
        {getSeogwipoBg()}
        <div style={{
            position:'relative', width:"640px", maxWidth: "90vw", margin:"auto"
        }}>
            <div style={{padding: "1rem 16px 0.5rem", marginTop:"4rem", marginBottom:"1rem"}}>
                <div className="Seogwipo-logo-box passenger" style={{marginBottom:"1.5rem"}}>
                    <img className="RF-Logo" src="/RF_LOGO_BLUE.png" alt="/RF_LOGO_WHITE.png"/>
                </div>
                <div style={{fontSize: `${Math.max(Math.min(1.5 * rem, 5*vw), 16)}px`,fontWeight: "bold", wordBreak:"keep-all"}}> 서귀포 혁신도시 자율주행 서비스 탑승 정보 등록 신청 </div>
            </div>
            <div style={{backgroundColor:'white',borderRadius: "16px 16px 0 0", padding:"1rem 0 2rem 0"}}>
                <p  style={{padding: "1rem 1.5rem 0.5rem", fontWeight:500, marginBottom:0}}>탑승객 정보 입력하기 </p>
                <PersonalInfo 
                    personalInfo={personalInfo}
                    setPersonalInfo={setPersonalInfo}
                />
                <div className="line-divider"/>
                <Agreement 
                    agreementList={agreementList}
                    setAgreementList={setAgreementList}
                />
                <div>
                    <Button className="press-btn Seogwipo"
                        onClick={submitForm}
                        id = {submitBtn && !!!invalidReason.length ? "able" : "disable"}
                    >
                        제출하기
                    </Button>
                </div>
            </div>
        </div>
    </div>
}

const CheckIcon = (props) => {
    const {
        clicked
    } = props;
    return clicked ? 
        <AiFillCheckCircle className= "checkbox fill"/> : 
        <AiOutlineCheckCircle className="checkbox"/>
}
const Agreement = props => {
    const {
        agreementList, setAgreementList
    } = props
    const [openModal, setOpenModal] = useState(false);
    
    const agreement_btn_texts = ["(필수) 동의사항을 숙지하였으며 이에 동의합니다.", "(필수) 개인정보 수집 및 이용에 동의합니다."];

    const agreementBtnOnClick = useCallback((idx) => {
        setAgreementList(list => {
            list[idx] = !list[idx]
            return [...list]
        });
    },[setAgreementList]);

    return <>
        <h6 style={{wordBreak:"keep-all", marginTop: "1.75rem",textAlign: "center", paddingInline:`${Math.min(5*vw, 30)}px`}}>
            자율주행 서비스 이용약관 <br/>/ 개인정보 수집∙이용 동의서
        </h6>
        <div className="consent-form jumbotron" style={{fontSize:"12px", marginTop:"20px"}}>
            {agreementShortText}
            <Button 
                className="btn-consent-form" 
                style={{marginBottom: "0.5rem"}}
                onClick={() => setOpenModal(open => !open)}>
                동의서 전문 확인하기
            </Button>
        </div>
        <div className="agree-box" style={{marginTop: "0.875rem"}}>
            {agreement_btn_texts.map((text, idx) => {
                return <Button 
                    style={{lineHeight:2}}
                    id="agree-btn" 
                    key={`agree-btn-text-${idx}`} 
                    onClick={() => agreementBtnOnClick(idx)}
                >
                    <CheckIcon clicked={agreementList[idx]}/>
                    <span className={agreementList[idx] ? "on": ""}>
                        {text}
                    </span>
                </Button>
            })}
        </div>
        <Modal isOpen={openModal} toggle={() => setOpenModal(open => !open)}>
            <ModalHeader toggle={() => setOpenModal(open => !open)}>동의서 전문</ModalHeader>
            <ModalBody>{agreementText}</ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => setOpenModal(open => !open)}>확인</Button>
            </ModalFooter>
        </Modal>
    </>
}

const PersonalInfo = props => {
    const {
        personalInfo,
        setPersonalInfo,
    } = props;

    const [isRelated, setIsRelated] = useState(undefined);
    const [underAge, setUnderAge] = useState(null);
    const purposeList = ["자율주행 체험", "일상 이동", "관광 중 탑승", "업무 관련"];

    const changeRelatedJob = useCallback((e) => {
        const value = e.target.value;
        setPersonalInfo(info => {
            let new_data = {...info};
            new_data.relatedJob.value = value
            return new_data;
        });
    },[setPersonalInfo]);

    useEffect(() => {
        setPersonalInfo(info => {
            let new_data = {...info};
            new_data.relatedJob.necessary = isRelated === 'on';
            new_data.isRelated.value = isRelated;
            return new_data;
        });
    },[isRelated, setPersonalInfo]);

    return <Form style={{padding: "1rem 1.5rem 0.5rem"}}>
        <CustumFormInput
            labelTxt="이름"
            type="text"
            necessary={personalInfo.name.necessary}
            placeHolder="홍길동"
            onBlur={(e) => {
                const value = e.target.value;
                setPersonalInfo(info => {
                    let new_data = {...info};
                    new_data.name.value = value
                    return new_data;
                });
            }}
        />
        <CustumFormInput
            type="tel"
            labelTxt="생년월일"
            format="####-##-##"
            necessary={personalInfo.birthDate.necessary}
            placeHolder="1990-01-01"
            helpText={!!underAge ? underAge: null }
            error={!!underAge}
            onChange={(e) => {
                let value = e.target.value;
                let error = null;
                try {
                    if (!date_regex.test(value) || isNaN(new Date(value).getDay())) {
                        throw Error("잘못된 날짜 입니다. 다시 입력해주시기 바랍니다.")
                    }
                    if (isUnderAge(value)) {
                        throw Error("미성년자는 법적대리인의 서면 동의가 필요합니다.");
                    }
                }
                catch (err) {
                    value = undefined;
                    error = err.message;
                }
                finally {
                    setUnderAge(error);
                    setPersonalInfo(info => {
                        let new_data = {...info};
                        new_data.birthDate.value = value
                        return new_data;
                    });
                }
            }}
        />
        <CustumFormInput
            type="tel"
            labelTxt="휴대전화"
            necessary={personalInfo.phoneNumber.necessary}
            format="###-####-####"
            placeHolder="010-1234-5678"
            onBlur={(e) => {
                const value = e.target.value;
                setPersonalInfo(info => {
                    let new_data = {...info};
                    new_data.phoneNumber.value = value;
                    return new_data;
                });
            }}
        />
        <CustumFormInput
            type="text"
            labelTxt="거주 지역"
            helpLabelText="시 단위까지만 작성해주세요"
            placeHolder="서귀포시"
            necessary={personalInfo.address.necessary}
            onBlur={(e) => {
                const value = e.target.value;
                setPersonalInfo(info => {
                    let new_data = {...info};
                    new_data.address.value = value;
                    return new_data;
                });
            }}
        />
        <PurposeInput
            purposeList={purposeList}
            personalInfo={personalInfo}
            setPersonalInfo={setPersonalInfo}
        />
        <CustumFormInput
            type="text"
            labelTxt="서비스를 알게 된 경로"
            placeHolder="내 답변"
            onBlur={(e) => {
                const value = e.target.value;
                setPersonalInfo(info => {
                    let new_data = {...info};
                    new_data.howToKnow.value = value;
                    return new_data;
                });
            }}
        />
        <FormGroup>
            <Label for="isRelated">
                자율주행 관련 업종에 종사하십니까?{personalInfo.purpose.necessary ? <span style={{color:'red'}}>*</span> : null}
            </Label>
            <div style={{marginBottom:"1rem"}} onChange={(e) => setIsRelated(e.target.value)}>
                <CustomInput  type="radio" id="isRelatedRadio1" name="isRelated" value={'on'} >
                    <Label style={{fontSize:"0.9rem", verticalAlign:"middle"}}>
                        Yes
                    </Label>
                </CustomInput>
                <CustomInput  type="radio" id="isRelatedRadio2" name="isRelated" value={'off'} >
                    <Label style={{fontSize:"0.9rem", verticalAlign:"middle"}}>
                        No
                    </Label>
                </CustomInput>
            </div>
            <Collapse isOpen={isRelated === 'on'}>
                <CustumFormInput
                    type="text"
                    labelTxt="직장명"
                    necessary={true}
                    placeHolder="RideFlux"
                    onChange={changeRelatedJob}
                />
            </Collapse>
        </FormGroup>
    </Form>
}

const PurposeInput = props => {
    const {
        purposeList, personalInfo, setPersonalInfo,
        helpText
    } = props;

    const [selectedList, setSelectedList] = useState({});
    const [customPurpose, setCustomPurpose] = useState("");
    const [customPurposeCheck, setCustomPurposeCheck] = useState(false);

    const changePurpose = useCallback((e) => {
        const key = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        setSelectedList(old => {
            let new_data = {...old};
            if (checked) {
                new_data[key] = value;
            }
            else {
                if (!!new_data[key])
                    delete new_data[key];
            }
            return new_data;
        });
    },[setSelectedList]);

    useEffect(() => {
        setPersonalInfo((old) => {
            let new_data = {...old};
            new_data.purpose.value = {
                ...selectedList,
            }
            if (customPurposeCheck) {
                new_data.purpose.value["Other"] = customPurpose;
            }
            return new_data;
        })
    },[setPersonalInfo, selectedList, customPurposeCheck, customPurpose]);

    return <>
        <FormGroup style={{marginBottom:"0.5rem"}} onChange={changePurpose}>
            <Label>
                탑승 목적 {personalInfo.purpose.necessary ? <span style={{color:'red'}}>*</span> : null}
                {!!helpText ? <FormText>{helpText}</FormText> : null}
            </Label>
            {purposeList.map((text, idx) => 
                <CustomInput 
                    style={{}}
                    type="checkbox"
                    key={`purpose-check-box-${idx}`}
                    id={`purpose-check-box-${idx}`}
                    value={text}
                    name={text}
                    label={<span style={{fontSize:"0.9rem", verticalAlign:"super"}}>{text}</span>}
                />
            )}
            <CustomInput 
                type="checkbox"
                key={`purpose-check-box-other`}
                id={`purpose-check-box-other`}
                onChange={(e)=>{
                    e.stopPropagation();
                    setCustomPurposeCheck(e.target.checked);
                }}
                label={<span style={{fontSize:"0.9rem", verticalAlign:"super"}}>{"Other"}</span>}
            />
            <Collapse isOpen={customPurposeCheck}>
                <TextField 
                    className='form-control'
                    name={"Other"} 
                    onChange={(e) => {
                        e.stopPropagation();
                        setCustomPurpose(e.target.value);
                    }}
                    disabled={!customPurposeCheck}
                    checked={customPurpose}
                    placeholder="내 답변"
                />
            </Collapse>
        </FormGroup>
    </>
}

const CustumFormInput = props => {
    const {
        labelTxt, id, onBlur, format, type, style,
        onChange, necessary, helpText, helpLabelText, placeHolder,
        error,
    } = props;
    return <FormGroup style={style}>
        <Label for={id}>
            {labelTxt} {necessary ? <span style={{color:'red'}}>*</span> : null}
            {!!helpLabelText ? <FormText>{helpLabelText}</FormText> : null}
        </Label>
        {type === 'tel' || type === 'number' ? 
        <NumberFormat 
            className='form-control'
            customInput={TextField}
            format={format}
            helperText={helpText}
            error={error}
            required={necessary}
            placeholder={placeHolder}
            id={id}
            type={type}
            name={labelTxt}
            onChange={onChange}
            onBlur={onBlur}
        />
        : <TextField 
            className='form-control'
            id={id}
            name={labelTxt}
            placeholder={placeHolder}
            onBlur={onBlur}
            onChange={onChange}
        />
        }
    </FormGroup>
}

export default withRouter(SeogwipoRegister);
