import React from 'react';
import { Jumbotron, Button } from 'reactstrap';
// import { BsExclamationCircleFill } from "react-icons/bs";
import { IoIosArrowRoundBack, IoIosAlert } from "react-icons/io"
import { MdGpsFixed } from "react-icons/md"
import { Divider } from '@material-ui/core';
import '../../style/SeogwipoCommon.css';

export function deg2rad(deg) {
  return deg * (Math.PI/180)
}

export function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  let R = 6371;
  let dLat = deg2rad(lat2-lat1);
  let dLon = deg2rad(lon2-lon1); 
  let a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  return R * c;
}

export const shuttle_service_txt = [
    // "운영시간",<br />,
    "오전 10시 30분 - 오후 1시 00분", <br />,
    "오후 2시 30분 - 오후 6시 30분", <br />];

export const floating_service_txt = [
    // "운영시간",<br />,
    "오전 10시 30분 - 오후 1시 00분", <br />,
    "오후 2시 30분 - 오후 6시 30분", <br />];

const holiday_info_txt = "※ 매월 첫째 주 금요일은 정기 휴무일입니다.";

export const validServiceArea =
    [[33.69507963262744, 126.06791671282751],
    [33.751671814009185, 127.04539260650579],
    [32.94841847375115, 127.07235510774858],
    [32.926373100734516, 126.05142884260424],
    [33.69507963262744, 126.06791671282751]
];

export const validReservationArea = [
    [33.26203528309774, 126.49813197346377],
    [33.26203528309774, 126.52234622047537],
    [33.24639865070796, 126.52234622047537],
    [33.24639865070796, 126.49813197346377],
];

export const floatingArea = [
    [33.24980899352796, 126.49940415407679],
    [33.25282538411124, 126.49951572792345],
    [33.25323110673597, 126.499513414926],
    [33.25352893892997, 126.49958683519186],
    [33.253736740015626, 126.49969296266708],
    [33.253980777881395, 126.49984180908245],
    [33.25427507254177, 126.5001567046798],
    [33.25453319441488, 126.50044497997472],
    [33.25599529219714, 126.50306050544823],
    [33.25617294849221, 126.5035209541792],
    [33.25624623342021, 126.5038102904167],
    [33.25634774127907, 126.50552676011034],
    [33.25596610048812, 126.5070527795527],
    [33.2563726527194, 126.50725976122354],
    [33.25658538047533, 126.50747319813888],
    [33.25673076352051, 126.50775676900597],
    [33.25726185545982, 126.50981963066586],
    [33.25728547401879, 126.51009315558608],
    [33.25727730332932, 126.5103078339701],
    [33.25722409417328, 126.5105334951037],
    [33.2571211906785, 126.51073260389768],
    [33.25581866786635, 126.51196325054798],
    [33.25559861622731, 126.51217910266605],
    [33.255432197722314, 126.5122766102178],
    [33.25526995050728, 126.51228824306527],
    [33.25511210610679, 126.51227302296587],
    [33.254719591254755, 126.5121947188151],
    [33.25467912415724, 126.512221771991],
    [33.2544709888822, 126.51317801397535],
    [33.25521093701842, 126.51333488127419],
    [33.25705185308812, 126.51374320776962],
    [33.257070116013765, 126.51380213048638],
    [33.25714736091709, 126.51395731119335],
    [33.257332442042035, 126.51402067623171],
    [33.25839306724146, 126.5143313912733],
    [33.2584474989452, 126.51441694436528],
    [33.25833238821185, 126.51727223245281],
    [33.25832399692913, 126.51743325517666],
    [33.2582974900216, 126.51757291389931],
    [33.258011991592205, 126.51835253378363],
    [33.25803966321098, 126.51851335800119],
    [33.25804502287762, 126.51873332923513],
    [33.25795563031245, 126.51893235633679],
    [33.25778916497458, 126.51901912234874],
    [33.257550093624786, 126.51898287151808],
    [33.25745052236763, 126.51888146635305],
    [33.25709924819291, 126.51897461054936],
    [33.25613182446064, 126.51944673246437],
    [33.25507392712908, 126.51983885332372],
    [33.2544205511032, 126.51991754619891],
    [33.254172960886095, 126.52001011597198],
    [33.253965632360654, 126.52002198081668],
    [33.253848299002215, 126.51999042865931],
    [33.25376688498804, 126.51992112091412],
    [33.25225945802288, 126.51947866083623],
    [33.251283936926406, 126.51902257085935],
    [33.250664217215856, 126.51847869320825],
    [33.25061433788225, 126.51840385095848],
    [33.250384406872755, 126.51839974678025],
    [33.250171759911716, 126.51820239384837],
    [33.25010789887966, 126.5180095904651],
    [33.24965599211935, 126.51772770736723],
    [33.24919016500996, 126.51734396242772],
    [33.24826921177668, 126.5170110127337],
    [33.24747049595746, 126.51681152763716],
    [33.247723355198914, 126.5145996584323],
    [33.2478425527173, 126.51280164277804],
    [33.24834393703001, 126.51075469143133],
    [33.249540279119564, 126.50661670883503],
    [33.249766052806685, 126.50218365400526],
    [33.24980899352796, 126.49940415407679]
];
const floatingValidPathLine = [
    //가로선
    [[33.25016864500309, 126.49971597832926],
    [33.25267549842992, 126.49979827297618],
    [33.25301808709014, 126.49979095546232],
    [33.25322554276071, 126.4998112356928],
    [33.253399263730934, 126.49985048773969],
    [33.25352792597199, 126.49989536221366],
    [33.253667976927396, 126.49996968271965],
    [33.25377419604936, 126.50003883053262],
    [33.25388267993108, 126.5001106484195],
    [33.253973120872935, 126.50017988640333],
    [33.25405688597894, 126.50027062501069],
    [33.254133835082165, 126.50034798852371],
    [33.25422895635247, 126.50046012535238],
    [33.25432642850146, 126.50059639441092],
    [33.25441938170276, 126.50073000665182],
    [33.2545147287536, 126.50089848227377],
    [33.255586350419264, 126.5028213633975],
    [33.255754380618775, 126.50312894083552],
    [33.25586123025395, 126.50335637958216],
    [33.25597758811628, 126.50370717708546],
    [33.25602809702899, 126.50393761838392],
    [33.25604993434343, 126.50432651130959],
    [33.25606715555789, 126.50468860176468],
    [33.256081993564, 126.50501851133893],
    [33.25610180821948, 126.50546643940149],
    [33.25609522753782, 126.5055120853144],
    [33.25565337706005, 126.5072020929769],
    [33.25562892305745, 126.5072880817444],
    [33.25595393716534, 126.50739625506804],
    [33.2560623212263, 126.50744393853842],
    [33.256164006970685, 126.50750775695914],
    [33.25626806355481, 126.50760107388975],
    [33.25637219441498, 126.5077131707958],
    [33.25645383810346, 126.50783880847345],
    [33.256508433471666, 126.50796459800209],
    [33.25672362015479, 126.50880045425674],
    [33.25699619873343, 126.5099016012352],
    [33.256996991589475, 126.51010281439518],
    [33.25695056597677, 126.5103338028844],
    [33.25689490210767, 126.51050850178292],
    [33.25627516798771, 126.51111560551509],
    [33.25547572020394, 126.51187930586495],
    [33.255379039618475, 126.51194154906206],
    [33.25527325870317, 126.51198237992058],
    [33.255162843403845, 126.51199104229616],
    [33.25504781478491, 126.51197290187527],
    [33.254494801087546, 126.51177744723336],
    [33.2544725979127, 126.51186342109473],
    [33.25429727695318, 126.51256461173273],
    [33.254104339820145, 126.5133732097987],
    [33.254458535692535, 126.5134517310939],
    [33.2550518883498, 126.51358795020293],
    [33.25513986852922, 126.51360624261447],
    [33.255631527864765, 126.51367595558777],
    [33.25698063698429, 126.51397701469584],
    [33.25703073911013, 126.514108199518],
    [33.25719574362015, 126.5142253340733],
    [33.257247669876655, 126.51424650997672],
    [33.258211068028395, 126.51447995996541],
    [33.2581279230869, 126.51628067033647],
    [33.25807826723709, 126.51742387443049],
    [33.25805840789427, 126.51753398394342],
    [33.257721576390765, 126.51844534683063]],

    [[33.25752608958311, 126.51860471152129],
    [33.25701702387537, 126.51869603921595],
    [33.255835749354524, 126.5192417725529],
    [33.255734515495604, 126.519293301406],
    [33.255610731039695, 126.51934227075623],
    [33.25547118915469, 126.51939669195053],
    [33.255196520044855, 126.51948136401892],
    [33.25491724953615, 126.51954191517783],
    [33.254482368635315, 126.51958185489896],
    [33.254193895417124, 126.51959416486666]],

    [[33.25383550607683, 126.51959612611431],
    [33.25322868723779, 126.51947335577404],
    [33.25301434788411, 126.51942087336063],
    [33.25278644290172, 126.51935773436912],
    [33.252163482585495, 126.51914115902362],
    [33.251930944904046, 126.5190458537469],
    [33.251759296859845, 126.518958263565],
    [33.251510731773244, 126.51879866139504],
    [33.251316397981405, 126.51867363826248],
    [33.251130955520416, 126.51851637424248],
    [33.25084131871041, 126.51822822947143],
    [33.25062403868221, 126.51799870888544]],

    [[33.25031880977726, 126.51775894169128],
    [33.24981031252099, 126.51741566852358],
    [33.2497719004624, 126.51739173551536],
    [33.24966321808215, 126.5172662470226],
    [33.24941464040236, 126.51710397079178],
    [33.24911203891892, 126.51696077131851],
    [33.248809458109, 126.51682293801035],
    [33.24847082321233, 126.51668798673698],
    [33.2479834018424, 126.51654849042271],
    [33.2478480562304, 126.51652240938446],
    [33.248123356377704, 126.51370949647276],
    [33.24819446369236, 126.51287212127144],
    [33.24821845921823, 126.51266810801759],
    [33.248275928181364, 126.5123780644756],
    [33.24892505645196, 126.51007274185015],
    [33.24952568894023, 126.50805470322332],
    [33.24979666396558, 126.50703913166038],
    [33.24987642580102, 126.50668993504038],
    [33.24992240361512, 126.50578024672308],
    [33.25006783331486, 126.5026809229484],
    [33.2501568252236, 126.50013991077267],
    [33.25016864500309, 126.49971597832926]],

    [[33.25266874717579, 126.49980099420948],
    [33.252530116740814, 126.50345570764458],
    [33.25248949802568, 126.50457464931088],
    [33.252487981290436, 126.50475976848132],
    [33.25246374255773, 126.50489940882544],
    [33.25216852412063, 126.50604929190885],
    [33.25103213794861, 126.5105250945407],
    [33.25052842008278, 126.5125506613716],
    [33.250297784281464, 126.51409449257707],
    [33.25028086280599, 126.5143789520622]],

    [[33.25027577602371, 126.51480821163382],
    [33.250263256355304, 126.51506581964962],
    [33.250332384930964, 126.5160312092476],
    [33.25035557228114, 126.51619740878162],
    [33.25043896986113, 126.51677641281393],
    [33.2504755091455, 126.51747907989245],
    [33.25046944430764, 126.51765885431556]],

    [[33.25562892305745, 126.5072880817444],
    [33.2544725979127, 126.51186342109473]],

    [[33.254104339820145, 126.5133732097987],
    [33.25405874727146, 126.51381880931105],
    [33.25405109417948, 126.51416761722373],
    [33.25407271093958, 126.51450821477546],
    [33.25411666809667, 126.51479771557396],
    [33.254171727297106, 126.51504423029205],
    [33.254246946703766, 126.51525844019308],
    [33.25430848483042, 126.51543248367804],
    [33.25437700420646, 126.51566282814791],
    [33.25444356153732, 126.51596830287352],
    [33.25450163417362, 126.51641064894167],
    [33.25452096993934, 126.51674589574134],
    [33.254540315220545, 126.51708382547015],
    [33.25451948278621, 126.51752392363379],
    [33.25446490088054, 126.51798030387087],
    [33.2544163520021, 126.5182488528043],
    [33.25432324140609, 126.51865178689411],
    [33.25412934171916, 126.51922160684649],
    [33.25406462841683, 126.51939097847938]],

    [[33.25389984694573, 126.50666732711582],
    [33.25277477295092, 126.51115924412468]],
    //세로선
    [[33.25248573795707, 126.50476246389854],
    [33.25001039822534, 126.50410307215097]],

    [[33.25562892305745, 126.5072880817444],
    [33.25216402674167, 126.50605199996532],
    [33.25136940828015, 126.50575332230038],
    [33.25115506339121, 126.50570087451192],
    [33.25084612438564, 126.50566773858152],
    [33.25004793555556, 126.50560516538985],
    [33.249930694638344, 126.50559777750777]],

    [[33.25505175528489, 126.50953685959448],
    [33.25410141680812, 126.50918803794065],
    [33.252489675049986, 126.50859610981607],
    [33.251595875114546, 126.50829528074634]],

    [[33.25449930912399, 126.51177742216166],
    [33.24900516525038, 126.50980939448233]],

    [[33.25052836750889, 126.51253724814416],
    [33.24978389939031, 126.51237773784979],
    [33.2489830489295, 126.5122104954345],
    [33.24871904444047, 126.51213953057876],
    [33.24837378991745, 126.5120421912621]],

    [[33.25811943918782, 126.51641754685207],
    [33.257488240953045, 126.51640224393583],
    [33.256965433790576, 126.51643731911767],
    [33.2565126472001, 126.51650956855745],
    [33.256057658654015, 126.51659524371587],
    [33.255782814294534, 126.51663431738419],
    [33.25557321134402, 126.51664083727309],
    [33.25518778443166, 126.51664564240534],
    [33.254515930136996, 126.51660909933932],
    [33.254508865635636, 126.51653133628153],
    [33.254231423016705, 126.5164818907228],
    [33.25396963332202, 126.51640016552686],
    [33.25358794221708, 126.51620642390789],
    [33.25328521458932, 126.516031028146],
    [33.25291666053413, 126.51573795439514],
    [33.252557341364856, 126.51550117021262],
    [33.252216388484, 126.51535013473469],
    [33.2506676720868, 126.51472824739557],
    [33.25046228403071, 126.51465963233932],
    [33.25044875990676, 126.51465970709887]],

    [[33.25010787925601, 126.5145274568577],
    [33.24978737891215, 126.514419239043],
    [33.24812152186902, 126.51381681185347]],

    //강정LH아파트
    // [[33.25257106888539, 126.50242261159731],
    // [33.25268603442113, 126.50242464277261],
    // [33.25278751879491, 126.5024374814436],
    // [33.25290939663562, 126.50247703231351],
    // [33.25300656614294, 126.50253818553048],
    // [33.253083428019245, 126.50259408838005],
    // [33.253391229179094, 126.50290623081047],
    // [33.2537343770389, 126.5024750397217],
    // [33.253859968553286, 126.50231604250848],
    // [33.253940468814754, 126.50215461774694],
    // [33.2539584150153, 126.5021330535169],
    // [33.25409830357267, 126.50216713671004],
    // [33.254172847159, 126.50220695617999],
    // [33.25420002420144, 126.50223899584506],
    // [33.25474271371959, 126.50323124900122],
    // [33.2549943809291, 126.50303397758157],
    // [33.25481496241673, 126.50269695631653],
    // [33.254778790889475, 126.50267033290878],
    // [33.254751721251026, 126.5026651206053],
    // [33.254668558718734, 126.50272461398839],
    // [33.25465520626483, 126.50276761488075],
    // [33.25485278533837, 126.5031367269001]],

    // [[33.253732155221925, 126.50248310071399],
    // [33.253268074066334, 126.50198404978863],
    // [33.25325669655338, 126.50195728647621],
    // [33.25325657836686, 126.50192777650594],
    // [33.25336195828948, 126.50178767361803],
    // [33.253388974229225, 126.50177947193083],
    // [33.25341157886721, 126.5017954403861],
    // [33.2537488667835, 126.50215302152712],
    // [33.25376026573347, 126.50218515049477],
    // [33.25376040533739, 126.50222002611571],
    // [33.25364609475497, 126.50238164213538]],

    // [[33.25395392846551, 126.50213844457524],
    // [33.25398928372749, 126.5019611787429],
    // [33.2540385389654, 126.50187773221222],
    // [33.25405420960912, 126.50185081519439],
    // [33.25417755704014, 126.5016945120606],
    // [33.25419099512898, 126.50167297326895],
    // [33.25417734203174, 126.50164085700861],
    // [33.253858149337816, 126.50129927014778],
    // [33.253810739715625, 126.50128075985474],
    // [33.25377920502405, 126.50128630460543],
    // [33.25369169646641, 126.50138606528571],
    // [33.253673857951426, 126.50143445697158],
    // [33.253689775870654, 126.50146924295336],
    // [33.25404969083264, 126.50184815802662]],

    // [[33.2544202093611, 126.50262407360351],
    // [33.25463591870985, 126.50245383327812],
    // [33.254656118974246, 126.50243225615998],
    // [33.254653779084904, 126.50241080679612],
    // [33.2544652998708, 126.50206310821929],
    // [33.25443361475393, 126.50203109408568],
    // [33.254399815272386, 126.50203396863593],
    // [33.254312166493136, 126.50209885345264],
    // [33.25429648505609, 126.50212308775015],
    // [33.25430335450458, 126.50214987696563],
    // [33.25452583688057, 126.50254567322796]]
];
const floatingValidPathCircle = [
    [33.25768620804523, 126.51862529675104, 17.5],
    [33.25401810250825, 126.51960049250233, 20],
    [33.25047252009904, 126.51787077095459, 20],
    [33.25028170063116, 126.51459356315954, 20]
];
export const floatingValidPath = {
    lines: floatingValidPathLine,
    circles: floatingValidPathCircle,
}

export const shuttle_path = [
    [[33.25027984702876, 126.49990313329116],
    [33.25039212706006, 126.49979786854432],
    [33.251546355592986, 126.49983421628998]],

    [[33.251546355592986, 126.49983421628998],
    [33.252605947653166, 126.49987915220859]],

    [[33.252605947653166, 126.49987915220859],
    [33.25290794248193, 126.49986670072728],
    [33.25311988459744, 126.49988158999493],
    [33.25332734025208, 126.49990187046012],
    [33.25356212453771, 126.49999174799771]],

    [[33.25356212453771, 126.49999174799771],
    [33.253711256202536, 126.50008211376804],
    [33.25384235568494, 126.50017258251084],
    [33.25400975649404, 126.50032186665428],
    [33.254168248826204, 126.50049803018743],
    [33.254304200765596, 126.50067432256462]],

    [[33.254304200765596, 126.50067432256462],
    [33.25492165065266, 126.50175735915695]],

    [[33.25492165065266, 126.50175735915695],
    [33.25570952541502, 126.50318553480598],
    [33.2558893710082, 126.50362987272511]],

    [[33.2558893710082, 126.50362987272511],
    [33.25596268778506, 126.50392725654949],
    [33.25598186470818, 126.504214215176],
    [33.25601626401076, 126.50492766438774]],

    [[33.25601626401076, 126.50492766438774],
    [33.256059163289336, 126.50551228847392],
    [33.255921482819225, 126.50603354037594]],

    [[33.255921482819225, 126.50603354037594],
    [33.25568390176388, 126.50694168414014]],

    [[33.25568390176388, 126.50694168414014],
    [33.25546564436838, 126.50760825783273],
    [33.25535912922349, 126.50803274571939]],

    [[33.25535912922349, 126.50803274571939],
    [33.25512836964898, 126.50895962009122],
    [33.255039564440835, 126.50930352051974]],

    [[33.255039564440835, 126.50930352051974],
    [33.25464444772803, 126.51085372162746]],

    [[33.25464444772803, 126.51085372162746],
    [33.25404330283746, 126.51332794034994]],

    [[33.25404330283746, 126.51332794034994],
    [33.253995477407166, 126.51377891756165],
    [33.253980831758525, 126.51406874211523],
    [33.2539908958309, 126.5143369674021],
    [33.25402367752234, 126.51465067493096]],

    [[33.25402367752234, 126.51465067493096],
    [33.2540698151168, 126.51492138390665],
    [33.254145191624744, 126.51517583480786],
    [33.25423867294488, 126.51544896593663],
    [33.25431167943229, 126.51567391984143],
    [33.254382765657326, 126.51598473502261],
    [33.25444087996099, 126.51643781186289]],

    [[33.25444087996099, 126.51643781186289],
    [33.254448246971535, 126.51659337509125],
    [33.254465286537766, 126.5169179030672],
    [33.254468342843076, 126.51712446377265],
    [33.25446247643614, 126.51735521897835],
    [33.25444319995417, 126.51761555891714],
    [33.25441915484648, 126.51780885434475],
    [33.25437963281548, 126.5180800364972]],

    [[33.25437963281548, 126.5180800364972],
    [33.254326523723385, 126.51833519603157],
    [33.254268843749976, 126.51857428341201],
    [33.25420213685022, 126.51881073714361],
    [33.25398328666015, 126.51934045040245]],

    [[33.25398328666015, 126.51934045040245],
    [33.25393151676074, 126.51935951351989],
    [33.2538820838231, 126.51940002627958],
    [33.25383278563659, 126.51947541473311],
    [33.253226122208986, 126.51939288619182],
    [33.25290348610921, 126.51931416913374],
    [33.25229408097927, 126.5191055678452]],

    [[33.25229408097927, 126.5191055678452],
    [33.252066092820456, 126.51902096860796],
    [33.251808657032065, 126.51889897274334],
    [33.25156234589367, 126.51873935829859],
    [33.251218779274915, 126.51849443080167],
    [33.25089521776746, 126.51817696210708]],

    [[33.25089521776746, 126.51817696210708],
    [33.250698047062414, 126.51790172503142],
    [33.25069542903134, 126.51780784456332],
    [33.25065902141198, 126.51771951512903],
    [33.25058433698295, 126.51764212688856],
    [33.250545133997605, 126.51741431232662],
    [33.25053051652836, 126.51713270867936],
    [33.2505091572018, 126.51685650763837],
    [33.25047650630099, 126.5165750035017],
    [33.250425436502205, 126.5161943411047]],

    [[33.250425436502205, 126.5161943411047],
    [33.25039999512587, 126.5160281538643],
    [33.250330918821746, 126.51507617672863],
    [33.25038624397233, 126.51481296657093],
    [33.25046482069667, 126.51473205127827],
    [33.25049801331901, 126.51457358832761],
    [33.25046606956452, 126.51447450495547],
    [33.25040036779837, 126.51438902187587],
    [33.250365677391045, 126.51416386701553]],

    [[33.250365677391045, 126.51416386701553],
    [33.250360865411736, 126.5140860953715],
    [33.250598410357874, 126.51257978255778]],

    [[33.250598410357874, 126.51257978255778],
    [33.250920569247484, 126.51138686825112],
    [33.25108719498872, 126.51076891517107],
    [33.25100721703324, 126.51049304085869]],

    [[33.25100721703324, 126.51049304085869],
    [33.25087151123192, 126.51037575805938],
    [33.250090521893476, 126.51010111358484]],

    [[33.250090521893476, 126.51010111358484],
    [33.24928466366889, 126.50980783408681]],

    [[33.24928466366889, 126.50980783408681],
    [33.249228112276754, 126.50975717919586],
    [33.24920305368168, 126.5096902525361],
    [33.249195974318496, 126.50960981218796],
    [33.24948076182306, 126.50866392130408]],

    [[33.24948076182306, 126.50866392130408],
    [33.2497630119403, 126.50764828887974],
    [33.24989837123724, 126.50710831039807]],

    [[33.24989837123724, 126.50710831039807],
    [33.249989030938536, 126.50666515818648],
    [33.250054537637546, 126.50556488789009],
    [33.25007118281544, 126.50521604461805]],

    [[33.25007118281544, 126.50521604461805],
    [33.250194068978686, 126.5026828906112]],

    [[33.250194068978686, 126.5026828906112],
    [33.25027984702876, 126.49990313329116]]
];

export const shuttle_stop_list = [
    { "location": [33.254279736531196, 126.51557483177118], "name": "stop1", "description": "국세공무원교육원", "roadview_location": [33.25427529119363, 126.51559095321481] },
    { "location": [33.25117687732441, 126.51844234747603], "name": "stop2", "description": "유포리아 지식산업센터", "roadview_location": [33.25117687732441, 126.51844234747603] },
    { "location": [33.250407256273895, 126.51384439559095], "name": "stop3", "description": "서귀포해양경찰서", "roadview_location": [33.250273681726, 126.513694904218] },
    { "location": [33.24998359687869, 126.50643179501519], "name": "stop4", "description": "스타벅스 맞은편", "roadview_location": [33.250015312822065, 126.50647185703258] },
    { "location": [33.252055860116464, 126.49985545835803], "name": "stop5", "description": "중흥S클래스 101동", "roadview_location": [33.2520559464259, 126.49987691985429] },
    { "location": [33.25591455064428, 126.50372631355617], "name": "stop6", "description": "강정상록아파트 105/106동", "roadview_location": [33.255885301981195, 126.50373989324646] },
    { "location": [33.25538793254354, 126.50790649026656], "name": "stop7", "description": "서귀포시 선거관리위원회", "roadview_location": [33.25538304229062, 126.50780993502725] },
    { "location": [33.254764338253935, 126.51038892347087], "name": "stop8", "description": "KT 신서귀포빌딩", "roadview_location": [33.254699404752074, 126.51049928170333] },
];

export function getSeogwipoBg() {
    return (<div className="Seogwipo-bg" />);
}

export function getSeogwipoName(className = "main-name") {
    return (<span className={className}>{"서귀포 혁신도시"}<br />{"자율주행 모빌리티 서비스"}</span>);
}

export function getSeogwipoTop(div_position = "absolute") {
    return (<div style={{
        padding: "0 0 0 0",
        width: "100vw",
        height: "min(15vh, 20vw)",
        backgroundColor: "#CCDEEC",
        paddingTop: "0.5vh",
        zIndex: "2",
        position: div_position
    }}>
        <div style={{ width: "20vw", float: "left", margin: "0", padding: "0", paddingTop: "1.5vh", paddingLeft: "1vw" }}>
            <img src='/jdc_logo.svg'
                style={{
                    paddingLeft: "0.5vh",
                    width: "min(22.5vh, 29vw)"
                }} alt="Smiley face" />
        </div>
        <img src='/RF_LOGO.png'
            style={{
                height: "min(7vh, 8vw)",
                marginTop: "0.8vh",
                float: "right",
                display: "inline-block", paddingRight: "3vw"
            }} alt="Smiley face" />
        <div style={{
            width: "100vw",
            fontSize: "min(2.2vh, 3.4vw)",
            fontWeight: "600",
            color: "#006fbd",
            textAlign: "right",
            margin: "0", padding: "0",
            paddingBottom: "0.2vh",
            paddingRight: "3vw",
            bottom: "0", position: "absolute", left: "0"
        }}>서귀포 혁신도시<br /> 자율주행 모빌리티 서비스
        </div>
    </div>);
}

function getServiceInfo(info_text, service_time_txt, backward_path) {
    return (<div className="main">
        {getSeogwipoBg()}
        {getBackwardBtn(() => {
            if (!!!backward_path) {
                if (window.location.pathname === "/") {
                    window.history.back();
                } else {
                    let path = window.location.pathname.split('/');
                    if (path.pop() === "") path.pop();
                    window.location.href = path.join('/');
                }
            } else {
                // window.location.replace(backward_path);
                window.location.href = backward_path;
            }
        })}
        <div className="service-info">
            <div className="service-info-text">
                {info_text}
            </div>
            <div className="service-info-round-box">
                <span> 운영시간</span>
                <Divider />
                <div className="table">
                    <div className="table-row" >
                        <div className="table-pivot" id="bold_pivot">
                            평일
                        </div>
                        <div className="table-cell" id="bold_cell">
                            {service_time_txt}
                            <div className="table-cell small indent">
                                {holiday_info_txt}
                            </div>
                        </div>
                    </div>
                    <div className="table-row">
                        <div >
                            주말
                        </div>
                        <div >
                            운영하지 않습니다
                        </div>
                    </div>
                </div>
            </div>
            <div className="sub-text">
                ※ 차량 점검 등 운영사의 사정으로 인해 사전고지 없이 일시 중단될 수 있습니다.
            </div>
        </div>
    </div >);
}

export function completedService(main_txt, sub_txt, btn_option = {}, main_txt_style = {}, sub_txt_style = {}) {
    let btn = { display: true, path: "", txt: "다시 호출하기", ...btn_option }
    return (<div className="main flex">
        {getSeogwipoBg()}
        <div className="service-info service-completed-wrapper">
            <div className="image-box">
                {/* <div className="img">asd</div> */}
                <img src="/pacifica_front_view.png" />
            </div>
            <div className={"service-info-text service-completed-text " + (!!main_txt_style.className ? main_txt_style.className : "")} style={{ ...main_txt_style }}>{main_txt}</div>
            <div className={"customer-service-info " + (!!sub_txt_style.className ? sub_txt_style.className : "")} style={{ ...sub_txt_style }}>{sub_txt}</div>
            {btn.display ? getPressBtn(btn.txt, () => {
                window.location.pathname = btn.path;
            }, "service-completed") : null}
        </div>
    </div >);
}

export function getOnService(eta) {
    let txt = (
        <div className="service-info-eta">
            <b> {eta}분 </b> 뒤 다른 이용객의 탑승이 완료될 예정입니다. <br /> 잠시 후에 다시 호출해 주세요.
        </div>);
    if ((eta === undefined || eta === null ? -1 : eta) < 0) {
        txt = (
            <div className="service-info-eta">
                <b> 잠시후 </b> 다른 이용객의 탑승이 완료될 예정입니다. <br /> 잠시 후에 다시 호출해 주세요.
            </div>);
    }
    return (
        <div className="on-service-wrapper">
            <div className="bg" />
            {getBackwardBtn(() => { window.history.back() })}
            <div className="service-info on-service">
                <div className="service-info-text">
                    현재 다른 탑승객이 <br /> 이용중입니다.
                </div>
                {txt}
            </div>
        </div >);
}

export function getNoService(service_txt = ["운영시간", <br />, "평일 오후 12시 - 오후 4시", <br />, "평일 오후 5시 - 오후 7시 30분", <br />], backward_path) {
    return (getServiceInfo(["차량 운행이 불가능하거나", <br />, "운영시간이 아닙니다."], service_txt, backward_path));
}

export function getInitialInfo() {
    return (
        <div className="initial-info-box">
            <div className="title">
                <IoIosAlert className="alert-mark" />
                <span>호출 전에 확인해 주세요!</span>
            </div>
            <div className="contents">
                <div >• &nbsp;&nbsp;안전요원의 탑승으로 뒷좌석만 이용 가능합니다. </div>
                <div >• &nbsp;&nbsp;미성년자는 법적대리인의 서면 동의가 필요하며,<br/>6세 이하는 탑승이 불가합니다. </div>
                <div >• &nbsp;&nbsp;호출 시 입력하신 분들에 대해서만 탑승이 가능합니다. </div>
            </div>
        </div>
    )
}

export function getOverlayBtn(txt, click_callback, bColor = "#3366FF", div_style = {}, jumbo_style = {}) {
    return (<div
        style={{
            textAlign: "center",
            marginTop: "80vh",
            marginLeft: "10vw",
            position: "absolute",
            zIndex: "2",
            ...div_style,
        }}>
        <Jumbotron style={{
            width: "80vw",
            height: "8vh",
            backgroundColor: bColor,
            margin: "0",
            padding: "0",
            color: '#FFFFFF',
            fontWeight: '900',
            fontSize: "4.5vh",
            ...jumbo_style,
        }} onClick={click_callback}><span style={{ position: "relative", top: "10%" }}>{txt}</span></Jumbotron>
    </div>)
};

export function getJumbotronBtn(txt, click_callback, style = {}) {
    return (
        <Jumbotron style={{
            width: "80vw",
            height: "8vh",
            backgroundColor: "#3366FF",
            margin: "0",
            padding: "0",
            color: '#FFFFFF',
            fontWeight: '900',
            fontSize: "4.5vh",
            ...style,
        }} onClick={click_callback}><span style={{ position: "relative", top: "10%" }}>{txt}</span></Jumbotron>
    );
}

export function inside(point, polygon) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html
    if(!!!point) return false;
    var x = point[0], y = point[1];

    var inside = false;
    for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i][0], yi = polygon[i][1];
        var xj = polygon[j][0], yj = polygon[j][1];

        var intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
};

export function getBackwardBtn(click_callback, class_name = "") {
    return (
        <Button className={"backward-btn-box " + class_name}>
            <IoIosArrowRoundBack onClick={click_callback} />
        </Button>
    )
}

export function getPressBtn(txt, click_callback, class_name = "", set_disable = false) {
    if (set_disable) {
        return (
            <Button className={"press-btn " + class_name} id="disable" disabled onClick={click_callback}>
                {txt}
            </Button>);
    }
    return (
        <Button className={"press-btn " + class_name} active onClick={click_callback}>
            {txt}
        </Button>
    );
}

export function getGPSBtn(clickHandler, className = "") {
    return (
        <Button className={"gps-box " + className} onClick={clickHandler}>
            {/* <img className="" src="/gps_btn.svg"/> */}
            <MdGpsFixed />
        </Button>
    );
}
