import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { toLatLng } from "./Util";
import { KakaoMapContext } from "./KakaoMap";


const KakaoPolyline = ({
    path,
    endArrow,
    strokeWeight,
    strokeColor,
    strokeOpacity,
    strokeStyle,
    zIndex,
    strokeOptions, 
    onClick ,
    onMouseOver,
    onMouseOut,
    onMouseMove,
    onMouseDown,
    onTrigger = [],
    visible = true,
    updateOnDrag = true,
    ...props
}) => {
    
    const { map, loaded, is_drag } = useContext(KakaoMapContext);
    
    const line = useMemo(() => {
        if (!!!map || !loaded || !window.kakao) {
            return null;
        }
        
        return new window.kakao.maps.Polyline({
            map,
        });
    }, [loaded, toLatLng]);

    useLayoutEffect(() => {
        if (line) {
            line.setMap(visible ? map : null);
        }
    }, [visible]);
    useLayoutEffect(() => {
        if (line) {
            if (!!strokeOptions) {
                line.setOptions(strokeOptions);
            }
            else {
                line.setOptions({
                    strokeWeight: strokeWeight,
                    strokeColor: strokeColor,
                    strokeOpacity: strokeOpacity,
                    strokeStyle: strokeStyle,
                });
            }
        }
    }, [line, strokeWeight, strokeColor, strokeOpacity, strokeStyle, strokeOptions]);
    useLayoutEffect(() => {
        if (!updateOnDrag && is_drag) {
            return;
        }
        if (line) {
            let latlng_path = [];
            if (Array.isArray(path)) {
                if (path.every(p => typeof p.getLat === 'function')) {
                    latlng_path = path;
                }
                else if (path.every(p => !!p)) {
                    latlng_path = path.map(point => {
                        if (Array.isArray(point) && typeof(point[0]) !== 'number') {
                            return point.map(p => toLatLng(p));
                        }
                        else {
                            return toLatLng(point)
                        }
                    });
                }
            }
            line.setPath(latlng_path);
        }
    }, [line, path, toLatLng, is_drag, updateOnDrag]);
    useLayoutEffect(() => {
        if (line && typeof(zIndex) === 'number') {
            line.setZIndex(zIndex);
        }
    }, [line, zIndex]);

    useLayoutEffect(() => {

    },[]);

    useEffect(() => {
        return () => {
            if (line) {
                console.log("Destroy kakao polyline");
                line.setMap(null);
            }
        }
    }, [line]);

    const onClickEvent = useCallback((line, onClick) => {
        return function (mouseEvent) {
            onClick(mouseEvent, line);
        }
    }, []);
    const onMouseOverEvent = useCallback((line, onMouseOver) => {
        return function (mouseEvent) {
            onMouseOver(mouseEvent, line);
        }
    }, []);
    const onMouseOutEvent = useCallback((line, onMouseOut) => {
        return function (mouseEvent) {
            onMouseOut(mouseEvent, line);
        }
    }, []);
    const onMouseMoveEvent = useCallback((line, onMouseMove) => {
        return function (mouseEvent) {
            onMouseMove(mouseEvent, line);
        }
    }, []);
    const onMouseDownEvent = useCallback((line, onMouseDown) => {
        return function (mouseEvent) {
            onMouseDown(mouseEvent, line);
        }
    }, []);
    const onTriggerEvent = useCallback((line, callback) => {
        return function (data) {
            callback(data, line);
        }
    }, []);

    useLayoutEffect(() => {
        if (!line || !onClick || typeof(onClick) !== 'function') {
            return;
        }
        window.kakao.maps.event.addListener(line, 'click', onClickEvent(line, onClick));
        return () => {
            window.kakao.maps.event.removeListener(line, 'click', onClickEvent(line, onClick));
        }
    }, [line, onClick, onClickEvent]);
    useLayoutEffect(() => {
        if (!line || !onMouseOver || typeof(onMouseOver) !== 'function') {
            return;
        }
        console.log('onMouseOver')
        window.kakao.maps.event.addListener(line, 'mouseover', onMouseOverEvent(line, onMouseOver));
        return () => {
            window.kakao.maps.event.removeListener(line, 'mouseover', onMouseOverEvent(line, onMouseOver));
        }
    }, [line, onMouseOver, onMouseOverEvent]);
    useLayoutEffect(() => {
        if (!line || !onMouseOut || typeof(onMouseOut) !== 'function') {
            return;
        }
        window.kakao.maps.event.addListener(line, 'mouseout', onMouseOutEvent(line, onMouseOut));
        return () => {
            window.kakao.maps.event.removeListener(line, 'mouseout', onMouseOutEvent(line, onMouseOut));
        }
    }, [line, onMouseOut, onMouseOutEvent]);
    useLayoutEffect(() => {
        if (!line || !onMouseMove || typeof(onMouseMove) !== 'function') {
            return;
        }
        window.kakao.maps.event.addListener(line, 'mouseout', onMouseMoveEvent(line, onMouseMove));
        return () => {
            window.kakao.maps.event.removeListener(line, 'mouseout', onMouseMoveEvent(line, onMouseMove));
        }
    }, [line, onMouseMove, onMouseMoveEvent]);
    useLayoutEffect(() => {
        if (!line || !onMouseDown || typeof(onMouseDown) !== 'function') {
            return;
        }
        window.kakao.maps.event.addListener(line, 'mouseout', onMouseDownEvent(line, onMouseDown));
        return () => {
            window.kakao.maps.event.removeListener(line, 'mouseout', onMouseDownEvent(line, onMouseDown));
        }
    }, [line, onMouseDown, onMouseDownEvent]);
    useLayoutEffect(() => {
        if (!line || !onTrigger.length) {
            return;
        }

        onTrigger.forEach(trigger_obj => {
            window.kakao.maps.event.addListener(line, trigger_obj.type, onTriggerEvent(line, trigger_obj.callback));
        });
        return () => {
            onTrigger.forEach(trigger_obj => {
                window.kakao.maps.event.removeListener(line, trigger_obj.type, onTriggerEvent(line, trigger_obj.callback));
            });
        }
    }, [line, onTrigger]);

    return null;
};

export default KakaoPolyline;