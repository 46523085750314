import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

const RouteChangeTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!TRACKING_ID) {
            console.error("No tracking ID");
            return;
        }
        if (!window.location.href.includes("localhost")) {
            ReactGA.initialize(
                process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
            );
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            const fullPath = location.pathname + location.search;
            ReactGA.set({ page: location.pathname + location.search });
            ReactGA.send({
                hitType: "pageview",
                page: fullPath,
                title: location.pathname,
            });
        }
    }, [initialized, location]);

    return null;
};
export default RouteChangeTracker;
