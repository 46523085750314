import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";

const TamraCancelModal = ({
    isReservation = false,
    isCall = false,
    onCancel = () => {},
    toggle = () => {},
    ...props
}) => {

    const text = isReservation ? "예약" : isCall ? "호출": "";

    return <Modal
        className="tamra-cancel-modal font-SUIT"
        contentClassName="tamra-cancel-box"
        toggle={toggle}
        {...props}
    >
        <ModalBody className="">
            <div className="main-box">
                <div>
                {text}{!!text ? '을' : ''} 취소하시겠습니까?
                </div>
                <div>
                취소된 {text}{!!text ? '은' : '것은'} 되돌릴 수 없습니다
                </div>
            </div>
            <div className="button-group">
                <Button onClick={toggle}>
                    뒤로
                </Button>
                <Button onClick={onCancel}>
                    {text} 취소하기
                </Button>
            </div>
        </ModalBody>
    </Modal>
}

export default TamraCancelModal;