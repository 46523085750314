import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Sound from "react-sound";
import axios from "axios";
import { Button, ButtonGroup, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from 'react-router-dom';
class AdminForPrivatePage extends Component {
    state = { new_call: null };
    constructor(props) {
        super(props);
        this.disableCall = this.disableCall.bind(this);
        this.ableCall = this.ableCall.bind(this);
        this.checkCall = this.checkCall.bind(this);
        this.cancelCall = this.cancelCall.bind(this);
        this.timer_alarm_on = 0;
        this.timer_resetted = false;
    }
    async getCallState() {
        await axios
            .get("/api/getPrivateCallState", {
                params: {
                    isClient: false
                }
            })
            .then(function (res) {
                if (res.statusText === "OK") {
                    return res.data;
                } else {
                    return {
                        new_call: undefined,
                        server_on: false,
                        call_available: false,
                        car_timeout: false,
                        server_available: 0,
                        location: undefined,
                        passenger_info: undefined,
                        req_time: undefined,
                        wait_for_passenger: undefined,
                        service_on: false
                    };
                }
            })
            .catch(function (error) {
                console.error("error while request GET to server");
                console.log(error);
                return {
                    new_call: undefined,
                    server_on: false,
                    server_available: 0,
                    car_timeout: false,
                    call_available: false,
                    location: undefined,
                    passenger_info: undefined,
                    req_time: undefined,
                    wait_for_passenger: undefined,
                    service_on: false
                };
            })
            .then((data) =>
                this.setState({
                    new_call: data.new_call,
                    server_on: data.server_on,
                    server_available: data.server_available,
                    car_timeout: data.car_timeout,
                    call_available: data.call_available,
                    location: data.location,
                    passenger_info: data.passenger_info,
                    req_time: data.req_time,
                    wait_for_passenger: data.wait_for_passenger,
                    service_on: data.service_on
                })
            );
    }
    async disableCall() {
        await axios
            .put("/api/disablePrivateCall")
            .then(function (res) {
                if (res.statusText === "OK") {
                    return true;
                } else {
                    alert("호출 안 받기 활성화에 실패했습니다.");
                }
            })
            .catch(function (error) {
                alert("호출 안 받기 활성화에 실패했습니다.");
            }).then((data) => {
                this.setState({
                    ct_end: undefined
                })
            }
            );
        this.timer_resetted = false;
        this.getCallState();
    }
    async ableCall(location) {
        await axios
            .put("/api/ablePrivateCall", { location: location })
            .then(function (res) {
                if (res.statusText === "OK") {
                } else {
                    alert("호출 받기 활성화에 실패했습니다.");
                }
            })
            .catch(function (error) {
                alert("호출 받기 활성화에 실패했습니다.");
            }).then((data) => {
                this.setState({
                    ct_end: Date.now() + 1000 * 5 * 60
                })
            }
            );
        this.timer_resetted = false;
        this.getCallState();
    }


    async disableService() {
        await axios
            .put("/api/disablePrivateService")
            .then(function (res) {
                if (res.statusText === "OK") {
                } else {
                    alert("서비스 끄기에 실패했습니다.");
                }
            })
            .catch(function (error) {
                alert("서비스 끄기에 실패했습니다.");
            }).then((data) => {
                this.setState({
                    service_on: false
                })
            }
            );
    }
    async ableService() {
        await axios
            .put("/api/ablePrivateService")
            .then(function (res) {
                if (res.statusText === "OK") {
                    return true;
                } else {
                    alert("서비스 켜기에 실패했습니다.");
                }
            }).catch(function (error) {
                alert("서비스 켜기에 실패했습니다.");
            }).then((data) => {
                this.setState({
                    service_on: true
                })
            }
            );
    }


    async checkCall() {
        await axios
            .put("/api/checkPrivateCall")
            .then(function (res) {
                if (res.statusText === "OK") {
                } else {
                    alert("예약 확인이 실패했습니다. 필요한 추가 작업은 없습니다.");
                }
            })
            .catch(function (error) {
                alert("예약 확인이 실패했습니다. 필요한 추가 작업은 없습니다.");
            }).then((data) => {
                this.setState({
                    ct_end: Date.now()
                })
            }
            );
        this.timer_resetted = false;
        this.getCallState();
    }

    async cancelCall() {
        await axios
            .put("/api/cancelCall")
            .then(function (res) {
                if (res.statusText === "OK") {
                    if (parseInt(res.data.result_code) < 0) {
                        alert("예약 문자 전송이 실패했습니다.");
                    }
                } else {
                    alert("예약 취소가 실패했습니다. 예약자에게 수동으로 연락이 필요할 수 있습니다.");
                }
            })
            .catch(function (error) {
                alert("예약 취소가 실패했습니다. 예약자에게 수동으로 연락이 필요할 수 있습니다.");
            }).then((data) => {
                this.setState({
                    ct_end: undefined
                })
            }
            );
        this.timer_resetted = false;
        this.getCallState();
    }

    async componentDidMount() {
        this.intervalId = await setInterval(() => this.getCallState(), 2000);
        this.getCallState();
    }
    async componentWillUnmount(){
        if(this.intervalId !== undefined){
            clearInterval(this.intervalId);}
    }


    async redirect(path) {
        clearInterval(this.intervalId);
        this.props.history.push(path);
    }

    async logout() {
        sessionStorage.removeItem("token");
        this.props.history.go(0);
    }

    getPassengerInfoTable() {
        const { passenger_info } = this.state;
        let passengerItems;
        if (passenger_info) {
            passengerItems = Object.keys(passenger_info).map((item, key) => (
                <ListGroupItem key={key}>{passenger_info[item]}</ListGroupItem>
            ));
        } else {
            passengerItems = <div />;
        }
        return (
            <div class="row">
                <div class="col- mx-auto">
                    <ListGroup style={{ color: "black" }} horizontal>{passengerItems}</ListGroup>
                </div>
            </div>);
    }

    getLogOutBtn() {
        return (<div
            style={{
                margin: "auto",
                width: "90%",
                padding: "10px 0",
                textAlign: "right",
                color: "black",
            }}
        >
            <Button sm={10} onClick={() => this.logout()}>Log Out</Button>
        </div>);
    }

    getCallAvailableForm() {
        const { location } = this.state;
        let location_text;
        if (location === "airport") {
            location_text = "공항";
        } else if (location === "station") {
            location_text = "쏘카 스테이션";
        }
        return (
            <div>
                <h1>{location_text} 차량 호출을 받고 있습니다. </h1>
                <Button size="lg" color="info" onClick={this.disableCall}>차량 호출 받지 않기</Button>
            </div>
        );
    }
    getCallUnavailableForm() {
        const { server_available, new_call } = this.state;
        return (
            <div>
                <h1>차량 호출을 받지 않고 있습니다.</h1>
                <ButtonGroup>
                    {
                        server_available === 1 && !new_call ?
                            (
                                <Button size="lg" color="info" onClick={() => this.ableCall("airport")}>공항 차량 호출 받기</Button>
                            ) : (
                                <Button size="lg">공항에 차량이 없습니다</Button>
                            )
                    }
                    {
                        server_available === 2 && !new_call ?
                            (
                                <Button size="lg" color="info" onClick={() => this.ableCall("station")}>쏘카 스테이션 차량 호출 받기</Button>
                            ) : (
                                <Button size="lg">쏘카 스테이션에 차량이 없습니다</Button>
                            )
                    }
                </ButtonGroup>
            </div>);
    }

    getNewCallInfo() {
        let passengerInfoTable = this.getPassengerInfoTable();
        return (
            <div>
                <Sound
                    url="../sound/ring.mp3"
                    playStatus="PLAYING"
                    allow="autoplay"
                />
                <h1>차량이 호출됐습니다.</h1>
                <br />
                <h5>[호출 시각] : {this.state.req_time}</h5>
                <br />
                <h5>[호출 승객 정보]</h5>
                {passengerInfoTable}
                <br />
                <Button size="lg" color="info" onClick={this.checkCall}>차량 호출 확인</Button>
            </div>);
    }

    getCallInfoForm() {
        const {
            server_on,
            call_available,
            car_timeout,
            wait_for_passenger
        } = this.state;
        let passenger_info_show = true;
        if (server_on && !car_timeout && call_available) {
            passenger_info_show = false;
        }
        let passengerInfoTable = this.getPassengerInfoTable();
        let cancelBtn = (<div />);
        if (wait_for_passenger) {
            cancelBtn = (<Button size="md" color="danger" onClick={this.cancelCall}>예약 취소</Button>);
        }
        return (
            <div>
                {
                    passenger_info_show ?
                        (<div>
                            <h5>[호출 시각] : {this.state.req_time}</h5>
                            <br />
                            <h5>[호출 승객 정보]</h5>
                            {passengerInfoTable}
                            <br />
                            {cancelBtn}
                        </div>) : <div />
                }
            </div>);
    }
    getServerOnForm() {
        const {
            new_call,
            call_available,
            car_timeout,
            wait_for_passenger
        } = this.state;
        let callForm;
        if (car_timeout) {
            callForm = (<h1>차량 위치가 확인되지 않습니다</h1>);
        } else {
            if (call_available) {
                callForm = this.getCallAvailableForm();
            } else if (new_call || wait_for_passenger) {
                callForm = <div>차량이 이미 예약되었습니다.</div>
            } else {
                callForm = this.getCallUnavailableForm();
            }
        }
        let newCallForm;
        if (new_call) {
            newCallForm = this.getNewCallInfo();
        } else {
            newCallForm = this.getCallInfoForm();
        }
        return (
            <div>
                {callForm}
                <br />
                <br />
                <br />
                {newCallForm}
            </div>
        );
    }
    resetCountDown() {
        this.setState({
            ct_end: Date.now() + 1000 * 5 * 60,
        });
        this.timer_alarm_on = 0;
        this.timer_resetted = true;
    }

    removeCountDown() {
        this.setState({
            ct_end: undefined,
        });
        this.timer_alarm_on = 0;
        this.timer_resetted = false;
    }

    doneTimerAlarm() {
        this.timer_alarm_on = -100000000000;
        this.setState({
            refresh: true,
        });
    }

    render() {
        const { server_on, ct_end, wait_for_passenger, new_call, service_on } = this.state;
        let adminForm;
        let logoutBtn = this.getLogOutBtn();
        if (server_on) {
            adminForm = this.getServerOnForm();
        } else {
            adminForm = (<h1>서버가 꺼져있습니다.</h1>);
        }
        let countDown = (<div />);
        // try {
        if (ct_end !== undefined) {
            let target_end = ct_end;
            let wait_for = "예약";
            let time_end_btn = <div />;
            let reset_btn = <Button size="md" color="dark" onClick={() => this.resetCountDown()}>리셋 타이머</Button>;
            if (wait_for_passenger) {
                if (!this.timer_resetted) {
                    target_end = new Date(this.state.req_time).getTime() + 1000 * 60 * 5;
                }
                wait_for = "탑승객";
                time_end_btn = <Button size="md" color="warning" onClick={() => this.removeCountDown()}>타이머 종료</Button>;
            }
            let distance = new Date(target_end).getTime() - new Date().getTime();
            if (distance > 0) {
                let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((distance % (1000 * 60)) / 1000);
                if (wait_for_passenger && minutes < 2) {
                    this.timer_alarm_on = this.timer_alarm_on + 1;
                }
                if (!new_call && this.timer_alarm_on < 1) {
                    countDown = (<div style={{ color: "purple" }}>
                        <h4>{wait_for}을 기다리는 중입니다.</h4>
                        <h3 >타이머 남은 시간: {minutes}m {seconds}s</h3>
                        {time_end_btn}
                        {reset_btn}
                    </div>);
                } else if (!new_call) {
                    countDown = (<div style={{ color: "purple" }}>
                        <h4>{wait_for}을 기다리는 중입니다.</h4>
                        <h3 >타이머 남은 시간: {minutes}m {seconds}s</h3>
                        <Sound
                            url="../sound/timer.mp3"
                            playStatus="PLAYING"
                            allow="autoplay"
                        />
                        {/* {time_end_btn} */}
                        <h5>3분 초과되었습니다! 탑승객에게 연락이 필요합니다.</h5>
                        <Button size="md" color="warning" onClick={() => this.doneTimerAlarm()}>소리 끄기</Button>
                    </div>);
                }
            } else {
                if (wait_for_passenger) {
                    countDown = (<div style={{ color: "purple" }}><h3>타이머 시간이 초과되었습니다!</h3>
                        <p style={{ color: "black" }}> *알람을 꺼도 예약은 계속 유지됩니다. <br /> 예약 취소를 원하면 예약취소 버튼을 눌러주세요.</p>
                        <Sound
                            url="../sound/timer.mp3"
                            playStatus="PLAYING"
                            allow="autoplay"
                        />
                        <Button size="md" color="warning" onClick={() => this.removeCountDown()}>소리 끄기(타이머가 사라집니다.)</Button>
                        <Button size="md" color="dark" onClick={() => this.resetCountDown()}>리셋 타이머</Button>
                    </div>);
                } else {
                    countDown = (<div style={{ color: "purple" }}><h3>타이머 시간이 초과되었습니다!</h3>
                        <p style={{ color: "black" }}> *알람을 꺼도 호출 받기는 계속 유지됩니다. <br /> 호출을 끄고 싶으면 호출 받지 않기 버튼을 눌러주세요.</p>
                        <Sound
                            url="../sound/timer.mp3"
                            playStatus="PLAYING"
                            allow="autoplay"
                        />
                        <Button size="md" color="warning" onClick={() => this.removeCountDown()}>소리 끄기(타이머가 사라집니다.)</Button>
                        <Button size="md" color="dark" onClick={() => this.resetCountDown()}>리셋 타이머</Button>
                    </div>);
                }
            }
        }
        let contents = (<div />);
        if (service_on) {
            contents = (<div><h1>호출 운영 중 입니다! <Button size="md" color="danger" onClick={this.disableService}>끄기</Button></h1>
                <br />
                <div style={{ paddingTop: "5vh" }} />
                {countDown}
                <div style={{ paddingTop: "1vh" }} />
                {adminForm}</div>);
        } else {
            contents = (<div><h1 style={{ color: "red" }}>운영 중단되었습니다. <Button size="md" color="info" onClick={this.ableService}>켜기</Button></h1></div>);
        }
        return (
            <div>
                {logoutBtn}
                <div
                    style={{
                        margin: "auto",
                        width: "70%",
                        paddingTop: "5vh",
                        textAlign: "center",
                        color: "black",
                    }}
                >
                    <h2 style={{ color: "orange" }}>[초대 승객에게 서비스]</h2>
                    <Link to={{ pathname: "/admin" }}>모든 승객에게 서비스하기</Link>
                    <br />
                    <br />
                    {contents}
                </div>
            </div >
        );
    }
}
export default withRouter(AdminForPrivatePage);
