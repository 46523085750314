import React, { useCallback } from "react";
import { Button } from "reactstrap";

const LogoutButton = (props) => {
    const {
        history
    } = props;
    const logout = useCallback(() => {
        sessionStorage.removeItem("token");
        history.go(0);
    },[history]);

    return <Button
        sm={10}
        style={{
            float: 'right'
        }}
        onClick={() => logout()}
    >
        Log Out
    </Button>;
}

export default LogoutButton;