import React from 'react'

const LocationButton = ({
    wrapperClassName = "location-button-wrapper",
    wrapperStyle,
    imageStyle,
    ...props
}) => {
    return (
        <div className={wrapperClassName} style={wrapperStyle}>
            <div {...props}>
                <img
                    src="/crosshair.svg"
                    style={imageStyle}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    alt=''
                />
            </div>
        </div>
    );
}

export default LocationButton;