import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { KakaoCustomOverlay, KakaoMap, KakaoMarker, KakaoPolyline } from "../../Component/Map/Kakao";
import { useInterval, usePosition } from "../../Hooks/Hooks";
import { Button, Spinner } from "reactstrap";
import TamraCancelModal from "./TamraCancelModal";
import LocationButton from "../../Component/LocationButton";
import { UnavailablePage } from "./TamraUnavailable";
import { PathGraph } from "./TamraShuttlePath";
import { toStandardLocation } from "../../Util/Common";

const STATUS = {
    RESERVED: 'RESERVED',
    SERVED: 'SERVED',
    CANCEL: 'CANCEL',
    EXPIRED: 'EXPIRED',
    ONBOARD: 'ONBOARD',
} 

function TamraShuttleConfirm ({
    history,
    location,
    ...props
}) {
    const position = usePosition(true);
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const { id: encrypted_id, representative } = query
    
    const [load, setLoad] = useState('loading');

    const [carId, setCarId] = useState('');
    const [encryptId, setEncryptId] = useState(undefined);
    const [numPassenger, setNumPassenger] = useState(0);

    const [carInfo, setCarInfo] = useState({});
    const [startLocation, setStartLocation] = useState({});
    const [endLocation, setEndLocation] = useState({});
    
    const [reservationStatus, setReservationStatus] = useState('');

    const [openCancelModal, setOpenCancelModal] = useState(false);

    const [center, setCenter] = useState(undefined);
    const [bounds, setBounds] = useState({
        bound : [{lng: 126.44718426116032, lat: 33.4887548039607}, {lng: 126.51668431338587, lat: 33.529292347712946}],
        padding: [0, 0]
    });

    const [stops, setStops] = useState([]);
    const [shuttleGraph, setShuttleGraph] = useState();
    const [shuttlePath, setShuttlePath] = useState([]);

    const getCarInfo = useCallback(() => {
        axios.get(`/api/tamra/carpool/confirm`, {
            params: { encrypted_id: encryptId }
        })
        .then( ({ data }) => {
            const {
                car_location, car_heading,
            } = data;
            setCarInfo({
                car_location, car_heading
            });
        })
        .catch(err => {
            return;
        });
    }, [encryptId]);

    const confirmCallback = useCallback(() => {
        axios.get(`/api/tamra/carpool/confirm`, {
            params: { encrypted_id: encryptId }
        })
        .then(({ data }) => {
            const { status } = data;
            setReservationStatus(status);
            return;
        })
        .catch( err => {
            return;
        });
    },[encryptId]);

    const onCancel = useCallback(async () => {
        await axios.put(`/api/tamra/carpool/cancel_call`, {
            encrypted_id: encryptId
        })
        .then( res => {
            const success = res.data;
            if (success) {
                setReservationStatus('CANCEL');
            }
        })
        .catch(err => {
            if (err.response?.status === 400) {
                console.error("이미 완료되었거나 취소된 서비스 입니다.");
            }
            else if(err.response?.status === 403) {
                console.error("서비스 이름이 올바르지 않거나 차량이 다른 서비스를 운영중입니다.");
            }
            else if(err.response?.status === 404) {
                console.error("차량이 받은 호출이 없습니다.");
            }
            alert("호출을 취소하지 못했습니다.");
        });
        confirmCallback()
    }, [encryptId, confirmCallback]);

    const stopMarkers = useMemo(() => {
        return stops.map((stop) => (
            stop.id === startLocation.id || stop.id === endLocation.id ? null :
            <KakaoMarker
                key={`${stop.name}-${stop.description}`}
                position={stop.location}
                title={stop.description}
                image={{
                    src: "/bus_station=default.svg",
                    width: 40,
                    height: 40,
                }}
                clickable={false}
            />
        ));
    }, [stops, startLocation, endLocation])

    useInterval(getCarInfo, 1000);
    useInterval(confirmCallback, 10000);

    useLayoutEffect(() => {
        async function wrapper() {
            setEncryptId(encrypted_id)

            const init_data = await Promise.allSettled([
                axios.get(`/api/tamra/carpool/confirm`, {
                    params: { encrypted_id }
                })
                .then(res => {
                    return res;
                }),
                axios.get("/api/tamra/carpool/get-stops")
                .then(res => res.data).catch(err => {
                    console.error(err);
                    return [];
                }),
                // TODO
                axios.get("/api/tamra/carpool/get-path")
                .then(res => res.data).catch(err => {
                    console.error(err);
                    return {geometry: []};
                }),
            ]);
            const status = init_data.every(({status}) => status === 'fulfilled');
            setLoad(status ? 'complete' : 'fail');

            const [
                {value: carData},
                {value: shuttle_stops},
                {value: shuttle_path}
            ] = init_data;
            
            setStops(shuttle_stops);
            const path_edges_info = shuttle_path.map(path => {
                const {
                    from, to, bidirection
                } = path.json_data;
                return {
                    path: path.geometry.flat(),
                    from, to, bidirection
                }
            });
            const graph = new PathGraph(path_edges_info);
            setShuttleGraph(graph);
            if (status && !!carData.data) {
                const {
                    vehicle_info, status,
                    start_stop_id, end_stop_id, car_location,
                    car_heading, num_passenger
                } = carData.data;
                const { id: carID } = vehicle_info
                setCarId(carID)
                setCarInfo({car_location, car_heading})
                setReservationStatus(status);
                if (status === STATUS.RESERVED || status === STATUS.ONBOARD) {
                    setNumPassenger(num_passenger);
                    if (shuttle_stops) {
                        setStartLocation(shuttle_stops.find(stop => stop.id === start_stop_id));
                        setEndLocation(shuttle_stops.find(stop => stop.id === end_stop_id));
                    }
                }
            }
        }
        wrapper();
    }, [location]);

    useLayoutEffect(() => {
        if (!!startLocation.location && !!endLocation.location && shuttleGraph) {
            const { path } = shuttleGraph.getPath(startLocation.order, endLocation.order, true);
            setShuttlePath(path);
            const lat_lng_min_max = path.flat().reduce((prev, location) => {
                const standard_location = toStandardLocation(location);
                prev.max_latitude = Math.max(standard_location.latitude, prev.max_latitude);
                prev.max_longitude = Math.max(standard_location.longitude, prev.max_longitude);
                prev.min_latitude = Math.min(standard_location.latitude, prev.min_latitude);
                prev.min_longitude = Math.min(standard_location.longitude, prev.min_longitude);
                return prev;
            },{
                max_latitude: -9999,
                max_longitude: -9999,
                min_latitude: 9999,
                min_longitude: 9999,
            });
            setBounds({
                bound : [
                    {lng: lat_lng_min_max.min_longitude, lat: lat_lng_min_max.min_latitude},
                    {lng: lat_lng_min_max.max_longitude, lat: lat_lng_min_max.max_latitude}
                ]
            })
        }
    }, [startLocation, endLocation, shuttleGraph])

    return (load === 'loading' ? 
    <UnavailablePage
        history={history}
        black={true}
        disable
    >
        불러오는 중입니다 <br/>
        잠시만 기다려 주십시오 <Spinner />
    </UnavailablePage>
    // : load === 'fail' ? 
    : reservationStatus === STATUS.SERVED ? 
    <UnavailablePage
        history={history}
        black={true}
    >
        이용해 주셔서 <br/>
        감사합니다.
    </UnavailablePage>
    : reservationStatus !== STATUS.RESERVED && reservationStatus !== STATUS.ONBOARD? 
        <UnavailablePage
            history={history}
            black={reservationStatus === STATUS.CANCEL}
        >
            {
            reservationStatus === STATUS.CANCEL ?
            <>호출이<br/> 취소되었습니다.</> : 
            reservationStatus === STATUS.SERVED ?
            <>이용이<br/> 완료되었습니다.</> :
            reservationStatus === STATUS.EXPIRED ? 
            <>만료된 <br/>예약 시간 입니다.</> :  
            <>Not Found: 404 <br/>올바르지 않은 경로입니다</>
            }
        </UnavailablePage>
    : <>
        <div
            className="font-SUIT"
            style={{
                position:"relative", height: "100%", display:"flex", flexDirection:"column-reverse"
            }}
        >
            <div className="tamra-bottom-box relative">
                <LocationButton
                    className="tamra-location-button"
                    onClick={()=>{
                        setCenter([position.latitude, position.longitude])
                    }}
                />
                <div
                    className="reservation-title"
                    style={{
                        flexDirection: 'row', justifyContent:'space-between'
                    }}
                >
                    <div>
                        {reservationStatus === STATUS.ONBOARD ? '목적지까지' : '차량이'} 이동중입니다.
                        <img src="/three_dot.svg"/>
                    </div>
                </div>
                <div className="line-divider" />
                <div className="info-box">
                    <div className="tamra-information-box">
                        <div>
                            <div>경로</div>
                            <div>
                                {`${startLocation.description} > ${endLocation.description}`}
                            </div>
                        </div>
                        <div>
                            <div>탑승 인원</div>
                            <div>{numPassenger}인</div>
                        </div>
                        <div className='list'>
                            <div>차량 번호</div>
                            <div>{carId}</div>
                        </div>
                        <div className='list'>
                            <div>문의</div>
                            <div>064-748-8039</div>
                        </div>
                    </div>
                </div>
                {reservationStatus !== STATUS.ONBOARD && representative &&
                    <Button onClick={(e) => {
                        setOpenCancelModal(true)
                    }}>
                        호출 취소
                    </Button>
                }
            </div>
            <KakaoMap
                width="100%"
                style={{position: "relative", flexGrow: 1}}
                center={center}
                bounds={bounds}
                level={6}
            >
                <KakaoMarker 
                    position={startLocation.location}
                    image={{
                        src: startLocation.id === endLocation.id
                            ? '/get_on_off=blue.svg'
                            : `/get_on=blue.svg`,
                        width: 48,
                        height: 48,
                        options: {
                            offset: [24, 40],
                        }
                    }}
                />
                {startLocation.id !== endLocation.id && <KakaoMarker 
                    position={endLocation.location}
                    image={{
                        src: `/get_off=blue.svg`,
                        width: 48,
                        height: 48,
                        options: {
                            offset: [24, 40],
                        }
                    }}
                />}
                {stopMarkers}
                <KakaoCustomOverlay position={carInfo.car_location} contentID="carIcon">
                    <img
                        src="/car_icon=mini.png"
                        style={{
                            transform: `rotate(${180 - (carInfo.car_heading ?? 90)}deg)`,
                            zIndex: 10
                        }}
                    />
                </KakaoCustomOverlay>
                {position.hasOwnProperty('latitude') ? 
                    <KakaoCustomOverlay
                        position={position || {latitude: 33.49377982021922, longitude: 126.47485842544015}}
                        contentID="mylocation"
                    >
                        <img
                            src="/mylocation=direction.svg" 
                            style={{transform: `rotate(${90 - (position.heading ?? 90)}deg)`}}    
                        />
                    </KakaoCustomOverlay> : null 
                }
                <KakaoPolyline
                    path={shuttlePath}
                    strokeColor="#0023f3"
                />
            </KakaoMap>
        </div>
        <TamraCancelModal
            isCall={true}
            isOpen={openCancelModal}
            centered={true}
            onCancel={onCancel}
            toggle={() => {setOpenCancelModal(p => !p)}}
        />
    </>)
};

export default withRouter(TamraShuttleConfirm);