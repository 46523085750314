import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import {
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    ButtonDropdown,
    UncontrolledDropdown,
    Button,
} from "reactstrap";

const SplitDropdown = (props) => {
    const {
        selectList,
        selectedValue,
        selectCallback,
        buttonCallback,
        direction,
        buttonName,
        buttonColorName,
        dropdownColorName,
        isLoading,
        EMPTY_DESCRIPTION = "화살표를 눌러 선택해 주세요.",
    } = props;

    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    const dropDownOnClick = useCallback(
        (name) => {
            if (selectCallback) selectCallback(name);
        },
        [selectCallback]
    );

    const onButtonClick = useCallback(() => {
        if (!selectedValue) {
            setIsDropDownOpen((open) => !open);
            return;
        }
        if (buttonCallback) buttonCallback();
    }, [buttonCallback, selectedValue]);

    const items = useMemo(
        () =>
            selectList?.map((name, idx) => (
                <DropdownItem
                    name={name}
                    key={`car-dropdown-${idx}=${name}`}
                    value={name}
                    onClick={() => dropDownOnClick(name)}
                >
                    {name}
                </DropdownItem>
            )),
        [selectList, dropDownOnClick]
    );

    return (
        <div style={{ textAlign: "center", marginTop: "10px" }}>
            <UncontrolledDropdown
                group
                direction={direction || "down"}
                isOpen={isDropDownOpen}
                toggle={() => setIsDropDownOpen((open) => !open)}
            >
                <Button
                    onClick={isLoading ? () => {} : onButtonClick}
                    color={buttonColorName || "secondary"}
                >
                    {buttonName || EMPTY_DESCRIPTION}
                </Button>
                <DropdownToggle
                    caret
                    color={dropdownColorName || "secondary"}
                />
                <DropdownMenu name="selectList">{items}</DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

export default SplitDropdown;
