import { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import SwitchButton from "bootstrap-switch-button-react";
import AdminTopBar from "../../Component/TopBar";
import LogoutButton from "../../Component/LogoutButton";
import DarkModeButton from "../../Component/DarkModeButton";
import SelectCar from "../../Component/SelectCar";
import { Snackbar } from "@material-ui/core";
import { Button, Table } from "reactstrap";
import axios from "axios";
import useGetAdminState from "../../Hooks/useGetAdminState";
import { DropdownButton, DropdownItems } from "../../Component/DropdownButton";

const SERVICE_NAME = "newtamra";
const ROUTE_NAMES = {
    downward: "하행",
    upward: "상행",
    shortcut: "왕복",
};

const NewTamraAdmin = ({ history, location }) => {
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const { car_name } = query;
    const [carName, setCarName] = useState(car_name);
    const [service, setService] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState("");
    const [trip, setTrip] = useState(false);
    const [carInfo] = useGetAdminState(SERVICE_NAME, 1000, carName);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [stops, setStops] = useState([]);
    const [currentStopOrder, setCurrentStopOrder] = useState(0);
    const [nextStopOrder, setNextStopOrder] = useState(0);
    const [selectedDestinationStopOrder, setSelectedDestinationStopOrder] =
        useState(0);
    const [serviceID, setServiceID] = useState(0);

    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [isFirstTimeInShortcutRoute, setIsFirstTimeInShortcutRoute] =
        useState(true);

    const selectCarCallback = useCallback(
        (car_name) => {
            setCarName(car_name);
            history.replace({
                search: `?car_name=${car_name}`,
            });
        },
        [history]
    );

    const setNewGoal = useCallback(async () => {
        await axios
            .put(`/api/${carName}/new_goal`)
            .then(() => {
                setSnackbarMessage("목적지를 다시 설정하였습니다.");
            })
            .catch((err) => {
                window.alert(
                    `목적지 재설정에 실패하였습니다. (${err.response.statusText})`
                );
            });
    }, [carName]);

    const turnServiceOn = useCallback(
        async (routeName) => {
            if (!routeName) {
                window.alert("노선을 먼저 선택해주세요.");
                return;
            }
            await axios
                .put(`/api/${SERVICE_NAME}/turn-service-on`, {
                    route_name: routeName,
                    car_name: carName,
                })
                .then(() => {
                    setSnackbarMessage("서비스를 시작하였습니다.");
                })
                .catch((err) => {
                    window.alert(
                        `서비스 시작에 실패하였습니다. (${err.response.statusText})`
                    );
                });
        },
        [carName]
    );

    const turnServiceOff = useCallback(async () => {
        await axios
            .put(`/api/${SERVICE_NAME}/turn-service-off`, {
                car_name: carName,
            })
            .then(() => {
                setSnackbarMessage("서비스를 종료하였습니다.");
            })
            .catch((err) => {
                window.alert(
                    `서비스 종료에 실패하였습니다. (${err.response.statusText})`
                );
            });
    }, [carName]);

    const registerService = useCallback(async () => {
        await axios
            .post(`/api/${SERVICE_NAME}/register-service`, {
                car_name: carName,
            })
            .catch((err) => {
                window.alert(
                    `서비스ID 등록에 실패하였습니다. (${err.response.statusText})`
                );
            });
    }, [carName]);

    const goToSelectedStop = useCallback(
        async (selectedStop) => {
            if (!serviceID && selectedDestinationStopOrder !== 1) {
                registerService();
            }
            await axios
                .post(`/api/${SERVICE_NAME}/go-to-selected-stop`, {
                    car_name: carName,
                    selected_stop: selectedStop,
                })
                .then(() => {
                    setSnackbarMessage("목적지가 갱신되었습니다.");
                })
                .catch((err) => {
                    window.alert(
                        `목적지 갱신에 실패하였습니다. (${err.response.statusText})`
                    );
                });
        },
        [carName, registerService, selectedDestinationStopOrder, serviceID]
    );

    const serviceExplainText = useMemo(() => {
        const { car_timeout, car_location, current_service } = carInfo;
        let text = "";
        if (
            !!current_service &&
            `${SERVICE_NAME.toUpperCase()}_${selectedRoute?.toUpperCase()}` !==
                current_service
        ) {
            text =
                "다른 사용자 페이지에서 운행 중이거나 서비스명이 일치하지 않습니다.";
        } else if (car_timeout || !car_location) {
            text = "차량의 위치를 확인할 수 없어 운행를 시작할 수 없습니다.";
        } else if (service) {
            text = `${ROUTE_NAMES[selectedRoute]} 노선을 주행 중입니다.`;
        } else {
            text =
                "서비스를 시작하려면 아래의 버튼을 통해 노선을 선택해주세요.";
        }

        return text;
    }, [carInfo, selectedRoute, service]);

    const destinationSelector = useMemo(() => {
        return (
            <div>
                <DropdownButton
                    dropdownName={
                        selectedDestinationStopOrder < 1
                            ? `정차 정류소를 선택하세요.`
                            : stops[selectedDestinationStopOrder - 1]
                                  ?.description
                    }
                    isOpen={dropdownIsOpen}
                    toggleHandler={() => setDropdownIsOpen((prev) => !prev)}
                >
                    <DropdownItems
                        items={stops
                            .filter((stop) =>
                                selectedRoute === "shortcut"
                                    ? true
                                    : stop.order > currentStopOrder
                            )
                            .map((stop) => stop.description)}
                        clickHandler={(destination) => {
                            setSelectedDestinationStopOrder(
                                stops.find(
                                    (stop) => stop.description === destination
                                )?.order || 0
                            );
                        }}
                    />
                </DropdownButton>
                {stops && (
                    <Button
                        color="success"
                        onClick={() => {
                            const _nextStop =
                                selectedRoute === "shortcut"
                                    ? stops[selectedDestinationStopOrder - 1]
                                    : selectedDestinationStopOrder >
                                      currentStopOrder
                                    ? stops[selectedDestinationStopOrder - 1]
                                    : undefined;
                            goToSelectedStop(_nextStop);
                        }}
                    >
                        {selectedDestinationStopOrder > currentStopOrder
                            ? `정류장을 목적지로 설정`
                            : `다음 정류장으로 이동`}
                    </Button>
                )}
            </div>
        );
    }, [
        stops,
        dropdownIsOpen,
        selectedDestinationStopOrder,
        currentStopOrder,
        goToSelectedStop,
        selectedRoute,
    ]);

    const setNewGoalButton = useMemo(() => {
        return (
            <Button
                onClick={() => {
                    setNewGoal();
                }}
            >
                목적지 재설정
            </Button>
        );
    }, [setNewGoal]);

    const tripContent = useMemo(() => {
        if (trip) {
            return (
                <div>
                    {!!currentStopOrder && (
                        <span>{`${
                            stops[currentStopOrder - 1]?.description
                        } > `}</span>
                    )}
                    {stops[nextStopOrder - 1] && (
                        <span>
                            <b>{stops[nextStopOrder - 1].description}</b>
                            정류장으로 이동 중입니다.
                        </span>
                    )}
                    <div>{setNewGoalButton}</div>
                </div>
            );
        }
        return (
            <div>
                {!!currentStopOrder && (
                    <span>
                        <b>{stops[currentStopOrder - 1]?.description}</b>
                        정류장으로 이동하였습니다.
                    </span>
                )}
                <div>{destinationSelector}</div>
            </div>
        );
    }, [
        currentStopOrder,
        destinationSelector,
        nextStopOrder,
        setNewGoalButton,
        stops,
        trip,
    ]);

    const routeSelector = useMemo(() => {
        return (
            <div>
                {Object.keys(ROUTE_NAMES).map((route) => (
                    <Button
                        key={route}
                        style={{ margin: "0 1vw" }}
                        color="primary"
                        onClick={() => {
                            setSelectedRoute(route);
                        }}
                    >
                        <b>{ROUTE_NAMES[route]}</b>
                    </Button>
                ))}
            </div>
        );
    }, []);

    const serviceToggleButton = useMemo(() => {
        if (service) {
            return (
                <Button
                    color="danger"
                    onClick={() => {
                        if (
                            stops[currentStopOrder] !== stops.length &&
                            serviceID &&
                            !window.confirm(
                                "운행을 완료하지 않았습니다. 그래도 서비스를 종료하시겠습니까?"
                            )
                        ) {
                            return;
                        }
                        turnServiceOff();
                        setSelectedRoute("");
                    }}
                >
                    서비스 종료
                </Button>
            );
        }
        return (
            <div>
                <Button
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                        setSelectedRoute("");
                    }}
                >
                    노선 다시 선택하기
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        turnServiceOn(selectedRoute);
                    }}
                >
                    <b>{ROUTE_NAMES[selectedRoute]}</b>노선 서비스 시작
                </Button>
            </div>
        );
    }, [
        currentStopOrder,
        selectedRoute,
        service,
        serviceID,
        stops,
        turnServiceOff,
        turnServiceOn,
    ]);

    const stopsTimeTable = useMemo(() => {
        return (
            <Table
                style={{
                    margin: "auto",
                    width: "70%",
                    borderCollapse: "collapse",
                }}
                bordered
            >
                <thead>
                    <tr>
                        <th>정류장</th>
                        <th>
                            {ROUTE_NAMES[selectedRoute] ===
                            ROUTE_NAMES.shortcut ? (
                                <SwitchButton
                                    onlabel="2차 시간표"
                                    offlabel="1차 시간표"
                                    onstyle="primary"
                                    offstyle="secondary"
                                    width={110}
                                    onChange={() =>
                                        setIsFirstTimeInShortcutRoute(
                                            (prev) => !prev
                                        )
                                    }
                                />
                            ) : (
                                "시간표"
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {stops
                        .filter((stop) => stop.order >= currentStopOrder - 1)
                        .map((stop, idx) => {
                            const serviceDescription =
                                stop.service_description.split("/");
                            return (
                                <tr
                                    key={idx}
                                    style={{
                                        backgroundColor:
                                            currentStopOrder === stop.order
                                                ? "#86A3B8"
                                                : nextStopOrder === stop.order
                                                ? "#E8D2A6"
                                                : "transparent",
                                    }}
                                >
                                    <td>{stop.name}</td>
                                    <td
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        {
                                            serviceDescription[
                                                isFirstTimeInShortcutRoute
                                                    ? 0
                                                    : 1
                                            ]
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        );
    }, [
        currentStopOrder,
        isFirstTimeInShortcutRoute,
        nextStopOrder,
        selectedRoute,
        stops,
    ]);

    useEffect(() => {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#DDE6ED";
    }, []);

    useEffect(() => {
        const getStops = async () => {
            await axios
                .get(
                    `/api/${SERVICE_NAME.toUpperCase()}/${selectedRoute.toUpperCase()}/get-stops`
                )
                .then((res) => {
                    const _stops = res.data;
                    setStops(_stops.sort((a, b) => a.order - b.order));
                })
                .catch((err) => {
                    setSnackbarMessage(
                        "정류장 정보를 불러오는데 실패하였습니다."
                    );
                    console.error(err);
                });
        };
        if (selectedRoute) {
            getStops();
        } else {
            setStops([]);
        }
    }, [selectedRoute]);

    useEffect(() => {
        setService((prev) => {
            if (prev === carInfo.service_on) return prev;
            return carInfo.service_on;
        });
        setSelectedRoute((prev) => {
            const _routeName = carInfo.current_service?.split("_")[1];
            if (prev === _routeName) return prev;
            if (!carInfo.service_on) return prev;
            return _routeName?.toLowerCase() || "";
        });
        setCurrentStopOrder((prev) => {
            if (prev === carInfo.current_stop_order) return prev;
            return carInfo.current_stop_order;
        });
        setNextStopOrder((prev) => {
            if (prev === carInfo.next_stop_order) return prev;
            return carInfo.next_stop_order;
        });
        setTrip((prev) => {
            if (prev === carInfo.trip_on) return prev;
            return carInfo.trip_on;
        });
        setServiceID((prev) => {
            const _serviceID = carInfo.service_ids?.[0] ?? 0;
            if (prev === _serviceID) return prev;
            return _serviceID;
        });
    }, [carInfo]);

    useEffect(() => {
        setSelectedDestinationStopOrder(currentStopOrder + 1);
    }, [currentStopOrder]);

    return (
        <div style={{ height: "100%" }}>
            <AdminTopBar>
                <LogoutButton history={history} />
                <DarkModeButton />
                <SelectCar
                    selectedCar={carName}
                    selectCallback={selectCarCallback}
                />
            </AdminTopBar>
            <div
                style={{
                    margin: "auto",
                    width: "70%",
                    paddingBlock: "5vh",
                    textAlign: "center",
                }}
            >
                <h1>
                    <b>new 탐라 자율차 운영자 페이지</b>
                </h1>
                <br />
                <div>
                    <h2 style={{ fontWeight: 300 }}>
                        {serviceExplainText}
                        {service && tripContent}
                    </h2>
                </div>
                {/* 차량이 켜져 있을 때만 버튼 보이도록 */}
                {!carInfo.car_timeout &&
                    carInfo.car_location &&
                    (selectedRoute ? serviceToggleButton : routeSelector)}
                <hr />
                {/* {currentStopOrder}/{nextStopOrder} */}
                {service && stopsTimeTable}
                {/* {stopsTable} */}
            </div>
            <Snackbar
                open={!!snackbarMessage}
                autoHideDuration={3000}
                onClose={() => setSnackbarMessage("")}
                message={snackbarMessage}
                action={
                    <Button onClick={() => setSnackbarMessage("")}>닫기</Button>
                }
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            />
        </div>
    );
};
export default withRouter(NewTamraAdmin);
