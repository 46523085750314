import React, { Component } from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import { getSeogwipoBg, getSeogwipoName, getNoService, shuttle_service_txt, validServiceArea, inside, completedService } from "./SeogwipoCommon.js";
import { agreementText, agreementShortText, isChild, isUnderAge } from "../../Component/Common.js";
import { Jumbotron } from 'reactstrap';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import "../../style/SeogwipoShuttlePassenger.css"

class SeogwipoFloating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            service_on: false,
            isModalOpened: false,
            isAgreed: false,
            isAgreed2: false,
            phoneValid1: true,
            birthdateValid1: true,
            requested: false,
        };

        this.page_location = "seogwipo_shuttle_passenger";
        this.is_invitation = false;
    }
    async getCallState() {
        await axios.get("/api/seogwipo/shuttle/getCallState", {
            params: {isClient: true, invitation: this.is_invitation}
        }).then(function (res) {
            if (res.statusText === "OK") {
                return res.data;
            } else {
                return {
                    service_on: false,
                    car_location: undefined
                };
            }
        }).catch(function (error) {
            console.error("error while request GET to server");
            console.log(error);
            return {
                service_on: false,
                car_location: undefined
            };
        }).then((data) =>
            this.setState({
                ...this.state,
                service_on: data.service_on,
                car_location: data.car_location,
            })
        );
    }

    async componentDidMount() {
        if(this.props.match.path.startsWith("/seogwipo/invitation/shuttle")){
            this.is_invitation = true;
        }

        this.intervalId = await setInterval(() => this.getCallState(), 2000);
        this.getCallState();
    }
    async componentWillUnmount(){
        if(this.intervalId !== undefined)
            clearInterval(this.intervalId);
    }
    
    getCustomerInfo() {
        return (
            <div className="form-wrapper">
                <p className="Seogwipo">탑승객 정보 입력하기 </p>
                {this.getForm()}
                {/* <span className="send-sms-info"> ※ &nbsp;예약 완료 시 안내 문자가 발송됩니다. </span> */}
            </div>
        );
    }
    getAgreePage() {
        let modal = this.getAgreementModal();
        let agreeText = this.getAgreeText();
        let agreeText2 = this.getAgreeText2();
        let confirmBtn = this.getConfirmBtn();
        return (
            <div>
                <p className="Seogwipo">탑승 전 안내사항</p>
                <div className = "consent-form">
                    <Jumbotron className = "consent-form jumbotron">
                        {agreementShortText}
                        <Button className="btn-consent-form"
                            onClick={this.toggle} 
                        >동의서 전문 확인하기 </Button>
                    </Jumbotron>
                </div>
                {modal}
                <div className="agree-box">
                    {agreeText}
                    {agreeText2}
                </div>
                <div >
                    {confirmBtn}
                </div>
            </div>);
    }
    handlePhoneChange1 = e => {
        this.setState({ phone1: e.target.value });
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            e.target.blur();
        }
    }
    handlePhoneBlur1 = e => {
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            this.setState({
                phoneValid1: true
            });
        } else {
            this.setState({
                phoneValid1: false
            });
        }
    }

    handleNameChange1 = e => {
        this.setState({ name1: e.target.value });
    }
    handleBirthChange1 = e => {
        this.setState({ birthdate1: e.target.value });
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            this.phoneInput1.focus();
        }
    }
    handleBdayBlur1 = e => {
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            try {
                new Date(e.target.value);
                this.setState({
                    birthdateValid1: true,
                    birthdateUnderage1: isUnderAge(e.target.value)
                });
            } catch {
                this.setState({
                    birthdateValid1: false
                });
            }
        } else {
            this.setState({
                birthdateValid1: false
            });
        }
    }
    getBirthDateHelperText() {
        if (!this.state.birthdateValid1) {
            return '8자리를 년/월/일 순으로 입력해주세요'
        }
        if (this.state.birthdateUnderage1) {
            return '미성년자는 법적대리인의 서면 동의가 필요합니다. \n 제출 완료 후 차량 내 안전요원에게 안내 요청 부탁드립니다.'
        }
        return '';
    }
    getForm() {
        if(this.is_invitation){
            return(
                <div className="form">
                    <TextField
                        required
                        id="passenger_name"
                        label="탑승자 성함"
                        fullWidth
                        onChange={this.handleNameChange1}
                        defaultValue={this.state.name1}
                    />
                    <NumberFormat
                        customInput={TextField}
                        format="####-##-##"
                        error={!this.state.birthdateValid1 || this.state.birthdateUnderage1}
                        helperText={this.getBirthDateHelperText()}
                        required
                        id="passenger_birthdate"
                        label="생년월일(YYYY-MM-DD)"
                        type="tel"
                        fullWidth
                        onChange={this.handleBirthChange1}
                        defaultValue={this.state.birthdate1}
                        onBlur={this.handleBdayBlur1}
                    />
                    <NumberFormat
                        customInput={TextField}
                        format="###-####-####"
                        error={!this.state.phoneValid1}
                        helperText={!this.state.phoneValid1 ? '11자리를 입력해주세요' : ''}
                        // getInputRef={(el) => (this.phoneInput1 = el)} 
                        inputProps={{ ref: input => this.phoneInput1 = input }}
                        required
                        id="passenger_phone"
                        label="휴대전화"
                        type="tel"
                        name="phone"
                        fullWidth
                        onChange={this.handlePhoneChange1}
                        defaultValue={this.state.phone1}
                        onBlur={this.handlePhoneBlur1}
                    />
                </div>
            )
        }
        return (
            <div className="form">
                <NumberFormat
                    customInput={TextField}
                    format="###-####-####"
                    error={!this.state.phoneValid1}
                    helperText={!this.state.phoneValid1 ? '11자리를 입력해주세요' : ''}
                    // getInputRef={(el) => (this.phoneInput1 = el)} 
                    inputProps={{ ref: input => this.phoneInput1 = input }}
                    required
                    id="passenger_phone"
                    label="휴대전화"
                    type="tel"
                    name="phone"
                    fullWidth
                    onChange={this.handlePhoneChange1}
                    defaultValue={this.state.phone1}
                    onBlur={this.handlePhoneBlur1}
                />
            </div>);
    }

    toggle = () => {
        this.setState({ isModalOpened: !this.state.isModalOpened });
    };
    getAgreementModal() {
        return (
            <Modal isOpen={this.state.isModalOpened} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>동의서 전문</ModalHeader>
                <ModalBody>{agreementText}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>확인</Button>
                </ModalFooter>
            </Modal>
        );
    }
    getAgreeText() {
        let check_icon = (this.state.isAgreed ? <AiFillCheckCircle className= "checkbox fill"/> : <AiOutlineCheckCircle className="checkbox"/>);
        return (
            <Button id="agree-btn"
                onClick={() => { this.setState({ isAgreed: !this.state.isAgreed }) }}>{check_icon}
                 <span className={this.state.isAgreed ? "on": ""}>(필수) 동의사항을 숙지하였으며 이에 동의합니다.</span></Button>);
    }
    getAgreeText2() {
        let check_icon = (this.state.isAgreed2 ? <AiFillCheckCircle className= "checkbox fill"/> : <AiOutlineCheckCircle className="checkbox"/>);
        return (
            <Button id="agree-btn"
                onClick={() => { this.setState({ isAgreed2: !this.state.isAgreed2 }) }}>{check_icon} 
                <span className={this.state.isAgreed2 ? "on": ""}>(필수) 개인정보 수집 및 이용에 동의합니다.</span></Button>);
    }
    getConfirmBtn() {
        let passengerInfoSet = false;
        if (this.is_invitation){
            if (this.state.name1 !== undefined && this.state.birthdate1 !== undefined && this.state.phone1 !== undefined
                && this.state.birthdateValid1 && this.state.phoneValid1 && !isChild(this.state.birthdate1)) {
                passengerInfoSet = true;
            }
        } else {
            if (this.state.phone1 !== undefined && this.state.phoneValid1) {
                passengerInfoSet = true;
            }
        }
        if (passengerInfoSet && this.state.isAgreed && this.state.isAgreed2) {
            return (<Button className="press-btn Seogwipo" id = "able"
                    onClick={this.requestCall} form='passengerForm' >제출하기</Button>);
        } else {
            let warning_tex="";
            if (this.is_invitation && isChild(this.state.birthdate1)) {
                warning_tex = <div style={{ color: "red", textAlign: "center" }}>* 6세 이하는 탑승할 수 없습니다.</div>
            }
            return (<div>{warning_tex}
                        <Button className="press-btn Seogwipo" id = "disable"
                        form='passengerForm' disabled >제출하기</Button></div>);
        }
    }

    requestCall = event => {
        event.preventDefault();
        axios.put("/api/seogwipo/shuttle/passenger",
            {
                'invitation': this.is_invitation,
                'passenger': this.is_invitation ? {
                        'name': this.state.name1, 'birthdate': this.state.birthdate1, 'phone': this.state.phone1
                    } : {
                        'phone': this.state.phone1
                    },
            }).then((res) => {
                if (res.statusText === "OK" && res.data.registered) {
                    alert("제출 완료되었습니다.");
                    this.setState((state)=>state.requested=true);
                    return {};
                } else {
                    alert("제출 실패하였습니다. 다시 제출해주세요.");
                    return {};
                }
            }).catch(function (error) {
                console.error("error while request GET to server");
                console.log(error.response);
                if(error.response.status === 400){
                    // 사전 탑승 등록x
                    alert("사전 탑승 등록된 정보가 아닙니다.")
                } else if(error.response.status === 403){
                    // 사전 탑승 등록 승인x
                    alert("사전 탑승 등록된 정보가 아닙니다.");
                } else {
                    alert("제출 실패하였습니다. 다시 제출해주세요.");
                }
                return {};
            });
        // ReactGA.event({
        //     category: 'User',
        //     action: 'Request Call'
        // });
    }


    redirect(path) {
        sessionStorage.setItem("passenger_name", this.state.name1);
        sessionStorage.setItem("req_time", this.state.requested_datetime);
        clearInterval(this.intervalId);
        this.props.history.push(path);
    }

    render() {
        const { service_on, car_location, requested } = this.state;
        if (service_on === true && inside(car_location, validServiceArea) && requested === false) {
            return (
            <div className="main">
                {getSeogwipoBg()}
                <div className="Seogwipo-Main-box agree-page">
                    <div className="Seogwipo-logo-box passenger">
                        <img className="RF-Logo" src="/RF_LOGO_BLUE.png" alt="/RF_LOGO_WHITE.png"/>
                    </div>
                    {getSeogwipoName("main-name passenger")}
                </div>
                <div className="Seogwipo-Main-round-box passenger">
                    {this.getCustomerInfo()}
                    <div className="line-divider"/>
                    {this.getAgreePage()}
                </div>
            </div>);
        } else if( requested === true ) {
            return (completedService("탑승 등록이 완료되었습니다.", "문의: 010-7299-8039", {display: false}));
        }else {
            return (getNoService(shuttle_service_txt, "/seogwipo/shuttle"));
        }
    }
}
export default withRouter(SeogwipoFloating);
