export const sejongValidServiceArea = [
    [36.474786473407725, 127.25997478584455],
    [36.45846124892161, 127.27437824054182],
    [36.46874671057097, 127.31333113616095],
    [36.4762019812213, 127.3290721124573],
    [36.495780867034775, 127.33986964335429],
    [36.5118797700662, 127.30493135660022],
];

export const tamraValidServiceArea = [
    [33.51521055366002, 126.51694560884822],
    [33.503680829143036, 126.51046640012734],
    [33.49017197421058, 126.47877810741392],
    [33.49141541371027, 126.44941627691256],
    [33.505193780745685, 126.44988867504442],
    [33.52312274396304, 126.49343533113499],
];

export const tamraJungmunValidServiceArea = [
    [33.25864407160936, 126.40552861985695],
    [33.25697480581639, 126.4015048716248],
    [33.25316871044495, 126.39937387813276],
    [33.249713838494756, 126.39530914208189],
    [33.24823272475387, 126.3948042806987],
    [33.24575188793432, 126.39452093466714],
    [33.24466945639045, 126.39442109775322],
    [33.24339183272055, 126.39312086518812],
    [33.24151148311968, 126.39394931024893],
    [33.24026047307306, 126.39626477994692],
    [33.24405515309635, 126.40916827876313],
    [33.24259229658644, 126.4163348802478],
    [33.2432049615817, 126.41816561345917],
    [33.242367381339534, 126.41836429796298],
    [33.23646729192548, 126.42537723856499],
    [33.23340761143685, 126.43254254265807],
    [33.23352259697318, 126.43403308686898],
    [33.23910373590217, 126.43603570732205],
    [33.23834768565829, 126.43236042098125],
    [33.240000122841536, 126.4289592288383],
    [33.24534875941507, 126.42939657591046],
    [33.25150066467871, 126.43198566108072],
    [33.25212779240311, 126.4232788016639],
    [33.25430770200987, 126.41897199536649],
    [33.25656455563169, 126.41189573982021],
];

export const scienceParkValidServiceArea = [
    [33.4629543, 126.5492297],
    [33.4566549, 126.5802359],
    [33.4453264, 126.5663685],
    [33.4250584, 126.5441645],
];

export const sangsangShuttleValidServiceArea = [
    [36.50837238506808, 127.25590093056434],
    [36.49493323968106, 127.25304412104036],
    [36.49496327754012, 127.2721519628524],
    [36.508873525944935, 127.27371882649707],
];
