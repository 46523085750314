import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";

import Home from "./pages/Home";
import AdminPage from "./pages/Service/IPS/Admin";
import ShuttleAdminPage from "./pages/Service/Seogwipo/SeogwipoShuttleAdmin";
import FloatingAdminPage from "./pages/Service/Seogwipo/SeogwipoFloatingAdmin";
import FloatingPrivateAdminPage from "./pages/Service/Seogwipo/SeogwipoFloatingPrivateAdmin";
import ReserveFromStation from "./pages/Service/IPS/ReserveFromStation";
import ReservationAirport from "./pages/Service/IPS/ReserveFromAirport";
import PrivateReservationStation from "./pages/Service/IPS/PrivateReserveFromStation";
import PrivateReservationAirport from "./pages/Service/IPS/PrivateReserveFromAirport";
import InfoAirport from "./pages/Service/IPS/InfoAirport";
import InfoStation from "./pages/Service/IPS/InfoStation";
import ConfirmPage from "./pages/Service/IPS/Confirm";
import AdminLogin from "./pages/Admin/Login";
import SeogwipoMain from "./pages/Service/Seogwipo/SeogwipoMain";
import SeogwipoShuttle from "./pages/Service/Seogwipo/SeogwipoShuttle";
import SeogwipoFloating from "./pages/Service/Seogwipo/SeogwipoFloating";
import SeogwipoPrivateFloating from "./pages/Service/Seogwipo/SeogwipoPrivateFloating";
import SeogwipoFloatingConfirm from "./pages/Service/Seogwipo/SeogwipoFloatingConfirm";
import SeogwipoShuttlePassenger from "./pages/Service/Seogwipo/SeogwipoShuttlePassenger";
import JungmoonShuttleAdmin from "./pages/Service/Jungmun/JungmoonShuttleAdmin";
import JungmoonShuttlePassenger from "./pages/Service/Jungmun/JungmoonShuttlePassenger";
import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "./context/auth";
import { Helmet } from "react-helmet";
import AdminForPrivate from "./pages/Service/IPS/AdminForPrivate";
import createHistory from "history/createBrowserHistory";
import SejongShuttleAdmin from "./pages/Service/Sejong/SejongShuttleAdmin";
import TestAdminPage from "./pages/Service/TestAdmin";
import Database from "./pages/Admin/Database";
import SeogwipoFloatingDemo from "./pages/Service/Seogwipo/SeogwipoFloatingDemo";
import SeogwipoFloatingConfirmDemo from "./pages/Service/Seogwipo/SeogwipoFloatingConfirmDemo";
import SeogwipoRegister from "./pages/Service/Seogwipo/SeogwipoRegister";
import TamraShuttleAdmin from "./pages/Service/Tamra/TamraShuttleAdmin";
import TamraJungmunAdmin from "./pages/Service/Tamra/TamraJungmunAdmin";
import ScienceParkAdmin from "./pages/Service/SciencePark/ScienceParkAdmin";
import TamraMain from "./pages/Service/Tamra/TamraMain";
import TamraShuttle from "./pages/Service/Tamra/TamraShuttle";
import TamraJungmun from "./pages/Service/Tamra/TamraJungmun";
import Register from "./pages/Service/Register";
import TamraShuttleReservation from "./pages/Service/Tamra/TamraShuttleReservation";
import TamraJungmunConfirm from "./pages/Service/Tamra/TamraJungmunConfirm";
import TamraShuttleConfirm from "./pages/Service/Tamra/TamraShuttleConfirm";
import TamraShuttleReservationAdmin from "./pages/Service/Tamra/TamraShuttleReservationAdmin";
import SeogwipoEnded from "./pages/Service/Seogwipo/SeogwipoEnded";
import TamraLuggageAdmin from "./pages/Service/Tamra/TamraLuggageAdmin";
import TamraCarpool from "./pages/Service/Tamra/TamraCarpool";
import TamraShuttleAdminTest from "./pages/Service/Tamra/TamraCarpoolAdmin";
import TamraCarpoolConfirm from "./pages/Service/Tamra/TamraCarpoolConfirm";
import TamraBoardingCheck from "./pages/Service/Tamra/TamraBoardingCheck";
import SangsangMain from "./pages/Service/Sangsang/SangsangMain";
import SangsangCarpool from "./pages/Service/Sangsang/SangsangCarpool";
import SangsangCarpoolAdmin from "./pages/Service/Sangsang/SangsangCarpoolAdmin";
import SangsangCarpoolConfirm from "./pages/Service/Sangsang/SangsangCarpoolConfirm";
import NemoRideTestAdmin from "./pages/Service/NemoRide/NemoRideAdmin";
import RouteChangeTracker from "./RouteChangeTracker";
import CarSharingAdmin from "./pages/Service/CarSharing/CarSharingAdmin";
import NewTamraAdmin from "./pages/Service/NewTamra/NewTamraAdmin";
import SangAmAdmin from "./pages/Service/SangAm/SangAmAdmin";

const history = createHistory();

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authTokens: sessionStorage.getItem("token"),
            setTokens: this.setAuth,
        };
        this.vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", this.vh + "px");
        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        });
    }
    setAuth = (data) => {
        this.setState({ authTokens: data });
        sessionStorage.setItem("token", this.state.authTokens);
    };

    render() {
        const App = () => (
            <div className="App">
                <RouteChangeTracker />
                <AuthContext.Provider value={this.state} history={history}>
                    <Helmet>
                        <style>{'body { background-color: white; }'}</style>
                        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=0" />
                    </Helmet>
                    <Switch>
                        <Route exact path="/confirm" component={ConfirmPage} />
                        <Route exact path="/guide_airport" component={InfoAirport} />
                        <Route exact path="/guide_station" component={InfoStation} />
                        <Route exact path="/station" component={ReserveFromStation} />
                        <Route exact path="/airport" component={ReservationAirport} />
                        <Route exact path="/invitation/station" component={PrivateReservationStation} />
                        <Route exact path="/invitation/airport" component={PrivateReservationAirport} />
                        <Route exact path="/login" component={AdminLogin} />

                        <PrivateRoute exact path="/seogwipo/shuttle/admin" component={ShuttleAdminPage} />
                        <PrivateRoute exact path="/seogwipo/floating/admin" component={FloatingAdminPage} />
                        <PrivateRoute exact path="/seogwipo/floating/invitation/admin" component={FloatingPrivateAdminPage} />
                        <Route exact path="/seogwipo/floating/demo" component={SeogwipoFloatingDemo} />
                        <Route exact path="/seogwipo/floating/demo/confirm" component={SeogwipoFloatingConfirmDemo} />
                        <Route path="/seogwipo" component={SeogwipoEnded} />
                        {/* <Route exact path="/seogwipo/register" component={SeogwipoRegister} /> */}

                        {/* <Route exact path="/seogwipo/shuttle" component={SeogwipoShuttle} />
                        <Route exact path="/seogwipo/shuttle/roadview" component={SeogwipoShuttle} />
                        <Route exact path="/seogwipo/invitation/shuttle" component={SeogwipoShuttle} />
                        <Route exact path="/seogwipo/invitation/shuttle/roadview" component={SeogwipoShuttle} />
                        <Route exact path="/seogwipo/shuttle/passenger" component={SeogwipoShuttlePassenger} />
                        <Route exact path="/seogwipo/invitation/shuttle/passenger" component={SeogwipoShuttlePassenger} />

                        <Route exact path="/seogwipo/floating" component={SeogwipoFloating} />
                        <Route exact path="/seogwipo/invitation/floating" component={SeogwipoPrivateFloating} />
                        <Route exact path="/seogwipo/floating/confirm/:service_id" component={SeogwipoFloatingConfirm} /> */}

                        <Route exact path="/register" component={Register} />

                        <Route exact path="/mobility" component={TamraMain} />
                        <Route exact path="/mobility/shuttle" component={TamraShuttle} />
                        <Route exact path="/mobility/jungmun" component={TamraJungmun} />
                        <Route exact path="/mobility/shuttle/reservation" component={TamraShuttleReservation} />
                        <Route exact path="/mobility/jungmun/confirm" component={TamraJungmunConfirm} />
                        <Route exact path="/mobility/shuttle/confirm" component={TamraShuttleConfirm} />

                        <Route exact path="/tamra" component={TamraMain} />
                        <Route exact path="/tamra/shuttle" component={TamraShuttle} />
                        <Route exact path="/tamra/carpool" component={TamraCarpool} />
                        <Route exact path="/tamra/jungmun" component={TamraJungmun} />
                        <Route exact path="/tamra/shuttle/reservation" component={TamraShuttleReservation} />
                        <Route exact path="/tamra/jungmun/confirm" component={TamraJungmunConfirm} />
                        {/* <Route exact path="/tamra/shuttle/confirm" component={TamraShuttleConfirm} /> */}
                        <Route exact path="/tamra/carpool/confirm" component={TamraCarpoolConfirm} />

                        <PrivateRoute exact path="/admin" component={AdminPage} />
                        <PrivateRoute exact path="/invitation/admin" component={AdminForPrivate} />

                        {/* <Route exact path="/jungmun" component={JungmoonMain} /> */}
                        <Route exact path="/jungmun/shuttle/passenger" component={JungmoonShuttlePassenger} />
                        <PrivateRoute exact path="/jungmun/shuttle/admin" component={JungmoonShuttleAdmin} />

                        <PrivateRoute exact path="/sejong/shuttle/admin/:car_name" component={SejongShuttleAdmin} />
                        <PrivateRoute exact path="/sejong/shuttle/admin" component={SejongShuttleAdmin} />

                        <Route exact path="/sangsang" component={SangsangMain} />
                        <Route exact path="/sangsang/carpool" component={SangsangCarpool} />
                        <Route exact path="/sangsang/carpool/confirm" component={SangsangCarpoolConfirm} />
                        <PrivateRoute exact path="/sangsang/carpool/admin" component={SangsangCarpoolAdmin} />

                        <PrivateRoute exact path="/mobility/shuttle/admin" component={TamraShuttleAdmin} />
                        <PrivateRoute exact path="/mobility/jungmun/admin" component={TamraJungmunAdmin} />

                        <PrivateRoute exact path="/tamra/shuttle/admin" component={TamraShuttleAdmin} />
                        <PrivateRoute exact path="/tamra/carpool/admin" component={TamraShuttleAdminTest} />
                        <PrivateRoute exact path="/tamra/carpool/passenger" component={TamraBoardingCheck} />
                        <PrivateRoute exact path="/tamra/jungmun/admin" component={TamraJungmunAdmin} />
                        <PrivateRoute exact path="/tamra/luggage/admin" component={TamraLuggageAdmin} />
                        
                        <PrivateRoute exact path="/carsharing/admin" component={CarSharingAdmin} />
                        <PrivateRoute exact path="/nemoride/admin" component={NemoRideTestAdmin} />
                        <PrivateRoute exact path="/newtamra/admin" component={NewTamraAdmin} />

                        <PrivateRoute exact path="/sangam/admin" component={SangAmAdmin} />

                        <Route exact path="/science/floating/admin" component={ScienceParkAdmin} />
                        <PrivateRoute exact path="/test/admin" component={TestAdminPage} />
                        <PrivateRoute exact path="/admin/database" component={Database} />
                        <PrivateRoute exact path="/admin/reservation" component={TamraShuttleReservationAdmin} />
                        <Route component={Home} />
                    </Switch>
                </AuthContext.Provider>
            </div>
        );
        return (
            <Switch>
                <App />
            </Switch>
        );
    }
}
export default App;