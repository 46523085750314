import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Button, Col, Collapse, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, InputGroupText, Label, ListGroup, ListGroupItem, Nav, NavItem, NavLink, TabContent, Table, TabPane } from "reactstrap";
import { toDBLocaleString } from "../../Util/Common";
import CustomRadioButton1 from "../../Component/CustomRadioButton1";

const INIT_SERVICE_NAME = 'tamra_shuttle';

function TamraShuttleReservationAdmin ({
    history,
    location,
    ...props
}) {

    const [serviceName, setServiceName] = useState([]);
    const [isErrorOccured, setIsErrorOccured] = useState(undefined);
    const [activeTab, setActiveTab] = useState("1");

    useEffect(() => {
        const {
            service_name
        } = Object.fromEntries(new URLSearchParams(location.search));
        if (!!!service_name) {
            history.replace(`${location.pathname}?service_name=${INIT_SERVICE_NAME}`)
        }
        else {
            try {
                const service_arr = service_name.split("_");
                setServiceName(service_arr.map(str => str.toLowerCase()));
            }
            catch (err) {
                setIsErrorOccured({
                    location: "service_arr split error",
                    msg: err,
                });
            }
        }
    }, [location, history]);


    if (isErrorOccured) {
        return <>
            Error Occured.<br/>
            {isErrorOccured.msg}
        </>
    }
    return (
        <Container className="mt-5">
            {serviceName.join("_")}
        <Nav tabs>
            <NavItem >
                <NavLink 
                    active={activeTab==='1'}
                    onClick={() => setActiveTab('1')}
                >
                    Reservation
                </NavLink>
            </NavItem>
            <NavItem >
                <NavLink 
                    active={activeTab==='2'}
                    onClick={() => setActiveTab('2')}
                >
                    Block Time Slot
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            <TabPane tabId='1'>
                <ReservationTable 
                    serviceName={serviceName}

                />
            </TabPane>
            <TabPane tabId='2'>
                Not completed
                <BlockTable
                    serviceName={serviceName}


                />
            </TabPane>
        </TabContent>
        </Container>
    );
};

export default withRouter(TamraShuttleReservationAdmin);

const FIND_STATUS = new RegExp(/status\sIN\s\(.*\)/i);

const ReservationTable = ({
    serviceName,
    setIsErrorOccured= () => {},
}) => {

    const [reservations, setReserevations] = useState([]);
    const [filteredReservations, setFilteredReservations] = useState([]);
    const [filter, setFilter] = useState('');

    const [selectedStatus, setSelectedStatus] = useState([]);
    const [openStatusDropdown, setOpenStatusDropdown] = useState(false);


    const [selectedStartTime, setSelectedStartTime] = useState({});
    const [selectedRegisteredTime, setSelectedRegisteredTime] = useState({});
    const [showExpired, setShowExpired] = useState(true);
    

    const [statuses, setStatuses] = useState(['IMPENDING', 'RESERVED', 'SERVED', 'CANCEL']);

    const [openFilter, setOpenFilter] = useState(false);
    const [onlyReserved, setOnlyReserved] = useState(true);
    const [sort, setSort] = useState({
        key: 'start_datetime',
        order: 'DESC',
    });

    const [areaInfo, setAreaInfo] = useState({});

    const getReservation = useCallback(async (service_name, where_clause) => {
        if (service_name.length !== 2 || !service_name.every(name => !!name)) {
            setIsErrorOccured({
                msg: "Invalid ServiceName",
            })
            return;
        }
        setIsErrorOccured(undefined);
        const body = await axios.get(`/api/${service_name[0]}/${service_name[1]}/get-reservation`,{
            params: {isAdmin: true, whereClause: where_clause}
        })
        .then( res => {
            if (Array.isArray(res.data)) {
                return res.data
                .map((datum) => {
                    datum.start_datetime = new Date(datum.start_datetime);
                    datum.registered_datetime = new Date(datum.registered_datetime);
                    return datum;
                });
            }
            throw "Not Array";
        })
        .catch( err => console.error(err));
        setReserevations(body ? body : []);
    }, []);

    const getAreaInfo = useCallback(async (service_name) => {
        console.log(service_name);
        if (!Array.isArray(service_name)) {
            console.error("Invalid serviceName(must be array):", service_name);
            return;
        }
        else if (service_name.length < 2 || service_name.findIndex(t => !!!t) !== -1) {
            console.error("serviceName is undefined:", service_name);
            return;
        }
        const stop = await axios.get(`/api/${service_name[0]}/${service_name[1]}/get-stops`)
        .then(res => res.data)
        .catch(err => {
            console.error(err);
            return [];
        });

        setAreaInfo(old => {
            return {
                ...old,
                stop: stop,
            }
        })
    }, []);

    const findId = useCallback((arr, target) => {
        if (!Array.isArray(arr) || arr.length === 0) {
            return undefined;
        }
        else if (target < 1) {
            return undefined;
        }
        return arr.find(item => item.id === target);
    },[]);

    const setFilterCallback = useCallback((selected_time, key) => {
        return (old_filter) => {
            const min_reg = new RegExp(`${key} >= '[^']*'`);
            const max_reg = new RegExp(`${key} <= '[^']*'`);
            const reg_exc = [min_reg.exec(old_filter)?.[0], max_reg.exec(old_filter)?.[0]];

            let new_filter = old_filter;
            if (selected_time.min) {
                const min_str = `${key} >= '${toDBLocaleString(selected_time.min)}'`;
                if (reg_exc[0]) {
                    if (reg_exc[0] != min_str) {
                        new_filter = new_filter.replace(min_reg, min_str);
                    }
                }
                else {
                    new_filter += (new_filter.length > 0 ? ' AND ' : '') + min_str;
                }
            }
            else {
                if (reg_exc[0]) {
                    const and_req = new RegExp(`((?<=${reg_exc[0]}) AND | AND (?=${reg_exc[0]}))`);
                    new_filter = new_filter.replace(and_req, '');
                    new_filter = new_filter.replace(min_reg, '');
                }
            }
            if (selected_time.max) {
                const max_str = `${key} <= '${toDBLocaleString(selected_time.max)}'`
                if (reg_exc[1]) {
                    if (reg_exc[1] != max_str) {
                        new_filter = new_filter.replace(max_reg, max_str);
                    }
                }
                else {
                    new_filter += (new_filter.length > 0 ? ' AND ' : '') + max_str;
                }
            }
            else {
                if (reg_exc[1]) {
                    const and_req = new RegExp(`((?<=${reg_exc[1]}) AND | AND (?=${reg_exc[1]}))`);
                    new_filter = new_filter.replace(and_req, '');
                    new_filter = new_filter.replace(max_reg, '');
                }
            }
            return new_filter;
        }
    }, []);

    const addStatusInFilter = useCallback((status, old_filter) => {
        let find_status;
        if ((find_status = FIND_STATUS.exec(old_filter)) !== null) {
            
        }
        else {
            
        }
    }, []);
    const deleteStatusInFilter = useCallback((status, old_filter) => {
        let find_status;
        if ((find_status = FIND_STATUS.exec(old_filter)) !== null) {
            
        }
        else {
            
        }
    }, []);

    const onClickStatusCB = useCallback((status) => {
        return (e) => {
            if (selectedStatus.includes(status)) {
                setFilter(old_filter => addStatusInFilter(status, old_filter));
            }
            else {

            }
        }
    }, [addStatusInFilter, deleteStatusInFilter]);

    const onClickSort = useCallback((key) => {
        return (e) => {
            setSort(old => {
                const new_sort = {};
                new_sort.key = key;
                if (old.key === key) {
                    if (old.order === 'ASC') {
                        new_sort.order = 'DESC';
                    }
                    else if (old.order === 'DESC') {
                        new_sort.order = '';
                    }
                    else {
                        new_sort.order = 'ASC';
                    }
                }
                else {
                    new_sort.order = 'ASC';
                }
                return new_sort;
            });
        }
    }, []);

    const getOrderChar = useCallback((key) => {
        if (sort.key === key) {
            if (sort.order === 'ASC') {
                return '↑';
            }
            else if (sort.order === 'DESC'){
                return '↓';
            }
        }
        return '';
    }, [sort]);
    

    useEffect(() => {
        const timeout_id = setTimeout(() => getReservation(serviceName, filter), 1500);
        return () => {
            clearTimeout(timeout_id);
        }
    }, [serviceName, filter]);

    useEffect(() => {
        // setStatuses(new Set(reservations.map(reservation => reservation.status)));
    }, [reservations]);

    useEffect(() => {
        getAreaInfo(serviceName);
    }, [serviceName]);

    // useEffect(() => {
    //     setFilter(old => {
    //         const reg_ex = new RegExp(/status\sIN\s\(.*\)/);
    //         if (selectedStatus.length > 0) {
    //             if (reg_ex.test(old)) {
    //                 return old.replace(reg_ex, `status IN (${selectedStatus.map(s => `'${s}'`).join(', ')})`);
    //             }
    //             return `${old}${!!old ? " AND " : ''}status IN (${selectedStatus.map(s => `'${s}'`).join(', ')})`;
    //         }
    //         else {
    //             if (reg_ex.test(old)) {
    //                 return old.replace(reg_ex, '');
    //             }
    //             return old;
    //         }
    //     });
    // }, [selectedStatus]);

    // useEffect(() => {
    //     let find_status;
    //     if ((find_status = FIND_STATUS.exec(filter)) !== null) {
    //         const status_list = find_status[0].slice(11, -1).split(',').map(s => s.trim());
    //         setSelectedStatus(status_list.map(s => s.toUpperCase()));
    //     }
    // }, [filter]);

    useEffect(() => {
        setFilter(setFilterCallback(selectedStartTime, 'start_datetime'));
    }, [selectedStartTime, setFilterCallback]);

    useEffect(() => {
        setFilter(setFilterCallback(selectedRegisteredTime, 'registered_datetime'));
    }, [selectedRegisteredTime, setFilterCallback]);

    useEffect(() => {
        let result;
        if (onlyReserved) {
            result = reservations.filter(reservation => ['IMPENDING', 'RESERVED'].includes(reservation.status))
        }
        else {
            result = [...reservations];
        }
        if (!!sort.key && !!sort.order) {
            const k = sort.key;
            const isASC = sort.order === 'ASC';
            result.sort((a, b) => {
                if (a[k] instanceof Date && b[k] instanceof Date) {
                    if (isNaN(a[k])) {
                        return 1;
                    }
                    else if (isNaN(b[k])) {
                        return -1;
                    }
                    return (a[k].getTime() - b[k].getTime()) * (isASC ? 1 : -1);
                }
                else if (Array.isArray(a[k]) && Array.isArray(b[k])){
                    return (a[k].join('').localeCompare(b[k].join(''))) * (isASC ? 1 : -1);
                }
                else {
                    if (!!!a[k]) {
                        return 1
                    }
                    else if (!!!b[k]) {
                        return -1
                    }
                    if (a[k] < b[k]) {
                        return (isASC ? -1 : 1);
                    }
                    else if (a[k] > b[k]){
                        return (isASC ? 1 : -1);
                    }
                    else {
                        return 0;
                    }
                }
            });
        }
        setFilteredReservations(result);
    }, [onlyReserved, reservations, sort]);

    return (
        <Container className="">
            <Container className="d-flex justify-content-between">
                <Button
                    // className="float-left"
                    color="primary" onClick={() => setOpenFilter(o => !o)}
                >
                    Filter
                </Button>
                <FormGroup
                    check inline
                    onClick={(e) => {
                        setOnlyReserved(o => !o);
                    }}
                >
                    <Input 
                        type="checkbox"
                        checked={onlyReserved}
                        readOnly
                    />
                    <Label check>미완료만 보기</Label>
                </FormGroup>
            </Container>
            <Collapse isOpen={openFilter}>
                <Container className="gap-3 my-3">
                    <Col className="gap-3">
                        <Input 
                            type='text'
                            value={filter}
                            placeholder="MYSQL where clause"
                            onChange={(e) => setFilter(e.target.value)}
                        />
                        <Form>
                            {/* <Dropdown
                                className="input-group"
                                isOpen={openStatusDropdown}
                                toggle={() => setOpenStatusDropdown(o => !o)}
                            >
                                <InputGroupText style={{width: "150px", justifyContent: 'flex-end'}}>status</InputGroupText>
                                <DropdownToggle
                                    className="form-control"
                                    aria-expanded
                                    data-toggle="dropdown"
                                    tag="div"
                                >
                                    {selectedStatus.join(', ')}
                                </DropdownToggle>
                                <DropdownMenu
                                    className="list-group-flush py-0"
                                >
                                    {statuses.map(status => 
                                        <ListGroupItem
                                            key={status}
                                            active={selectedStatus.includes(status)}
                                            action
                                            onClick={onClickStatusCB(status)}
                                        >
                                            {status}
                                        </ListGroupItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown> */}
                            <InputGroup
                                onChange={(e) => {
                                    const {
                                        value, id
                                    } = e.target;
                                    if (id.startsWith('min')) {
                                        setSelectedStartTime(old => ({
                                            ...old,
                                            min: value
                                        }));
                                    }
                                    else {
                                        setSelectedStartTime(old => ({
                                            ...old,
                                            max: value
                                        }));
                                    }
                                }}
                            >
                                <InputGroupText style={{width: "150px", justifyContent: 'flex-end'}}>StartTime</InputGroupText>
                                <Input className="" style={{maxWidth:"200px"}} id="min-start-datetime" type="datetime-local"/>
                                <span className="mx-3"> ~ </span>
                                <Input className="" style={{maxWidth:"200px"}} id="max-start-datetime" type="datetime-local"/>
                            </InputGroup>
                            <InputGroup
                                onChange={(e) => {
                                    const {
                                        value, id
                                    } = e.target;
                                    if (id.startsWith('min')) {
                                        setSelectedRegisteredTime(old => ({
                                            ...old,
                                            min: value
                                        }));
                                    }
                                    else {
                                        setSelectedRegisteredTime(old => ({
                                            ...old,
                                            max: value
                                        }));
                                    }
                                }}
                            >
                                <InputGroupText style={{width: "150px", justifyContent: 'flex-end'}}>RegisteredTime</InputGroupText>
                                <Input className="" style={{maxWidth:"200px"}} id="min-registered-datetime" type="datetime-local"/>
                                <span className="mx-3"> ~ </span>
                                <Input className="" style={{maxWidth:"200px"}} id="max-registered-datetime" type="datetime-local"/>
                            </InputGroup>
                            {/* <InputGroup>
                                <InputGroupText style={{width: "100px"}}>phone</InputGroupText>
                                <Dropdown isOpen={openCollapse}
                                    toggle={() => {setOpenCollapse(o=>!o)}}
                                >
                                    <Input 
                                        style={{width:"200px", overflowY: "hidden", height: "calc(1.5em + 0.75rem + 2px)"}}
                                        type="text" 
                                        onClick={(e) => {
                                            setOpenCollapse(o => !o)
                                        }}
                                    />
                                    <DropdownMenu
                                        style={{top:"100%", width:"100%", }}
                                        onClick={(e) => {
                                            console.log(e, e.currentTarget);
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                    >
                                        <DropdownItem active={true}>asdd</DropdownItem>
                                        <DropdownItem active={true}>asdd</DropdownItem>
                                        <DropdownItem active={true}>asdd</DropdownItem>
                                        <DropdownItem active={true}>asdd</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </InputGroup> */}
                        </Form>
                    </Col>
                </Container>
            </Collapse>
            <Table striped>
                <thead>
                    <tr>
                        <th style={{cursor: 'pointer', userSelect: 'none'}} onClick={onClickSort('status')}>status {getOrderChar('status')}</th>
                        <th style={{cursor: 'pointer', userSelect: 'none'}} onClick={onClickSort('start_datetime')}>탑승 시각 {getOrderChar('start_datetime')}</th>
                        <th style={{cursor: 'pointer', userSelect: 'none'}} onClick={onClickSort('phone')}>phone {getOrderChar('phone')}</th>
                        <th style={{cursor: 'pointer', userSelect: 'none'}} onClick={onClickSort('start_stop_id')}>탑승 정류장 {getOrderChar('start_stop_id')}</th>
                        <th style={{cursor: 'pointer', userSelect: 'none'}} onClick={onClickSort('end_stop_id')}>하차 정류장 {getOrderChar('end_stop_id')}</th>
                        <th style={{cursor: 'pointer', userSelect: 'none'}} onClick={onClickSort('car_name')}>차량 번호 {getOrderChar('car_name')}</th>
                        <th style={{cursor: 'pointer', userSelect: 'none'}} onClick={onClickSort('registered_datetime')}>등록 시각 {getOrderChar('registered_datetime')}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredReservations.map(reservation => {
                        const s_date = reservation.start_datetime;
                        const r_date = reservation.registered_datetime;
                        const start_stop = findId(areaInfo.stop, reservation.start_stop_id);
                        const end_stop = findId(areaInfo.stop, reservation.end_stop_id);
                        return (
                            <tr key={`tr-${reservation.id}`}>
                                <td>
                                    {reservation.status}
                                </td>
                                <td>
                                    {`${s_date.getFullYear()}/${String(s_date.getMonth()+1).padStart(2,'0')}/${String(s_date.getDate()).padStart(2,'0')} ${String(s_date.getHours()).padStart(2,'0')}:${String(s_date.getMinutes()).padStart(2,'0')}`}
                                </td>
                                <td style={{wordBreak: "break-word"}}>
                                    {reservation.phone ? reservation.phone.map(p => <div key={`${reservation.id}-phone=${p}`}>{p}</div>) : undefined}
                                </td>
                                <td>{start_stop ? start_stop.name : ''}</td>
                                <td>{end_stop ? end_stop.name : ''}</td>
                                <td>{reservation.car_name}</td>
                                <td>
                                    {!isNaN(r_date) ? `${r_date.getFullYear()}/${String(r_date.getMonth()+1).padStart(2,'0')}/${String(r_date.getDate()).padStart(2,'0')} ${String(r_date.getHours()).padStart(2,'0')}:${String(r_date.getMinutes()).padStart(2,'0')}` : undefined}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Container>
    );
}

const BlockTable = ({
    serviceName,
    setIsErrorOccured= () => {},
}) => {

    const [blockDates, setBlockDates] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);

    const getBlockDate = useCallback(async (service_name) => {
        if (!!!service_name || service_name.length !== 2 || !service_name.every(name => !!name)) {
            setIsErrorOccured({
                msg: "Invalid ServiceName",
            })
            return;
        }
        setIsErrorOccured(undefined);
        const body = await axios.get(`/api/tamra/block/get-block-date`,{
            service_name,
        })
        .then( res => {
            if (Array.isArray(res.data)) {
                return res.data.sort((a, b) => {
                    return new Date(a.start_datetime) < new Date(b.start_datetime);
                });
            }
            throw "Not Array";
        })
        .catch( err => {
            console.error(err);
            return [];
        });

        setBlockDates(body);
    }, []);

    const onClickDateItem = useCallback((e) => {
        const value = e.target.value;
        setSelectedDates(old_date => {
            if(old_date.includes(value)) {
                return [...old_date, value];
            }
            else {
                return old_date.filter(date => date !== value);
            }
        });
    }, []);

    useEffect(() => {
        const timeout_id = setTimeout(() => getBlockDate(serviceName), 1500);
        return () => {
            clearTimeout(timeout_id);
        }
    }, [serviceName]);

    return <Container>
        <Table>
            <thead>
                <tr>
                    <th></th>
                    <th>start</th>
                    <th>end</th>
                    <th>car name</th>
                    <th>description</th>
                </tr>
            </thead>
            <tbody>
                {blockDates.map(date => 
                    <tr key={`block-date-${date.id}`}>
                        <td>
                            <CustomRadioButton1 
                                type="checkbox"
                                id={`block-date-${date.id}`}
                                value={date.id}
                                checked={selectedDates.includes(date.id)}
                                onChange={onClickDateItem}
                            />
                        </td>
                        <td>{toDBLocaleString(date.start_datetime)}</td>
                        <td>{toDBLocaleString(date.end_datetime)}</td>
                        <td>{date.car_name}</td>
                        <td>{date.description}</td>
                    </tr>
                )}
            </tbody>
        </Table>
        
    </Container>
}