import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from "reactstrap";
import '../style/Common.css';

const agreementText = (
    <div className='agreement-modal-content'>
        <h2>
            라이드플럭스 자율주행자동차
            <br />
            탑승 동의서
        </h2>
        <ol>
            <li>안전한 운행을 위한 유의사항 및 승객 고지사항 및 동의사항</li>
            <ul>
                {[
                    "상기 차량 및 본 시범 서비스의 제어권은 동승한 세이프티 드라이버 및 (주)라이드플럭스에 있으며, 탑승이 이루어지는 동안 탑승자는 세이프티 드라이버의 지시를 준수해야 합니다. 세이프티 드라이버에게 위해를 가하는 언행을 행하는 경우, 자율주행 차량 운행에 방해가 되는 행동을 하는 경우, 차량 내부 시설물을 훼손하거나 훼손할 가능성이 있는 행동을 하는 경우 세이프티 드라이버 또는 (주)라이드플럭스는 해당 탑승자의 탑승을 거부할 수 있으며, 탑승자의 언행으로 인해 손해가 발생할 경우 탑승자는 법적 책임을 부담할 수 있습니다.",
                    "연구개발 및 시범 서비스 주행 안정성 확인을 위해 탑승 서비스를 제공하는 동안 차량 내부 녹화 및 음성 녹음이 진행되며, 해당 내용은 향후 당사의 연구 목적으로 활용될 수 있습니다.",
                    "시범 서비스 운행 중에는 반드시 안전벨트를 착용하여 주시기 바랍니다.",
                    "상기 차량의 운행과 관련된 일체의 자료는 당사의 영업비밀에 해당되어 탑승자가 임의의 목적으로 촬영∙녹음할 수 없으며, 이로 인한 손해가 발생할 경우 법적 책임을 부담할 수 있습니다.",
                    "세이프티 드라이버는 안전한 운행을 위해 수동으로 차량을 운행할 수 있습니다.",
                    "만 6세 이하의 아동은 안전상의 이유(카시트 미설치)로 시승이 제한되며, 만 6세~만 13세의 미성년자는 법정대리인의 동의 하에 법정대리인과 동승하여 시승이 가능합니다.",
                    "동물은 이동가방에 넣은 반려동물 및 공인기관에서 인증한 맹인 인도견에 한해 동반 탑승이 가능합니다.",
                ].map((list, idx) => (
                    <li key={idx}>{list}</li>
                ))}
            </ul>
            <li>동의 거부 권리</li>
            <ul>
                <li>탑승자는 본 안내에 따른 탑승 고지사항 및 동의사항에 거부하실 권리가 있습니다. 다만, 탑승 고지사항 및 동의사항에 거부할 경우 서비스 이용에 어려움이 있음을 알려드립니다.</li>
            </ul>
        </ol>
        <h2>개인정보 수집∙이용 동의서</h2>
        <p>(주)라이드플럭스는 [개인정보보호법]에 의거하여, 자율주행 자동차 시범 서비스 제공을 위해 아래와 같은 내용으로 개인정보를 수집하고 있으며, 탑승자가 제공한 모든 정보는 다음의 목적을 위해 활용하며, 하기 목적 이외의 용도로는 사용되지 않습니다.</p>
        <ol>
            <li>개인정보 수집 항목 및 수집∙이용 목적</li>
            <ul>
                <li>수집 항목 (필수항목)</li>
                <ul>
                    <li>성명, 생년월일, 휴대전화번호, 위치정보 및 운행 영상 및 음성</li>
                </ul>
                <li>수집 및 이용 목적</li>
                <ul>
                    <li>시범서비스 제공</li>
                    <li>사고 발생시 대응 및 보험처리</li>
                    <li>도난방지</li>
                    <li>서비스 이용에 대한 통계</li>
                    <li>서비스 개선을 위한 설문조사</li>
                    <li>그 외 민원처리</li>
                </ul>
            </ul>
            <li>개인정보 보유 및 이용기간</li>
            <ul>
                <li>수집∙이용 동의일로부터 1년</li>
            </ul>
            <li>동의 거부 권리</li>
            <ul>
                <li>탑승자는 본 안내에 따른 탑승 고지사항 및 동의사항에 거부하실 권리가 있습니다. 다만, 탑승 고지사항 및 동의사항에 거부할 경우 서비스 이용에 어려움이 있음을 알려드립니다.</li>
            </ul>
        </ol>
    </div>
);

const agreementShortTextContent = <>
        <h3 className="agree-info-title">동의사항에 관한 안내</h3>
        <ul className="agree-info-content">
            <li>안전을 위해 탑승 중 안전을 위해 탑승 동안 <b>차량 내부 녹화 및 음성 녹음</b>이 진행되고 있습니다.</li>
            <li>상기 차량의 운행과 관련한 일체 자료는 당사의 영업비밀에 해당되므로, 탑승자는 차량 운행과 관련하여 <b>무단으로 녹음, 촬영 및 배포할 수 없습니다.</b></li>
            <li>탑승자의 안전을 위해 전문 교육을 받은 <b>세이프티 드라이버 2명</b>이 동승합니다.</li>
            <li>차량 트렁크에는 <b>수하물을 실을 수 없습니다.</b></li>
            <li>안전상의 이유로 <b>만 6세 미만의 아동은 탑승이 제한</b>되며, 만 6세~만 13세의 미성년자는 법정대리인의 동의 하에 탑승이 가능합니다.</li>
            <li>서비스 제공 등을 위해 <b>개인정보를 수집 및 이용</b>합니다.</li>
        </ul>
</>;

const appendingBRtag = (text) => {
    if (typeof text === 'string') {
        return <>
            {text.split('\n').reduce((_prev, _cur, _idx, _arr) => {
                _prev.push(_cur);
                if (_idx < _arr.length - 1) {
                    _prev.push(<br/>);
                }
                return _prev;
            },[])}
        </>
    }
    else if (Array.isArray(text)) {
        return <>
            {text.reduce((_prev, _cur, _idx, _arr) => {
                _prev.push(_cur);
                if (_idx < _arr.length - 1) {
                    _prev.push(<br/>);
                }
                return _prev;
            }, [])}
        </>
    }
    return text;
}

const agreementMapperTop = (_agreement_text, _agreement_idx) => {
    if (typeof(_agreement_text) !== 'object' || _agreement_text === null) {
        return _agreement_text;
    }
    return <>
        <div className="title" key={`agreement-chapter-${_agreement_idx}-title`}>
            {appendingBRtag(_agreement_text.title)}
        </div>
        <div className="data" key={`agreement-chapter-${_agreement_idx}-content`}>
            {Array.isArray(_agreement_text.data) ? 
                _agreement_text.data.map(agreementMapperDetail) :
                _agreement_text.data
            }
        </div>
    </>
}
const agreementMapperDetail = (_agreement_detail, _agreement_detail_idx) => {
    if (typeof(_agreement_detail) !== 'object' || _agreement_detail === null) {
        return _agreement_detail;
    }
    return <div className='box'>
        <div className="title" key={`agreement-chapter-${_agreement_detail_idx}-title`}>
            {appendingBRtag(_agreement_detail.title)}
        </div>
        <div className="data" key={`agreement-chapter-${_agreement_detail_idx}-content`}>
            {appendingBRtag(_agreement_detail.data)}
        </div>
    </div>
}


export const AgreementModal = ({
    open,
    toggle = () => {},
    headerProps = {visible: true},
    bodyProps = {},
    footerProps = {buttonProps: {}},
    ...props
}) => {
    const {buttonProps: _buttonProps, ..._footerProps} = footerProps;

    return (
        <Modal {...props} isOpen={open} toggle={toggle} >
            {headerProps.visible ? <ModalHeader toggle={toggle} {...headerProps} >동의서 전문</ModalHeader> : null}
            <ModalBody {...bodyProps} >
                {agreementText}
            </ModalBody>
            <ModalFooter {..._footerProps} >
                <Button {..._buttonProps} onClick={toggle}>닫기</Button>
            </ModalFooter>
        </Modal>
    )
}

export const AgreementShorTextComponent = ({
    className = "consent-form jumbotron",
    buttonTag = 'button',
    buttonWrapperProps = {className: ''},
    buttonProps = {className: "btn-consent-form"},
    modalProps = {},
    ...props
}) => {

    const [open, setOpen] = React.useState(false);
    const toggle = React.useCallback(() => setOpen(open => !open), []);

    const btn = React.useMemo(() => {
        const text = "동의서 전문 확인하기"
        switch (buttonTag) {
            case 'button':
                return <Button 
                    {...buttonProps}
                    onClick={toggle} 
                >{text}</Button>
            case 'div' :
                return <div 
                    {...buttonProps}
                    onClick={toggle} 
                >{text}</div>
            default :
                return null;
        }
    }, [buttonTag, buttonProps, toggle]);

    return (
        <div {...props} className={className}>
            {agreementShortTextContent}
            <div {...buttonWrapperProps}>
                {btn}
            </div>
            <AgreementModal
                {...modalProps}
                open={open}
                toggle={toggle}
            />
        </div>
    )
};
