import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";

import LogoutButton from "../../Component/LogoutButton";
import SelectCar from "../../Component/SelectCar";
import AdminTopBar from "../../Component/TopBar";
import DarkModeButton from "../../Component/DarkModeButton";
import { useInterval } from "../../Hooks/Hooks";

import '../../style/Sangam.css';

const LOCATION = "Sangam";
const SERVICE_NAME = "Sangam_Test";
const INIT_CARINFO = {
  car_timeout: true,
  current_service: "",
};
const DESTINATION_TYPE = {
  WayPoint: 'WayPoint',
  Destination: 'Destination',
}

const SangAmAdmin = ({ history, location }) => {
  const query = Object.fromEntries(new URLSearchParams(location.search));
  const {
    car_name
  } = query;

  const [carName, setCarName] = useState(car_name);
  const [carInfo, setCarInfo] = useState(INIT_CARINFO);
  const [stops, setStops] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getState = useCallback(async() => {
    try {
      const res = await axios.get(
        `/api/${LOCATION}/state`,
        { params: { car_name: carName } }
      );
      setCarInfo(res.data)
    }
    catch (err) {
      setCarInfo(INIT_CARINFO);
    }
  }, [carName]);

  const selectCarCallback = useCallback((car_name) => {
    setCarName(car_name);
    history.replace({
      search: `?car_name=${car_name}`
    });
  },[history]);

  const turnServiceOn = useCallback(async (car_name) => {
    setIsLoading(true);
    await axios.put(
      `/api/${LOCATION}/turn-service-on`,
      { car_name }
    )
    .catch((err) => {
      window.alert(`호출 켜기에 실패했습니다. (${err.response.statusText})`)
    })
    .then(() => setIsLoading(false));
    getState();
  }, [getState]);
  const turnServiceOff = useCallback(async (car_name) => {
    setIsLoading(true);
    await axios.put(
      `/api/${LOCATION}/turn-service-off`,
      { car_name }
    ).catch((err) => {
      window.alert(`호출 끄기에 실패했습니다. (${err.response.statusText})`)
    })
    .then(() => setIsLoading(false));
    getState();
  }, [getState]);

  const endTrip = useCallback(async (car_name) => {
    setIsLoading(true);
    await axios.put(
      `/api/${LOCATION}/end-trip`,
      { car_name }
    ).catch((err) => {
      window.alert(`Trip 종료에 실패했습니다. (${err.response.statusText})`)
    })
    .then(() => setIsLoading(false));
    getState();
  }, [getState]);

  const goToDestination = useCallback(async (car_name, stop) => {
    setIsLoading(true);
    await axios.put(
      `/api/${LOCATION}/go-to-destination`,
      { car_name, stop }
    ).catch((err) => {
      window.alert(`목적지 전송에 실패했습니다\n(${err.response?.data || err.response.statusText})`)
    })
    .then(() => setIsLoading(false));
    getState();
  }, [getState]);

  const turnOnOffSwitch = useMemo(() => {
    const {
      current_service = "",
    } = carInfo;

    if (!!current_service) {
      if (current_service.toUpperCase() !== SERVICE_NAME.toUpperCase()) {
      }
    }
    return current_service ?
      <Button
        className="sangam-btn service-btn"
        onClick={turnServiceOff.bind(this, carName)}
        color='danger'
        disabled={isLoading}
      >
        Service Off
      </Button>
      : <Button
        className="sangam-btn service-btn"
        onClick={turnServiceOn.bind(this, carName)}
        color='primary'
        disabled={isLoading}
      >
        Service On
      </Button>
  }, [carName, carInfo, isLoading, turnServiceOff, turnServiceOn, ]);
  
  const destinationBtn = useMemo(() => {
    const {
      current_target_destination,
      trip_on,
    } = carInfo;

    const waypoint_stops = stops.filter((stop) => stop.service_description === DESTINATION_TYPE.WayPoint);
    const destination_stops = stops.filter((stop) => stop.service_description === DESTINATION_TYPE.Destination);

    const stop_btns = <>
      {trip_on && destination_stops.map((stop) => (<Button 
        className="sangam-btn destination-btn"
        disabled={isLoading}
        onClick={goToDestination.bind(this, carName, stop)}
        color='success'
      >
        {stop?.name ?? "???"}
      </Button>))}
      {waypoint_stops.map((stop) => (<Button 
        className="sangam-btn destination-btn"
        disabled={isLoading}
        onClick={goToDestination.bind(this, carName, stop)}
        color='success'
      >
        {stop?.name ?? "???"}
      </Button>))}
    </>

    return <div className="destination-btns">
      {trip_on && 
          <div className="current-destionation">
          {current_target_destination ? 
            <>현재 목적지는 <b>{current_target_destination?.name}</b> 입니다.</>
          : <>현재 목적지가 설정되지 않았습니다.</>
          }
        </div>
      }
      {stop_btns}
    </div>
  }, [carName, carInfo, stops, isLoading, goToDestination])

  const tripBtn = useMemo(() => {
    const {
      trip_on,
    } = carInfo;

    return trip_on &&
      <Button className="sangam-btn trip-btn" disabled={isLoading} onClick={endTrip.bind(this, carName)}>
        End Trip
      </Button>
  }, [carName, carInfo, isLoading, endTrip]);

  useEffect(() => {
    const bodyElt = document.querySelector("body");
    bodyElt.style.backgroundColor = "#F9DEDE";
    async function init() {
      const res = await axios.get(`/api/${LOCATION}/stops`);
      setStops(res.data);
    };
    init()
  }, []);

  useEffect(() => {
    setCarInfo(INIT_CARINFO);
  }, [carName]);

  useEffect(() => {
    getState();
  }, [getState]);

  useInterval(getState, 1000);

  return <div style={{ height: "100%" }}>
    <AdminTopBar>
      <LogoutButton history={history} />
      <DarkModeButton />
      <SelectCar selectedCar={carName} selectCallback={selectCarCallback} />
    </AdminTopBar>
    <div
      style={{
        margin: "auto",
        width: "70%",
        paddingBlock: "5vh",
        textAlign: "center",
      }}
    >
      <h1 style={{display:"flex", justifyContent: 'center', alignItems:'center'}}>
        <b> - </b>
        <b style={{wordBreak: 'keep-all'}}>&nbsp;상암 무인 운영자 페이지&nbsp;</b>
        <b> - </b>
      </h1>
      {carInfo.car_timeout ? 
        <div>
          차량의 위치를 확인할 수 없어 운행를 시작할 수 없습니다. 
        </div> : 
        <>
          {!!carInfo.current_service && carInfo.current_service.toUpperCase() !== SERVICE_NAME.toUpperCase() ? 
            <div>
              다른 사용자 페이지에서 운행 중이거나 서비스명이 일치하지 않습니다.
            </div>
            :
            <>
            <br/>
            <div className="control-panel">
              {turnOnOffSwitch}
              {carInfo.service_on && tripBtn}
            </div>
            <hr />
            {carInfo.service_on && destinationBtn}
            </>
          }
        </>
      }
    </div>
  </div >
}

export default withRouter(SangAmAdmin);
