import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { MdArrowDownward } from 'react-icons/md';
import { Button, ButtonGroup, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

class JungmoonAdminPage extends Component {
    state = {
        car_timeout: true,
        service_on: false,
        trip_on: false,
        audio_guidance_destination: ["목적지 없이 출발", "공항으로 출발", "중문으로 출발"],
        selected_audio_guidance_code: 0,
        passenger_info: [],
        audianceDropdownOpen: false,

        current_reservation: undefined,
        selected_reservation: undefined,

        reservations: [],
        reservationDropdownOpen: false,
        stops: [],
    };

    async getCallState() {
        await axios
            .get("/api/jungmun/shuttle/getCallState", {
                params: {
                    isClient: false
                }
            })
            .then(function (res) {
                if (res.statusText === "OK") {
                    return res.data;
                } else {
                    return {
                        car_timeout: true,
                        car_location: undefined,
                        service_on: false,
                        trip_on: false,
                    };
                }
            })
            .catch(function (error) {
                console.error("error while request GET to server");
                console.log(error);
                return {
                    car_timeout: true,
                    car_location: undefined,
                    service_on: false,
                    trip_on: false,
                };
            })
            .then((data) => {
                this.setState({
                    passenger_info: data.passenger,
                    car_timeout: data.car_timeout,
                    car_location: data.car_location,
                    service_on: data.service_on,
                    trip_on: data.trip_on,
                    current_reservation: this.state.reservations.find(reservation => reservation.id === data.current_reservation_id),
                });
            }
            );
    }

    async disableService() {
        await axios.put("/api/jungmun/shuttle/disableService")
        .then(function (res) {
            if (res.statusText === "OK") {
            } else {
                alert("서비스 종료에 실패했습니다.");
            }
        })
        .catch(function (error) {
            alert("서비스 종료에 실패했습니다.\n(catch error)");
        })
        .then((data) => {
            this.setState({
                passenger_info: [],
                trip_on: false
            });
        });
    }

    async ableService(departure_stop, destination_stop) {
        await axios.put("/api/jungmun/shuttle/ableService", {
            'location_data': { 
                'departure': {name: departure_stop.name, location: [departure_stop.location?.latitude, departure_stop.location?.longitude]},
                'destination': {name: destination_stop.name, location: [destination_stop.location?.latitude, destination_stop.location?.longitude]} }
        })
        .then(function (res) {
            if (res.statusText === "OK") {
                return true;
            } else {
                alert("서비스 등록에 실패했습니다.");
            }
        })
        .catch(function (error) {
            alert("서비스 등록에 실패했습니다.\n(catch error)");
        })
        .then((data) => {
            if (data === undefined) {
                return;
            }
            this.setState({
                passenger_info: [],
                trip_on: true
            });
        });
    }

    async turnOnService() {
        const { selected_reservation } = this.state

        await axios.put("/api/jungmun/shuttle/onService",{
            current_reservation_id: selected_reservation?.id,
            num_passenger: selected_reservation?.num_passenger,
        })
        .then((res) => {
            if (res.statusText === "OK") {
                this.setState({
                    service_on: true,
                    current_reservation: selected_reservation
                });
            }
            else {
                alert("서비스 켜기에 실패했습니다.");
            }
        })
        .catch(function (error) {
            console.log(error)
            alert("서비스 켜기에 실패했습니다.\n(catch error)");
        });
    }
    async turnOffService() {
        await axios.put("/api/jungmun/shuttle/offService")
        .then((res) => {
            if (res.statusText === "OK") {
                this.setState({
                    passenger_info: [],
                    service_on: false,
                    trip_on: false,
                })
            } else {
                alert("서비스 끄기에 실패했습니다.");
            }
        })
        .catch(function (error) {
            alert("서비스 끄기에 실패했습니다.\n(catch error)");
        });
    }

    async getReservation() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        const {data} = await axios.get("/api/jungmun/shuttle/getReservation", {
            params: {
                min_time: today.toDateString(),
                max_time: tomorrow.toDateString(),
                status: "RESERVED",
            }
        });        
        if (Array.isArray(data)) {
            this.setState(prev => ({
                ...prev,
                reservations: data
            }))
        }
    }
    async getStops() {
        const stops = await axios.get("/api/jungmun/shuttle/get-stops")
        .then(res => res.data)
        .catch(err => {
            console.error(err);
            return [];
        })
        this.setState({stops});
    }

    async componentDidMount() {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = '#F9DEDE';
        await Promise.all([this.getReservation(), this.getStops()]);
        await this.getCallState();
        this.intervalId = setInterval(() => this.getCallState(), 2000);
    }
    async componentWillUnmount() {
        if (this.intervalId !== undefined) {
            clearInterval(this.intervalId);
        }
    }

    findStop(stop_id) {
        return (stop) => stop.id === stop_id;
    }
    async redirect(path) {
        clearInterval(this.intervalId);
        this.props.history.push(path);
    }

    async logout() {
        sessionStorage.removeItem("token");
        this.props.history.go(0);
    }

    getLogOutBtn() {
        return (<div
            style={{
                margin: "auto",
                width: "90%",
                padding: "10px 0",
                textAlign: "right",
                color: "black",
            }}
        >
            <Button sm={10} onClick={() => this.logout()}>Log Out</Button>
        </div>);
    }
    getCourseBtn(departure_stop = {}, destination_stop = {}, color = "warning", style = { width: "100%", marginTop: "5%" }) {
        return (
            <Button
                style={style}
                color={color}
                onClick={this.ableService.bind(this, departure_stop, destination_stop)}
            >
                <span style={{ color: "white" }}>
                    {departure_stop.name}
                    <br />
                    <MdArrowDownward></MdArrowDownward>
                    <br />
                    {destination_stop.name}
                </span>
            </Button>
        );
    }

    getCourseBtns() {
        const { stops } = this.state;
        
        const air_port_stop = stops.find(stop => stop.name === '공항');
        const other_stops = stops.filter(stop => stop.name !== '공항');

        if (!air_port_stop || !other_stops.length) {
            return null;
        }

        return (
            <div style={{
                display: "flex",
                width: "100%",
                gap: "20px",
                justifyContent: "center",
            }}>
                <div style={{
                    width: "45%",
                    maxWidth: "250px",
                    wordBreak: "break-all"
                }}>
                    <ButtonGroup vertical style={{
                        fontSize: "10px",
                        textAlign: "center",
                        width: "100%"
                    }}>
                        {other_stops.map(stop => this.getCourseBtn(air_port_stop, stop, "info"))}
                    </ButtonGroup>

                </div>

                <div style={{
                    width: "45%",
                    maxWidth: "250px",
                    wordBreak: "break-all"
                }}>
                    <ButtonGroup vertical style={{
                        fontSize: "10px",
                        textAlign: "center",
                        width: "100%"
                    }}>
                        {other_stops.map(stop => this.getCourseBtn(stop, air_port_stop, "success"))}
                    </ButtonGroup>
                </div>
            </div>
        );
    }
    selectDestination(index) {
        this.setState({
            selected_audio_guidance_code: index,
        }, () => { });
    }

    getDropdownItem({
        name,
        value,
        key,
        onClick=()=>{}
    }) {
        return (<DropdownItem name={name} value={value} key={key} onClick={onClick} >{name}</DropdownItem>)
    }
    async sendSoundTrigger(){
        await axios.put("/api/jungmun/shuttle/audio_guidance", {
            audio_guidance_code: this.state.selected_audio_guidance_code
        }).then(function (res) {
            if (res.statusText === "OK") {
                return true;
            } else {
                alert("음성안내에 실패했습니다.");
            }
        }).catch(function (error) {
            alert("음성안내에 실패했습니다.");
        })
    }

    getAudianceDropdown() {
        if (this.state.audio_guidance_destination !== undefined) {
            const { audianceDropdownOpen } = this.state;
            let items = []
            for (const idx in this.state.audio_guidance_destination) {
                let dest = this.state.audio_guidance_destination[idx];
                items.push(this.getDropdownItem({
                    name: dest,
                    value: dest,
                    onClick: this.selectDestination.bind(this, idx)
                }));
            }
            return (
                <div style={{ textAlign: "center" }}>
                    <ButtonGroup>
                        <ButtonDropdown direction="down" isOpen={audianceDropdownOpen} toggle={() => this.setState({ audianceDropdownOpen: !this.state.audianceDropdownOpen })}>
                            <DropdownToggle caret>
                                {this.state.audio_guidance_destination[this.state.selected_audio_guidance_code]}
                            </DropdownToggle>
                            <DropdownMenu name="cars" >
                                {items}
                            </DropdownMenu>
                        </ButtonDropdown>
                        <Button size="md" color="success" onClick={this.sendSoundTrigger.bind(this)}>음성안내</Button>
                    </ButtonGroup>
                </div>
            )
        } else {
            return (<div />)
        }
    }
    
    convertReservation(reservation) {
        const {
            stops
        } = this.state
        try {
            const start_datetime = new Date(reservation.start_datetime);
            if (isNaN(start_datetime)) {
                throw "NaN";
            }
            const start_time = start_datetime.toLocaleTimeString().slice(0, -3);
            const {
                phone,
                start_stop_id,
                end_stop_id,
                num_passenger,
            } = reservation;
            
            const start_stop = stops.find(this.findStop(start_stop_id));
            const end_stop = stops.find(this.findStop(end_stop_id));


            return {
                time: `${start_time}`,
                phone: `${phone}`,
                path: `${start_stop.name} -> ${end_stop.name}`,
                num: `${num_passenger} 명`,
            }
        }
        catch (err) {
            return {
                time: '',
                phone: '',
                path: '',
                num: ``,
            };
        }
    }

    getServiceBtn() {
        const { service_on, trip_on, selected_reservation, current_reservation } = this.state;
        const service_btn = service_on ? 
            trip_on ? null : 
            <Button
                style={{width: "250px", marginTop: '1rem'}}
                color="danger"
                onClick={this.turnOffService.bind(this)}
            >
                {current_reservation ? "예약 종료" : "끄기"}
            </Button>: 
            <Button
                style={{width: "250px", marginTop: '1rem'}}
                color="primary"
                onClick={this.turnOnService.bind(this)}
            >
                {!selected_reservation ? "바로 켜기" : "예약 시작"}
            </Button>
        return service_btn;
    }
    getReservationDropdown() {
        const {
            reservations,
            reservationDropdownOpen,
            selected_reservation = {},
            service_on,
            current_reservation = {}
        } = this.state;
        
        const displayed_reservation = service_on ? current_reservation : selected_reservation;
        const displayed_reservation_str = this.convertReservation(displayed_reservation);
        
        return (
            <div className="mt-3 text-center">
            <ButtonDropdown style={{width: "100%", maxWidth: "500px"}} direction="down" isOpen={reservationDropdownOpen} toggle={() => {this.setState({reservationDropdownOpen: !reservationDropdownOpen})}}>
                <DropdownToggle caret disabled={service_on}>
                    {displayed_reservation.id ? `${displayed_reservation_str.time} | ${displayed_reservation_str.path}` : "예약 선택"}
                </DropdownToggle>
                <DropdownMenu style={{width: "100%", maxWidth: "500px"}} name="reservations" >
                    {reservations.map((reservation, idx) => {
                        const reservation_string = this.convertReservation(reservation);
                        return this.getDropdownItem({
                            name: `${reservation_string.time} | ${reservation_string.path}`,
                            value: idx,
                            onClick: (e) => {
                                const index = e.target.value;
                                this.setState({selected_reservation: reservations[index]})
                            }
                        })
                    })}
                    <DropdownItem onClick={() => this.setState({selected_reservation: undefined})}>선택 하지 않음</DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
            {displayed_reservation.id ? 
            <table className="mt-3" style={{width: "100%", maxWidth: "500px",margin:"auto", textAlign: "left"}}>
                <tbody >
                    <tr >
                        <td>경로</td>
                        <td>{displayed_reservation_str.path}</td>
                    </tr>
                    <tr>
                        <td>인원 수</td>
                        <td>{displayed_reservation_str.num}</td>
                    </tr>
                    <tr>
                        <td>대표 연락처</td>
                        <td>{displayed_reservation_str.phone}</td>
                    </tr>
                </tbody>
            </table>
            : null }
            </div>
        )
    }
    getStartReservation() {
        const { trip_on, current_reservation, service_on, stops } = this.state;

        let contents = null;
        if (!service_on) {
            return null;
        }
        if (!current_reservation) {
            return null;
        }
        if (trip_on) {
            contents = <Button
                style={{width: "250px", marginTop: "2rem", height: "20vh"}}
                color="info"
                onClick={this.turnOffService.bind(this)}
            >
                운행 완료
            </Button>
        }
        else {
            const start_stop = stops.find(this.findStop(current_reservation.start_stop_id));
            const end_stop = stops.find(this.findStop(current_reservation.end_stop_id));
            contents = <Button
                style={{width: "250px", marginTop: "2rem", height: "20vh"}}
                color="info"
                onClick={
                    this.ableService.bind(this, start_stop, end_stop)
                }
                disabled={trip_on}
            >
                운행 하기
            </Button>
        }
        return contents;
    }
    getContents() {
        const { trip_on, current_reservation, service_on, selected_reservation, stops, reservations } = this.state;

        const reservation_dropdown = this.getReservationDropdown();
        const start_reservation = this.getStartReservation();
        const service_btn = this.getServiceBtn();
        let trip_btn = null;
        if (current_reservation) {
            trip_btn = this.getStartReservation();
        }
        else {
            if (trip_on) {
                trip_btn = (<div style={{ textAlign: "center" }}>
                    <h3> </h3>
                    <h2 style={{ color: "blue" }}>서비스 중입니다.
                    </h2>
                    <h2>
                        <Button style={{ width: "70vw", }} size="md" color="danger"
                            onClick={this.turnOffService.bind(this)}>서비스 종료
                        </Button>
                    </h2>
                </div>);
            }
            else {
                trip_btn = service_on ? this.getCourseBtns() : null;
            }
        }
        return <>
            {service_on && !current_reservation ? null : reservation_dropdown}
            <div className="d-flex flex-column align-items-center" style={{margin: "auto"}}>
                {service_btn}
                {trip_btn}
            </div>
        </>
    }

    render() {
        const { car_timeout } = this.state;
        
        const select_destination_btn = this.getAudianceDropdown();
        const logoutBtn = this.getLogOutBtn();


        let contents = null;
        if (car_timeout) {
            contents = <div><h2 style={{ color: "red" }}>차량의 위치를 확인할 수 없어 서비스를 시작할 수 없습니다.</h2></div>;
        }
        else {
            contents = this.getContents()
        }

        return (
            <div style={{ height: "100%" }}>
                {logoutBtn}
                <div
                    style={{
                        margin: "auto",
                        width: "70%",
                        paddingTop: "5vh",
                        textAlign: "center",
                        color: "black",
                    }}
                >
                    <h2><b>- 중문 셔틀 운영자 페이지 -</b></h2>
                    <br />
                    {select_destination_btn}
                    <br />
                    {contents}
                </div>
            </div >
        );
    }
}
export default withRouter(JungmoonAdminPage);
