import React, { Component } from 'react';
import { getInfoPage } from "../../Component/Common.js";

class InfoStation extends Component {
    render() {
        return (
            <div>
                {getInfoPage("쏘카 스테이션 제주")}
            </div>
        );
    }
}
export default InfoStation;