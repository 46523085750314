import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";

function PrivateRoute({ component: Component, ...rest }) {
  const auth = useAuth();
  let original_path = window.location.pathname;
  let original_search = window.location.search;
  return (
    <Route
      {...rest}
      render={props =>
        auth.authTokens ? (
          <Component {...props} />
        ) : (
          <Redirect to={{pathname: "/login", state: {goto: original_path, search: original_search}}} />
        )
      }
    />
  );
}

export default PrivateRoute;