import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Button } from "reactstrap";
import { AiOutlineLeft } from "react-icons/ai";
import { usePosition } from "../../Hooks/Hooks";
import { KakaoMap, KakaoMarker, KakaoPolyline } from "../../Component/Map/Kakao.js";
import { TamraPassengerForm } from "./TamraPassengerForm";
import KakaoCustomOverlay from "../../Component/Map/KakaoCustomOverlay";
import LocationButton from "../../Component/LocationButton";
import SelectStop from "../../Component/SelectStop";
import { PathGraph } from "./TamraShuttlePath";

const DAY_STRING = ["일","월","화","수","목","금","토",];
const UNDEFINED_DATE = {year: -1, month: -1, date: -1};
const UNDEFINED_TIME = {hour: -1, min: -1};
const UNDEFINED_CARS = [];

const INIT_CENTER = [33.511045030318414, 126.48122531110278];
const INIT_BOUNDS = {
    bound : [{lng: 126.44718426116032, lat: 33.4887548039607}, {lng: 126.51668431338587, lat: 33.529292347712946}],
    padding: [0, 0]
};
function TamraShuttle ({
    history,
    location,
    ...props
}) {
    const position = usePosition(true);

    const [mode, setMode] = useState(0);
    // 0: select start stop, select end stop,
    // 2: select date, 
    // else: show form

    const [stops, setStops] = useState([]);
    const [startStop, setStartStop] = useState(-1);
    const [endStop, setEndStop] = useState(-1);

    const [selectedDay, setSelectedDay] = useState(UNDEFINED_DATE);
    const [selectedTime, setSelectedTime] = useState(UNDEFINED_TIME);
    const [availableCars, setAvailableCars] = useState(UNDEFINED_CARS);

    const [center, setCenter] = useState(INIT_CENTER);
    const [bounds, setBounds] = useState(INIT_BOUNDS);

    const [shuttlePath, setShuttlePath] = useState([]);
    const [shuttleGraph, setShuttleGraph] = useState();
    const [ETA, setETA] = useState(0);

    const [openSubmitErrorModal, setOpenSubmitErrorModal] = useState(false);

    const storeAction = useCallback(({mode, startStop, endStop, selectedDay, selectedTime}) => {
        // console.log("store", {mode, startStop, endStop, selectedDay, selectedTime})
        history.push(history.location.pathname, {
            mode: mode,
            startStop: startStop,
            endStop: endStop,
            selectedDay: selectedDay,
            selectedTime: selectedTime,
        });
    }, [history]);
    const replaceAction = useCallback(({mode, startStop, endStop, selectedDay, selectedTime}) => {
        // console.log("replace", {mode, startStop, endStop, selectedDay, selectedTime})
        history.replace(history.location.pathname, {
            mode: mode,
            startStop: startStop,
            endStop: endStop,
            selectedDay: selectedDay,
            selectedTime: selectedTime,
        });
    }, [history]);

    const goBackCB = useCallback((new_location) => {
        if (!!!new_location.state) {
            return;
        }
        const {
            mode: _mode,
            startStop: _startStop = -1,
            endStop: _endStop = -1,
            selectedDay: _selectedDay = UNDEFINED_DATE,
            selectedTime: _selectedTime = UNDEFINED_TIME,
        } = new_location.state;
        
        setMode(_mode);
        setStartStop(_startStop);
        setEndStop(_endStop);
        setSelectedDay(_selectedDay);
        setSelectedDay(_selectedTime);
    }, [history, storeAction]);
    
    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);
    const onClickgoBackBtn = useCallback(() => {
        if (mode !== 0) {
            goBack();
            return;
        }
        if (startStop < 0 && endStop < 0) {
            goBack();
        }
        else if (startStop >= 0) {
            setStartStop(-1);
            setEndStop(-1);
        }
    }, [goBack, mode, startStop, endStop]);

    const onClickStopConfirm = useCallback(() => {
        let new_mode = mode;
        if (mode < 2) {
            new_mode = 2;
        }
        else if (mode === 2) {
            new_mode = 3;
        }
        setMode(new_mode);
        storeAction({
            mode: new_mode,
            startStop: startStop,
            endStop: endStop,
            selectedDay: selectedDay,
            selectedTime: selectedTime,
        });
    }, [mode, startStop, endStop, selectedDay, selectedTime, storeAction]);

    const onClickStopMarker = useCallback(async (marker, idx) => {
        if (mode !== 0) {
            return;
        }
        let start_stop = startStop, end_stop = endStop;

        if (startStop < 0) {
            start_stop = idx;
        }
        else if (endStop < 0) {
            end_stop = idx;
        }
        else if (endStop >=0) {
            if (idx === endStop) {
                end_stop = -1;
            }
            else {
                end_stop = idx;
            }
        }
        replaceAction({
            mode: 0, startStop: start_stop, endStop: end_stop,
        });
        setStartStop(start_stop);
        setEndStop(end_stop);
    }, [mode, startStop, endStop, replaceAction]);
    const onClickStopDescription = useCallback((isStart) => {
        if (isStart) {
            if (startStop < 0) {
                return null;
            }
            return () => {
                setStartStop(-1);
            }
        }
        else {
            if (endStop < 0) {
                return null;
            }
            return () => {
                setEndStop(-1);
            }
        }
    }, [startStop, endStop]);
    
    const onSubmit = useCallback(async (passengers, passenger_num) => {
        const start_stop = stops[startStop];
        const end_stop = stops[endStop];


        // const car_name = availableCars[0];
        // const car_name = availableCars[Math.floor(Math.random() * availableCars.length)];
        const reservation_time = new Date(selectedDay.year, selectedDay.month, selectedDay.date, selectedTime.hour, selectedTime.min);
        const {success, id, err} = await axios.post("/api/tamra/shuttle/register-reservation",{
            passengers,
            passenger_num: passenger_num,
            time: reservation_time,
            startStop: start_stop.id,
            endStop: end_stop.id,
            availableCarNames: availableCars
        })
        .then(res => ({
            ...res.data,
            id: res.data.encrypted_id
        }))
        .catch(err => {
            console.log(err.response);
            return {success: false, err};
        });
        if (!success) {
            console.error(err);
            if (err.custom_code){
                switch(err.custom_code) {
                    case 406: {
                        //http://reservation.com/tamra/shuttle/reservation?id=${err.reservation_id}\n
                        alert(`기예약된 건이 있어 예약이 불가능합니다. 탑승 일정 확인바랍니다.`);
                        break;
                    }
                    case 409: {
                        alert("예약가능일정에 변경사항이 있습니다. 새로고침 후 재예약 바랍니다.");
                        break;
                    }
                    default: {

                    }
                }
            }
        }
        else {
            history.push({
                pathname: '/tamra/shuttle/reservation',
                search: `id=${id}`
            });
        }
        return 
    }, [selectedDay, selectedTime, availableCars, stops, startStop, endStop, history]);

    const onClickLocationButton = useCallback((e) => {
        if (position.latitude && position.longitude) {
            setCenter(position);
        }
    }, [position]);

    const selectedText = useMemo(() => {
        if (mode === 0) {
            if (startStop < 0) {
                return <div><b>출발</b>할 정류소를 선택해주세요.</div>;
            }
            return <div><b>도착</b>할 정류소를 선택해주세요.</div>;
        }
        else {
            return <div>경로 확인</div>;
        }
    },[startStop, mode]);

    const selectStops = useMemo(() => {
        return (
            <div className="tamra-footer" >
                <div className="title">
                    <GoBackIcon 
                        onClick={onClickgoBackBtn}
                    />
                    {selectedText}
                </div>
                <ShuttleSelectStop 
                    startStop={startStop}
                    endStop={endStop}
                    stops={stops}
                    onClickStart={mode === 0 ? onClickStopDescription(true) : null}
                    onClickEnd={mode === 0 ? onClickStopDescription(false) : null}
                />
                <div className="button-line">
                    {ETA > 0 ? <div>예상 소요시간 약<b>{ETA}분</b></div> : null}
                    <Button 
                        className="btn"
                        onClick={onClickStopConfirm}
                        disabled={startStop < 0 || endStop < 0}
                        >
                        확인
                    </Button>
                </div>
            </div>
        )
    }, [mode, selectedText, stops, startStop, endStop, ETA, onClickStopConfirm, onClickStopDescription]);

    const stopMarkers = useMemo(() => {
        return stops.map((stop, idx) => {
            return <KakaoMarker
                key={`${stop.name}-${stop.description}`}
                position={stop.location}
                title={stop.description}
                image={{
                    src: [startStop, endStop].every(idx => idx < 0)? '/bus_station=default.svg' : 
                        [startStop, endStop].includes(idx) ? '/bus_station=focused.svg' :
                        '/bus_station=unfocused.svg',
                    width: [startStop, endStop].includes(idx) ? 48 : 40,
                    height: [startStop, endStop].includes(idx) ? 56 : 40,
                }}
                zIndex={[startStop, endStop].includes(idx) ? 10 : 1}
                clickable
                onClick={(marker) => onClickStopMarker(marker, idx)}
            />
        })
    }, [stops, startStop, endStop, onClickStopMarker]);

    const myLocationIconInit = useMemo(() => {
        let img = document.createElement('img');
        img.src = '/mylocation=direction.svg';
        img.id = 'mylocation';

        img.addEventListener('mousedown',(e) => {
            e.preventDefault();
        });
        return img;
    }, []);

    const myLocationIcon = useMemo(() => {
        const my_location = document.getElementById('mylocation');
        let heading = !!position.heading ? position.heading : 90;
        if (my_location) {
            my_location.style.transform = 'rotate(' + (90-heading) + 'deg)';
            return my_location;
        }
        else {
            myLocationIconInit.style.transform = 'rotate(' + (90-heading) + 'deg)';
            return myLocationIconInit;
        }
    }, [position, myLocationIconInit]);

    const locationButton = useMemo(() => {
        return <LocationButton 
            className="tamra-location-button"
            onClick={onClickLocationButton}
        />
    }, [onClickLocationButton]);

    const selectContainer = () => {
        switch (mode) {
            case 0: {
                return <div
                    className="tamra-footer-wrapper bottom relative"
                >
                    {locationButton}
                    {selectStops}
                </div>;
            }
            case 1: {
                return <div
                    className="tamra-footer-wrapper bottom relative"
                >
                    {locationButton}
                    {selectStops}
                </div>;
            }
            case 2: {
                if (startStop < 0 || endStop < 0) {
                    return null
                }
                return <SelectDate 
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    setAvailableCars={setAvailableCars}
                    onClickConfirm={onClickStopConfirm}
                    goBack={onClickgoBackBtn}
                    locationButton={locationButton}
                    startStopId={stops[startStop].id}
                    endStopId={stops[endStop].id}
                />;
            }
            default: {
                const reservation_time = new Date(selectedDay.year, selectedDay.month, selectedDay.date, selectedTime.hour, selectedTime.min);
                return <div className="tamra-footer relative additional-padding">
                    {locationButton}
                    <div className="title">
                        <GoBackIcon 
                            onClick={onClickgoBackBtn}
                        />
                        {selectedText}
                    </div>
                    <ShuttleSelectStop 
                        startStop={startStop}
                        endStop={endStop}
                        stops={stops}
                    />
                    <div>
                        <div>
                            {/* asdasd */}
                        </div>
                    </div>
                    <div className="line-divider"/> 
                    <TamraPassengerForm 
                        startLocation={stops[startStop]}
                        endLocation={stops[endStop]}
                        reservationTime={reservation_time}
                        onSubmit={onSubmit}
                    />
                </div>
            }
        }
    };

    useEffect(() => {
        async function wrapper() {
            const [{value: shuttle_stops}, {value: shuttle_path}] = await Promise.allSettled([
                axios.get("/api/tamra/shuttle/get-stops")
                .then(res => res.data).catch(err => {
                    console.error(err);
                    return [];
                }),
                axios.get("/api/tamra/shuttle/get-path")
                .then(({data}) => data).catch(err => {
                    console.error(err);
                    return [];
                }),
            ]);
            
            setStops(shuttle_stops);
            if (shuttle_path.length > 0){
                const path_edges_info = shuttle_path.map(path => {
                    const {
                        from, to, bidirection, time
                    } = JSON.parse(path.json_data);
                    return {
                        path: path.geometry.flat(),
                        from, to, bidirection, time
                    }
                });
                
                const graph = new PathGraph(path_edges_info);
                setShuttleGraph(graph);
                setShuttlePath(graph.path);
            }
            else {
                alert("경로를 불러오는데 실패했습니다.");
            }
        }
        wrapper();
    }, []);

    useEffect(() => {
        const unlisten = history.listen((new_location, action) => {
            if (action === 'POP') {
                goBackCB(new_location);
            }
        });
        return () => {
            unlisten();
        }
    }, [history, goBackCB]);

    useEffect(() => {
        if (!!shuttleGraph) {
            if (!(startStop < 0 || endStop < 0)) {
                const {
                    path, time
                } = shuttleGraph.getPath(stops[startStop].order, stops[endStop].order, true);
                setShuttlePath(path);
                setETA(Math.ceil(time / 60));
            }
            else {
                setShuttlePath(shuttleGraph.path);
                setETA(0);
            }
        }
    }, [stops, startStop, endStop, shuttleGraph]);

    useEffect(() => {
        if (mode === 2) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }, [mode]);

    return <div className="font-SUIT tamra-kakao-map-wrapper flex">
        <KakaoMap
            className="tamra-kakao-map"
            center={center}
            bounds={bounds}
        >
            {mode !== 2 ? 
            <>
                {stopMarkers}
            </>
            : <>
                <KakaoMarker 
                    position={stops[startStop]?.location}
                    image={{
                        src: '/get_on=blue.svg',
                        width: 48,
                        height: 48,
                        options: {
                            offset: [24, 40]
                        }
                    }}
                />
                <KakaoMarker 
                    position={stops[endStop]?.location}
                    image={{
                        src: '/get_off=blue.svg',
                        width: 48,
                        height: 48,
                        options: {
                            offset: [24, 40]
                        }
                    }}
                />
            </>}
            <KakaoCustomOverlay 
                position={position}
                content={myLocationIcon}
            />
            <KakaoPolyline
                path={shuttlePath}
                strokeColor="#1261FB"
                strokeWeight={5}
            />
        </KakaoMap>
        {selectContainer()}
    </div>
};

export default withRouter(TamraShuttle);

const SelectDate = ({
    selectedDay = UNDEFINED_DATE,
    setSelectedDay = () => {},
    selectedTime = UNDEFINED_TIME,
    setSelectedTime = () => {},
    setAvailableCars = () => {},

    onClickConfirm = () => {},
    goBack = () => {},
    locationButton = null,
    startStopId,
    endStopId
}) => {

    const [availables, setAvailables] = useState([]);
    const [isSelectedDay, setIsSelectedDay] = useState(false);
    const [isSelectedTime, setIsSelectedTime] = useState(false);

    const time_slot_container = useRef(null);
    const day_slot_container = useRef(null);


    const time_slots = useMemo(() => {
        const available_date = availables.find(({date}) => {
            const day = new Date(date);
            return day.getMonth() === selectedDay.month && day.getDate() === selectedDay.date;
        });
        if (!available_date?.available) {
            return null;
        }
        const {
            result
        } = available_date.time.reduce(({result, prev_hour}, {available, time: time_slot}) => {
            const time = new Date(time_slot);
            const hour = time.getHours();
            const min = time.getMinutes();

            if (prev_hour === undefined || prev_hour !== hour) {
                result.push([]);
            }
            
            result[result.length - 1].push(
                <Button 
                    className={`time ${selectedTime.hour === hour && selectedTime.min === min ? 'selected' : ''}`}
                    disabled={available.length === 0}
                    key={`time-slot-${hour}-${min}`}
                    onClick={() => {
                        if (selectedTime.hour === hour && selectedTime.min === min) {
                            setSelectedTime(UNDEFINED_TIME);
                            setAvailableCars(UNDEFINED_CARS);
                        }
                        else {
                            setAvailableCars(available);
                            setSelectedTime({
                                hour,
                                min,
                            });
                        }
                    }}
                >
                    {String(hour).padStart(2,'0')}:{String(min).padStart(2,'0')}
                </Button>
            );
            return {
                result, prev_hour: hour
            }
        }, {
            result: [],
            prev_hour: undefined,
        });

        return result.map( (time_box, idx) => <div className="time-box" key={`time-box-${idx}`}>
            {time_box}
        </div>)
    }, [selectedDay, selectedTime, setSelectedTime, setAvailableCars, availables]);

    const day_slots = useMemo(() => {
        const {
            result
        } = availables.reduce(({result, prev_date}, {date, available}, index) => {
            const cur = new Date(date);
            const cur_month = cur.getMonth();
            const cur_date = cur.getDate();
            if (!!!prev_date || prev_date.getMonth() !== cur_month) {
                result.push(
                <div className="month"
                    key={`day-slot-${cur_month}`}
                >
                    {cur_month + 1}월
                </div>);
            }
            result.push(
            <Button 
                className={`date ${selectedDay.month === cur_month && selectedDay.date === cur_date ? 'selected' : ''}`}
                disabled={!available}
                key={`day-slot-${cur_month}-${cur_date}`}
                onClick={() => {
                    if (selectedDay.month === cur_month && selectedDay.date === cur_date) {
                        setSelectedDay(UNDEFINED_DATE);
                        setSelectedTime(UNDEFINED_TIME);
                    }
                    else {
                        setSelectedDay({
                            year: cur.getFullYear(),
                            month: cur_month,
                            date: cur_date,
                        });
                        setSelectedTime(UNDEFINED_TIME);
                        setAvailableCars(UNDEFINED_CARS);
                    }
                }}
            >
                <div>{cur_date}</div>
                <div>{DAY_STRING[cur.getDay()]}</div>
            </Button>);
            return {
                result,
                prev_date: cur
            }
        }, {result: [], prev_date: undefined});

        return result
    }, [selectedDay, setSelectedDay, setAvailableCars, availables]);

    useEffect(() => {
        if (time_slot_container?.current && isSelectedDay) {
            time_slot_container.current.scrollIntoView({behavior: 'smooth', inline:'end'});
        }
        if (day_slot_container?.current && !isSelectedDay) {
            day_slot_container.current.scrollIntoView({behavior:'smooth', inline:'start'});
        }
    }, [isSelectedDay, time_slot_container, day_slot_container]);
    useEffect(() => {
        setIsSelectedDay(selectedDay.month >= 0 && selectedDay.date > 0);
    }, [selectedDay]);

    useEffect(() => {
        setIsSelectedTime(selectedTime.hour >= 0 && selectedTime.min >= 0);
    }, [selectedTime]);

    useEffect(() => {
        const wrapper = async () => {
            const avails = await axios.get("/api/tamra/shuttle/get-available/day", {
                params: {
                    start_stop: startStopId,
                    end_stop: endStopId,
                }
            })
            .then(res => res.data).catch(err => {
                console.error(err);
                return [];
            });
            setAvailables(avails)
        }
        wrapper();
    }, []);
    
    return <div className="tamra-shuttle-date relative">
        {locationButton}
        <div className="tamra-shuttle-date-text">
            <div>
                <div>
                    <GoBackIcon 
                        onClick={goBack}
                    />
                    탑승할 날짜와 시간을 선택해주세요.
                </div>
                <div>
                    오늘로부터 2주 내의 날짜만 예약 가능합니다.
                </div>
            </div>
        </div>
        <div className="line-divider" />
        <div className="tamra-shuttle-date-selector"
            ref={day_slot_container}
        >
            <div>
                {day_slots}
            </div>
        </div>
        {isSelectedDay ? 
            <div className="line-divider" /> 
        : null}
        <div className={`tamra-shuttle-time-selector ${isSelectedDay ? '' : 'invisible'}`} 
            ref={time_slot_container}
        >
        {time_slots}
        </div>
        
        
        <Button
            className="selector"
            disabled={!(isSelectedDay && isSelectedTime)}
            onClick={onClickConfirm}
        >
            확인
        </Button>
    </div>
}

const ShuttleSelectStop = ({
    startStop,
    endStop,
    stops = [],
    onClickStart,
    onClickEnd
}) => {

    return (
        <>
            <SelectStop
                on={startStop >= 0}
                onClick={onClickStart}
            >
                {startStop < 0 ? "출발할 정류소" : stops[startStop]?.description}
            </SelectStop>
            <SelectStop
                on={endStop >= 0}
                arrow={true}
                onClick={onClickEnd}
            >
                {endStop < 0 ? "도착할 정류소" : stops[endStop]?.description}
            </SelectStop>
        </>
    )
}

const GoBackIcon = ({
    onClick= ()=>{}
}) => {
    return <AiOutlineLeft 
        className="tamra-go-back-icon"
        onClick={onClick}
    />
}