import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
    Button,
    Input,
    Form,
    InputGroup
} from "reactstrap";
import axios from "axios";
import { AuthContext } from "../../context/auth";
import sha256 from 'crypto-js/sha256';
import Jumbotron from 'reactstrap/lib/Jumbotron';

class AdminLogin extends Component {
    constructor(props) {
        super(props);
        this.state = { token: null, pw_invalid: false };
    }
    static contextType = AuthContext;
    requestLogin = event => {
        event.preventDefault();
        axios.post("/api/admin/login",
            { 'admin_pw': this.state.admin_pw }
        ).then(function (res) {
            if (res.statusText === "OK" && res.data.pw_matched) {
                return { token: res.data };
            } else {
                return { pw_invalid: true };
            }
        }).catch(function (error) {
            console.error("error while request GET to server");
            console.log(error);
        }).then((data) => this.setState(data));
    }
    handlePWChange = e => {
        this.setState({ admin_pw: sha256(e.target.value).toString() });
    }
    handleUpdate() {
        this.context.setTokens("wow");
    }
    render() {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#FFF";
        if (this.state.token) {
            this.handleUpdate();
        }
        if (this.context.authTokens) {
            if(this.props.location.state !== undefined && this.props.location.state.goto !== undefined)
                this.props.history.replace(`${this.props.location.state.goto}${this.props.location.state.search}`);

            return(
                <div className="main flex" style={{alignContent:"center", alignItems:"center", justifyContent:"center"}}>
                    <Jumbotron style={{display:"flex", flexDirection:"column", backgroundColor: "#d5d5d5"}}>
                    <Link style={{margin:"0.5em", textDecoration: 'line-through'}} to="/admin"> 일반 승객 IPS 관리자 페이지로 이동 </Link>
                    <Link style={{margin:"0.5em", textDecoration: 'line-through'}} to="/invitation/admin"> 초대 승객 IPS 관리자 페이지로 이동 </Link>
                    <Link style={{margin:"0.5em", textDecoration: 'line-through'}} to="/seogwipo/shuttle/admin"> 서귀포 고정노선 관리자 페이지로 이동 </Link>
                    <Link style={{margin:"0.5em", textDecoration: 'line-through'}} to="/seogwipo/floating/admin"> 일반 승객 서귀포 자유노선 관리자 페이지로 이동 </Link>
                    <Link style={{margin:"0.5em", textDecoration: 'line-through'}} to="/seogwipo/floating/invitation/admin"> 초대 승객 서귀포 자유노선 관리자 페이지로 이동 </Link>
                    <Link style={{margin:"0.5em", textDecoration: 'line-through'}} to="/sejong/shuttle/admin"> 세종 관리자 페이지로 이동 </Link>
                    <Link style={{margin:"0.5em"}} to="/jungmun/shuttle/admin"> 중문 관리자 페이지로 이동 </Link>
                    <Link style={{margin:"0.5em"}} to="/tamra/shuttle/admin"> 탐라 셔틀 페이지로 이동 </Link>
                    <Link style={{margin:"0.5em"}} to="/tamra/jungmun/admin"> 탐라 중문 페이지로 이동 </Link>
                    <Link style={{margin:"0.5em"}} to="/tamra/carpool/admin"> 탐라 카풀 페이지로 이동 </Link>
                    <Link style={{margin:"0.5em"}} to="/sangsang/carpool/admin"> 상상 카풀 페이지로 이동 </Link>
                    <Link style={{margin:"0.5em"}} to="/test/admin"> 테스트 페이지로 이동 </Link>
                    </Jumbotron>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        margin: "auto",
                        width: "70%",
                        padding: "100px 0",
                        textAlign: "center"
                    }}>
                    <Form onSubmit={this.requestLogin}>
                        <h1>관리자 로그인 페이지입니다.</h1>
                        <InputGroup size="lg">
                            <Input
                                type="password"
                                placeholder="관리자 암호를 입력하세요."
                                onChange={this.handlePWChange}
                                required
                                invalid={this.state.pw_invalid}
                            />
                            <Button>확인</Button>
                        </InputGroup>
                    </Form>
                    <br />
                    <br />
                    <Link to="/">호출 페이지로 이동</Link>
                </div>);
        }
    }
}

export default withRouter(AdminLogin);