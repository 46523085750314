import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { toLatLng } from "./Util";
import { KakaoMapContext } from "./KakaoMap";

const KakaoCircle = ({
    position,
    radius,

    strokeWeight,
    strokeColor,
    strokeOpacity,
    strokeStyle,
    strokeOptions,

    fillColor,
    fillOpacity,
    zIndex,

    onClick ,
    onMouseOver,
    onMouseOut,
    onMouseMove,
    onMouseDown,
    onTrigger = [],
    visible = true,
    ...props
}) => {
    
    const { map, loaded } = useContext(KakaoMapContext);
    
    const circle = useMemo(() => {
        if (!!!map || !loaded || !window.kakao) {
            return null;
        }
        return new window.kakao.maps.Circle({
            map,
            fillColor: fillColor,
            fillOpacity: fillOpacity,
        });
    }, [loaded, fillColor, fillOpacity]);

    useLayoutEffect(() => {
        if (circle) {
            circle.setMap(visible ? map : null);
        }
    }, [visible]);
    useLayoutEffect(() => {
        if (circle && !!!strokeOptions) {
            circle.setOptions({
                radius: radius,
                strokeWeight: strokeWeight,
                strokeColor: strokeColor,
                strokeOpacity: strokeOpacity,
                strokeStyle: strokeStyle,
            });
        }
    }, [circle, strokeWeight, strokeColor, strokeOpacity, strokeStyle, strokeOptions]);
    useLayoutEffect(() => {
        if (circle && !!strokeOptions) {
            circle.setOptions(strokeOptions);
        }
    }, [circle, strokeOptions]);
    
    useLayoutEffect(() => {
        if (circle && position) {
            const p = toLatLng(position);
            
            if (p) {
                circle.setPosition(p);
            }
        }
    }, [circle, position, toLatLng]);

    useLayoutEffect(() => {
        if (circle && !isNaN(radius)) {
            circle.setRadius(typeof radius === 'number' ? radius : Number(radius));
        }
    }, [circle, radius, toLatLng]);

    useLayoutEffect(() => {
        if (circle && typeof(zIndex) === 'number') {
            circle.setZIndex(zIndex);
        }
    }, [circle, zIndex]);

    useEffect(() => {
        return () => {
            if (circle) {
                console.log("Destroy kakao circle", circle)
                circle.setMap(null);
            }
        }
    }, [circle]);



    // const onClickEvent = useCallback((circle, onClick) => {
    //     return function (mouseEvent) {
    //         onClick(mouseEvent, circle);
    //     }
    // }, []);
    // const onMouseOverEvent = useCallback((circle, onMouseOver) => {
    //     return function (mouseEvent) {
    //         onMouseOver(mouseEvent, circle);
    //     }
    // }, []);
    // const onMouseOutEvent = useCallback((circle, onMouseOut) => {
    //     return function (mouseEvent) {
    //         onMouseOut(mouseEvent, circle);
    //     }
    // }, []);
    // const onMouseMoveEvent = useCallback((circle, onMouseMove) => {
    //     return function (mouseEvent) {
    //         onMouseMove(mouseEvent, circle);
    //     }
    // }, []);
    // const onMouseDownEvent = useCallback((circle, onMouseDown) => {
    //     return function (mouseEvent) {
    //         onMouseDown(mouseEvent, circle);
    //     }
    // }, []);
    // const onTriggerEvent = useCallback((circle, callback) => {
    //     return function (data) {
    //         callback(data, circle);
    //     }
    // }, []);

    // useLayoutEffect(() => {
    //     if (!circle || !onClick || typeof(onClick) !== 'function') {
    //         return;
    //     }
    //     window.kakao.maps.event.addListener(circle, 'click', onClickEvent(circle, onClick));
    //     return () => {
    //         window.kakao.maps.event.removeListener(circle, 'click', onClickEvent(circle, onClick));
    //     }
    // }, [circle, onClick, onClickEvent]);
    // useLayoutEffect(() => {
    //     if (!circle || !onMouseOver || typeof(onMouseOver) !== 'function') {
    //         return;
    //     }
    //     window.kakao.maps.event.addListener(circle, 'mouseover', onMouseOverEvent(circle, onMouseOver));
    //     return () => {
    //         window.kakao.maps.event.removeListener(circle, 'mouseover', onMouseOverEvent(circle, onMouseOver));
    //     }
    // }, [circle, onMouseOver, onMouseOverEvent]);
    // useLayoutEffect(() => {
    //     if (!circle || !onMouseOut || typeof(onMouseOut) !== 'function') {
    //         return;
    //     }
    //     window.kakao.maps.event.addListener(circle, 'mouseout', onMouseOutEvent(circle, onMouseOut));
    //     return () => {
    //         window.kakao.maps.event.removeListener(circle, 'mouseout', onMouseOutEvent(circle, onMouseOut));
    //     }
    // }, [circle, onMouseOut, onMouseOutEvent]);
    // useLayoutEffect(() => {
    //     if (!circle || !onMouseMove || typeof(onMouseMove) !== 'function') {
    //         return;
    //     }
    //     window.kakao.maps.event.addListener(circle, 'mouseout', onMouseMoveEvent(circle, onMouseMove));
    //     return () => {
    //         window.kakao.maps.event.removeListener(circle, 'mouseout', onMouseMoveEvent(circle, onMouseMove));
    //     }
    // }, [circle, onMouseMove, onMouseMoveEvent]);
    // useLayoutEffect(() => {
    //     if (!circle || !onMouseDown || typeof(onMouseDown) !== 'function') {
    //         return;
    //     }
    //     window.kakao.maps.event.addListener(circle, 'mouseout', onMouseDownEvent(circle, onMouseDown));
    //     return () => {
    //         window.kakao.maps.event.removeListener(circle, 'mouseout', onMouseDownEvent(circle, onMouseDown));
    //     }
    // }, [circle, onMouseDown, onMouseDownEvent]);
    // useLayoutEffect(() => {
    //     if (!circle || !onTrigger.length) {
    //         return;
    //     }

    //     onTrigger.forEach(trigger_obj => {
    //         window.kakao.maps.event.addListener(circle, trigger_obj.type, onTriggerEvent(circle, trigger_obj.callback));
    //     });
    //     return () => {
    //         onTrigger.forEach(trigger_obj => {
    //             window.kakao.maps.event.removeListener(circle, trigger_obj.type, onTriggerEvent(circle, trigger_obj.callback));
    //         });
    //     }
    // }, [circle, onTrigger]);

    return null;
};

export default KakaoCircle;