import React, { Component } from 'react';
import { MdGpsFixed } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
class ConfirmPage extends Component {
    getConfirmPage() {
        let name = "당신";
        let n = sessionStorage.getItem("passenger_name");
        if (n !== undefined && n !== "" && n != null) {
            name = n + "님";
        }
        let call_location = sessionStorage.getItem("call_location");
        let arrival_location = "제주공항";
        let map_img_path = '/socarMap.png';
        if (call_location === "제주공항") {
            arrival_location = "쏘카 스테이션 제주";
            map_img_path = '/airportMap.png';
        } else {
            call_location = "쏘카 스테이션 제주";
        }
        // let req_time = sessionStorage.getItem("req_time");
        let req_time = new Date().toLocaleString();
        return (
            <div className="main">
                <div style={{
                    padding: "0",
                    width: "100vw",
                    height: "max(25vh, 55vw)",
                    backgroundColor: "#4969BA",
                    paddingTop: "5vh"
                }}>
                    <div>
                        <div style={{ width: "20vw", float: "left", display: "inline-block", margin: "0", padding: "0", paddingTop: "1vh" }}>
                            <img src='/rideflux_car.png'
                                style={{
                                    paddingLeft: "3vh",
                                    width: "28vw"
                                }} alt="Smiley face" />
                        </div>
                        <div style={{ width: "75vw", float: "right", display: "inline-block", margin: "0", padding: "0", paddingTop: "1vh" }}>
                            <div style={{
                                paddingLeft: "8vw",
                                fontSize: "4.5vw",
                                fontWeight: "600",
                                color: "#1F1C2A"
                            }}>보다 안전한 자율주행 모빌리티
                                <div style={{
                                    color: "#FBF9F9"
                                }}>
                                    <img src='/RF_LOGO_WHITE.png'
                                        style={{
                                            height: "7vw"
                                        }} alt="Smiley face" />
                                    <span>
                                        {' '} with
                                    <img src='/socar.png'
                                            style={{
                                                height: "4.5vw"
                                            }} alt="Smiley face" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        paddingTop: "25vw",
                        paddingBottom: "3vh",
                        paddingLeft: "5vw"
                    }}>
                        <div style={{
                            width: "90vw",
                            height: "13vw",
                            borderRadius: "8px",
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0px 12px 16px #455B6314",
                            position: "relative"
                        }}>
                            <div
                                style={{
                                    top: "2.5vw",
                                    color: "#454F63",
                                    margin: "0",
                                    position: "absolute",
                                    fontSize: "4.5vw",
                                    textAlign: "center",
                                    fontWeight: "600"
                                }}>
                                <span style={{ fontSize: "5vw", paddingLeft: "5vw", paddingRight: "5vw" }}><MdGpsFixed /></span>
                                <span style={{ paddingLeft: "3vw", paddingRight: "3vw", color: "#78849E" }}> {call_location} </span>
                                <span style={{ color: "#78849E" }}><BsArrowRight /></span>
                                <span style={{ paddingLeft: "3vw", paddingRight: "3vw", color: "#454F63" }}>{arrival_location}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontWeight: "900" }}>
                    <div style={{ color: "#454F63", fontSize: "3.7vw", paddingTop: "3vh", textAlign: "left", paddingLeft: "10vw" }}>
                        예약 시각: {req_time}
                    </div>
                    <div style={{ color: "#454F63", fontWeight: "900", fontSize: "5vw", paddingLeft: "10vw", paddingTop: "1vh" }}>
                        차량 호출이 완료되었습니다!
                    </div>
                    <p style={{ color: "#454F63", fontSize: "4vw", paddingLeft: "10vw", paddingTop: "3vh" }}>{name}을 위한<br />
                        <span style={{ color: "#4969BA" }}>특별한 자율주행 차량</span>이<br />
                1분이내 승차장소에<br />
                도착할 예정입니다.</p>
                    <p style={{ color: "#454F63", fontSize: "3.5vw", paddingLeft: "10vw" }}>
                        승차장소로 이동해주세요.<br />
                    </p>
                    <div style={{ paddingLeft: "10vw", color: "#b30000", fontSize: "3.7vw", fontWeight: "400" }}>예약완료 후
                    <span style={{ fontWeight: "900" }}> 5분</span> 이내 탑승하지 않을 시</div>
                    <div style={{ paddingLeft: "10vw", color: "#b30000", fontSize: "3.7vw", fontWeight: "400" }}>예약이 취소됩니다.</div>
                </div>
                <img src={map_img_path} style={{
                    width: "90vw",
                    paddingTop: "1vh",
                    paddingBottom: "3vh",
                    paddingLeft: "10vw"
                }} alt="Smiley face" />
            </div >);
    }
    render() {
        let page = this.getConfirmPage();
        return (<div>
            {page}
        </div>
        );
    }
}
export default ConfirmPage;