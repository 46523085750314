import React, {
    useCallback,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { distance } from "random-location";
import { Button } from "reactstrap";
import ReactSound from "react-sound";
import { useGetState } from "../../Hooks/Hooks.js";
import AdminTopBar from "../../Component/TopBar.js";
import LogoutButton from "../../Component/LogoutButton.js";
import SelectCar from "../../Component/SelectCar.js";
import KakaoMap from "../../Component/Map/KakaoMap.js";
import KakaoMarker from "../../Component/Map/KakaoMarker.js";
import KakaoCircle from "../../Component/Map/KakaoCircle.js";
import KakaoCustomOverlay from "../../Component/Map/KakaoCustomOverlay.js";
import "../../style/TestAdmin.css";
import { Snackbar } from "@material-ui/core";
import LocationButton from "../../Component/LocationButton.js";

const MAX_RADIUS = 1500; // meter
const MIN_RADIUS = 300; //meter

const MODE_KEY = "test/admin/mode";
const RANDOM_MODE = "random";
const SELECT_MODE = "select";
const MAXIMUM_ITER = 100;

const DESTINATION_SOUND_FILE = "../sound/doremisol.mp3";
const LOADING_TARGETS = {
    SERVICE: "service",
    RANDOM_GOAL: "random_goal",
    GOAL: "specific_goal",
    TRIP: "trip",
};
const LOADING_TYPE = {
    START: "start",
    END: "end",
};

const loadingReducer = (state, action) => {
    switch (action.type) {
        case "start":
            return { isLoading: true, target: action.target };
        case "end":
            return { isLoading: false, target: "" };
        default:
            return state;
    }
};

const CarSharingAdmin = (props) => {
    const { history, location } = props;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const { car_name } = query;

    const [carName, setCarName] = useState(car_name);
    const [carInfo, serviceOn, , getCallState] = useGetState(carName, 2000);
    const [centerPosition, setCenterPosition] = useState(undefined);
    const [adpPoint, setAdpPoint] = useState(undefined);
    const [mode, setMode] = useState(SELECT_MODE);

    const [soundStatus, setSoundStatus] = useState("STOPPED");

    const [stops, setStops] = useState([]);

    const [loading, loadingDispatch] = useReducer(loadingReducer, {
        isLoading: false,
        target: "",
    });
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const mapDragEndAbortController = useRef(undefined);
    const randomGoalAbortController = useRef(undefined);

    const selectCarCallback = useCallback(
        (car_name) => {
            setCarName(car_name);
            history.replace({
                search: `?car_name=${car_name}`,
            });
        },
        [history]
    );

    const getADP = useCallback(async (location_point, abortController) => {
        if (abortController.current) {
            abortController.current.abort("previous request canceled");
        }
        abortController.current = new AbortController();
        return await axios
            .post(
                "/adp/seogwipo/floating/get-point",
                {
                    ...location_point,
                    type: "AP",
                },
                { signal: abortController.current.signal }
            )
            .then(function (res) {
                abortController.current = undefined;
                if (res.statusText === "OK") {
                    return res.data;
                } else {
                    return undefined;
                }
            })
            .catch(function (e) {
                console.error("error while POST to adp");
                console.log(e);
            })
            .then((data) => {
                if (data) {
                    return {
                        latitude: data.start_latitude,
                        longitude: data.start_longitude,
                    };
                }
                return;
            });
    }, []);

    const setGoalAPI = useCallback(async (car_name, adp, description) => {
        const { latitude, longitude } = adp;
        await axios
            .post("/api/car-sharing/setGoal", {
                car_name: car_name,
                goal: { latitude, longitude },
                description,
            })
            .then(function (res) {
                if (res.statusText === "OK") {
                    navigator.vibrate(500);
                    setSoundStatus("PLAYING");
                    return res.data;
                } else {
                    return undefined;
                }
            })
            .catch(function (err) {
                console.error("error while POST to adp");
                if (err.response?.status === 403) {
                    alert(
                        `${err.response.data}에서 서비스 중입니다. 종료 후 이용해주세요.`
                    );
                } else {
                    alert("목적지 전송에 실패했습니다.");
                }
            });
    }, []);

    const turnServiceOn = useCallback(
        async (_serviceName) => {
            if (loading.isLoading && loading.target === LOADING_TARGETS.SERVICE)
                return;
            loadingDispatch({
                type: LOADING_TYPE.START,
                target: LOADING_TARGETS.SERVICE,
            });
            await axios
                .put("/api/car-sharing/turn-service-on", {
                    car_name: carName,
                    service_name: _serviceName,
                })
                .catch(function (err) {
                    console.error("fail to turn service on");
                    alert("서비스 시작에 실패했습니다.");
                });
            loadingDispatch({ type: LOADING_TYPE.END });
            getCallState();
        },
        [carName, loading, getCallState]
    );

    const turnServiceOff = useCallback(
        async (_serviceName) => {
            if (loading.isLoading && loading.target === LOADING_TARGETS.SERVICE)
                return;
            loadingDispatch({
                type: LOADING_TYPE.START,
                target: LOADING_TARGETS.SERVICE,
            });
            await axios
                .put("/api/car-sharing/turn-service-off", {
                    car_name: carName,
                    service_name: _serviceName,
                })
                .catch(function (err) {
                    console.error("fail to turn service off");
                    alert("서비스 종료에 실패했습니다.");
                });
            getCallState();
            loadingDispatch({
                type: LOADING_TYPE.END,
            });
        },
        [carName, loading, getCallState]
    );

    const endTrip = useCallback(async () => {
        if (loading.isLoading && loading.target === LOADING_TARGETS.TRIP)
            return;
        loadingDispatch({
            type: LOADING_TYPE.START,
            target: "trip",
        });
        await axios
            .put("/api/car-sharing/end-trip", {
                car_name: carName,
            })
            .catch(function (err) {
                console.error("fail to end trip");
                alert("운행 종료에 실패했습니다.");
            });
        getCallState();
        loadingDispatch({
            type: LOADING_TYPE.END,
        });
    }, [carName, getCallState, loading]);

    const sendGoal = async () => {
        if (!adpPoint) {
            alert("도착지가 설정되지 않았습니다.");
            return;
        }
        if (loading.target === LOADING_TARGETS.GOAL) return;
        loadingDispatch({
            type: LOADING_TYPE.START,
            target: LOADING_TARGETS.GOAL,
        });
        await setGoalAPI(carName, adpPoint, LOADING_TARGETS.GOAL);
        getCallState();
        loadingDispatch({ type: LOADING_TYPE.END });
    };

    const serviceOnOffButtonCallback = useCallback(() => {
        if (serviceOn) {
            turnServiceOff();
        } else {
            turnServiceOn();
        }
    }, [serviceOn, turnServiceOn, turnServiceOff]);

    const setRandomGoal = async () => {
        if (loading.target === LOADING_TARGETS.RANDOM_GOAL) return;
        if (stops.length === 0) {
            alert(`정류장이 없어 목적지를 설정할 수 없습니다.`);
            return;
        }
        loadingDispatch({
            type: LOADING_TYPE.START,
            target: LOADING_TARGETS.RANDOM_GOAL,
        });
        let random_point = undefined;
        let i = 0;

        const stop_list = [...stops];
        do {
            if (stop_list.length === 0) {
                break;
            }
            const rand_idx = Math.floor(Math.random() * stop_list.length);
            const [stop] = stop_list.splice(rand_idx, 1);

            const _distance = distance(stop.location, carInfo.pose);
            if (_distance > MIN_RADIUS && _distance < MAX_RADIUS) {
                random_point = stop.location;
                break;
            }
        } while (i < MAXIMUM_ITER);

        if (stop_list.length === 0) {
            alert(`적정거리의 정류장을 고를 수 없습니다. 이동 후 재시도 해주십시요.`);
            return;
        }
        if (random_point === undefined) {
            alert(`${MAXIMUM_ITER}번 안에 random stop을 설정하는데 실패했습니다. 재시도 해주십시요.`);
            return;
        }

        const adp = await getADP(random_point, randomGoalAbortController);
        if (adp) {
            setAdpPoint(adp);
            setCenterPosition(adp);
            await setGoalAPI(carName, adp, LOADING_TARGETS.RANDOM_GOAL);
            getCallState();
            loadingDispatch({ type: LOADING_TYPE.END });
        }
    };

    const onCenterChange = useCallback((map, event) => {
        setCenterPosition(map.getCenter());
    }, []);

    const moveToCurrentCarLocation = useCallback(() => {
        if (carInfo?.pose?.latitude && carInfo?.pose?.longitude) {
            setCenterPosition(carInfo.pose);
        }
    }, [carInfo]);

    useEffect(() => {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#DFDFDF";
        const stored_mode = localStorage.getItem(MODE_KEY);
        if ([RANDOM_MODE, SELECT_MODE].includes(stored_mode)) {
            setMode(stored_mode);
        }
        async function init() {
            try {
                const [cheomdan, jungmun] = await Promise.all([
                    axios.get("/api/nemoride/cheomdan/get-stops"),
                    axios.get("/api/tamra/jungmun/get-stops"),
                ]);
                setStops([...cheomdan.data, ...jungmun.data]);
            }
            catch(err) {
                alert("정류장 정보를 가져오는데 실패했습니다.");
            }
        }
        init();
    }, []);

    useEffect(() => {
        getCallState().then((data) => {
            if (data && data.pose?.[0] && data.pose?.[1]) {
                setCenterPosition(data.pose);
            }
        });
    }, [carName, getCallState]);

    const canNotSettingGoal = (serviceOn && carInfo.current_service !== "CAR_SHARING") || carInfo.car_timeout;

    return (
        <div style={{ height: "100%", position: "relative" }}>
            <AdminTopBar>
                <LogoutButton history={history} />
                <Button
                    sm={10}
                    style={{
                        float: "left",
                    }}
                    onClick={() => {
                        const new_mode =
                            mode === RANDOM_MODE ? SELECT_MODE : RANDOM_MODE;
                        setMode(new_mode);
                        localStorage.setItem(MODE_KEY, new_mode);
                    }}
                >
                    {" "}
                    {mode === RANDOM_MODE ? "특정으로" : "랜덤으로"}
                </Button>
                <SelectCar
                    selectedCar={carName}
                    selectCallback={selectCarCallback}
                />
            </AdminTopBar>
            <div
                style={{
                    margin: "auto",
                    marginTop: "1rem",
                    textAlign: "center",
                    color: "black",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "90%",
                }}
            >
                <ReactSound
                    url={DESTINATION_SOUND_FILE}
                    playStatus={soundStatus}
                    onFinishedPlaying={() => {
                        setSoundStatus("STOPPED");
                    }}
                />
                <h1 className="test-admin title">
                    <b>CAR Sharing 페이지</b>
                </h1>
                {canNotSettingGoal ? (
                    <div style={{ fontSize: "1.7rem", color: "red" }}>
                        {carInfo.car_timeout ? (
                            "차량 위치를 확인할 수 없습니다."
                        ) : (
                            <span>
                                현재 {carInfo.current_service}에서 서비스 중입니다.
                            </span>
                        )}
                    </div>
                ) : carInfo.trip_on ? (
                    <Button
                        className="push-button"
                        color={loading.isLoading ? "secondary" : "warning"}
                        onClick={endTrip}
                    >
                        {loading.isLoading &&
                        loading.target === LOADING_TARGETS.TRIP
                            ? "종료 중.."
                            : "운행 종료"}
                    </Button>
                ) : serviceOn ? (
                    <>
                        <Button
                            className={`push-button ${
                                mode === RANDOM_MODE
                                    ? ""
                                    : "test-admin-invisible"
                            }`}
                            color={loading.isLoading ? "secondary" : "primary"}
                            disabled={loading.isLoading}
                            onClick={setRandomGoal}
                        >
                            {loading.target === LOADING_TARGETS.RANDOM_GOAL
                                ? "전송 중.."
                                : "랜덤 목적지 설정"}
                        </Button>
                        <Button
                            className={`push-button ${
                                mode === SELECT_MODE
                                    ? ""
                                    : "test-admin-invisible"
                            }`}
                            color={loading.isLoading ? "secondary" : "success"}
                            disabled={loading.isLoading}
                            onClick={sendGoal}
                        >
                            {loading.target === LOADING_TARGETS.GOAL
                                ? "전송 중.."
                                : "목적지 전송"}
                        </Button>
                    </>
                ) : (
                    <h3>
                        목적지를 전송하려면{" "}
                        <b style={{ color: "royalblue" }}>하단 버튼</b>을 눌러{" "}
                        <b style={{ color: "royalblue" }}>서비스를 켜주세요.</b>
                    </h3>
                )}
                <br />
                <div style={{position: "relative", width: "100%"}}>
                    <KakaoMap
                        className="test-admin map-containter"
                        center={centerPosition}
                        level={6}
                        onCenterChange={onCenterChange}
                        onDragEnd={async (map) => {
                            const kakao_latlng = map.getCenter();
                            const adp = await getADP(
                                {
                                    latitude: kakao_latlng.getLat(),
                                    longitude: kakao_latlng.getLng(),
                                },
                                mapDragEndAbortController
                            );
                            if (adp) {
                                setAdpPoint(adp);
                            }
                        }}
                    >
                        <KakaoMarker position={centerPosition} />
                        <KakaoCustomOverlay
                            position={carInfo?.pose}
                            contentID={"car_icon"}
                        >
                            <img
                                src="/car_icon=mini.png"
                                alt="car_icon"
                                onClick={(e) => e.preventDefault()}
                                style={{
                                    transform: `rotate(${
                                        (isNaN(carInfo.heading) || carInfo.heading === null) ? 90 : 180 - carInfo.heading
                                    }deg)`,
                                }}
                            />
                        </KakaoCustomOverlay>
                        <KakaoCircle
                            position={carInfo?.pose}
                            radius={MAX_RADIUS}
                            fillOpacity={0}
                            strokeWeight={3}
                            strokeColor={"#000000"}
                            strokeOpacity={1}
                            strokeStyle={"dash"}
                        />
                        <KakaoMarker
                            position={adpPoint}
                            image={{
                                src: "/marker=arrival.svg",
                                width: 48,
                                height: 48,
                                options: {
                                    offset: [24, 40],
                                },
                            }}
                        />
                    </KakaoMap>
                    <LocationButton 
                        wrapperClassName=""
                        className="tamra-location-button"
                        style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                            margin: "0",
                        }}
                        onClick={moveToCurrentCarLocation}
                    />
                </div>
                <Button
                    color={carInfo.current_service ? "warning" : "primary"}
                    onClick={serviceOnOffButtonCallback}
                    style={{ marginTop: "20px" }}
                >
                    서비스 <b>{carInfo.current_service ? "끄기" : "켜기"}</b>
                </Button>
                <div className="reactive-font" style={{ marginTop: "5px" }}>
                    updated at{" "}
                    <b>{new Date(carInfo?.updated_time).toLocaleString()}</b>
                </div>
            </div>
            <Snackbar
                open={!!snackbarMessage}
                autoHideDuration={5000}
                onClose={() => setSnackbarMessage("")}
                message={snackbarMessage}
                action={
                    <Button onClick={() => setSnackbarMessage("")}>닫기</Button>
                }
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            />
        </div>
    );
};

export default withRouter(CarSharingAdmin);
