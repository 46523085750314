import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Sound from "react-sound";
import axios from "axios";
import { Link } from 'react-router-dom';
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { inside, validServiceArea } from "./SeogwipoCommon.js";
import SeogwipoMiniMapContainer from "./SeogwipoMiniMapContent.js";

class FloatingPrivateAdminPage extends Component {
    state = {
        new_call: undefined,
        car_timeout: true,
        call_available: false,
        passenger_info: undefined,
        req_time: undefined,
        car_location: undefined,
        service_on: false,
        view_passenger_info: false,
        trip_description: undefined,
        current_service_departure: [0, 0],
        current_service_destination: [0, 0]
    };

    async getCallState() {
        await axios
            .get("/api/seogwipo/floating/getPrivateCallState", {
                params: {
                    isClient: false
                }
            })
            .then(function (res) {
                if (res.statusText === "OK") {
                    return res.data;
                } else {
                    return {
                        new_call: undefined,
                        call_available: false,
                        car_timeout: false,
                        passenger_info: undefined,
                        req_time: undefined,
                        service_on: false,
                        car_location: undefined,
                        current_service_departure: undefined,
                        current_service_destination: undefined,
                        view_passenger_info: false,
                        trip_description: undefined,
                    };
                }
            })
            .catch(function (error) {
                console.error("error while request GET to server");
                console.log(error);
                return {
                    new_call: undefined,
                    car_timeout: false,
                    call_available: false,
                    passenger_info: undefined,
                    req_time: undefined,
                    service_on: false,
                    car_location: undefined,
                    current_service_departure: undefined,
                    current_service_destination: undefined,
                    view_passenger_info: false,
                    trip_description: undefined,
                };
            })
            .then((data) =>
                this.setState({
                    new_call: data.new_call,
                    car_timeout: data.car_timeout,
                    call_available: data.call_available,
                    passenger_info: data.passenger_info,
                    req_time: data.req_time,
                    service_on: data.service_on,
                    car_location: data.car_location,
                    current_service_departure: data.current_service_departure,
                    current_service_destination: data.current_service_destination,
                    view_passenger_info: data.new_call || this.state.view_passenger_info,
                    trip_description: data.trip_description,
                })
            );
    }
    async disableCall() {
        await axios
            .put("/api/seogwipo/floating/disablePrivateCall")
            .then(function (res) {
                if (res.statusText === "OK") {
                } else {
                    alert("호출 서비스 종료를 실패했습니다.");
                }
            })
            .catch(function (error) {
                alert("호출 서비스 종료를 실패했습니다.");
            }).then((data) => { });
        this.getCallState();
    }

    async ableCall() {
        await axios
            .put("/api/seogwipo/floating/ablePrivateCall", {})
            .then(function (res) {
                if (res.statusText === "OK") {
                    return true;
                } else {
                    alert("호출 받기 활성화에 실패했습니다.");
                }
            }).catch(function (error) {
                alert("호출 받기 활성화에 실패했습니다.");
            }).then((data) => {
            }
            );
        this.getCallState();
    }

    async checkCall() {
        await axios
            .put("/api/seogwipo/floating/checkPrivateCall")
            .then(function (res) {
                if (res.statusText === "OK") {
                } else {
                    alert("예약 확인이 실패했습니다. 필요한 추가 작업은 없습니다.");
                }
            })
            .catch(function (error) {
                alert("예약 확인이 실패했습니다. 필요한 추가 작업은 없습니다.");
            }).then((data) => {
            }
            );
        this.getCallState();
    }

    async cancelCall() {
        await axios
            .put("/api/seogwipo/floating/cancelCall")
            .then(function (res) {
                if (res.statusText === "OK") {
                    if (parseInt(res.data.result_code) < 0) {
                        alert("예약 문자 전송이 실패했습니다.");
                    } else {
                        this.setState({
                            view_passenger_info: false
                        });
                    }
                } else {
                    alert("예약 취소가 실패했습니다. 예약자에게 수동으로 연락이 필요할 수 있습니다.");
                }
            }).catch(function (error) {
                alert("예약 취소가 실패했습니다. 예약자에게 수동으로 연락이 필요할 수 있습니다.");
            }).then((data) => {
            }
            );
        this.getCallState();
    }

    async addNoCustomerTrip() {
        let x_min = 33.2462646;
        let x_max = 33.2623064;
        let y_min = 126.4980204;
        let y_max = 126.5233833;
        let rand_x = Math.random() * (x_max - x_min) + x_min;
        let rand_y = Math.random() * (y_max - y_min) + y_min;
        while (!inside([rand_x, rand_y], validServiceArea)) {
            rand_x = Math.random() * (x_max - x_min) + x_min;
            rand_y = Math.random() * (y_max - y_min) + y_min;
        }
        await axios
            .put("/api/seogwipo/floating/addNoCustomerTrip", {
                random_destination: [rand_x, rand_y]
            })
            .then(function (res) {
                if (res.statusText === "OK") {
                }
            }).catch(function (error) {
                alert("가상 목적지 설정이 실패하였습니다.");
            }).then((data) => {
            }
            );
        this.getCallState();
    }

    async endNoCustomerTrip() {
        await axios
            .put("/api/seogwipo/floating/endNoCustomerTrip")
            .then(function (res) {
                if (res.statusText === "OK") {
                }
            }).catch(function (error) {
                alert("가상 목적지 도착 완료 설정에 실패하였습니다.");
            }).then((data) => {
            }
            );
        this.getCallState();
    }

    async addToCustomerTrip() {
        await axios
            .put("/api/seogwipo/floating/addToCustomerTrip")
            .then(function (res) {
                if (res.statusText === "OK") {
                }
            }).catch(function (error) {
                alert("탑승 장소로 이동 설정에 실패하였습니다.");
            }).then((data) => {
            }
            );
        this.getCallState();
    }

    async endToCustomerTrip() {
        await axios
            .put("/api/seogwipo/floating/endToCustomerTrip")
            .then(function (res) {
                if (res.statusText === "OK") {
                }
            }).catch(function (error) {
                alert("탑승 장소 도착 완료 설정에 실패하였습니다.");
            }).then((data) => {
            }
            );
        this.getCallState();
    }

    async addCustomerTrip() {
        await axios
            .put("/api/seogwipo/floating/addCustomerTrip")
            .then(function (res) {
                if (res.statusText === "OK") {
                }
            }).catch(function (error) {
                alert("손님탑승 설정이 실패하였습니다");
            }).then((data) => {
            }
            );
        this.getCallState();
    }

    async endCustomerTrip() {
        await axios
            .put("/api/seogwipo/floating/endCustomerTrip", { is_private: true })
            .then(function (res) {
                if (res.statusText === "OK") {
                }
            }).catch(function (error) {
                alert("목적지 도착 완료 설정에 실패하였습니다.");
            }).then((data) => {
            }
            );
        this.setState({
            view_passenger_info: false
        });
        this.getCallState();
    }

    async componentDidMount() {
        this.intervalId = await setInterval(() => this.getCallState(), 2000);
        this.getCallState();
    }
    async componentWillUnmount(){
        if(this.intervalId !== undefined)
            clearInterval(this.intervalId);
    }
    
    async clear() {
        clearInterval(this.intervalId);
    }

    async logout() {
        sessionStorage.removeItem("token");
        this.props.history.go(0);
    }
    getMap() {
        return (<div style={{ width: "80vw", height: "20%" }}>
            <SeogwipoMiniMapContainer departure={this.state.current_service_departure} destination={this.state.current_service_destination} />
        </div>);
    }

    isUnderAge(birthdate) {
        if ((new Date() - new Date(birthdate)) / 31557600000.0 < 19) {
            return true;
        } else {
            return false;
        }
    }

    getPassengerInfoTable() {
        const { passenger_info } = this.state;
        let passengerItems;
        let with_under_age = (<div />);
        if (passenger_info) {
            passengerItems = Object.keys(passenger_info).map((item, key) => (
                <ListGroupItem key={key}>{passenger_info[item]}</ListGroupItem>
            ));
            if ("birthdate1" in passenger_info && this.isUnderAge(passenger_info["birthdate1"])) {
                with_under_age = (<div style={{ color: "red", textAlign: "center" }}>* 미성년자가 탑승했습니다. 법적대리인의 서면 동의를 받아주세요.</div>);
            }
            if ("birthdate2" in passenger_info && this.isUnderAge(passenger_info["birthdate2"])) {
                with_under_age = (<div style={{ color: "red", textAlign: "center" }}>* 미성년자가 탑승했습니다. 법적대리인의 서면 동의를 받아주세요.</div>);
            }
            if ("birthdate3" in passenger_info && this.isUnderAge(passenger_info["birthdate3"])) {
                with_under_age = (<div style={{ color: "red", textAlign: "center" }}>* 미성년자가 탑승했습니다. 법적대리인의 서면 동의를 받아주세요.</div>);
            }
        } else {
            passengerItems = <div />;
        }
        return (
            <div>
                {with_under_age}
                <ListGroup style={{ color: "black" }} horizontal>{passengerItems}</ListGroup>
            </div>
        );
    }

    getLogOutBtn() {
        return (<div
            style={{
                margin: "auto",
                width: "90%",
                padding: "10px 0",
                textAlign: "right",
                color: "black",
            }}
        >
            <Button sm={10} onClick={() => this.logout()}>Log Out</Button>
        </div>);
    }

    getCallAvailableForm() {
        return (
            <div>
                <h1> 차량 호출을 받고 있습니다. </h1>
                <Button size="lg" color="info" onClick={this.disableCall.bind(this)}>차량 호출 받지 않기</Button>
            </div>
        );
    }
    getCallUnavailableForm() {
        return (
            <div>
                <h1>차량 호출을 받지 않고 있습니다.</h1>
                <Button size="lg" color="info" onClick={this.ableCall.bind(this)}>호출 받기</Button>
            </div >);
    }

    getNewCallInfo() {
        let passengerInfoTable = this.getPassengerInfoTable();
        return (
            <div style={{
                textAlign: "left",
                width: "90%"
            }}>
                <Sound
                    url="../sound/ring.mp3"
                    playStatus="PLAYING"
                    allow="autoplay"
                />
                <h3>새로운 호출이 있습니다.</h3>
                <br />
                <h5>[호출 시각] : {this.state.req_time}</h5>
                <h5>[호출 승객 정보] : </h5>
                {passengerInfoTable}
                {this.getMap()}
            </div>);
    }

    getCallInfoForm() {
        const {
            view_passenger_info
        } = this.state;
        if (view_passenger_info) {
            let passengerInfoTable = this.getPassengerInfoTable();
            return (
                <div style={{
                    textAlign: "left",
                    width: "90%"
                }}>
                    <h5>[호출 시각] : {this.state.req_time}</h5>
                    <h5>[호출 승객 정보] : </h5>
                    {passengerInfoTable}
                    {this.getMap()}
                </div>);
        } else {
            return (<div />);
        }
    }

    openPrevCallInfo() {
        this.setState({
            view_passenger_info: true
        });
    }
    closePrevCallInfo() {
        this.setState({
            view_passenger_info: false
        });
    }
    render() {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#DFDFDF";
        const { car_timeout, service_on, car_location, call_available, passenger_info, view_passenger_info, new_call, trip_description } = this.state;

        let logoutBtn = this.getLogOutBtn();
        let contents = (<div />);
        let btns = (<div />);
        let adminForm;

        if (service_on) {
            if (new_call) {
                adminForm = this.getNewCallInfo();
                btns = (<Button size="md" style={{ width: "50vw", marginTop: "0.5vh" }} color="info" onClick={this.checkCall.bind(this)}>차량 호출 확인 / 알림 소리 종료 </Button>);
            } else if (call_available && trip_description === "trip_without_customer") {
                adminForm = (<div style={{ marginTop: "5vh" }}><h2>가상 목적지로 이동 중입니다.</h2></div>);
                btns = (<Button size="md" style={{ width: "50vw", marginTop: "0.5vh" }} color="info" onClick={this.endNoCustomerTrip.bind(this)}>가상 목적지 도착 완료</Button>);
            } else if (!call_available && trip_description === "trip_to_customer") {
                adminForm = (<div style={{ marginTop: "5vh" }}><h2>탑승 장소로 이동 중입니다.</h2></div>);
                btns = (<Button size="md" style={{ width: "50vw", marginTop: "0.5vh" }} color="info" onClick={this.endToCustomerTrip.bind(this)}>탑승 장소 도착 완료</Button>);
            } else if (!call_available && trip_description === "trip_with_customer") {
                adminForm = (<div style={{ marginTop: "5vh" }}><h2>손님이 탑승하여 목적지로 이동 중입니다.</h2></div>);
                btns = (<Button size="md" style={{ width: "50vw", marginTop: "0.5vh" }} color="info" onClick={this.endCustomerTrip.bind(this)}>목적지 도착 완료</Button>);
            } else if (call_available) {
                adminForm = (<div>
                    <h2>고객의 호출을 기다리고 있습니다.</h2>
                    <h2>빈차 주행 클릭 시 가상 목적지가 설정됩니다.</h2>
                    {this.getCallInfoForm()}
                </div>)
                let prevCallInfoViewerBtn = (<div />);
                if (passenger_info && !view_passenger_info) {
                    prevCallInfoViewerBtn = (<Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="info" onClick={this.openPrevCallInfo.bind(this)}>이전 호출 정보 보기</Button>);
                } else if (view_passenger_info) {
                    prevCallInfoViewerBtn = (<Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="info" onClick={this.closePrevCallInfo.bind(this)}>이전 호출 정보 닫기</Button>);
                }
                btns = (<div>
                    {prevCallInfoViewerBtn}
                    <Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="info " onClick={this.addNoCustomerTrip.bind(this)}>빈차주행</Button>
                    <Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="danger" onClick={this.disableCall.bind(this)}>서비스 종료</Button>
                </div>);
            } else {
                adminForm = this.getCallInfoForm();
                btns = (<div>
                    <Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="info " onClick={this.addToCustomerTrip.bind(this)}>탑승장소로 이동</Button>
                    <Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="success " onClick={this.addCustomerTrip.bind(this)}>손님탑승</Button>
                    <Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="danger" onClick={this.cancelCall.bind(this)}>예약 취소</Button>
                </div>)
            }
            contents = (
                <div style={{ marginTop: "3vh" }}>
                    {adminForm}
                    <div style={{
                        zIndex: "1"
                    }}>
                        {btns}
                    </div>
                </div>);
        } else if (car_timeout || car_location === undefined) {
            contents = (<div style={{ marginTop: "5vh" }}><h2 style={{ color: "red" }}>차량의 위치를 확인할 수 없습니다.</h2></div>);
        } else if (!car_timeout && !inside(car_location, validServiceArea)) {
            contents = (<div style={{ marginTop: "5vh" }}><h2 style={{ color: "red" }}>차량이 운영구역 밖에 있어 서비스를 시작할 수 없습니다.</h2></div>);
        } else {
            contents = (
                <div style={{ marginTop: "5vh" }}>
                    <h2 style={{ color: "red" }}>고객이 호출을 할 수 없는 상태입니다.</h2>
                    <Button style={{ width: "50vw", marginTop: "1vh" }} size="md" color="info" onClick={this.ableCall.bind(this)}>서비스 시작</Button>
                </div>);
        }
        return (
            <div style={{ height: "100%", position: "relative" }}>
                {logoutBtn}
                <div
                    style={{
                        margin: "auto",
                        width: "80%",
                        textAlign: "center",
                        color: "black"
                    }}
                >
                    <h1><b>- 서귀포 자유노선형 운영자 페이지 <span style={{ color: "orange" }}>[초대 승객용]</span> - </b></h1>
                    <Link to={{ pathname: "/seogwipo/floating/admin" }} onClick={this.clear.bind(this)}>모든 승객에게 서비스하기</Link>
                    {contents}
                </div>
            </div >
        );
    }
}
export default withRouter(FloatingPrivateAdminPage);
