import React from "react";
import { withRouter } from "react-router-dom";
import { getSeogwipoBg, getSeogwipoName } from "./SeogwipoCommon.js";
import "../../style/SeogwipoMain.css";

function SeogwipoServiceEnd(props) {
    return (
        <div className="main flex">
            {getSeogwipoBg()}
            <div className="service-info">
                <div className="Seogwipo-Main-box">
                    <div className="Seogwipo-logo-box">
                        <img
                            className="RF-Logo"
                            src="/RF_LOGO_BLUE.png"
                            alt="/RF_LOGO_WHITE.png"
                        />
                        <img
                            id="Logo-Cross"
                            src="/logo_cross.svg"
                            alt="/favico.ico"
                        />
                        <img
                            className="JDC-Logo"
                            src="/jdc_logo.png"
                            alt="/jdc_logo.svg"
                        />
                    </div>
                    {getSeogwipoName()}
                </div>
                <div
                    className="service-info-round-box"
                    style={{
                        width: "auto",
                        margin: "0 2rem",
                    }}
                >
                    <div>
                        <div
                            style={{
                                color: "#5a5a5a",
                                wordBreak: "keep-all",
                                whiteSpace: "nowrap",
                                fontSize: "1.125rem",
                                textAlign: "center",
                                paddingInline: "1rem",
                            }}
                        >
                            본&nbsp;서비스는 <br className="show-mobile" />
                            <b style={{ color: "#2f2f2f" }}>
                                2022년&nbsp;12월&nbsp;31일
                            </b>
                            부로
                            <br className="show-mobile" /> 운영이 &nbsp;
                            <b style={{ color: "#2f2f2f" }}>종료</b> 되었습니다.
                        </div>
                        <div className="MuiDivider-root" />
                        <div
                            style={{
                                textAlign: "left",
                                paddingInline: "2rem",
                                textIndent: "-0.9em",
                                wordBreak: "keep-all",
                            }}
                        >
                            <div>
                                <div>
                                    1. 제주국제공항-중문관광단지 자율주행 서비스
                                    [
                                    <a href="https://tadatada.com/rideflux/jungmun/">
                                        바로가기
                                    </a>
                                    ]
                                </div>
                                <div
                                    style={{
                                        fontSize: "1em",
                                        color: "#5a5a5a",
                                        paddingLeft: "1rem",
                                    }}
                                >
                                    <div>
                                        ● 국내 최장거리 자율주행 서비스 (1인
                                        8,000원)
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    2. 탐라자율차 서비스 [
                                    <a href="https://reservation.rideflux.com/tamra">
                                        바로가기
                                    </a>
                                    ]
                                </div>
                                <div
                                    style={{
                                        fontSize: "1em",
                                        color: "#5a5a5a",
                                        paddingLeft: "1rem",
                                    }}
                                >
                                    <div>
                                        ● 제주국제공항 인근 해안도로를 순환하는
                                        노선형 자율주행 서비스
                                    </div>
                                    <div>
                                        ● 중문관광단지 내 원하는 지점까지
                                        자유롭게 이동하는 구역형 자율주행 서비스
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(SeogwipoServiceEnd);
