import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Button, DropdownItem, Table } from "reactstrap";
import { inside } from "../../Component/PageCommon.js";
import { tamraValidServiceArea } from "../../../constants/ValidServiceArea.js";
import { useInterval } from "../../Hooks/Hooks.js"
import ReactSound from "react-sound";
import LogoutButton from "../../Component/LogoutButton.js";
import AdminTopBar from "../../Component/TopBar.js";
import DarkModeButton from "../../Component/DarkModeButton.js";
import { Snackbar } from "@material-ui/core";
import SelectCar from "../../Component/SelectCar.js";
import { DropdownButton, DropdownItems } from "../../Component/DropdownButton.js";

const TamraAdminPage = (props) => {
    const {
        history,
        location,
    } = props;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const {
        car_name
    } = query;
    const service_name = "TAMRA_CARPOOL";

    const [service, setService] = useState(false);
    const [trip, setTrip] = useState(false);
    const [carInfo, setCarInfo] = useState({
        car_timeout: true,
        car_location: undefined,
        passenger: [],
        trip_cnt: 0,
        total_trip_cnt: 0,
        current_service: "",
        current_service_id: undefined,

    });
    const [shuttlePath, setShuttlePath] = useState([])
	const [callAvailable, setCallAvailable] = useState(false)
    const [hasCall, setHasCall] = useState(false)
    const [currentShuttleStopID, setCurrentShuttleStopID] = useState(carInfo.current_shuttle_stop_id || 0)
    const [nextShuttleStopIdx, setNextShuttleStopIdx] = useState(0);
    const [needToBoardingCheckTeamCount,setNeedToBoardingCheckTeamCount] = useState(0);

    const [carName, setCarName] = useState(car_name);

    const [stops, setStops] = useState([]);
    const [destinationStopIdx, setDestinationStopIdx] = useState(-1);
    const [isTestDriveMode, setIsTestDriveMode] = useState(false);
    const [selectedDestinationStopIdx, setSelectedDestinationStopIdx] = useState(-1);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

    const [isInside, setIsInside] = useState(false);

    const [soundPlayStatus, setSoundPlayStatus] = useState({
        shortNotification: "STOPPED",
        longNotification: "STOPPED",
        warning: "STOPPED",
    });
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const boardPassengersCountRef = useRef(0);
    const alightPassengersCountRef = useRef(0);

    const changeSoundStatus = useCallback((soundType, newStatus = 'STOPPED') => {
        if (!soundType || !Object.keys(soundPlayStatus).includes(soundType)) {
            console.error('failed to change sound status')
            return
        }
        setSoundPlayStatus((prev) => {
            if (prev[soundType] !== newStatus) {
                return {...prev, [soundType]: newStatus}
            } else return prev
        })
    }, [])

    const onFinishedSoundPlay = useCallback((soundType) => {
        changeSoundStatus(soundType, 'STOPPED')
    }, [changeSoundStatus]);

    const getCallState = useCallback(async () => {
        if (!carName)
            return;
        const { statusText, data } = await axios.get(`/api/tamra/carpool/getCallState`, {
            params: {
                isClient: false,
                car_name: carName,
            }
        }).catch(err => {
            return err.response;
        });
        if (statusText === 'OK') {
            setCarInfo(data);
            setShuttlePath((prev) => {
                if (JSON.stringify(prev) === JSON.stringify(data.shuttle_path)) {
                    return prev;
                }
                return data.shuttle_path;
            })
            setService(data.service_on);
            setTrip(data.trip_on);
			setCallAvailable(data.call_available);
            setDestinationStopIdx(stops.findIndex(stop => stop.id === data.next_shuttle_stop_id));
        }
        else {
            setCarInfo(info => {
                return {
                    ...info,
                    car_location: undefined,
                    trip_cnt: 0,
                    total_trip_cnt: 0,
                }
            });
            setService(false);
            setTrip(false);
        }
    }, [carName, stops]);

    const turnServiceOff = useCallback(async () => {
        if (!carName)
            return;
        if (isTestDriveMode && !window.confirm(`탑승 중인 승객이 있습니다. 그래도 종료하시겠습니까?`)) {
            return;
        } else if (hasCall && !isTestDriveMode) {
            window.alert(
                `수행해야할 호출이 남아있어 운행을 종료할 수 없습니다.
                \n종료를 원하시면 호출을 중단한 이후에 남은 호출을 수행해 주세요.`
            );
            return
        }
        const res = await axios.put(`/api/tamra/carpool/turn-service-off`, {
            car_name: carName,
        })
            .catch(err => err.response);
        if (res.statusText === 'OK') {
            setService(false);
        } else {
            window.alert(`운행 종료에 실패하였습니다. (${res.statusText})`)
        }
    }, [carName, hasCall]);
    const turnServiceOn = useCallback(async () => {
        if (!carName)
            return;
        const res = await axios.put(`/api/tamra/carpool/turn-service-on`, {
            car_name: carName,
        })
            .catch(err => err.response);
        if (res.statusText === 'OK') {
            setService(true);
        } else {
            window.alert(`운행 시작에 실패했습니다. (${res.statusText})`)
        }
    }, [carName]);

    const goNextStop = useCallback(async (nextStop, stops) => {
        if (needToBoardingCheckTeamCount > 0 && 
            !window.confirm(
                '탑승이 확인되지 않은 승객이 있습니다. 그래도 이동하시겠습니까?\n(탑승하지 않은 승객의 호출은 취소됩니다.)'
            )
        ) return;

        const res = await axios.post(`/api/tamra/carpool/go_to_next_stop`, {
            car_name: carName,
            next_stop: stops[nextStop]
        }).catch(err => err.response);
        if (res.statusText === 'OK') {
            setSelectedDestinationStopIdx(-1)
            setDestinationStopIdx(nextStop);
            setTrip(true);
        }
    }, [carName, needToBoardingCheckTeamCount]);

    const goSelectedStop = useCallback(async (destination_shuttle_stop_id) => {
        if (!window.confirm(`정차할 정류소 이전의 콜은 받을 수 없으며, 정차 정류소에 도착하기 전까지는 운행 종료를 할 수 없게됩니다. 진행할까요?`)) return;
        axios.put(`/api/tamra/carpool/new_dummy_passenger`, {
            car_name: carName,
            destination_shuttle_stop_id
        }).then(() => {
            setIsTestDriveMode(true);
            setSnackbarMessage('목적지 수동 설정이 완료되었습니다. (설정한 정류소에서 정차)');
            setSelectedDestinationStopIdx(-1);
        }).catch((err) => {
            window.alert(`목적지 수동 설정에 실패하였습니다. 호출 받기가 꺼져있고 탑승한 승객이 없어야 합니다. (${err.response.statusText})`)
        })
    }, [carName])

    const selectCarCallback = useCallback((car_name) => {
        setCarName(car_name);
        history.replace({
            search: `?car_name=${car_name}`
        });
    },[history]);

	const turnCallOn = useCallback(async () => {
        if (!currentShuttleStopID) {
            window.alert(`최근에 방문한 정류장이 존재해야 호출을 켤 수 있습니다.
            \n가까운 정류장으로 이동 후 호출을 켜주세요.`)
            return
        }
		setCallAvailable(true)
		await axios.put(`/api/tamra/carpool/ableCall`, {
            car_name: carName,
        }).catch((err) => {
            window.alert(`호출 켜기에 실패했습니다. (${err.response.statusText})`)
        })
	}, [carName, currentShuttleStopID])

	const turnCallOff = useCallback(async () => {
		setCallAvailable(false)
		await axios.put(`/api/tamra/carpool/disableCall`, {
            car_name: carName,
        }).catch((err) => {
            window.alert(`호출 끄기에 실패했습니다. (${err.response.statusText})`)
        })
	}, [carName])

    const passengerBoardingCheck = useCallback(async (phone) => {
        const isSuccessBoarding = await axios
            .put(`/api/tamra/carpool/register-passenger`, {
                car_name: carName,
                phone
            })
            .then(() => true)
            .catch((err) => {
                window.alert(`탑승 확인에 실패하였습니다. ${err.response.statusText}`)
                return false
            });
        return isSuccessBoarding
    }, [carName])

    const setNewGoal = useCallback(async () => {
        await axios.put(`/api/${carName}/new_goal`)
            .then(() => {
                setSnackbarMessage('목적지를 다시 설정하였습니다.')
            })
            .catch((err) => {
                window.alert(`목적지 재설정에 실패하였습니다. (${err.response.statusText})`)
            });
    }, [carName])

    const service_button = useMemo(() => {
        return <Button
            style={{ width: "100%" }}
            size="md"
            color={service ? "danger" : "info"}
            onClick={service ? turnServiceOff : turnServiceOn}
        >
            {service ? "운행 종료" : "운행 시작"}
        </Button>
    }, [service, isInside, turnServiceOff, turnServiceOn]);

    const stop_text = useMemo(() => {
        if (stops.length < 1 || destinationStopIdx < 0) {
            return null;
        }
        const currentShuttleStopDescription = stops.find((stop) => stop.id === currentShuttleStopID)?.description;
        return (
            <h2 style={{fontWeight: 300}}>
                {trip && currentShuttleStopDescription && `${currentShuttleStopDescription} > `}
                <span style={{fontWeight: 500}}>{` ${stops[destinationStopIdx]["description"]}`}</span>
                {` 정류장으로 ${trip ? "이동 중입니다" : "이동하였습니다"}.`}
            </h2>
        );
    }, [stops, trip, destinationStopIdx, currentShuttleStopID])

    const destinationDropDown = useMemo(
        () => {
            if (callAvailable || hasCall) return null;
            return <div>
                <DropdownButton
                    dropdownName={
                        selectedDestinationStopIdx === -1
                            ? `${
                                  currentShuttleStopID ? "정차 정류소를" : "첫 정류소를"
                              } 선택하세요.`
                            : stops[selectedDestinationStopIdx]?.description
                    }
                    isOpen={dropdownIsOpen}
                    toggleHandler={() => setDropdownIsOpen((prev) => !prev)}
                >
                    <DropdownItems
                        items={stops.map((stop) => stop.description)}
                        clickHandler={(destination) => {
                            setSelectedDestinationStopIdx(
                                stops.findIndex(
                                    (stop) => stop.description === destination
                                )
                            );
                        }}
                    />
                </DropdownButton>
                {stops && selectedDestinationStopIdx !== -1 && (
                    <Button
                        color="success"
                        onClick={() =>
                            currentShuttleStopID
                                ? goSelectedStop(stops[selectedDestinationStopIdx]?.id)
                                : goNextStop(selectedDestinationStopIdx, stops)
                        }
                    >
                        정류장을 목적지로 설정
                    </Button>
                )}
            </div>
        },
        [
            stops,
            dropdownIsOpen,
            selectedDestinationStopIdx,
            goNextStop,
            goSelectedStop,
            currentShuttleStopID,
            callAvailable,
            hasCall,
        ]
    );

    const stop_content = useMemo(() => {
        if (stops.length == 0) {
            return (<div />);
        }
        const setNewGoalButton = <Button onClick={setNewGoal}>기존 목적지 재설정</Button>
        if (trip) {
            return <div style={{paddingBottom: "28px"}}>
                {stop_text}
                {setNewGoalButton}
                {destinationDropDown}
            </div>
        }
        return (
            <div style={{ paddingBottom: "28px" }}>
                {stop_text}
                {currentShuttleStopID ? (
                    <Button
                        size="md"
                        color="success"
                        onClick={() => goNextStop(nextShuttleStopIdx, stops)}
                    >
                        <strong style={{ fontSize: "1.1rem", color: "white" }}>
                            {stops[nextShuttleStopIdx]?.description}
                        </strong>
                        {` 정류장을 목적지로 설정`}
                    </Button>
                ) : stops ? (
                    destinationDropDown
                ) : (
                    "최근 정류장이 없어 다음 정류장을 목적지로 설정 할 수 없습니다."
                )}
                <br />
            </div>
        );
    }, [
        stop_text,
        stops,
        trip,
        goNextStop,
        currentShuttleStopID,
        shuttlePath,
        setNewGoal,
        nextShuttleStopIdx,
        destinationDropDown,
    ]);

    const content_info = useMemo(() => {
        const {
            car_timeout, car_location, current_service
        } = carInfo;
        const _inside = isInside;
        let text = '';
        let color = 'red';
        let service_show = service;
        let stop_show = false;
        if (!!current_service && service_name !== current_service)
            text = "다른 사용자 페이지에서 운행 중이거나 서비스명이 일치하지 않습니다."
        else if (car_timeout || !!!car_location)
            text = "차량의 위치를 확인할 수 없어 운행를 시작할 수 없습니다."
        else if (service) {
            if (_inside) {
                color = 'blue'
                text = "" //trip ? "순환 노선을 주행 중입니다." : "";
                // if (trip) {
                    stop_show = true;
                // }
                changeSoundStatus('warning', 'STOPPED');
            }
            else {
                text = "차량이 운영구역을 벗어났습니다. 운행을 중단 해주세요!";
                if (hasCall || callAvailable) {
                    changeSoundStatus('warning', 'PLAYING')
                }
            }
        }
        else if (!car_timeout && !_inside)
            text = "차량이 운영구역 밖에 있어 운행을 시작할 수 없습니다."
        else {
            text = "고객이 탑승을 할 수 없는 상태입니다."
            service_show = true;
        }

        return {
            text,
            color,
            service_show,
            trip_show: color === 'blue',
            stop_show
        };
    }, [carInfo, service, service_name, isInside, hasCall, callAvailable, changeSoundStatus]);

    const tripToggleButton = useMemo(() => {
        const {
            car_timeout
        } = carInfo;
        if (car_timeout || !service) {
            return null;
        }
		return (
			<Button
				style={{width: '100%'}}
				color={callAvailable ? 'warning' : 'info'}
				onClick={() => callAvailable ? turnCallOff() : turnCallOn()}
			>
				호출 {callAvailable ? '중단' : '받기'}
			</Button>
		);
    }, [carInfo, service, callAvailable, turnCallOn, turnCallOff]);

    const stopsTable = useMemo(() => {
        const {
            shuttle_path: _shuttle_path,
            current_shuttle_stop_id,
            current_service,
            minimum_index_offset,
            destination_shuttle_path_id,
        } = carInfo;
        if (!_shuttle_path?.length || current_service !== service_name || !stops.length) {
            return <div>정류소별 승하차 인원을 받아오지 못했습니다.</div>;
        }
        const shuttle_path = _shuttle_path.slice(0, stops.length + minimum_index_offset + 1);
        const currentShuttlePathID = shuttle_path.find(
            (stop) => stop.stop_id === current_shuttle_stop_id
        )?.id;

		const TABLE_HEAD = [
			{board_passengers: '승차 인원'},
			{alight_passengers: '하차 인원'},
		];

		const currentStopBackgroundColor = '#86A3B8';
		const nextStopBackgroundColor = '#E8D2A6';

        const PassengerCountCell = (props) => {
            const { passengerCount = 0, circle = false, size = '30px', style } = props
            return (
                <div
                    style={{
                        width: size,
                        height: size,
                        border: circle ? "3px solid #d35e56" : "",
                        borderRadius: "50%",
                        margin: '0 auto',
                        ...style,
                    }}
                >
                    {passengerCount}
                </div>
            );
        } 

		const stopsTable = (
            <Table striped className="tamra-admin-reservation">
                <thead>
                    <tr>
                        <th></th>
                        {TABLE_HEAD.map((head, idx) => (
                            <th key={idx}>{Object.values(head)[0]}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {shuttle_path.map((path) => (
                        <tr key={path.id}>
                            <td
                                style={{
                                    backgroundColor:
                                        path.id === currentShuttlePathID
                                            ? currentStopBackgroundColor
                                            : path.id === destination_shuttle_path_id
                                            ? nextStopBackgroundColor
                                            : "",
                                    padding: '3px',
                                }}
                            >
                                {stops.find((stop) => stop.id === path.stop_id).description}
                            </td>
                            {TABLE_HEAD.map((head, idx) => {
                                const title = Object.keys(head)[0];
                                const value = path[title]?.reduce((acc, [_id, _phone, _name, num]) => acc + num, 0) ?? path[title];
                                return (
                                    <td
                                        key={idx}
                                        style={{
                                            backgroundColor:
                                                path.id ===
                                                currentShuttlePathID
                                                    ? currentStopBackgroundColor
                                                    : path.id ===
                                                      destination_shuttle_path_id
                                                    ? nextStopBackgroundColor
                                                    : "",
                                            padding: '3px',
                                        }}
                                    >
                                        <PassengerCountCell
                                            passengerCount={value}
                                            circle={!!value}
                                        />
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
		const colorMemo = (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                테이블 배경 색:
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                        style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: currentStopBackgroundColor,
							marginRight: '10px'
                        }}
                    />
                    최근 정류장
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                        style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: nextStopBackgroundColor,
							marginRight: '10px'
                        }}
                    />
                    다음 정차할 정류장
                </div>
            </div>
        );

		return (
			<>
				{stopsTable}
				{colorMemo}
			</>
		)
    }, [stops, carInfo, ])

    const boardingCheckTable = useMemo(() => {
        if (!shuttlePath) return;
        const currentShuttlePath = shuttlePath?.find((stop) => stop.stop_id === currentShuttleStopID);
        const boardPassengers = currentShuttlePath?.board_passengers ?? [];
        const reservationIDs = Array.from(new Set(boardPassengers?.map((boardingPassenger) => boardingPassenger[0]))).sort((a, b) => a[0] - b[0]);
        if (!reservationIDs.length) return;

        const teamColor = '#ABC4AA';
        const leftStopsUntilAlight = shuttlePath.reduce((_leftStopsUntilAlight, stop, idx) => {
            const { alight_passengers } = stop;
            if (!alight_passengers.length) return _leftStopsUntilAlight;

            alight_passengers.forEach((alight_passenger) => {
                _leftStopsUntilAlight[alight_passenger[0]] = idx;
            })
            return _leftStopsUntilAlight;
        }, {});

        const LeftStopsTableUntilAlight = (
            <div style={{ display: "flex", justifyContent: "space-around", flexDirection: 'column' }}>
                {reservationIDs.map((id, idx) => (
                    <div style={{ display: "flex", alignItems: "center",}}>
                        <div
                            style={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: teamColor,
                                marginRight: '10px',
                                border: '1px solid #2D2727'
                            }}
                        />
                        {leftStopsUntilAlight[id]}정류장 뒤 하차
                    </div>
                ))}
            </div>
        );
        const _needToBoardingCheckTeamCount = boardPassengers.filter(([, phone]) =>
            !carInfo.passenger_info?.find((info) => info.phone === phone)
        ).length;
        setNeedToBoardingCheckTeamCount(_needToBoardingCheckTeamCount);

        return (
            <>
                <h5>승객 탑승 확인하기</h5>
                <div>
                    {_needToBoardingCheckTeamCount ? (
                        <span>
                            탑승이 확인되지 않은 팀:{" "}
                            <strong
                                style={{ fontSize: "1.1rem", color: "#e36" }}
                            >
                                {_needToBoardingCheckTeamCount}
                            </strong>
                            팀
                        </span>
                    ) : (
                        "모든 팀의 탑승이 확인되었습니다."
                    )}
                </div>
                <div style={{display: 'flex'}}>
                    <Table style={{marginRight: '10px'}}>
                        <thead>
                            <tr>{/**/}</tr>
                        </thead>
                        <tbody>
                            {boardPassengers.map(([id, phone, name, num_passenger, additional_phones]) => (
                                <tr>
                                    <td
                                        style={{
                                            backgroundColor: teamColor,
                                            verticalAlign: 'middle',
                                            color: '#000'
                                        }}
                                    >
                                        <div>대표자 {name}님 ({phone})</div>
                                        <div style={{color: '#000'}}>
                                            {num_passenger - additional_phones.length !== 1 && (
                                                <span
                                                style={{
                                                    backgroundColor: '#ccc',
                                                    borderRadius: '20px',
                                                    marginRight: '10px',
                                                    padding: '1px 5px'
                                                }}
                                                >
                                                    어린이 {num_passenger - additional_phones.length - 1}명 포함
                                                </span>
                                            )}
                                            <b>총 {num_passenger}명</b>
                                        </div>
                                    </td>
                                    <td
                                        style={{
                                            backgroundColor: teamColor,
                                            verticalAlign: 'middle',
                                            color: '#000'
                                        }}
                                    >
                                        {carInfo.passenger_info?.find(
                                            (info) => info.phone === phone
                                        ) ? (
                                            `탑승이 확인되었습니다.`
                                        ) : (
                                            <Button
                                                onClick={() =>
                                                    passengerBoardingCheck(phone)
                                                }
                                                style={{margin: 0}}
                                                disable={trip}
                                            >
                                                탑승 확인
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {LeftStopsTableUntilAlight}
                </div>
            </>
        );
    }, [carInfo, currentShuttleStopID, trip]);


    useEffect(() => {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#F9DEDE";
    }, []);

    useEffect(() => {
        async function wrapperFunction() {
            const _stops = await axios.get("/api/tamra/carpool/get-stops")
                .then((res) => res.data)
                .catch(_ => []);
            setStops(_stops);
        }
        wrapperFunction();
    }, []);

    useEffect(() => {
        setIsInside(inside(carInfo.car_location, tamraValidServiceArea));
        setHasCall(
            shuttlePath?.filter(
                (stop) =>
                    stop.stop_id !== carInfo.current_shuttle_stop_id &&
                    stop.board_passengers.length + stop.alight_passengers.length
            )?.length > 0
        );
        setCurrentShuttleStopID(carInfo.current_shuttle_stop_id);
        const currentShuttleStop = stops.find((stop) => stop.id === carInfo.current_shuttle_stop_id);
        const nextShuttleStop = stops.find((stop) => stop.order === currentShuttleStop?.order % stops.length + 1);
        const nextShuttleStopOrder = nextShuttleStop?.order
        const _nextShuttleStopIdx = stops.findIndex(
            (stop) =>
                stop.order ===
                (nextShuttleStopOrder > stops.length
                    ? nextShuttleStopOrder % stops.length
                    : nextShuttleStopOrder)
        );
        setNextShuttleStopIdx(_nextShuttleStopIdx);
        if (carInfo.passenger_info?.find(({phone}) => phone === '000-0000-0000')) {
            setIsTestDriveMode(true);
        }
    }, [carInfo]);

    useEffect(() => {
        if (isTestDriveMode && !hasCall) {
            setIsTestDriveMode(false);
        }
    }, [isTestDriveMode, hasCall])

    useEffect(() => {
        const currentBoardingPassengers = shuttlePath?.find((stop) => stop.stop_id === currentShuttleStopID)?.board_passengers
        setNeedToBoardingCheckTeamCount(currentBoardingPassengers?.length || 0)
    }, [currentShuttleStopID])

    useEffect(() => {
        if (!currentShuttleStopID) return
        if (currentShuttleStopID === carInfo.destination_shuttle_stop_id) {
            changeSoundStatus('longNotification', 'PLAYING');
            setSnackbarMessage(`이번 정류장은 정차해야하는 정류장입니다.`)
        }
    }, [currentShuttleStopID])

    useEffect(() => {
        if (!shuttlePath || !shuttlePath.length) return;
        const boardPassengersCount = shuttlePath.reduce((sum, path) => sum + path.board_passengers.length, 0);
        // 탑승 예정 인원에 변동이 없는 경우 return
        if (boardPassengersCount === boardPassengersCountRef.current) return;
        const alightPassengersCount = shuttlePath.reduce((sum, path) => sum + path.alight_passengers.length, 0);
        // 정상적으로 탑승하여 하차 인원은 그대로인데 승차 인원만 줄어든 경우는 제외.
        if (alightPassengersCount !== alightPassengersCountRef.current) {
            changeSoundStatus('shortNotification', 'PLAYING');
            if (boardPassengersCountRef.current < boardPassengersCount) {
                setSnackbarMessage(`새로운 호출이 들어왔습니다.`);
            } else {
                setSnackbarMessage(`취소된 호출이 있습니다.`);
            };
        };
        boardPassengersCountRef.current = boardPassengersCount;
        alightPassengersCountRef.current = alightPassengersCount;
    } , [shuttlePath, boardPassengersCountRef, alightPassengersCountRef, changeSoundStatus]);

    useEffect(() => {
        getCallState();
    }, [getCallState]);

    useInterval(getCallState, 2000);

    return <div style={{ height: "100%"}}>
        <AdminTopBar>
            <LogoutButton history={history} />
            <DarkModeButton />
            <SelectCar selectedCar={carName} selectCallback={selectCarCallback} />
        </AdminTopBar>
        <ReactSound
            url="/sound/C6C5G5.mp3"
            playStatus={soundPlayStatus.shortNotification}
            autoLoad
            onFinishedPlaying={() => onFinishedSoundPlay('shortNotification')}
        />
        <ReactSound
            url="/sound/ring.mp3"
            playStatus={soundPlayStatus.longNotification}
            autoLoad
            onFinishedPlaying={() => onFinishedSoundPlay('longNotification')}
        />
        <ReactSound
            url="/sound/timer_loud.mp3"
            playStatus={soundPlayStatus.warning}
            autoLoad
            onFinishedPlaying={() => onFinishedSoundPlay('warning')}
        />
        <div
            style={{
                margin: "auto",
                width: "70%",
                paddingBlock: "5vh",
                textAlign: "center",
            }}
        >
            <h1><b>- Tamra 카풀 운영자 페이지 - </b></h1>
            <br />
            <br />
            <div>
                <h2 style={{fontWeight: 300}}>
                    {content_info.text}
                    {content_info.stop_show ? stop_content : null}
                    {/* {content_info.trip_show ? trip_button : null} */}
                    {content_info.stop_show
                        ?   <div style={{fontSize: '1.5rem'}}>
                                현재 탑승 중인 인원: <b>{carInfo.passenger_info?.reduce(
                                    (acc, { num_passenger }) => num_passenger + acc
                                    , 0
                                )}명 {isTestDriveMode && '(가상 승객)'}</b>
                            </div>
                        : null
                    }
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: service && isInside ? "1vh" : "5vh" 
                    }}>
                        {tripToggleButton}
                        {content_info.service_show ? service_button : null}
                    </div>
                </h2>
            </div>
            <hr />
            {boardingCheckTable}
            {stopsTable}
        </div>
        <Snackbar
            open={!!snackbarMessage}
            autoHideDuration={3000}
            onClose={()=> setSnackbarMessage('')}
            message={snackbarMessage}
            action={<Button onClick={() => setSnackbarMessage('')}>닫기</Button>}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        />
    </div >
}

export default withRouter(TamraAdminPage);
