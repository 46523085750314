export const toLatLng = (position) => {
    try {

        if (!window.kakao) {
            throw "[toLatLng] kakao is not loaded."
        }
        if (typeof position.getLat === 'function') {
            return position;
        }
        else if (Array.isArray(position) && position.length > 1 && position.slice(0, 2).every(p => p !== undefined && p !== null)) {
            return new window.kakao.maps.LatLng(position[0], position[1]);
        }
        else if (typeof position === "object"){
            if (position.hasOwnProperty('lat')) {
                return new window.kakao.maps.LatLng(position.lat, position.lng);
            }
            else if (position.hasOwnProperty('x')) {
                return new window.kakao.maps.LatLng(position.y, position.x);
            }
            else if (position.hasOwnProperty('latitude')) {
                return new window.kakao.maps.LatLng(position.latitude, position.longitude);
            }
        }
        else if (!!!position) {
            return;
        }
        else {
            throw "[toLatLng] Invalid position value"
        }
    }
    catch (err) {
        console.error(err);
        return;
    }
};
export const toPoint = (position) => {
    try {
        if (!window.kakao) {
            throw "[toPoint] kakao is not loaded."
        }
        if (Array.isArray(position) && position.every(p => p !== undefined && p !== null) && position.length === 2) {
            return new window.kakao.maps.Point(position[0], position[1]);
        }
        else if (typeof position === "object"){
            if (position.hasOwnProperty('x')) {
                return new window.kakao.maps.Point(position.x, position.y);
            }
        }
        else if (!!!position) {
            return;
        }
        else {
            throw "[toPoint] Invalid position value"
        }
    }
    catch (err) {
        console.error(err);
        return;
    }
}
export const toSize = (position) => {
    try {
        if (!window.kakao) {
            throw "[toSize] kakao is not loaded."
        }
        if (Array.isArray(position) && position.every(p => p !== undefined && p !== null) && position.length === 2) {
            return new window.kakao.maps.Size(position[0], position[1]);
        }
        else if (typeof position === "object"){
            if (position.hasOwnProperty('width')) {
                return new window.kakao.maps.Size(position.width, position.height);
            }
        }
        else if (!!!position) {
            return;
        }
        else {
            throw "[toSize] Invalid position value"
        }
    }
    catch (err) {
        console.error(err);
        return;
    }
}

export function addEventHandle(target, type, callback) {
    if (target.addEventListener) {
        target.addEventListener(type, callback);
    } else {
        target.attachEvent('on' + type, callback);
    }
}

export function removeEventHandle(target, type, callback) {
    if (target.removeEventListener) {
        target.removeEventListener(type, callback);
    } else {
        target.detachEvent('on' + type, callback);
    }
}