import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { toLatLng, toPoint, toSize } from "./Util";
import { KakaoMapContext } from "./KakaoMap";


const KakaoMarker = ({
    position,
    image,
    title,
    draggable,
    clickable,
    zIndex,
    opacity,
    altitude,
    range,
    onClick ,
    onMouseOver,
    onMouseOut,
    onRightClick,
    onDragStart,
    onDragEnd,
    onTrigger = [],
    visible = true,
    ...props
}) => {
    
    const { map, loaded } = useContext(KakaoMapContext);

    const onEvent = useCallback((target, func) => {
        if(target && typeof(func) === 'function') {
            return function (event) {
                func(target, event);
            }
        }
    }, []);

    const marker = useMemo(() => {
        if (!!!map || !loaded || !window.kakao) {
            return null;
        }
        const latlng = toLatLng([0,0]);
        return new window.kakao.maps.Marker({
            map, position: latlng
        });
    }, [loaded, window.kakao, toLatLng]);
    

    useLayoutEffect(() => {
        if (marker && position) {
            const p = toLatLng(position);
            if (p) {
                marker.setPosition(p);
            }
        }
    }, [marker, position, toLatLng]);

    useLayoutEffect(() => {
        if (marker) {
            marker.setVisible(visible);
        }
    }, [marker, visible]);
    useLayoutEffect(() => {
        if (!marker || !image || typeof(image) !== 'object') {
            return;
        }
        if (image.hasOwnProperty('src')) {
            let width = !!image.width ? image.width : 30;
            let height = !!image.height ? image.height : 30;
            let options;
            if (!!image.options && typeof(image.options) === 'object') {
                options = {
                    ...image.options,
                    offset: toPoint(image.options.offset),
                    spriteOrigin: toPoint(image.options.spriteOrigin),
                    spriteSize: toSize(image.options.spriteSize),
                }
            }
            marker.setImage(new window.kakao.maps.MarkerImage(
                image.src,
                new window.kakao.maps.Size(width, height),
                options
            ));
        }
        else {
            marker.setImage(image);
        }
    }, [marker, image]);
    useLayoutEffect(() => {
        if (marker) {
            marker.setTitle(title);
        }
    }, [marker, title]);
    useLayoutEffect(() => {
        if (marker) {
            marker.setAltitude(altitude);
        }
    }, [marker, altitude]);
    useLayoutEffect(() => {
        if (marker && typeof(range) === 'number') {
            marker.setRange(range);
        }
    }, [marker, range]);
    useLayoutEffect(() => {
        if (marker && typeof(opacity) === 'number') {
            marker.setOpacity(opacity);
        }
    }, [marker, opacity]);
    useLayoutEffect(() => {
        if (marker && typeof(zIndex) === 'number') {
            marker.setZIndex(zIndex);
        }
    }, [marker, zIndex]);
    useLayoutEffect(() => {
        if (marker) {
            marker.setDraggable(draggable);
        }
    }, [marker, draggable]);
    useLayoutEffect(() => {
        if (marker) {
            marker.setClickable(clickable);
        }
    }, [marker, clickable]);

    useLayoutEffect(() => {

    },[]);

    useEffect(() => {
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        }
    }, [marker]);


    useLayoutEffect(() => {
        if (marker && marker.getClickable() && typeof(onClick) === 'function') {
            const click_func = onEvent(marker, onClick);
            window.kakao.maps.event.addListener(marker, 'click', click_func);
            return () => {
                window.kakao.maps.event.removeListener(marker, 'click', click_func);
            }
        }
        return;
    }, [marker, onEvent, onClick]);
    useLayoutEffect(() => {
        if (marker && typeof(onMouseOver) === 'function') {
            const mouse_over_func = onEvent(marker, onMouseOver);
            window.kakao.maps.event.addListener(marker, 'mouseover', mouse_over_func);
            return () => {
                window.kakao.maps.event.removeListener(marker, 'mouseover', mouse_over_func);
            }
        }
        return;
    }, [marker, onEvent, onMouseOver]);
    useLayoutEffect(() => {
        if (marker && typeof(onMouseOut) === 'function') {
            const mouse_out_func = onEvent(marker, onMouseOut);
            window.kakao.maps.event.addListener(marker, 'mouseout', mouse_out_func);
            return () => {
                window.kakao.maps.event.removeListener(marker, 'mouseout', mouse_out_func);
            }
        }
        return;
    }, [marker, onEvent, onMouseOut]);
    useLayoutEffect(() => {
        if (marker && marker.getClickable() && typeof(onRightClick) === 'function') {
            console.log("right_click event is changed");
            const right_click_func = onEvent(marker, onRightClick);
            window.kakao.maps.event.addListener(marker, 'rightclick', right_click_func);
            return () => {
                window.kakao.maps.event.removeListener(marker, 'rightclick', right_click_func);
            }
        }
        return;
    }, [marker, onEvent, onRightClick]);
    useLayoutEffect(() => {
        if (marker && marker.getDraggable() && typeof(onDragStart) === 'function') {
            console.log("drag_start event is changed");
            const drag_start_func = onEvent(marker, onRightClick);
            window.kakao.maps.event.addListener(marker, 'dragstart', drag_start_func);
            return () => {
                window.kakao.maps.event.removeListener(marker, 'dragstart', drag_start_func);
            }
        }
        return;
    }, [marker, onEvent, onDragStart]);
    useLayoutEffect(() => {
        if (marker && marker.getDraggable() && typeof(onDragEnd) === 'function') {
            const drag_end_func = onEvent(marker, onDragEnd);
            window.kakao.maps.event.addListener(marker, 'dragend', drag_end_func);
            return () => {
                window.kakao.maps.event.removeListener(marker, 'dragend', drag_end_func);
            }
        }
        return;
    }, [marker, onEvent, onDragEnd]);
    useLayoutEffect(() => {
        if (!marker || !onTrigger.length) {
            return;
        }

        onTrigger.forEach(trigger_obj => {
            window.kakao.maps.event.addListener(marker, trigger_obj.type, trigger_obj.callback);
        });
        return () => {
            onTrigger.forEach(trigger_obj => {
                window.kakao.maps.event.removeListener(marker, trigger_obj.type, trigger_obj.callback);
            });
        }
    }, [marker, onTrigger]);

    return null;
};

export default KakaoMarker;