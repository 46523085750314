import React, { useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { randomAnnulusPoint } from 'random-location';
import { booleanPointInPolygon, polygon, point } from '@turf/turf'
import { Button } from "reactstrap";
import ReactSound from "react-sound";
import { useGetState } from "../Hooks/Hooks.js";
import AdminTopBar from "../Component/TopBar.js";
import LogoutButton from "../Component/LogoutButton.js";
import SelectCar from "../Component/SelectCar.js";
import KakaoMap from "../Component/Map/KakaoMap.js";
import KakaoMarker from "../Component/Map/KakaoMarker.js";
import KakaoCircle from "../Component/Map/KakaoCircle.js";
import KakaoCustomOverlay from "../Component/Map/KakaoCustomOverlay.js";
import '../style/TestAdmin.css';
import SplitDropdown from "../Component/SplitDropdown.js";
import { Snackbar } from "@material-ui/core";
import LocationButton from "../Component/LocationButton.js";

const MAX_RADIUS = 1500; // meter
const MIN_RADIUS = 300; //meter

const MODE_KEY = 'test/admin/mode';
const RANDOM_MODE = 'random';
const SELECT_MODE= 'select';
const MAXIMUM_ITER = 100;

const DESTINATION_SOUND_FILE = "../sound/doremisol.mp3";

const TestAdminPage = (props) => {
    const {
        history,
        location,
    } = props;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const {
        car_name,
        service_name,
    } = query;

    const [carName, setCarName] = useState(car_name);
    const [carInfo, serviceOn, , getCallState] = useGetState(carName, 2000);
    const [centerPosition, setCenterPosition] = useState(undefined);
    const [adpPoint, setAdpPoint] = useState(undefined);
    const [mode, setMode] = useState(SELECT_MODE);

    const [soundStatus, setSoundStatus] = useState("STOPPED");

    const [ADPBlacklistArea, setADPBlacklistArea] = useState([]);
    const [ADPWhitelistArea, setADPWhitelistArea] = useState([]);
    const [serviceNames, setServiceNames] = useState([
        "new_tamra",
        "LGL_truck",
        "cheomdan_test",
        "sangam_test",
        "coastal_road"
    ]);
    const [selectedServiceName, setSelectedServiceName] = useState(
        serviceNames.includes(service_name) ? service_name : ""
    );
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const mapDragEndAbortController = useRef(undefined);
    const randomGoalAbortController = useRef(undefined);

    const selectCarCallback = useCallback((car_name) => {
        setCarName(car_name);
        history.replace({
            search: `?car_name=${car_name}`
        });
    },[history]);

    const getADP = useCallback(async (location_point, abortController) => {
        if (abortController.current) {
            abortController.current.abort('previous request canceled');
        }
        abortController.current = new AbortController();
        return await axios
            .post(
                "/adp/seogwipo/floating/get-point",
                {
                    ...location_point,
                    type: "AP",
                },
                { signal: abortController.current.signal }
            )
            .then(function (res) {
                abortController.current = undefined;
                if (res.statusText === "OK") {
                    return res.data;
                } else {
                    return undefined;
                }
            })
            .catch(function (e) {
                console.error("error while POST to adp");
                console.log(e);
            })
            .then((data) => {
                if (data) {
                    return {
                        latitude: data.start_latitude,
                        longitude: data.start_longitude,
                    };
                }
                return;
            });
    }, []);

    const setGoalAPI = useCallback(async (car_name, adp) => {
        const { latitude, longitude } = adp;
        await axios.post('/api/admin/setGoal',
            { car_name: car_name, goal: { latitude, longitude } },
        )
        .then(function (res) {
            if (res.statusText === "OK") {
                navigator.vibrate(500);
                setSoundStatus("PLAYING");
                return res.data;
            } else {
                return undefined;
            }
        })
        .catch(function (err) {
            console.error("error while POST to adp");
            if (err.response?.status === 403) {
                alert(`${err.response.data}에서 서비스 중입니다. 종료 후 이용해주세요.`);
            }
            else {
                alert("목적지 전송에 실패했습니다.");
            }
        })
    }, []);

    const turnServiceOn = useCallback(async (_serviceName) => {
        if (isLoading) return;
        setIsLoading(true);
        await axios
            .put("/api/admin/turn-service-on", {
                car_name: carName,
                service_name: _serviceName,
            })
            .catch(function (err) {
                console.error("fail to turn service on");
                alert("서비스 시작에 실패했습니다.");
            });
        setIsLoading(false);
        getCallState();
    }, [carName, isLoading, getCallState]);

    const turnServiceOff = useCallback(async (_serviceName) => {
        if (isLoading) return;
        setIsLoading(true);
        await axios
            .put("/api/admin/turn-service-off", {
                car_name: carName,
                service_name: _serviceName,
            })
            .catch(function (err) {
                console.error("fail to turn service off");
                alert("서비스 종료에 실패했습니다.");
            });
        getCallState();
        setIsLoading(false);
    }, [carName, isLoading, getCallState]);

    const selectServiceNameCallback = useCallback(
        async (serviceName) => {
            if (serviceOn && carInfo.current_service !== serviceName.toUpperCase()) {
                await turnServiceOff(selectedServiceName);
                setSnackbarMessage(`${selectedServiceName}를 종료했습니다.`);
            }
            setSelectedServiceName(serviceName);
            const prevSearch = history.location.search;
            if (prevSearch.includes("service_name=")) {
                const search = prevSearch.replaceAll(
                    /service_name=[^&]*/g,
                    `service_name=${serviceName}`
                );
                history.replace({ search });
                return;
            }
            history.replace({
                search: `${prevSearch}&service_name=${serviceName}`,
            });
        },
        [carInfo.current_service, history, selectedServiceName, serviceOn, turnServiceOff]
    );

    const endTrip = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);
        await axios
            .put("/api/admin/end-trip", {
                car_name: carName,
                service_name: selectedServiceName
            })
            .catch(function (err) {
                console.error("fail to end trip");
                alert("운행 종료에 실패했습니다.");
            }
        );
        getCallState();
        setIsLoading(false);
    }, [carName, getCallState, isLoading, selectedServiceName]);

    const sendGoal = async () => {
        if (!adpPoint) {
            alert('도착지가 설정되지 않았습니다.');
            return;
        }
        if (isLoading) return;
        setIsLoading(true);
        await setGoalAPI(carName, adpPoint);
        getCallState();
        setIsLoading(false);
    }

    const serviceOnOffButtonCallback = useCallback(() => {
        if (serviceOn) {
            turnServiceOff(selectedServiceName);
        } else {
            turnServiceOn(selectedServiceName);
        }
    }, [serviceOn, selectedServiceName, turnServiceOn, turnServiceOff])

    const isInside = (point, polygons) => {
        return polygons.every(_polygon => booleanPointInPolygon(point, _polygon))
    }
    const isOutside = (point, polygons) => {
        return polygons.every(_polygon => !booleanPointInPolygon(point, _polygon))
    }
    const setRandomGoal = async () => {
        let random_point = undefined;
        let must_be_inside = false;
        let must_be_outside = false
        let i = 0;
        do {
            random_point = randomAnnulusPoint({
                latitude: carInfo.pose.latitude,
                longitude: carInfo.pose.longitude,
            }, MIN_RADIUS, MAX_RADIUS);
            const random_point_turf = point([random_point.longitude, random_point.latitude]);
            must_be_inside = isInside(random_point_turf, ADPWhitelistArea);
            must_be_outside = isOutside(random_point_turf, ADPBlacklistArea);
            i++;
        } while(
            (!must_be_inside || !must_be_outside) && i < MAXIMUM_ITER
        );
        if (!must_be_inside || !must_be_outside) {
            alert(`${MAXIMUM_ITER}번 안에 random goal을 설정하는데 실패했습니다. 재시도 해주십시요.`);
            return;
        }

        const adp = await getADP(random_point, randomGoalAbortController);
        if (adp) {
            setAdpPoint(adp);
            setCenterPosition(adp);
            await setGoalAPI(carName, adp);
        }
    }

    const onCenterChange = useCallback((map, event) => {
        setCenterPosition(map.getCenter());
    }, []);

    const moveToCurrentCarLocation = useCallback(() => {
        if (carInfo?.pose?.latitude && carInfo?.pose?.longitude) {
            setCenterPosition(carInfo.pose);
        }
    }, [carInfo]);

    useEffect(() => {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#DFDFDF";
        const stored_mode = localStorage.getItem(MODE_KEY);
        if ([RANDOM_MODE, SELECT_MODE].includes(stored_mode)) {
            setMode(stored_mode);
        }
    }, []);

    useEffect(() => {
        async function reloadAreaInfo() {
            const white_area_list = await axios.get("/api/get-area-info", {
                params: {
                    area_name: `${carName}_white_area`,
                }
            })
            .then(res => res.data.map(
                area_data => {
                    const geo_collections = area_data.geometry;
                    const polygon_obj = geo_collections.map(
                        polygon_raw => polygon(polygon_raw.map(
                            line => line.map(
                                point => [point.y, point.x]
                            )
                        ))
                    );
                    return polygon_obj;
                })
                .flat()
            )
            .catch((err) => {
                console.error(err)
                return [];
            });
            setADPWhitelistArea(white_area_list)
            // TODO: BlackList추가
            // booleanPointInPolygon()
        }
        reloadAreaInfo();
    }, [carName]);

    useEffect(() => {
        getCallState().then(data => {
            if (data && data.pose?.[0] && data.pose?.[1]) {
                setCenterPosition(data.pose);
            }
        })
    }, [carName, getCallState]);

    const canNotSettingGoal =
        (serviceOn &&
            carInfo.current_service !== selectedServiceName.toUpperCase()) ||
        carInfo.car_timeout;

    return (
        <div style={{ height: "100%", position: "relative" }}>
            <AdminTopBar>
                <LogoutButton history={history} />
                <Button
                    sm={10}
                    style={{
                        float: "left",
                    }}
                    onClick={() => {
                        const new_mode =
                            mode === RANDOM_MODE ? SELECT_MODE : RANDOM_MODE;
                        setMode(new_mode);
                        localStorage.setItem(MODE_KEY, new_mode);
                    }}
                >
                    {" "}
                    {mode === RANDOM_MODE ? "특정으로" : "랜덤으로"}
                </Button>
                <SelectCar
                    selectedCar={carName}
                    selectCallback={selectCarCallback}
                />
            </AdminTopBar>
            <div
                style={{
                    margin: "auto",
                    marginTop: "1rem",
                    textAlign: "center",
                    color: "black",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "90%",
                }}
            >
                <ReactSound
                    url={DESTINATION_SOUND_FILE}
                    playStatus={soundStatus}
                    onFinishedPlaying={() => {
                        setSoundStatus("STOPPED");
                    }}
                />
                <h1 className="test-admin title">
                    <b>테스트 페이지</b>
                </h1>
                {canNotSettingGoal ? (
                    <div style={{ fontSize: "1.7rem", color: "red" }}>
                        {carInfo.car_timeout ? (
                            "차량 위치를 확인할 수 없습니다."
                        ) : (
                            <span>
                                {carInfo.current_service}에서 서비스 중입니다.
                                <br />
                                <span style={{ fontSize: '1rem', color: '#000' }}>
                                    {serviceNames.find(
                                        service_name => service_name.toUpperCase() === (carInfo.current_service??"").toUpperCase()
                                    ) ? 
                                        `${carInfo.current_service}를 이어서
                                        이용하시려면 하단에서 서비스를 다시 선택해
                                        주세요.` : ""
                                    }
                                </span>
                            </span>
                        )}
                    </div>
                ) : carInfo.trip_on ? (
                    <Button
                        className="push-button"
                        color={isLoading ? "secondary" : "warning"}
                        onClick={endTrip}
                    >
                        {isLoading ? "종료 중.." : "운행 종료"}
                    </Button>
                ) : (
                    <>
                        <Button
                            className={`push-button ${
                                mode === RANDOM_MODE
                                    ? ""
                                    : "test-admin-invisible"
                            }`}
                            color="primary"
                            onClick={() => {
                                setRandomGoal();
                            }}
                        >
                            랜덤 목적지 설정
                        </Button>
                        <Button
                            className={`push-button ${
                                mode === SELECT_MODE
                                    ? ""
                                    : "test-admin-invisible"
                            }`}
                            color={isLoading ? "secondary" : "success"}
                            onClick={sendGoal}
                        >
                            {isLoading ? "전송 중.." : "목적지 전송"}
                        </Button>
                    </>
                )}
                <br />
                <div style={{position: "relative", width: "100%"}}>
                    <KakaoMap
                        className="test-admin map-containter"
                        center={centerPosition}
                        level={6}
                        onCenterChange={onCenterChange}
                        onDragEnd={async (map) => {
                            const kakao_latlng = map.getCenter();
                            const adp = await getADP(
                                {
                                    latitude: kakao_latlng.getLat(),
                                    longitude: kakao_latlng.getLng(),
                                },
                                mapDragEndAbortController
                            );
                            if (adp) {
                                setAdpPoint(adp);
                            }
                        }}
                    >
                        <KakaoMarker position={centerPosition} />
                        <KakaoCustomOverlay
                            position={carInfo?.pose}
                            contentID={"car_icon"}
                        >
                            <img
                                src="/car_icon=mini.png"
                                alt="car_icon"
                                onClick={(e) => e.preventDefault()}
                                style={{
                                    transform: `rotate(${
                                        (isNaN(carInfo.heading) || carInfo.heading === null) ? 90 : 180 - carInfo.heading
                                    }deg)`,
                                }}
                            />
                        </KakaoCustomOverlay>
                        <KakaoCircle
                            position={carInfo?.pose}
                            radius={MAX_RADIUS}
                            fillOpacity={0}
                            strokeWeight={3}
                            strokeColor={"#000000"}
                            strokeOpacity={1}
                            strokeStyle={"dash"}
                        />
                        <KakaoMarker
                            position={adpPoint}
                            image={{
                                src: "/marker=arrival.svg",
                                width: 48,
                                height: 48,
                                options: {
                                    offset: [24, 40],
                                },
                            }}
                        />
                    </KakaoMap>
                    <LocationButton 
                        wrapperClassName=""
                        className="tamra-location-button"
                        style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                            margin: "0",
                        }}
                        onClick={moveToCurrentCarLocation}
                    />
                </div>
                {(!carInfo.current_service ||
                    serviceNames.find(service_name => service_name.toUpperCase() === (carInfo.current_service??"").toUpperCase()
                )) && (
                    <SplitDropdown
                    selectList={serviceNames}
                    selectedValue={selectedServiceName}
                    EMPTY_DESCRIPTION="서비스를 선택해 주세요."
                    selectCallback={selectServiceNameCallback}
                    buttonCallback={serviceOnOffButtonCallback}
                    buttonName={
                        isLoading
                        ? "처리 중..."
                        : selectedServiceName
                        ? `${selectedServiceName} ${
                            serviceOn ? "끄기" : "켜기"
                        }`
                        : ""
                    }
                    direction="up"
                    buttonColorName={serviceOn ? "danger" : "primary"}
                    isLoading={isLoading}
                    />
                )}
                <div className="reactive-font" style={{ marginTop: "5px" }}>
                    updated at{" "}
                    <b>{new Date(carInfo?.updated_time).toLocaleString()}</b>
                </div>
            </div>
            <Snackbar
                open={!!snackbarMessage}
                autoHideDuration={5000}
                onClose={()=> setSnackbarMessage('')}
                message={snackbarMessage}
                action={<Button onClick={() => setSnackbarMessage('')}>닫기</Button>}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            />
        </div>
    );
}
export default withRouter(TestAdminPage);
