import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, ButtonGroup } from "reactstrap";
import { drawPassengerList , isBoardedUnderAge, inside } from "../../Component/PageCommon.js";
import { sejongValidServiceArea } from "../../../constants/ValidServiceArea.js";

class SejongAdminPage extends Component {
    state = { service_on: false, trip_on: false, passenger_info: [], car_name: "ioniq_v1_1", cars:[], dropdownOpen: false, current_service: "" };
    service_name = "SEJONG_SHUTTLE";

    async getCallState() {
        await axios
            .get(`/api/${this.state.car_name}/sejong/shuttle/getCallState`, {
                params: {
                    isClient: false
                }
            })
            .then(function (res) {
                if (res.statusText === "OK") {
                    return res.data;
                } else {
                    return {
                        current_service: res.current_service,
                        car_location: undefined,
                        service_on: false,
                        trip_on: false,
                        trip_cnt: 0,
                        total_trip_cnt: 0,
                    };
                }
            })
            .catch(function (error) {
                console.error("error while request GET to server");
                console.log(error);
                return {
                    car_location: undefined,
                    service_on: false,
                    trip_on: false,
                    trip_cnt: 0,
                    total_trip_cnt: 0,
                };
            })
            .then((data) =>
                this.setState({
                    car_timeout: data.car_timeout,
                    passenger_info: data.passenger,
                    car_location: data.car_location,
                    service_on: data.service_on,
                    trip_on: data.trip_on,
                    trip_cnt: data.trip_cnt,
                    total_trip_cnt: data.total_trip_cnt,
                    current_service: data.current_service,
                })
            );
    }

    async getTripCnt(){
        if(this.state.service_on){
            await axios
            .get(`/api/${this.state.car_name}/sejong/shuttle/get-trip-cnt`, {
                params: {
                    isClient: false
                }
            })
            .then(function (res) {
                if (res.statusText === "OK") {
                    this.setState({
                        trip_cnt: res.data.trip_cnt,
                        total_trip_cnt: res.data.total_trip_cnt,
                    })
                }
            })
            .catch(function (error) {
                console.error("error while request GET to server");
                console.log(error);
            });
        }
    }

    async disableService() {
        await axios
            .put(`/api/${this.state.car_name}/sejong/shuttle/disableService`)
            .then(function (res) {
                if (res.statusText === "OK") {
                } else {
                    alert("서비스 끄기에 실패했습니다.");
                }
            })
            .catch(function (error) {
                alert("서비스 끄기에 실패했습니다.");
            }).then((data) => {
                this.setState({
                    passenger_info :[],
                    service_on: false
                })
            }
            );
    }

    async ableService() {
        await axios
            .put(`/api/${this.state.car_name}/sejong/shuttle/ableService`)
            .then(function (res) {
                if (res.statusText === "OK") {
                    return true;
                } else {
                    alert("서비스 켜기에 실패했습니다.");
                }
            }).catch(function (error) {
                alert("서비스 켜기에 실패했습니다.");
            }).then((data) => {
                this.setState({
                    service_on: data
                })
            }
            );
    }

    async disableTrip() {
        await axios
            .put(`/api/${this.state.car_name}/sejong/shuttle/disableTrip`)
            .then(function (res) {
                if (res.statusText === "OK") {
                } else {
                    alert("순환 운행 종료 실패했습니다.");
                }
            })
            .catch(function (error) {
                console.log(error);
                alert("순환 운행 종료 실패했습니다.");
            }).then((data) => {
                this.setState({
                    trip_on: false
                })
            }
        );
    }

    async ableTrip() {
        await axios
            .put(`/api/${this.state.car_name}/sejong/shuttle/ableTrip`)
            .then(function (res) {
                if (res.statusText === "OK") {
                    return true;
                } else {
                    alert("순환 운행 실행 실패했습니다.");
                }
            }).catch(function (error) {
                alert("순환 운행 실행 실패했습니다.");
            }).then((data) => {
                this.setState({
                    trip_on: data
                })
            }
            );
    }

    async componentDidMount() {
        if(this.props.match.params.car_name  === undefined){
            this.props.history.replace(this.props.match.path+"/"+this.state.car_name);
            window.location.reload();
        }
        this.state.car_name = this.props.match.params.car_name;
        await axios.get("/api/get_cars").then((res) =>{
            this.setState({
                cars: res.data.cars,
            })
            if(res.data.cars.length === 0){
                alert("서버에서 차량 정보를 불러올 수 없습니다.")
            }
        }).then(() => {
            if(!(this.state.cars.includes(this.state.car_name)) && (this.props.match.params.car_name !== "ioniq_v1_1")){
                this.props.history.replace("/sejong/shuttle/admin/ioniq_v1_1");
                window.location.reload();
            }
        });
        this.intervalId = await setInterval(() => this.getCallState(), 2000);
        this.intervalTrip = await setInterval(()=> this.getTripCnt(), 60000);
        this.getCallState();
    }
    async componentWillUnmount(){
        if(this.intervalId !== undefined){
            clearInterval(this.intervalId);}
        if(this.intervalTrip !== undefined){
            clearInterval(this.intervalTrip);}
    }

    async redirect(path) {
        clearInterval(this.intervalId);
        clearInterval(this.intervalTrip);
        this.props.history.push(path);
    }

    async logout() {
        sessionStorage.removeItem("token");
        this.props.history.go(0);
    }

    selectCar(index) {
        this.setState({
            car_name: this.state.cars[index],
        },()=>{
            this.props.history.replace(this.state.car_name);
            this.getCallState();
        });
    }

    getDropdownItem(idx, name) {
        return (<DropdownItem name={name} value={name} onClick={this.selectCar.bind(this, idx)} >{name}</DropdownItem>)
    }

    getDropdown() {
        if (this.state.cars !== undefined) {
            const { dropdownOpen } = this.state;
            let items = []
            for (const car_idx in this.state.cars) {
                let car_name = this.state.cars[car_idx];
                items.push(this.getDropdownItem(car_idx, car_name));
            }
            return (
                <div style={{textAlign:"center"}}>
                    <ButtonDropdown direction="down" isOpen={dropdownOpen} toggle={() => this.setState({dropdownOpen: !this.state.dropdownOpen})}>
                        <DropdownToggle caret>
                            {this.state.car_name}
                        </DropdownToggle>
                        <DropdownMenu name="cars" >
                            {items}
                        </DropdownMenu>
                    </ButtonDropdown>
                </div>
            )
        } else {
            return (<div />)
        }
    }

    getLogOutBtn() {
        return (<div
            style={{
                margin: "auto",
                width: "90%",
                padding: "10px 0",
                textAlign: "right",
                color: "black",
            }}
        >
            <Button sm={10} onClick={() => this.logout()}>Log Out</Button>
        </div>);
    }

    render() {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#F9DEDE";
        const { service_on, car_timeout, car_location, trip_on, trip_cnt, total_trip_cnt, passenger_info, car_name } = this.state;
        let logoutBtn = this.getLogOutBtn();
        let contents = (<div />);
        let passengers = drawPassengerList(passenger_info);
        let warning_under_age = isBoardedUnderAge(passenger_info);
        let select_car_btn = this.getDropdown();

        if (service_on) {
            if (inside(car_location, sejongValidServiceArea)) {
                if (trip_on) {
                    contents = (<div>
                        <span style= {{fontSize: "4vw", width: "40%", textAlign: "center"}}>
                            {car_name} 운행 횟수: {trip_cnt} <br />
                            총 운행 횟수: {total_trip_cnt} </span>
                        <h2 style={{ color: "blue" }}>순환 노선을 주행 중입니다. 
                            <Button style={{ width: "70vw", marginTop: "5vh" }} size="md" color="warning" onClick={this.disableTrip.bind(this)}
                                >순환 운행 종료</Button>
                            <Button style={{ width: "70vw", marginTop: "1vh" }} size="md" color="danger" onClick={this.disableService.bind(this)}>
                                서비스 종료</Button>
                        </h2></div>);
                } else {
                    contents = (<div>
                        <span style= {{fontSize: "4vw", width: "40%", textAlign: "center"}}>
                            {car_name} 운행 횟수: {trip_cnt} <br />
                            총 운행 횟수: {total_trip_cnt} </span>
                        <h2>
                            <Button style={{ width: "70vw", marginTop: "5vh" }} size="md" color="info" onClick={this.ableTrip.bind(this)}
                                >순환 운행 시작</Button>
                            <Button style={{ width: "70vw", marginTop: "1vh" }} size="md" color="danger" onClick={this.disableService.bind(this)}
                                >서비스 종료</Button>
                        </h2></div>);
                }
            } else {
                contents = (<div><h2 color="red">차량이 운영구역을 벗어났습니다. 서비스를 중단 해주세요! <Button style={{ width: "70vw", marginTop: "5vh" }} size="md" color="danger" onClick={this.disableService.bind(this)}>서비스 종료</Button></h2></div>);
            }
        } else if (car_timeout || car_location === undefined) {
            contents = (<div><h2 style={{ color: "red" }}>차량의 위치를 확인할 수 없어 서비스를 시작할 수 없습니다.</h2></div>);
        } else if (!car_timeout && !inside(car_location, sejongValidServiceArea)) {
            contents = (<div><h2 style={{ color: "red" }}>차량이 운영구역 밖에 있어 서비스를 시작할 수 없습니다.</h2></div>);
        } else if (this.state.current_service !== "" && this.service_name !== this.state.current_service) {
            contents = (<div><h2 style={{ color: "red" }}>다른 사용자 페이지에서 서비스 중이거나 서비스명이 일치하지 않습니다.</h2></div>);
        } else {
            contents = (<div><h2 style={{ color: "red" }}>고객이 탑승을 할 수 없는 상태입니다. <Button style={{ width: "70vw", marginTop: "5vh" }} size="md" color="info" onClick={this.ableService.bind(this)}>서비스 시작</Button></h2></div>);
        }
        return (
            <div style={{ height: "100%" }}>
                {logoutBtn}
                <div
                    style={{
                        margin: "auto",
                        width: "70%",
                        paddingTop: "5vh",
                        textAlign: "center",
                        color: "black",
                    }}
                >
                    <h1><b>- 세종 셔틀 운영자 페이지 - </b></h1>
                    <br />
                    {select_car_btn}
                    <br />
                    {contents}
                    {service_on ? warning_under_age: ""}
                    {service_on ? passengers : ""}
                </div>
            </div >
        );
    }
}
export default withRouter(SejongAdminPage);
