import React, { Children, useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { KakaoPolyline, KakaoMap, KakaoCustomOverlay, KakaoMarker, KakaoCircle, KakaoPolygon } from "../../Component/Map/Kakao";
import LocationButton from "../../Component/LocationButton";
import { usePosition } from "../../Hooks/Hooks";
import { Button, Modal, Toast } from "reactstrap";
import { AiOutlineLeft } from "react-icons/ai";
import SelectStop from "../../Component/SelectStop";
import { getDistanceFromLatLonInKm } from "../../Util/Common";
import { TamraPassengerForm } from "./TamraPassengerForm";
import { validServiceArea } from "../Seogwipo/SeogwipoCommon";
import { inside } from "../../Component/PageCommon";


const INIT_LOCATION = {location: [], description: '', adp: [], stop_id: undefined};
const INIT_CENTER = [33.2494970378694, 126.41789857800048];
const INIT_BOUNDS = {
    bound : [{lng: 126.39112494719097, lat: 33.23258064584429}, {lng: 126.43686185965106, lat: 33.26085463241552}],
    padding: [0, 0]
};

function TamraJungmun ({
    history,
    location,
    ...props
}) {

    const position = usePosition(true);

    const [mode, setMode] = useState(0);
    const [isStart, setIsStart] = useState('');
    // 0: select stop, 1-'start': select departure location, 1-'end': select arrival location
    // 2: write form


    const [validPath, setValidPath] = useState([]);
    const [validArea, setValidArea] = useState([]);
    const [reservationArea, setReservationArea] = useState([]);
    const [center, setCenter] = useState(INIT_CENTER);
    const [bounds, setBounds] = useState(INIT_BOUNDS);

    const [validCircleCenter, setValidCircleCenter] = useState([]); // = stops[selectedStop].location

    
    const [adp, setAdp] = useState([]);
    const [adpPath, setAdpPath] = useState([]);
    const [adpAddress, setAdpAddress] = useState(undefined);
    const [recommendedAdp, setRecommendedAdp] = useState(undefined); // {path, goal}

    const [stops, setStops] = useState([]);
    const [selectedStop, setSelectedStop] = useState(-1);
    const [selectedStopAdp, setSelectedStopAdp] = useState({});
    const [isNearby, setIsNearby] = useState(false);
    

    const [openInfoToast, setOpenInfoToast] = useState(false);
    const [openChangeRecommendedAdpToast, setOpenChangeRecommendedAdpToast] = useState(false);
    const [openInvalidLocationToast, setOpenInvalidLocationToast] = useState(false);
    const [openRecommendModal, setOpenRecommendModal] = useState(false);

    const [startLocation, setStartLocation] = useState({...INIT_LOCATION});
    const [endLocation, setEndLocation] = useState({...INIT_LOCATION});

    const getADP = useCallback(async (location) => {
        let body = {
            type: "ADP"
        };
        if (Array.isArray(location)) {
            body = {
                latitude: location[0], longitude: location[1]
            };
        }
        else if (typeof location === 'object' && !!location) {
            if (location.hasOwnProperty('x')) {
                body = {
                    latitude: location.y, longitude: location.x
                };
            }
            else if (location.hasOwnProperty('lat')){
                body = {
                    latitude: location.lat, longitude: location.lng !== undefined ? location.lng : location.lon
                };
            }
            else if (location.hasOwnProperty('latitude')){
                body = location;
            }
        }
        const ad_point = await axios.post("/adp/tamra/floating/get-point", body)
        .then((res)=>{
            if (res.statusText === "OK" && !!res.data) {
                const {
                    start_latitude,
                    start_longitude,
                    start_theta
                } = res.data;
                return [start_latitude, start_longitude, start_theta];
            }
        }).catch((err)=>{
            console.error("error while request GET to server");
            console.log(err);
            return undefined;
        });
        return ad_point;
    }, []);
    const getADPath = useCallback(async (departure, destination) => {
        if (!inside(departure, reservationArea) || !inside(destination, reservationArea)) {
            console.error("departure or destination is not in valid reservation area.");
            return {};
        }
        let ad_path = await axios.post("/adp/tamra/jungmun/get-path", {
            departure,
            destination,
        })
        .then((res)=>{
            if (res.statusText === "OK" && !!res.data) {
                const { data } = res;
                const { goal, recommended } = data;
                return {
                    goal: !!data.goal,
                    goal_location: [goal?.location.latitude, goal?.location.longitude, goal?.theta],
                    goal_path: goal?.path.map(point => [point.latitude, point.longitude]),
                    goal_path_length: goal?.length,
                    recommendation: !!data.recommendation,
                    recommendated_goal: [recommended?.location.latitude, recommended?.location.longitude, recommended?.theta],
                    recommendated_path: recommended?.path.map(point => [point.latitude, point.longitude]),
                    recommendated_length: recommended?.length,
                }
            }
        })
        .catch((err)=>{
            console.error("error while request GET to server", err);
        });
        return ad_path;
    }, [reservationArea]);
    const getAddress = useCallback((location) => {
        if (!window.kakao) {
            return;
        }
        let geocoder = new window.kakao.maps.services.Geocoder();
        return new Promise ((res, rej) => {
            geocoder.coord2Address(location[1], location[0], (result, status) => {
                if (status === window.kakao.maps.services.Status.OK) {
                    res(result[0]);
                }
                else {
                    console.error(status);
                    rej(undefined);
                }
            })
        });

    }, []);

    const onClickLocationButton = useCallback((e) => {
        if (position.latitude && position.longitude) {
            setCenter(position);
        }
    }, [position]);

    const goBack = useCallback((e) => {
        switch (mode) {
            case 0: {
                history.goBack();
            }
            case 1: {
                // setEndStop(-1);
                // setMode(0);
                setIsStart('');
                setAdp([]);
                setAdpPath([]);
                setAdpAddress(undefined);
                break;
            }
            case 2: {
                setStartLocation({...INIT_LOCATION});
                setEndLocation({...INIT_LOCATION});
                // setMode(1);
                break;
            }
            default: {}
        }
        setMode(m => {
            if (m === 0) {
                return 0;
            }
            return m - 1;
        });
    }, [mode, history]);

    const selectADMode = useCallback((to) => {
        setMode(1);
        setIsStart(to);
        const selected_location = [stops[selectedStop].location.latitude, stops[selectedStop].location.longitude];
        setCenter(selected_location);
        setValidCircleCenter(selected_location);
    }, [stops, selectedStop]);

    const selectPath = useCallback(() => {
        const selected_stop = stops[selectedStop];
        if (isStart === 'start') {
            setStartLocation({
                location: [...center],
                description: adpAddress,
                adp: [...adp],
            });
            setEndLocation({
                location: [selected_stop.location.latitude, selected_stop.location.longitude],
                description: selected_stop.description,
                adp: [...selectedStopAdp],
                // stop_id: selected_stop.id,
            });
        }
        else {
            setStartLocation({
                location: [selected_stop.location.latitude, selected_stop.location.longitude],
                description: selected_stop.description,
                adp: selectedStopAdp,
                // stop_id: selected_stop.id,
            });
            setEndLocation({
                location: [...center],
                description: adpAddress,
                adp: [...adp],
            });
        }
    }, [isStart, center, adp, adpAddress, stops, selectedStop, selectedStopAdp]);


    const onDragEnd = useCallback(async (map) => {
        if ((mode === 1) && selectedStop >= 0 && stops[selectedStop]) {
            const center_ll =[map.getCenter().getLat(), map.getCenter().getLng()];
            const current_stop = stops[selectedStop];
            const current_stop_location = [current_stop.location.latitude, current_stop.location.longitude];

            if (isStart === 'start') {
                const start_location = center_ll;
                const end_location = current_stop_location;
                const [{value: ad_path = {}}, {value: start_adp = {}}] = await Promise.allSettled([
                    getADPath(
                        start_location,
                        end_location,
                    ),
                    getADP(start_location),
                ]);
                
                if (ad_path.goal) {
                    const adp_address = await getAddress(start_adp);
                    if (!!adp_address) {
                        setAdpAddress(adp_address.address.address_name);
                    }
                    setAdpPath([start_adp, ...ad_path.goal_path, ad_path.goal_location]);
                    setAdp(start_adp);
                }
            }
            else {
                const start_location =  current_stop_location;
                const end_location = center_ll;
                const [{value: ad_path = {}}, {value: start_adp = {}}] = await Promise.allSettled([
                    getADPath(
                        start_location,
                        end_location,
                    ),
                    getADP(start_location),
                ]);
                if (ad_path?.goal) {
                    const end_adp = ad_path.goal_location;
                    const adp_address = await getAddress(end_adp);

                    if (!!adp_address) {
                        setAdpAddress(adp_address.address.address_name);
                    }
                    setAdpPath([start_adp, ...ad_path.goal_path, end_adp]);
                    setAdp(end_adp);
                }
                setRecommendedAdp(ad_path?.recommendation ? 
                    {
                        path: ad_path.recommendated_path,
                        goal: ad_path.recommendated_goal
                    } 
                    : undefined
                );
            }
        }
    }, [mode, getAddress, getADPath, getADP, stops, selectedStop, isStart]);
    
    const onCurrentCenterChange = useCallback((map) => {
        const center_ll = [map.getCenter().getLat(), map.getCenter().getLng()];
        setCenter(center_ll);
    }, [mode]);

    const onSubmit = useCallback(async (passengers, passenger_num) => {
        if (!startLocation || !endLocation) {
            return;
        }
        const available_car_name = await axios.post(`/api/tamra/jungmun/get-closest-car`,{
            serviceable: true,
            ref_location: [startLocation.adp[0], startLocation.adp[1]],
        })
        .then(res => res.data)
        .catch(err => console.error(err))

        if (!available_car_name) {
            alert("현재 서비스 가능한 잔여 차량이 없습니다. \n잠시후 다시 시도해 주십시요.");
            return;
        }
        await axios.post(`/api/tamra/jungmun/call_request`, {
            passenger_info: passengers,
            passenger_num: passenger_num,
            departure_location: [startLocation.adp[0], startLocation.adp[1]],
            departure_description: startLocation.description,
            destination_location: [endLocation.adp[0], endLocation.adp[1]],
            destination_description: endLocation.description,
            boarding_list: [startLocation.location, endLocation.location],
            car_name: available_car_name,
        })
        .then(({ data }) => {
            if (data.requested) {
                history.push({
                    pathname: '/tamra/jungmun/confirm',
                    search: `id=${data.service_id}`
                });
            }
            else {
                alert("현재 다른 탑승객이 이용 중입니다. 잠시 후에 다시 호출해주세요.");
            }
        })
        .catch(err => {
            if(err.response?.status === 400){
                alert("사전 탑승 등록된 정보가 아닙니다.")
            }
            else if (err.response?.status === 403) {
                alert("다른 서비스 운행중입니다.");
            }
            else if (err.response?.status === 404) {
                alert("서비스중인 차량이 없습니다.");
            }
            else {
                alert("제출 실패하였습니다. 다시 제출해주세요.");
            }
        });
    }, [startLocation, endLocation, history, location]);

    const changeADPToRecommened = useCallback(async (bool) => {
        if (bool) {
            setAdpPath(recommendedAdp.path);
            setAdp(recommendedAdp.goal);
            setOpenChangeRecommendedAdpToast(true);
        }
        setRecommendedAdp(undefined);
        setOpenRecommendModal(false);
    }, [recommendedAdp])

    // const availableCarCallback = useCallback(async () => {
    //     await axios.get('/api/tamra/')
    // }, []);

    const myLocationIconInit = useMemo(() => {
        let img = document.createElement('img');
        img.src = '/mylocation=direction.svg';
        img.id = 'mylocation';

        img.addEventListener('mousedown',(e) => {
            e.preventDefault();
        });
        return img;
    }, []);
    const myLocationIcon = useMemo(() => {
        const my_location = document.getElementById('mylocation');
        let heading = !!position.heading ? position.heading : 90;
        if (my_location) {
            my_location.style.transform = 'rotate(' + (90-heading) + 'deg)';
            return my_location;
        }
        else {
            myLocationIconInit.style.transform = 'rotate(' + (90-heading) + 'deg)';
            return myLocationIconInit;
        }
    }, [position, myLocationIconInit]);

    const stopMarkers = useMemo(() => {
        return stops.map((stop, idx) => {
            return <KakaoMarker 
                position={stop.location}
                key={`jungmun-stop-${idx}`}
                clickable
                onClick={(marker) => {
                    if (selectedStop === idx) {
                        setSelectedStop(-1);
                    }
                    else {
                        setSelectedStop(idx);
                    }
                }}
                title={stop.name}
                image={{
                    src: selectedStop === -1 ? '/bus_station=default.svg' : 
                        selectedStop === idx ? '/bus_station=focused.svg' :
                        '/bus_station=unfocused.svg',
                    width: selectedStop === idx ? 48 : 40,
                    height: selectedStop === idx ? 56 : 40,
                }}
            />
        })
    }, [stops, selectedStop]);

    const toasts = useMemo(() => {
        return <div className="tamra-toast-container">
            <Toast 
                className="tamra-toast"
                id={"info-toast"}
                isOpen={openInfoToast}
                onClick={e => {e.preventDefault()}}
            >
                승차 아이콘 위치로 차량이 도착합니다.
            </Toast>
            <Toast 
                className="tamra-toast"
                id={"location-toast"}
                isOpen={openChangeRecommendedAdpToast && isNearby}
                onClick={e => {e.preventDefault()}}
            >
                {isStart === 'start' ? "승차" : "하차"} 위치가 변경되었습니다.
            </Toast>
            <Toast 
                className="tamra-toast"
                id={"invalid-toast"}
                isOpen={openInvalidLocationToast}
                onClick={e => {e.preventDefault()}}
            >
                이용할 수 없는 지역입니다.
            </Toast>
            {/* {isNearby ? 
            } */}
        </div>
    }, [openInfoToast, openChangeRecommendedAdpToast, openInvalidLocationToast, isNearby, isStart]);

    const recommend_modal = useMemo(() => {
        return (
            <Modal
                isOpen={openRecommendModal}
                toggle={() => setOpenRecommendModal(o => !o)}
                backdropClassName="backdropClassName"
                contentClassName="tamra-footer-modal"
                className="className"
                modalClassName="footer-modal"
                wrapClassName="wrapClassName"
                centered
            >
                <img
                    src="/fast_car_icon.png"
                />
                <div className="text">
                    <span>하차 위치를 맞은 편으로 변경하면</span><br/>
                    <span><b>더 빠르게</b> 이동할 수 있어요</span>
                </div>
                <div className="button-group">
                    <Button
                        className="cancel"
                        onClick={() => changeADPToRecommened(false)}
                    >
                    그대로
                    </Button>
                    <Button
                        className="select"
                        color="info"
                        onClick={() => changeADPToRecommened(true)}
                    >
                    변경하기
                    </Button>
                </div>
            </Modal>
        )
    }, [openRecommendModal, recommendedAdp]);

    const validAreaPolygon = useMemo(() => {
        return (
            <KakaoPolygon
                path={[
                    validServiceArea,
                    validArea,
                ]}
                strokeOpacity={0.0}
                fillColor="#000"
                fillOpacity={0.13}
                id="area"
            />
        )
    }, [validArea]);

    const selectingCicle = useMemo(() => {
        if (mode !== 1) {
            return null;
        }
        return (
            <KakaoCircle
                position={validCircleCenter}
                radius={2000}
                strokeColor={'#222'}
                strokeOpacity={0.8}
                strokeWeight={2}
                strokeStyle={'shortdash'}
                fillColor={'#F10000'}
                fillOpacity={0.0}
            />
        )
    }, [validCircleCenter, mode])
    const mapContent = () => {
        switch (mode) {
            case 0:
                return stopMarkers;
            case 1: {
                const is_start = isStart === 'start';
                return <>
                    <KakaoMarker 
                        position={is_start ? adp : selectedStopAdp}
                        image={{
                            src: '/get_on=black.svg',
                            width: 48,
                            height: 48,
                            options: {
                                offset: [24, 40],
                            }
                        }}
                    />
                    <KakaoMarker
                        position={is_start ? selectedStopAdp : adp}
                        image={{
                            src: '/get_off=black.svg',
                            width: 48,
                            height: 48,
                            options: {
                                offset: [24, 40],
                            }
                        }}
                    />
                    <KakaoMarker 
                        position={center}
                        image={{
                            src: `/marker=${is_start ? 'departure' : 'arrival'}.svg`,
                            width: 48,
                            height: 48,
                            options: {
                                offset: [24, 40],
                            }
                        }}
                    />
                    <KakaoPolyline
                        path={adpPath}
                        strokeColor="#1261FB"
                        strokeWeight={5}
                    />
                </>;
            }
            case 2: {
                const is_start = isStart === 'start';
                return <>
                    <KakaoMarker 
                        position={is_start ? adp : selectedStopAdp}
                        image={{
                            src: `/get_on=${is_start ? 'green' : 'blue'}.svg`,
                            width: 48,
                            height: 48,
                            options: {
                                offset: [24, 40],
                            }
                        }}
                    />
                    <KakaoMarker
                        position={is_start ? selectedStopAdp : adp}
                        image={{
                            src: `/get_off=${is_start ? 'blue' : 'green'}.svg`,
                            width: 48,
                            height: 48,
                            options: {
                                offset: [24, 40],
                            }
                        }}
                    />
                    <KakaoPolyline
                        path={adpPath}
                        strokeColor="#1261FB"
                        strokeWeight={5}
                    />
                </>;
            }
            default:
                return null;
        }
    }

    useEffect(() => {
        async function wrapper() {
            const [{value: jungmun_area_info}, {value: jungmun_stops}] = await Promise.allSettled([
                axios.get("/api/tamra/jungmun/get-area-info")
                .then(res => res.data)
                .catch(err => {
                    console.error(err);
                    return {};
                }),
                axios.get("/api/tamra/jungmun/get-stops")
                .then(res => res.data)
                .catch(err => {
                    console.error(err);
                    return [{
                        address: "제주특별자치도 제주시 용담삼동 2400-4",
                        description: "어영공원",
                        id: 9,
                        location: {latitude: 33.25233827164625, longitude: 126.4220057985892},
                        name: "stop4",
                        order: 4,
                        roadview_location: {latitude: 33.25233827164625, longitude: 126.4220057985892},
                        service_name: "TAMRA_SHUTTLE",
                    }];
                })
            ]);
            const {
                path, reservation_area, valid_area
            } = jungmun_area_info;
            if (!!path) {
                setValidPath(path);
            }
            if (!!reservation_area) {
                setReservationArea(reservation_area[0][0].map(point => [point.y, point.x]));
            }
            if (!!valid_area) {
                setValidArea(valid_area[0][0]);
            }
            setStops(jungmun_stops);
        };
        wrapper();
    }, []);

    useEffect(() => {
        if (validCircleCenter.length < 2) {
            return;
        }
        setIsNearby(!(getDistanceFromLatLonInKm(center[0], center[1], validCircleCenter[0], validCircleCenter[1]) > 2.000));
    }, [center, validCircleCenter]); // => change center => adp

    useEffect(() => {
        if (selectedStop < 0) {
            setSelectedStopAdp([]);
            return;
        }
        getADP(stops[selectedStop].location)
        .then(ad_point => {
            setSelectedStopAdp(ad_point);
        });
    }, [stops, selectedStop]);

    useEffect(() => {
        if ((mode === 1)) {
            if (!isNearby) {
                setOpenInvalidLocationToast(true);
                return;
            }
        }
        setOpenInvalidLocationToast(false);
    }, [center, isNearby, mode]);

    useEffect(() => {
        if ((mode === 1)) {
            setOpenInfoToast(true);
            const timeout_id = setTimeout(() => setOpenInfoToast(false), 2000);
            return () => {
                clearTimeout(timeout_id);
            }
        }
        setOpenInfoToast(false);
    }, [mode]);

    useEffect(() => {
        if (mode === 1 && openChangeRecommendedAdpToast) {
            const timeout_id = setTimeout(() => setOpenChangeRecommendedAdpToast(false), 2000);
            return () => {
                clearTimeout(timeout_id);
            }
        }
    }, [mode, openChangeRecommendedAdpToast]);

    return <>
    <div className="font-SUIT tamra-kakao-map-wrapper flex">
        {mode === 1 ? <JungmunSelectLocationTopBar /> : null}
        <KakaoMap
            className="tamra-kakao-map"
            center={center}
            bounds={bounds}
            onCenterChange={onCurrentCenterChange}
            onDragEnd={onDragEnd}
        >
            <KakaoPolyline
                path={validPath}
                strokeColor="#04A7A7"
                strokeWeight={5}
            />
            <KakaoCustomOverlay 
                position={position}
                contentID="mylocation"
            >
                <img 
                    src="/mylocation=direction.svg" 
                    style={{transform: `rotate(${position.heading ?? 90}deg`}} 
                />
            </KakaoCustomOverlay>
            {mapContent()}
            {validAreaPolygon}
            {selectingCicle}
        </KakaoMap>
        {mode === 0 ? 
            <JungmunSelectStop
                stops={stops}
                selectedStop={selectedStop}
                onClick={selectADMode}
                onClickLocationButton={onClickLocationButton}
                goBack={goBack}
            />
        : mode === 1 ? 
            <JungmunSelectLocation 
                startLocation={isStart === 'start' ? adpAddress : stops[selectedStop]?.description}
                endLocation={isStart === 'start' ? stops[selectedStop]?.description : adpAddress}
                green={isStart}
                onClickLocationButton={onClickLocationButton}
                goBack={goBack}
                // title={}
            >
                <Button 
                    className="btn"
                    onClick={() => {
                        if (recommendedAdp) {
                            setOpenRecommendModal(true);
                            return;
                        }
                        setMode(2);
                        selectPath();
                    }}
                    disabled={!isNearby || !!!adpAddress}
                >
                    확인
                </Button>
            </JungmunSelectLocation>
        : mode === 2 ? 
            <JungmunSelectLocation
                wrapperClassName="relative"
                startLocation={startLocation.description}//current_stop.description}
                endLocation={endLocation.description}//adpAddress}
                green={isStart}
                onClickLocationButton={onClickLocationButton}
                goBack={goBack}
            >
                <div className="line-divider"/>
                <TamraPassengerForm
                    startLocation={startLocation}
                    endLocation={endLocation}
                    reservation={false}
                    onSubmit={onSubmit}
                />
            </JungmunSelectLocation>
        : null}
    </div>
    {toasts}
    {recommend_modal}
    </>
};

export default withRouter(TamraJungmun);


const JungmunSelectStop = ({
    stops = [],
    selectedStop = -1,
    onClick=() => {},
    goBack=() => {},
    onClickLocationButton=()=>{},
    ...props
}) => {

    return (
        <div className="tamra-footer-wrapper bottom relative">
            <LocationButton 
                className="tamra-location-button"
                onClick={onClickLocationButton}
            />
            <div className="tamra-footer jungmun" >
                <div className="title">
                    <AiOutlineLeft
                        className="tamra-go-back-icon"
                        onClick={goBack}
                    />
                    <div>출발하거나 도착할 <b>정류소</b>를 선택해주세요.</div>
                </div>
                <SelectStop 
                    on={!(selectedStop < 0)}
                    clickable={false}
                >
                    {selectedStop < 0 ? "정류소" : stops[selectedStop]?.description}
                </SelectStop>
                <div className="icon-box">
                    <Button
                        disabled={selectedStop < 0}
                        onClick={() => onClick('end')}
                    >
                    <img src={selectedStop < 0 ? "/to_departure=white.svg" : "/to_departure=blue.svg"}/>
                        <div>출발지로</div>
                    </Button>
                    <Button
                        disabled={selectedStop < 0}
                        onClick={() => onClick('start')}
                    >
                        <img src={selectedStop < 0 ? "/to_arrival=white.svg" : "/to_arrival=blue.svg"}/>
                        <div>도착지로</div>
                    </Button>
                </div>
            </div>
        </div>);
}

const JungmunSelectLocation = ({
    wrapperClassName = 'bottom relative',
    className = '',
    startLocation = "출발지",
    endLocation = "도착지",
    green="start",
    goBack=() => {},
    onClickLocationButton=()=>{},
    title,
    children,
    style,
    ...props
}) => {

    return <>
        <div className={`tamra-footer-wrapper ${wrapperClassName}`} style={style}>
            <LocationButton 
                className="tamra-location-button"
                onClick={onClickLocationButton}
            />
            <div className="tamra-footer jungmun additional-padding" >
                <div className="title">
                    <AiOutlineLeft
                        className="tamra-go-back-icon"
                        onClick={goBack}
                    />
                    <div>{green === 'start' ? "출발" : "도착"}하는 <b className="green">지역</b>을 선택해주세요.</div>
                </div>
                <SelectStop 
                    on={!!startLocation}
                    color={green === 'start' ? "green" : undefined}
                    busIcon={green !== 'start'}
                    clickable={false}
                >
                    {startLocation}
                </SelectStop>
                <SelectStop 
                    on={!!endLocation}
                    busIcon={green === 'start'}
                    color={green !== 'start' ? "green" : undefined}
                    arrow
                    clickable={false}
                >
                    {endLocation}
                </SelectStop>
                {Children.toArray(children)}
            </div>
        </div>
    </>
}

const JungmunSelectLocationTopBar = ({
    ...props
}) => {
    return (
    <div className="tamra-jungmun-topbar"  style={{position: 'relative'}}>
        <span>반경 2km 내 경로만 지정할 수 있습니다.</span>
        <div>
            <div><span>선택가능 반경</span><div className="available-radius-icon"/></div>
            <div><span>경로</span> <div className="path-icon"/></div>
        </div>
    </div>);
}