import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectCar from "../../Component/SelectCar";
import AdminTopBar from "../../Component/TopBar";
import DarkModeButton from "../../Component/DarkModeButton";
import LogoutButton from "../../Component/LogoutButton";
import { withRouter } from "react-router-dom";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { DropdownButton, DropdownItems } from "../../Component/DropdownButton";
import axios from "axios";
import { useGetCallState } from "../../Hooks/Hooks";

const LOCATION = "tamra";
const SERVICE = "luggage";
const INITIAL_DEPARTURE = "출발지 선택";
const INITIAL_DESTINATION = "목적지 선택";
const START_ZONES = ['공항', '중문'];
const SERVICE_NAME = [LOCATION.toUpperCase(), SERVICE.toUpperCase()].join("_");

const TamraLuggageAdmin = (props) => {
    const { history, location } = props;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const { car_name: carNameInQuery } = query;
    const [carName, setCarName] = useState(carNameInQuery);
    const [carInfo, isServiceOn, isCarrying] = useGetCallState(
        carName,
        LOCATION,
        SERVICE,
        2000,
        { isClient: false }
    );
    const [stops, setStops] = useState([]);

    const [startZoneIdx, setStartZoneIdx] = useState(-1);
    const [departureToggleIsOpen, setDepartureToggleIsOpen] = useState({
        [carName]: false,
    });
    const [destinationToggleIsOpen, setDestinationToggleIsOpen] = useState({
        [carName]: false,
    });
    const [departure, setDeparture] = useState({
        [carName]: INITIAL_DEPARTURE,
    });
    const [destination, setDestination] = useState({
        [carName]: INITIAL_DESTINATION,
    });

    const setDepartureFromLatestState = useCallback(
        (targetCarName, newDeparture) => {
            setDeparture((prev) => ({
                ...prev,
                [targetCarName]: newDeparture,
            }));
        },
        []
    );

    const setDestinationFromLatestState = useCallback(
        (targetCarName, newDestination) => {
            setDestination((prev) => ({
                ...prev,
                [targetCarName]: newDestination,
            }));
        },
        []
    );

    const selectCarCallback = useCallback(
        (car_name) => {
            setCarName(car_name);
            history.replace({
                search: `?car_name=${car_name}`,
            });
        },
        [history]
    );

    const getPlaceToggleHandler = useCallback(
        (placeType) => {
            if (isCarrying) return () => {};
            if (placeType === "departure") {
                return () =>
                    setDepartureToggleIsOpen((prev) => ({
                        ...prev,
                        [carName]: !prev[carName],
                    }));
            }
            if (placeType === "destination") {
                return () =>
                    setDestinationToggleIsOpen((prev) => ({
                        ...prev,
                        [carName]: !prev[carName],
                    }));
            }
        },
        [carName, isCarrying]
    );

    const onServiceStatusChange = useCallback(async () => {
        if (!isServiceOn) {
            const airportName =
                stops.find((stop) => stop.name?.includes(START_ZONES[0]))
                    ?.name || INITIAL_DEPARTURE;
            setDepartureFromLatestState(
                carName,
                startZoneIdx === 0 ? airportName : INITIAL_DEPARTURE
            );
            setDestinationFromLatestState(
                carName,
                startZoneIdx === 1 ? airportName : INITIAL_DESTINATION
            );
            const destination_shuttle_stop_id =
                startZoneIdx === 0 ? 59 : startZoneIdx === 1 ? 10 : undefined;
            await axios.put(`/api/${LOCATION}/${SERVICE}/turn-service-on`, {
                departure: START_ZONES[startZoneIdx],
                destination: START_ZONES[+!startZoneIdx],
                destination_shuttle_stop_id,
                car_name: carName,
            });
        } else {
            await axios.put(`/api/${LOCATION}/${SERVICE}/turn-service-off`, {car_name: carName});
            setStartZoneIdx(-1);
        }
    }, [carName, isServiceOn, startZoneIdx, stops]);

    const onCarryStatusChange = useCallback(async () => {
        const current_stop = stops.find(
            (stop) => stop.name === departure[carName]
        );
        const next_stop = stops.find(
            (stop) => stop.name === destination[carName]
        );

        const requestBody = {
            current_stop,
            next_stop,
            car_name: carName,
        };

        if (isCarrying) {
            await axios.put(`/api/${LOCATION}/${SERVICE}/end_to_stop`, {
                car_name: carName,
            });
        } else {
            await axios.post(`/api/${LOCATION}/${SERVICE}/go_to_stop`,
                requestBody
            );
        }
    }, [carName, isCarrying, departure, destination, stops]);

    const placeDropdowns = useMemo(() => {
        if (startZoneIdx === -1 || !stops.length) return null;

        const jungmunStopNames = stops
            .filter((stop) => !stop.name?.includes(START_ZONES[0]))
            .map((stop) => stop.name);

        const airportStopName = [
            stops.find((stop) => stop.name?.includes(START_ZONES[0]))
                ?.name,
        ];

        const buttonItems = [airportStopName, jungmunStopNames];
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <DropdownButton
                    dropdownName={departure[carName] || INITIAL_DEPARTURE}
                    isOpen={departureToggleIsOpen[carName]}
                    toggleHandler={getPlaceToggleHandler("departure")}
                >
                    <DropdownItems
                        items={
                            startZoneIdx === 0
                                ? [airportStopName, ...jungmunStopNames]
                                : buttonItems[startZoneIdx]
                        }
                        clickHandler={(departure) =>
                            setDeparture((prev) => ({
                                ...prev,
                                [carName]: departure,
                            }))
                        }
                    />
                </DropdownButton>
                <span style={{ padding: "10px" }}>부터</span>
                <DropdownButton
                    dropdownName={destination[carName] || INITIAL_DESTINATION}
                    isOpen={destinationToggleIsOpen[carName]}
                    toggleHandler={getPlaceToggleHandler("destination")}
                >
                    <DropdownItems
                        items={buttonItems[+!startZoneIdx]}
                        clickHandler={(destination) =>
                            setDestinationFromLatestState(carName, destination)
                        }
                    />
                </DropdownButton>
                <span style={{ padding: "10px" }}>까지</span>
            </div>
        );
    }, [
        departure,
        destination,
        carName,
        departureToggleIsOpen,
        destinationToggleIsOpen,
        stops,
        startZoneIdx,
        getPlaceToggleHandler,
        setDestinationFromLatestState,
    ]);

    const departureRadioButton = useMemo(
        () => (
            <FormGroup
                onChange={(event) => {
                    const zoneIdx = START_ZONES.findIndex(
                        (zone) => zone === event.target.value
                    );
                    setStartZoneIdx(zoneIdx);
                }}
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <Label>
                    <Input type="radio" value={START_ZONES[0]} name="departureRadio" />
                    {START_ZONES[0]}에서 출발
                </Label>
                <Label>
                    <Input type="radio" value={START_ZONES[1]} name="departureRadio" />
                    {START_ZONES[1]}에서 출발
                </Label>
            </FormGroup>
        ),
        []
    );

    const serviceToggleButton = useMemo(() => {
        const buttonDisabled = startZoneIdx === -1;
        return (
            <div style={{ margin: "20px auto 0", maxWidth: "350px" }}>
                {!isServiceOn && departureRadioButton}
                <Button
                    onClick={() => onServiceStatusChange()}
                    color={
                        isServiceOn
                            ? "danger"
                            : buttonDisabled
                            ? "secondary"
                            : "primary"
                    }
                    style={{ width: "100%" }}
                    disabled={buttonDisabled && !isServiceOn}
                >
                    {isServiceOn ? (
                        "편도 운행 종료"
                    ) : (
                        <span>
                            <b>{START_ZONES[startZoneIdx]}</b>
                            {startZoneIdx !== -1 && `에서`} 편도 운행 시작
                        </span>
                    )}
                </Button>
            </div>
        );
    }, [
        onServiceStatusChange,
        isServiceOn,
        departureRadioButton,
        startZoneIdx
    ]);

    const carryToggleButton = useMemo(() => (
        <div style={{ margin: "20px" }}>
            <Button
                color={isCarrying ? "primary" : "info"}
                style={{ width: "350px" }}
                onClick={() => {
                    if (
                        departure[carName] === INITIAL_DEPARTURE ||
                        destination[carName] === INITIAL_DESTINATION
                    ) {
                        alert("출발지와 목적지를 모두 지정해 주세요");
                    } else if (departure[carName] === destination[carName]) {
                        alert(
                            "출발지와 목적지가 동일합니다. 다시 확인해 주세요."
                        );
                    } else {
                        onCarryStatusChange();
                        if (isCarrying) {
                            setDepartureFromLatestState(
                                carName,
                                destination[carName]
                            );
                            setDestinationFromLatestState(
                                carName,
                                INITIAL_DESTINATION
                            );
                        }
                    }
                }}
            >
                배송 {isCarrying ? "완료" : "시작"}
            </Button>
        </div>
    ), [isCarrying, departure, destination, carName]);

    const body = useMemo(() => {
        const { car_name, car_pose, service } = carInfo;
        const car_location = car_pose?.pose;
        let contents;
        if (isServiceOn) {
            contents = (
                <div style={{ marginTop: "30px" }}>
                    <div style={{fontSize: '1.5rem'}}>
                        <b>{START_ZONES[startZoneIdx]}지역에서 출발</b>하는 운행건을
                        수행중입니다.
                    </div>
                    {placeDropdowns}
                    {carryToggleButton}
                    {!isCarrying && serviceToggleButton}
                </div>
            );
        } else if (!car_location) {
            contents = (
                <div style={{ marginTop: "5vh" }}>
                    <h2 style={{ color: "red" }}>
                        차량의 위치를 확인할 수 없습니다.
                    </h2>
                </div>
            );
        }
        // else if (service !== "" && SERVICE_NAME !== service) {
        //     contents = (
        //         <div>
        //             <h3 style={{ color: "red" }}>
        //                 다른 관리자 페이지에서 서비스 중이거나
        //                 <br />
        //                 서비스명이 일치하지 않습니다.
        //             </h3>
        //             <h5>현재 이용중인 서비스: {service}</h5>
        //         </div>
        //     );
        // }
        else {
            contents = (
                <div style={{ marginTop: "5vh" }}>
                    <h2 style={{ color: "#DB005B" }}>
                        목적지 설정을 위해 출발 지역을 선택해 주세요.
                    </h2>
                    {serviceToggleButton}
                </div>
            );
        }
        return contents;
    }, [
        carInfo,
        isServiceOn,
        isCarrying,
        serviceToggleButton,
        carryToggleButton,
    ]);

    useEffect(() => {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#DFDFDF";
        async function init() {
            const stop_list = await axios.get(`/api/${LOCATION}/${SERVICE}/get-stops`)
            .then(res => res.data)
            .catch(err => {
                console.error(err);
                return [];
            });
            setStops(stop_list);
        }
        init();
    }, []);

    useEffect(() => {
        const {
            current_shuttle_stop_id,
            next_shuttle_stop_id,
            trip_on: is_carrying,
            car_name,
            destination_shuttle_stop_id,
        } = carInfo;
        const current_shuttle_stop = stops.find(stop => stop.id == current_shuttle_stop_id);
        const next_shuttle_stop = stops.find(stop => stop.id == next_shuttle_stop_id);

        if (isServiceOn && destination_shuttle_stop_id) {
            setStartZoneIdx(destination_shuttle_stop_id === 10 ? 1 : 0);
        }
        
        if (!is_carrying) {
            return;
        }
        setDepartureFromLatestState(car_name, current_shuttle_stop ? current_shuttle_stop.name : undefined);
        setDestinationFromLatestState(car_name, next_shuttle_stop ? next_shuttle_stop.name : undefined);
    }, [carInfo, stops, isServiceOn]);

    return (
        <div style={{ height: "100%" }}>
            <AdminTopBar>
                <LogoutButton history={history} />
                <DarkModeButton />
                <SelectCar
                    selectedCar={carName}
                    selectCallback={selectCarCallback}
                />
            </AdminTopBar>
            <div
                style={{
                    margin: "auto",
                    width: "70%",
                    paddingTop: "5vh",
                    textAlign: "center",
                    color: "black",
                }}
            >
                <h1>
                    <b>탐라 캐리어 배송 서비스 운영자 페이지</b>
                </h1>
                {body}
            </div>
        </div>
    );
};

export default withRouter(TamraLuggageAdmin);
