import React from 'react';
import { Jumbotron } from 'reactstrap';
import { AiOutlineDoubleRight } from "react-icons/ai";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Table } from "reactstrap";

import SeogwipoMapContainer from "../Service/Seogwipo/SeogwipoMapContent";
import { isUnderAge } from "./Common.js";


export function getTop(top_message, topleft_img_src="", div_position = "absolute") {
    return (<div style={{
        padding: "0",
        width: "100vw",
        height: "max(11vh, 20vw)",
        backgroundColor: "#CCDEEC",
        // paddingTop: "0.5vh",
        zIndex: "2",
        position: div_position
    }}>
        <div style={{ width: "20vw", float: "left", margin: "0", padding: "0", paddingTop: "1.5vh", paddingLeft: "1vw" }}>
            <img src={topleft_img_src}
                style={{
                    paddingLeft: "0.5vh",
                    width: "27vw"
                }} alt="/RF_LOGO.png" />
        </div>
        {/* <img src='/RF_LOGO.png'
            style={{
                height: "3vh",
                marginTop: "0.8vh",
                float: "right",
                display: "inline-block", paddingRight: "3vw"
            }} alt="Smiley face" /> */}
        <div style={{
            width: "100vw",
            fontSize: "2.2vh",
            fontWeight: "600",
            color: "#006fbd",
            textAlign: "right",
            margin: "0", padding: "0",
            paddingBottom: "0.2vh",
            paddingRight: "3vw",
            bottom: "0", position: "absolute", left: "0"
        }}>
            {top_message}
        </div>
    </div>);
}

export function getServiceInfo(info_text) {
    return (<div>
        <SeogwipoMapContainer draggable={false} opacity={"0.5"} />
        <div
            style={{
                width: "100%",
                textAlign: "center",
                paddingTop: "40vh",
                paddingLeft: "2.5vw",
                float: "center",
                position: "absolute",
                zIndex: "1"
            }}>
            <Jumbotron style={{
                margin: "0", width: "95vw", height: "23vh", backgroundColor: "#646464", opacity: "0.8", color: '#FFFFFF',
                display:"flex", justifyContent:"center", alignItems:"center",
                }}>
                <span style={{
                    display: "inline-block", verticalAlign: "middle", lineHeight: "max(5vh, 5vw)",
                    fontWeight: 'bold',
                    fontSize: "3vh",
                    color:"white",
                    textAlign: "center",
                }}>{info_text}</span>
                <br />
            </Jumbotron>
        </div>
    </div >);
}

export function getOnService() {
    return (getServiceInfo(["현재 다른 탑승객이", <br />, "이용중입니다."]));
}

export function getNoService() {
    return (getServiceInfo(["차량 운행이 불가능하거나", <br />, "운영시간이 아닙니다."]));
}

export function getInitialInfo() {
    return (
        <div>
            <div style={{ margin: "0", paddingTop: "2.5vh", paddingLeft: "7vw" }}>
                <span style={{ color: "#F8061E", fontSize: "7vw" }}>
                    <BsExclamationCircleFill />
                </span>
                <span style={{ color: "#454F63", fontWeight: "900", fontSize: "5vw" }}> 호출 전에 확인해 주세요!</span>
            </div>
            <div style={{ width: "95vw", paddingLeft: "12vw", color: "#030000", fontWeight: "600", paddingTop: "2vh", fontSize: "3.8vw" }}>
                <div ><span style={{ color: "#b30000", fontWeight: "900", paddingRight: "2vw" }}><AiOutlineDoubleRight /></span>안전요원의 탑승으로 뒷좌석만 이용 가능합니다.</div>
                <div style={{ paddingBottom: "1vh" }}><span style={{ color: "#b30000", fontWeight: "900", paddingRight: "2vw" }}><AiOutlineDoubleRight /></span>미성년자는 탑승이 불가합니다.</div>
            </div>
        </div>
    )
}

export function getOverlayBtn(txt, click_callback, bColor = "#3366FF") {
    return (<div
        style={{
            textAlign: "center",
            marginTop: "80vh",
            marginLeft: "10vw",
            position: "absolute",
            zIndex: "1"
        }}>
        <Jumbotron style={{
            width: "80vw",
            height: "8vh",
            backgroundColor: bColor,
            margin: "0",
            padding: "0",
            color: '#FFFFFF',
            fontWeight: '900',
            fontSize: "4.5vh"
        }} onClick={click_callback}><span style={{ position: "relative", top: "10%" }}>{txt}</span></Jumbotron>
    </div>)
};

export function getPassengerRow(passenger_data){
    let td_style = {backgroundColor: isUnderAge(passenger_data["birthdate"]) ? "lightgreen" : ""};

    let time;
    try { 
        time = new Date(passenger_data["time"]);
    }catch (err){
        time = new Date();
        passenger_data["time"] = time;
        console.error(err);
    }
    let hour = time.getHours(), min = time.getMinutes();
    hour = ("00" + hour).slice(-2);
    min = ("00" + min).slice(-2);
    if(passenger_data["stop_description"] !== undefined){
        return (<tr style={td_style} >
            <td width="20%"> {hour}:{min}</td>
            <td width="40%"> {passenger_data["name"]}</td>
            <td width="40%"> {passenger_data["stop_description"]}</td>
        </tr>)
    } else {
        return (<tr style={td_style} >
            <td width="20%"> {hour}:{min}</td>
            <td width="80%"> {passenger_data["name"]}</td>
        </tr>)
    }
 };

 export function drawPassengerList(passenger_list){
     let passenger_table_rows = [];
     for (let passenger in passenger_list){
         passenger_table_rows.push(getPassengerRow(passenger_list[passenger]));
     }

     return(
         <div style={{display:"flex", marginTop: "3vh", maxHeight: "40vh", overflowY:"auto"}}>
             <Table striped bordered hover style={{textAlign:"center" ,backgroundColor:"white"}}>
                 <tbody>
                     {passenger_table_rows}
                 </tbody>
             </Table>
         </div>
     );
 };

 export function isBoardedUnderAge(passenger_list){
    if(passenger_list === undefined) return false;
    let birthdate = passenger_list.map((elem)=>{return elem["birthdate"]});

    return(
        <div style={{color: "red", textAlign: "center", marginTop:"2vh"}}> 
        {birthdate.find(isUnderAge) ? "* 미성년자가 탑승했습니다. 법적대리인의 서면 동의를 받아주세요." : ""}
        </div>
    );
}

export function inside(point, polygon) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html
    if(!!!point) return false;
    var x = point[0], y = point[1];

    var inside = false;
    for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i][0], yi = polygon[i][1];
        var xj = polygon[j][0], yj = polygon[j][1];

        var intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
};
