import React from "react";

const AdminTopBar = (props) => {
    const {
        children,
    } = props;

    return (<div
        className="admin-top-bar"
    >
        {React.Children.toArray(children)}
    </div>);
}

export default AdminTopBar;