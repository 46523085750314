import React, { useState, useMemo, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Button, Input } from "reactstrap";
import { useGetCarState } from "../../Hooks/Hooks";
import { Snackbar } from "@material-ui/core";

const IS_NUMBER = new RegExp(/^[0-9]/);

const TamraBoardingCheck = (props) => {
    const { history, location } = props;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const { car_name: carName } = query;
  
    const [carInfo, getCallState] = useGetCarState(2000, 'tamra', 'carpool');
    const serviceOnCarNames = Object.keys(carInfo);
    const isServiceOn = serviceOnCarNames.includes(carName);

    const [phoneNumberArray, setPhoneNumberArray] = useState(Array(8).fill(""));
    const [phoneIsVaild, setPhoneIsVaild] = useState(false);
    const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);

    const focusToElementById = useCallback((elementId) => {
        const element = document.getElementById(elementId)
        element.focus()
        return !!element
    }, []);

    const phoneNumberInputHandler = useCallback(
        (keyValue, idx) => {
            const deleteKey = ['Backspace', 'Delete'];
            if (!IS_NUMBER.test(keyValue) && !deleteKey.includes(keyValue)) {
                return;
            }
            const value = deleteKey.includes(keyValue) ? '' : Number(keyValue);
            const newPhoneNumberArray = [...phoneNumberArray];
            const prevPhoneNumber = phoneNumberArray[idx];

            if (value === '' && !prevPhoneNumber && idx > 0) {
                newPhoneNumberArray[idx - 1] = value;
                focusToElementById(`num${idx - 1}`);
            }
            else if (value === '' && prevPhoneNumber) {
                newPhoneNumberArray[idx] = value;
            }
            else {
                newPhoneNumberArray[idx] = value;
                if (idx < phoneNumberArray.length - 1) {
                    focusToElementById(`num${idx + 1}`);
                }
            }
            setPhoneNumberArray(newPhoneNumberArray)
        },
        [phoneNumberArray, focusToElementById]
    );

    const initializePhoneNumber = useCallback(() => {
        setPhoneNumberArray(Array(8).fill(""));
        focusToElementById(`num0`)
    }, [focusToElementById]);

    const onSubmit = useCallback(async () => {
        const fullNumber = [...phoneNumberArray];
        const middleNumber = fullNumber.splice(0, 4).join("");
        const tailNumber = fullNumber.join("");

        await axios
            .put(`/api/${carName}/tamra/carpool/register-passenger`, {
                phone: `010-${middleNumber}-${tailNumber}`,
            })
            .then(() => {
                setSnackbarIsOpen(true);
                initializePhoneNumber();
                focusToElementById(`num0`)
            })
            .catch((err) => {
                setPhoneIsVaild(false);
            });
    }, [phoneNumberArray, carName]);

    const header = useMemo(
        () => (
            <header
                style={{
                    padding: "20px",
                    display: "flex",
                    width: "100%",
                    height: "10%",
                }}
            >
                <img
                    src="/RF_LOGO_BLUE.png"
                    style={{
                        width: "89px",
                        height: "21px",
                        zIndex: 10,
                    }}
                />
            </header>
        ),
        [history]
    );

    const phoneNumber = useMemo(() => {
        const numberInputStyle = {
            width: "28px",
            height: "40px",
            backgroundColor: "transparent",
            margin: "2px",
            border: `1px solid ${phoneIsVaild ? "#94A3B8" : "#D35E56"}`,
            padding: 0,
            textAlign: "center",
            color: "#20377C",
        };

        const middleNumber = [...phoneNumberArray];
        const tailNumber = middleNumber.splice(4);
        return (
            <div style={{ display: "flex", marginTop: "21px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {[0, 1, 0, "-"].map((initialNum, idx) => (
                        <div
                            key={`initialNum-${idx}`}
                            style={{ margin: "2px" }}
                        >
                            {initialNum}
                        </div>
                    ))}
                </div>
                {middleNumber.map((num, idx) => (
                    <Input
                        key={idx}
                        id={`num${idx}`}
                        type="tel"
                        maxLength={1}
                        value={num}
                        onKeyDown={(event) => phoneNumberInputHandler(event.key, idx)}
                        style={numberInputStyle}
                    />
                ))}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ margin: "2px" }}>-</div>
                </div>
                {tailNumber.map((num, idx) => (
                    <Input
                        key={idx}
                        id={`num${idx + middleNumber.length}`}
                        type="tel"
                        min={0}
                        max={9}
                        maxLength={1}
                        value={num}
                        style={numberInputStyle}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                if (phoneIsVaild) onSubmit()
                                else initializePhoneNumber()
                            }
                            else {
                                phoneNumberInputHandler(e.key, idx + middleNumber.length)
                            }
                        }}
                    />
                ))}
            </div>
        );
    }, [phoneNumberArray, phoneNumberInputHandler, phoneIsVaild]);

    const submitButton = useMemo(() => {
        return (
            <Button
                id="submit-btn"
                style={{
                    backgroundColor: `${phoneIsVaild ? "#3366FF" : "#D35E56"}`,
                    borderRadius: "20px",
                    marginTop: "40px",
                    width: "136px",
                    height: "38px",
                }}
                onClick={phoneIsVaild ? onSubmit : initializePhoneNumber}
            >
                {phoneIsVaild ? "확인" : "초기화"}
            </Button>
        );
    }, [onSubmit, phoneIsVaild]);

    const inputGroup = useMemo(() => {
        return (
            <div
                style={{
                    color: "#20377C",
                    position: "absolute",
                    top: "230px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        height: "38px",
                        fontSize: "1.25rem",
                        textAlign: "center",
                        fontWeight: 700,
                    }}
                >
                    {isServiceOn
                        ? "탑승객 정보 확인"
                        : carName
                        ? "서비스가 꺼져있는 차량입니다."
                        : "잘못된 주소로 접근하였습니다."}
                </div>
                <div
                    style={{
                        height: "38px",
                        textAlign: "center",
                        fontSize: "0.9rem",
                    }}
                >
                    {isServiceOn
                        ? "탑승객 번호를 입력해주세요."
                        : carName
                        ? `어드민에서 ${carName} 차량의 서비스를 켜주세요`
                        : "탑승하신 차량에 해당하는 url을 확인 후 직접 접속해 주세요"}
                </div>
                {isServiceOn && phoneNumber}
                {isServiceOn && !phoneIsVaild && (
                    <span
                        style={{
                            position: "absolute",
                            top: "145px",
                        }}
                    >
                        <img
                            src="/wrong_sign_in_circle.svg"
                            style={{ width: "12px", height: "12px" }}
                        />
                        <span style={{ color: "#D35E56", fontSize: "0.7rem" }}>
                            번호가 올바르지 않습니다.
                        </span>
                    </span>
                )}
                {isServiceOn && submitButton}
                <Snackbar
                    open={snackbarIsOpen}
                    autoHideDuration={3000}
                    onClose={() => setSnackbarIsOpen(false)}
                    message={`탑승 확인이 완료되었습니다.`}
                    action={
                        <Button onClick={() => setSnackbarIsOpen(false)}>
                            닫기
                        </Button>
                    }
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                />
            </div>
        );
    }, [
        phoneNumber,
        submitButton,
        phoneIsVaild,
        snackbarIsOpen,
        isServiceOn,
        carName,
        phoneNumberArray,
        focusToElementById
    ]);

    const carIDBoard = useMemo(() => {
        return (
            <div
                style={{
                    width: "131px",
                    height: "30px",
                    backgroundColor: "#fff",
                    position: "absolute",
                    bottom: "180px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        width: "126px",
                        height: "27px",
                        borderRadius: "8px",
                        border: "2px solid #20377C",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <img
                        src="/RF_LOGO_BLUE.png"
                        style={{
                            width: "85px",
                            height: "18px",
                            zIndex: 10,
                        }}
                    />
                </div>
            </div>
        );
    }, []);

    const body = useMemo(() => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                }}
                onTouchEnd={(e) => {
                    if (!e.target.id) {
                        const idx = phoneNumberArray.findIndex(num => num === '');
                        focusToElementById(`num${idx}`);
                        e.preventDefault()
                    }
                }}
            >
                {inputGroup}
                {carIDBoard}
            </div>
        );
    }, [inputGroup, carIDBoard]);

    useEffect(() => {
        let emptyCellCount = phoneNumberArray.filter((num) => !num).length;
        if (emptyCellCount) setPhoneIsVaild(true);
    }, [phoneNumberArray]);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: 'center',
                overflow: "hidden",
            }}
        >
            <img
                src="/image_solati_frontview.jpg"
                style={{
                    position: "fixed",
                    height: "100%",
                    zIndex: -1,
                }}
            />
            {header}
            {body}
        </div>
    );
};
export default withRouter(TamraBoardingCheck);
