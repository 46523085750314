import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { ButtonGroup, Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Table, Dropdown, UncontrolledDropdown, Input, FormGroup, Label } from "reactstrap";
import { inside } from "../../Component/PageCommon.js";
import { tamraValidServiceArea } from "../../../constants/ValidServiceArea.js";
import { useInterval } from "../../Hooks/Hooks.js"
import { BsArrowRight } from "react-icons/bs";
import ReactSound from "react-sound";
import LogoutButton from "../../Component/LogoutButton.js";
import AdminTopBar from "../../Component/TopBar.js";
import DarkModeButton from "../../Component/DarkModeButton.js";

const RESERVED = 'RESERVED';
const EXPIRED = 'EXPIRED';
const SERVED = 'SERVED';
const CANCEL = 'CANCEL';
const IMPENDING = 'IMPENDING';
const ONEHOUR = 'ONEHOUR';
const AVAILABLE_STATUS = [RESERVED, IMPENDING, ONEHOUR];

const TamraAdminPage = (props) => {
    const {
        history,
        location,
    } = props;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const {
        car_name
    } = query;
    const service_name = "TAMRA_SHUTTLE";

    const [service, setService] = useState(false);
    const [trip, setTrip] = useState(false);
    const [carInfo, setCarInfo] = useState({
        car_timeout: true,
        car_location: undefined,
        passenger: [],
        trip_cnt: 0,
        total_trip_cnt: 0,
        current_service: "",

    });

    const [carName, setCarName] = useState(car_name);
    const [cars, setCars] = useState([]);

    const [stops, setStops] = useState([]);
    const [nextStop, setNextStop] = useState(0);
    const [currentForwardStop, setCurrentForwardStop] = useState(-1);

    const [isCarDropDownOpen, setIsCarDropDownOpen] = useState(false);
    const [isStopDropDownOpen, setIsStopDropDownOpen] = useState(false);
    const [isInside, setIsInside] = useState(false);

    const [reservationIds, setReservationIds] = useState(undefined);
    const [reservations, setReservations] = useState([]);
    const [isGetReservations, setIsGetReservations] = useState(false);

    const [reservationDropDownOpen, setReservationDropDownOpen] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState({});
    const [currentReservationId, setCurrentReservationId] = useState(0);
    const [reservationFilters, setReservationFilters] = useState({
        showOnlyReserved: true
    });

    const [soundPlayStatus, setSoundPlayStatus] = useState("STOPPED");

    const onFinishedSoundPlay = useCallback(() => {
        setSoundPlayStatus('STOPPED');
    }, []);

    const getReservation = useCallback(async () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const today_end = new Date();
        today_end.setHours(24, 0, 0, 0);

        const reservation_info = await axios.get("/api/tamra/shuttle/get-reservation",{
            params: {minTime: today, maxTime: today_end, isAdmin: true, carName: carName}
        })
        .then(res => res.data)
        .catch(err => {
            console.log(err);
            return [];
        });
        const sorted_reservations = reservation_info.map(reservation => {
            const start_date = new Date(reservation.start_datetime);
            const result =  {
                ...reservation,
                start_datetime: start_date,
            };
            if (start_date.getTime() < Date.now() + 3600000 && reservation["status"] === RESERVED) {
                result["status"] = ONEHOUR;
            }

            return result;
        })
        .sort(({start_datetime: a}, {start_datetime: b}) => a - b);
        
        setReservations(sorted_reservations);
        setIsGetReservations(true);
        setSelectedReservation(current_selected => {
            try {
                const selected_reservation_idx = reservation_info.findIndex(reservation => reservation.id === current_selected.id);
                if (selected_reservation_idx < 0 || !(AVAILABLE_STATUS.includes(reservation_info[selected_reservation_idx].status))) {
                    return {};
                }
            }
            catch (err) {
                console.debug("selected Reservation not changed.\n", err);
            }
            return current_selected;
        });
        
    }, [carName]);

    const getCallState = useCallback(async () => {
        if (!!!carName)
            return;
        const res = await axios.get(`/api/${carName}/tamra/shuttle/getCallState`, {
            params: {
                isClient: false
            }
        }).catch(err => {
            return err.response;
        });
        if (res.statusText === 'OK') {
            const {
                car_timeout, car_location, service_on, passenger,
                trip_on, trip_cnt, total_trip_cnt, current_service,
                next_shuttle_stop_id, reservation_id,
            } = res.data;
            setCarInfo({
                car_timeout, car_location, trip_cnt, total_trip_cnt,
                current_service, passenger,
            });
            setService(service_on);
            setTrip(trip_on);
            setCurrentForwardStop(stops.findIndex(stop => stop.id === next_shuttle_stop_id));
            setCurrentReservationId(reservation_id);
        }
        else {
            setCarInfo(info => {
                return {
                    ...info,
                    car_location: undefined,
                    trip_cnt: 0,
                    total_trip_cnt: 0,
                }
            });
            setService(false);
            setTrip(false);
        }
    }, [carName, stops]);

    const toggleReservationDrop = useCallback(() => {
        setReservationDropDownOpen(o => !o);
    }, []);

    const reservationFilterCB = useCallback(() => {
        const check_arr = [() => true];
        if (reservationFilters.showOnlyReserved) {
            check_arr.push((arg) => AVAILABLE_STATUS.includes(arg));
        }
        if (reservationFilters.notShowCancel) {
            check_arr.push((arg) => arg !== CANCEL);
        }
        return (reservation) => {
            return check_arr.every(func => func(reservation?.status));
        }
    }, [reservationFilters]);

    // const getTripCnt = useCallback(async () => {
    //     if(!service)
    //         return;
    //     await axios.get(`/api/${carName}/tamra/shuttle/get-trip-cnt`, {
    //         params: {
    //             isClient: false
    //         }
    //     });
    // },[service]);

    const turnServiceOff = useCallback(async () => {
        if (!!!carName)
            return;
        const res = await axios.put(`/api/${carName}/tamra/shuttle/turn-service-off`)
            .catch(err => err.response);
        if (res.statusText === 'OK') {
            setService(false);
            // setTrip(false);
        }
        else if (res.status === 400) {
            alert("수행중인 예약이 있습니다. 예약 서비스 완료를 먼저 눌러주십시요.");
        }
    }, [carName]);

    const turnServiceOn = useCallback(async () => {
        if (!!!carName)
            return;
        const res = await axios.put(`/api/${carName}/tamra/shuttle/turn-service-on`)
            .catch(err => err.response);
        if (res.statusText === 'OK') {
            setService(true);
        }
    }, [carName]);

    const startReservation = useCallback(async () => {
        if (!!!carName){
            return;
        }
        if (Object.keys(selectedReservation).length === 0) {
            return;
        }

        const [start_stop] = stops.filter(stop => selectedReservation.start_stop_id === stop.id);
        const [end_stop] = stops.filter(stop => selectedReservation.end_stop_id === stop.id);
        const res = await axios.put(`/api/${carName}/tamra/shuttle/start-reservation`, {
            reservation_id: selectedReservation.id,
            departure: [start_stop.location.latitude, start_stop.location.longitude],
            destination: [end_stop.location.latitude, end_stop.location.longitude],
            passengers: selectedReservation.phone,
            num_passenger: selectedReservation.num_passenger,
        }).catch(err => err.response);
        if (res.statusText === 'OK') {
            setCurrentReservationId(selectedReservation.id);
        }
    }, [carName, selectedReservation]);
    const endReservation = useCallback(async () => {
        if (!!!carName){
            return;
        }
        if (!(currentReservationId > 0)) {
            return;
        }
        const res = await axios.put(`/api/${carName}/tamra/shuttle/end-reservation`)
        .catch(err => err.response);
        if (res.statusText === 'OK') {
            setCurrentReservationId(0);
            setSelectedReservation({});
        }
    }, [carName, currentReservationId]);

    const goNextStop = useCallback(async (currentForwardStop, nextStop, stops) => {
        let current_shuttle_stop = {};
        if (currentForwardStop < 0) {
            current_shuttle_stop = {
                id: -1,
            };
        }
        else {
            current_shuttle_stop = {
                id: stops[currentForwardStop].id,
                location: [stops[currentForwardStop].location.latitude, stops[currentForwardStop].location.longitude],
                order: stops[currentForwardStop].order,
                description: stops[currentForwardStop].description,
            };
        }
        const res = await axios.post(`/api/${carName}/tamra/shuttle/go_to_stop`, {
            current_shuttle_stop, 
            next_shuttle_stop: {
                id: stops[nextStop].id,
                location: [stops[nextStop].location.latitude, stops[nextStop].location.longitude],
                order: stops[nextStop].order,
                description: stops[nextStop].description,
            },
        }).catch(err => err.response);
        if (res.statusText === 'OK') {
            setCurrentForwardStop(nextStop);
            setNextStop((nextStop + 1) % stops.length);
            setTrip(true);
        }

        // const res = await axios.put(`/api/${carName}/tamra/shuttle/next_stop`, {
        //     next_shuttle_stop: {
        //         id: stops[nextStop].id,
        //         location: [stops[nextStop].location.latitude, stops[nextStop].location.longitude],
        //         order: stops[nextStop].order,
        //     },
        //     // next_shuttle_stop_id: stops[nextStop].id
        // }).catch(err => err.response);
        // if (res.statusText === 'OK') {
        //     setCurrentForwardStop(nextStop);
        //     setNextStop((nextStop + 1) % stops.length);
        // }
    }, [carName]);

    const arriveNextStop = useCallback(async () => {

        const res = await axios.put(`/api/${carName}/tamra/shuttle/end_to_stop`, {
        }).catch(err => err.response);
        if (res.statusText === 'OK') {
            setTrip(false);
        }
    }, [carName]);

    const carDropDownOnClick = useCallback((name) => {
        history.replace({
            search: `?car_name=${name}`
        });
    }, [history]);

    const car_items = useMemo(() => {
        return [...cars, undefined].map((name, idx) => {
            return <DropdownItem name={name} key={`car-dropdown-${idx}=${name}`} value={name} onClick={() => carDropDownOnClick(name)} >{name}</DropdownItem>
        });
    }, [cars, carDropDownOnClick]);

    const trip_count_content = useMemo(() => {
        return <span
            style={{ fontSize: "4vw", width: "40%", textAlign: "center" }}
        >
            {carName} 운행 횟수: {carInfo.trip_cnt}
            <br />
            총 운행 횟수: {carInfo.total_trip_cnt}
        </span>
    }, [carInfo, carName]);
    const service_button = useMemo(() => {
        return <Button
            style={{ width: "100%", marginTop: service && isInside ? "1vh" : "5vh" }}
            size="md"
            color={service ? "danger" : "info"}
            onClick={service ? turnServiceOff : turnServiceOn}
        >
            {service ? "서비스 종료" : "서비스 시작"}
        </Button>
    }, [service, isInside, turnServiceOff, turnServiceOn]);
    // const trip_button = useMemo(() => {
    //     return <Button
    //         style={{ width: "70vw", marginTop: "5vh" }}
    //         size="md"
    //         color={trip ? "warning" : "info"}
    //         onClick={trip ? disableTrip : ableTrip}
    //     >
    //         {trip ? "순환 운행 종료" : "순환 운행 시작"}
    //     </Button>
    // }, [trip, disableTrip, ableTrip]);

    const stop_items = useMemo(() => {
        if (stops.length === 0) {
            return <div />;
        }
        return stops.map((name, idx) => {
            return <DropdownItem name={stops[idx]["name"]} key={`stop-dropdown-${idx}`} value={stops[idx]["name"]} onClick={() => setNextStop(idx)} >{stops[idx]["description"]}</DropdownItem>
        });
    }, [stops]);

    const stop_text = useMemo(() => {
        if (stops.length < 1 || currentForwardStop < 0) {
            return null;
        }
        return <>
        <h2 className="text-blue" >{`${stops[currentForwardStop]["description"]}정류장으로 ${trip ? "이동 중입니다" : "이동하였습니다"}.`}</h2>
        </>
    }, [stops, trip, currentForwardStop])

    const stop_content = useMemo(() => {
        if (stops.length == 0) {
            return (<div />);
        }
        if (trip) {
            return <div style={{paddingBottom: "28px"}}>
                {stop_text}
                <Button 
                    size="md"
                    color="warning" 
                    onClick={() => arriveNextStop()}
                >
                    {"정류장에 도착"}
                </Button>
            </div>
        }
        return (
            <div style={{paddingBottom: "28px"}}>
                {stop_text}
                <br />
                <ButtonGroup>
                    <ButtonDropdown direction="down" isOpen={isStopDropDownOpen} toggle={() => setIsStopDropDownOpen(open => !open)}>
                        <DropdownToggle caret>
                            {stops[nextStop]["description"]}
                        </DropdownToggle>
                        <DropdownMenu name="shuttleStop" >
                            {stop_items}
                        </DropdownMenu>
                    </ButtonDropdown>
                    <Button size="md" color="success" onClick={() => goNextStop(currentForwardStop, nextStop, stops)} >정류장으로 출발</Button>
                </ButtonGroup>
                <br />
            </div>
        )
    }, [stop_text, stop_items, stops, nextStop, currentForwardStop, isStopDropDownOpen, trip, goNextStop])

    const content_info = useMemo(() => {
        const {
            car_timeout, car_location, current_service
        } = carInfo;
        const _inside = isInside;
        let text = '';
        let color = 'red';
        let service_show = service;
        let stop_show = false;
        if (!!current_service && service_name !== current_service)
            text = "다른 사용자 페이지에서 서비스 중이거나 서비스명이 일치하지 않습니다."
        else if (car_timeout || !!!car_location)
            text = "차량의 위치를 확인할 수 없어 서비스를 시작할 수 없습니다."
        else if (service) {
            if (_inside) {
                color = 'blue'
                text = "순환 노선을 주행 중입니다." //trip ? "순환 노선을 주행 중입니다." : "";
                // if (trip) {
                    stop_show = true;
                // }
            }
            else
                text = "차량이 운영구역을 벗어났습니다. 서비스를 중단 해주세요!"
        }
        else if (!car_timeout && !_inside)
            text = "차량이 운영구역 밖에 있어 서비스를 시작할 수 없습니다."
        else {
            text = "고객이 탑승을 할 수 없는 상태입니다."
            service_show = true;
        }

        return {
            text,
            color,
            service_show,
            trip_show: color === 'blue',
            stop_show
        }
    }, [carInfo, service, service_name, isInside]);

    const reservation_selection = useMemo(() => {
        const {
            car_timeout
        } = carInfo;
        if (car_timeout || !service || !stops.length) {
            return null;
        }
        if (!!currentReservationId && reservations.length > 0) {
            const reservation = reservations.find(_reservation => +_reservation.id === +currentReservationId);
            const [start_stop] = stops.filter(stop => selectedReservation.start_stop_id === stop.id);
            const [end_stop] = stops.filter(stop => selectedReservation.end_stop_id === stop.id);
            return <h5>
                현재 <br/>
                {reservation ? reservation?.start_datetime.toLocaleTimeString() : undefined} : {start_stop?.description}
                <BsArrowRight style={{height: "1.5em", marginInline: "6px"}}/>
                {end_stop?.description} <br/>
                예약을 운행중입니다
            </h5>;
        }
        else {
            const [start_stop] = stops.filter(stop => selectedReservation.start_stop_id === stop.id);
            const [end_stop] = stops.filter(stop => selectedReservation.end_stop_id === stop.id);
            return <div className="d-flex justify-content-center" style={{marginBottom: "1vh"}}>
                <Dropdown 
                    style={{width:"80%"}}
                    isOpen={reservationDropDownOpen}
                    toggle={toggleReservationDrop}
                    group
                >
                    <Button
                        color="secondary"
                        onClick={toggleReservationDrop}
                    >
                        {selectedReservation.start_datetime ? 
                        <>
                        {`${selectedReservation.start_datetime.toLocaleTimeString()} : ${start_stop.description}`}
                        <BsArrowRight style={{height: "1.5em", marginInline: "6px"}}/>
                        {end_stop?.description} 
                        </>
                        : "선택안됨"}
                    </Button>
                    <DropdownToggle caret
                        color="secondary"
                        style={{flexGrow: 0}}
                    >
                    </DropdownToggle>
                    <DropdownMenu
                        style={{width:"100%"}}
                    >
                        {reservations.filter(reservation => [RESERVED, IMPENDING, ONEHOUR].includes(reservation.status))
                        .map((reservation, idx) => 
                        <DropdownItem
                            key={`reservation-dropdown-${reservation.id}`}
                            onClick={()=>{setSelectedReservation(reservation)}}
                        >
                            <>
                            {`${reservation.start_datetime.toLocaleTimeString()} : ${stops.find(stop => stop.id === reservation.start_stop_id)?.description}`}
                            <BsArrowRight style={{height: "1.5em", marginInline: "6px"}}/>
                            {stops.find(stop => stop.id === reservation.end_stop_id)?.description} 
                            </>
                        </DropdownItem>)}
                        <DropdownItem
                            onClick={()=>{setSelectedReservation({})}}
                        >
                            선택안함
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>;
        }
        
    }, [reservations, carInfo, service, selectedReservation, currentReservationId, stops, reservationDropDownOpen, toggleReservationDrop]);

    const reservation_button = useMemo(() => {
        const {
            car_timeout
        } = carInfo;
        if (car_timeout || !service) {
            return null;
        }
        if (!!currentReservationId) {
            return <Button 
                style={{width: "100%"}}
                color="warning"
                onClick={endReservation}
            >
                예약 서비스 완료
            </Button>
        }
        else {    
            return <Button 
                style={{width: "100%"}}
                color="info"
                onClick={startReservation}
                active={!!!selectedReservation.id}
            >
                예약 서비스 시작
            </Button>
        }
    }, [carInfo, service, startReservation, selectedReservation, currentReservationId]);

    const reservation_filter_checkbox = useMemo(() => {
        return (
            <div className="d-flex justify-content-around">
                <FormGroup check inline 
                    onClick={(e) => {
                        setReservationFilters(filters => ({
                            ...filters,
                            showOnlyReserved: !!!filters.showOnlyReserved
                        }))
                    }}
                >
                    <Input 
                        type="checkbox"
                        checked={!!reservationFilters.showOnlyReserved}
                        readOnly
                    />
                    <Label check>활성화된 예약만 보기</Label>
                </FormGroup>
                <FormGroup check inline
                    onClick={(e) => {
                        setReservationFilters(filters => ({
                            ...filters,
                            notShowCancel: !!!filters.notShowCancel
                        }))
                    }}
                >
                    <Input 
                        type="checkbox"
                        checked={!!reservationFilters.notShowCancel}
                        readOnly
                    />
                    <Label check>취소예약 숨기기</Label>
                </FormGroup>
            </div>
        )
    }, [reservationFilters]);

    const reservation_list = useMemo(() => {
        if (!reservations.length) {
            return <div>예약 내역이 없습니다.</div>;
        }
        return (
        <>
        <Table
            striped
            className="tamra-admin-reservation"
        >
            <thead>
                <tr>
                    <th>승차시각</th>
                    <th>출발지</th>
                    <th>도착지</th>
                    <th>예약자 핸드폰</th>
                </tr>
            </thead>
            <tbody>
                {reservations
                .filter(reservationFilterCB())
                .map(reservation => {
                    const {
                        start_datetime, start_stop_id, end_stop_id, phone, status
                    } = reservation;

                    if (status === CANCEL) {
                        return <tr className="cancel">
                            <th>{new Date(start_datetime).toLocaleTimeString()}</th>
                            <th className="cancel" colSpan="4">예약이 취소되었습니다</th>
                        </tr>
                    }


                    return <tr 
                        key={`reservation-info-${reservation.id}`}
                        className={!!reservation.status ? reservation.status.toLowerCase() : ''}
                    >
                        <th>{new Date(start_datetime).toLocaleTimeString()}</th>
                        <th>{stops.find(s => s.id === start_stop_id)?.description}</th>
                        <th>{stops.find(s => s.id === end_stop_id)?.description}</th>
                        <th>{Array.isArray(phone) && phone.length > 0 ? phone[0] : phone}</th>
                    </tr>
                })}
            </tbody>
        </Table>
        {reservation_filter_checkbox}
        </>)
    }, [reservations, stops, reservation_filter_checkbox, reservationFilterCB])


    useEffect(() => {
        async function wrapperFunction() {
            const _car_names = await axios.get("/api/get_cars")
                .then((res) => res.data.cars)
                .catch(_ => []);
            setCars(_car_names);
        }
        wrapperFunction();
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#F9DEDE";
    }, []);

    useEffect(() => {
        async function wrapperFunction() {
            const _stops = await axios.get("/api/tamra/shuttle/get-stops")
                .then((res) => res.data)
                .catch(_ => []);
            setStops(_stops);
        }
        wrapperFunction();
    }, []);

    useEffect(() => {
        if (cars.length === 0 || !!car_name)
            return;
        history.replace({
            search: `?car_name=${cars[0]}`
        });

    }, [cars, history, car_name]);

    useEffect(() => {
        setCarName(car_name);
    }, [car_name]);

    useEffect(() => {
        setIsInside(inside(carInfo.car_location, tamraValidServiceArea));
    }, [carInfo]);

    useEffect(() => {
        getCallState();
    }, [getCallState]);
    useEffect(() => {
        getReservation();
    }, [getReservation]);

    useEffect(() => {
        if (!isGetReservations) {
            return;
        }
        if (!reservationIds) {
            const reservation_set = new Set();
            reservations.forEach(reservation => reservation_set.add(reservation.id));
            setReservationIds(reservation_set);
        }
        else {
            const new_reservations = reservations.filter(reservation => !reservationIds.has(reservation.id));
            if (new_reservations.length > 0) {
                for (const new_reservation of new_reservations) {
                    reservationIds.add(new_reservation.id);
                }
                setSoundPlayStatus('PLAYING');
            }
        }
    }, [reservations, reservationIds, isGetReservations]);

    useInterval(getCallState, 2000);
    useInterval(getReservation, 10 * 1000);

    return <div style={{ height: "100%"}}>
        <AdminTopBar>
            <LogoutButton history={history} />
            <DarkModeButton />
        </AdminTopBar>
        <ReactSound
            url="/sound/ring.mp3"
            playStatus={soundPlayStatus}
            autoLoad
            onFinishedPlaying={onFinishedSoundPlay}
        />
        <div
            style={{
                margin: "auto",
                width: "70%",
                paddingBlock: "5vh",
                textAlign: "center",
            }}
        >
            <h1><b>- Tamra 셔틀 운영자 페이지 - </b></h1>
            <br />
            <div style={{ textAlign: "center" }}>
                <ButtonDropdown direction="down" isOpen={isCarDropDownOpen} toggle={() => setIsCarDropDownOpen(open => !open)}>
                    <DropdownToggle caret>
                        {carName}
                    </DropdownToggle>
                    <DropdownMenu name="cars" >
                        {car_items}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
            <br />
            <div>
                {content_info.trip_show ? trip_count_content : null}
                <h2 className={`text-${content_info.color}`} >
                    {content_info.text}
                    {content_info.stop_show ? stop_content : null}
                    {/* {content_info.trip_show ? trip_button : null} */}
                    {reservation_selection}
                    {reservation_button}
                    {content_info.service_show ? service_button : null}
                </h2>
            </div>
            {reservation_list}
        </div>
    </div >
}

export default withRouter(TamraAdminPage);
