import React from 'react';
import { BsArrowRight } from "react-icons/bs";
import { MdGpsFixed } from 'react-icons/md';
import {
    Card,
    CardText,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from "reactstrap";
import MapContainer from "./MapContent";
import '../style/Common.css';

export const agreementText = (
    <div className="modal-agreementText">
        <b>라이드플럭스 자율주행자동차 탑승 동의서</b>
        <p >(주)라이드플럭스는 자율주행 자동차 시범 서비스 제공함에 있어 다음과 같이 탑승자에 대한 주요 고지 사항을 안내드립니다.</p>
        <div className ="sub1">1. 안전한 운행을 위한 유의사항 및 승객 고지사항 및 동의사항</div>
        <div className ="sub2"> - 상기 차량 및 본 시범 서비스의 제어권은 동승한 세이프티 드라이버 및 (주)라이드플럭스에 있으며, 탑승이 이루어지는 동안 탑승자는 세이프티 드라이버에 지시를 준수해야 합니다. 세이프티 드라이버에게 위해를 가하는 언행을 행하는 경우, 자율주행 차량 운행에 방해가 되는 행동을 하는 경우, 차량 내부 시설물을 훼손하거나 훼손할 가능성이 있는 행동을 하는 경우 세이프티 드라이버 또는 (주)라이드플럭스는 해당 탑승자의 탑승을 거부할 수 있으며, 탑승자의 언행으로 인해 손해가 발생할 경우 탑승자는 법적 책임을 부담할 수 있습니다.</div>
        <div className ="sub2"> - 연구개발 및 시범 서비스 주행 안정성 확인을 위해 탑승 서비스를 제공하는 동안 차량 내부가 녹화되며, 녹화된 내용은 향후 당사의 연구 목적으로 활용될 수 있습니다. </div>
        <div className ="sub2"> - 시범 서비스 운행 중에는 반드시 안전벨트를 착용하여 주시기 바랍니다.</div>
        <div className ="sub2"> - 상기 차량의 운행과 관련된 일체의 자료는 당사의 영업비밀에 해당되어 탑승자가 임의의 목적으로 촬영∙녹음할 수 없으며, 이로 인한 손해가 발생할 경우 법적 책임을 부담할 수 있습니다. </div>
        <div className ="sub2"> - 세이프티 드라이버는 안전한 운행을 위해 수동으로 차량을 운행할 수 있습니다.</div>
        <div className ="sub2"> - 만 6세 이하의 아동은 안전상의 이유(카시트 미설치)로 시승이 제한되며, 만 6세 이상의미성년자는 법정대리인의 동의하에 법정대리인과 동승하여 시승이 가능합니다.</div>
        <div className ="sub1">2. 동의 거부 권리</div>
        <div className ="sub2"> - 탑승자는 본 안내에 따른 탑승 고지사항 및 동의사항에 거부하실 권리가 있습니다. 다만, 탑승 고지사항 및 동의사항에 거부할 경우 서비스 이용에 어려움이 있음을 알려드립니다. </div>
        <p>본인은 탑승에 따른 고지사항 및 동의사항을 숙지하였으며 이에 동의합니다.</p>
        <hr />
        <b>개인정보 수집∙이용 동의서</b>
        <p>(주)라이드플럭스는 [개인정보보호법]에 의거하여, 자율주행 자동차 시범 서비스 제공을 위해 아래와 같은 내용으로 개인정보를 수집하고 있으며, 탑승자가 제공한 모든 정보는 다음의 목적을 위해 활용하며, 하기 목적 이외의 용도로는 사용되지 않습니다.</p>
        <div className="sub1">1. 개인정보 수집 항목 및 수집∙이용 목적</div>
        <div className="sub2"> - 수집 항목 (필수항목)</div>
        <div className="sub3"> * 성명, 생년월일, 휴대전화번호, 위치정보 및 운행영상</div>
        <div className="sub2"> - 수집 및 이용 목적 </div>
        <div className="sub3"> * 시범서비스 제공</div>
        <div className="sub3"> * 사고 발생시 대응 및 보험처리</div>
        <div className="sub3"> * 도난방지</div>
        <div className="sub3"> * 그 외 민원처리</div>
        <div className="sub1">2. 개인정보 보유 및 이용기간</div>
        <p className="sub2"> - 수집∙이용 동의일로부터 1년</p>
        <div className="sub1">3. 동의 거부 권리</div>
        <p className="sub2"> - 탑승자는 본 안내에 따른 탑승 고지사항 및 동의사항에 거부하실 권리가 있습니다. 다만, 탑승 고지사항 및 동의사항에 거부할 경우 서비스 이용에 어려움이 있음을 알려드립니다. </p>
        <p>본인은 위 동의사항을 숙지하였으며 이에 동의합니다.</p>
    </div>
)

const agreementShortTextContent = <>
        <div className="agree-info-title">동의사항에 관한 안내</div>
        <div className="agree-info-content">
            <div>• &nbsp; 안전을 위해 탑승 동안 <b>차량 내부가 녹화</b>되고 있습니다.</div>
            <div>• &nbsp; 상기 차량의 운행과 관련한 일체 자료는 당사의 영업비밀에 해당되므로, 탑승자는 차량 운행과 관련하여 <b>무단으로 녹음, 촬영 및 배포할 수 없습니다.</b></div>
            <div>• &nbsp; 탑승자의 안전을 위해 전문 교육을 받은 세이프티 드라이버 2명이 동승합니다.</div>
            <div>• &nbsp; 차량 트렁크에는 수하물을 실을 수 없습니다.</div>
            <div>• &nbsp; 안전상의 이유로 미취학 아동은 탑승이 제한되며, 8세 이상의 미성년자는 법정대리인의 동의 하에 탑승이 가능합니다.</div>
            <div>• &nbsp; 서비스 제공 등을 위해 개인정보를 수집 및 이용합니다.</div>
        </div>
</>;
export const agreementShortText = (
    <div>
        {agreementShortTextContent}
    </div>
)

export const agreementTextWithOption = (
    <div className="modal-agreementText">
        <b>라이드플럭스 자율주행자동차 탑승 동의서</b>
        <p >(주)라이드플럭스는 자율주행 자동차 시범 서비스 제공함에 있어 다음과 같이 탑승자에 대한 주요 고지 사항을 안내드립니다.</p>
        <div className ="sub1">1. 안전한 운행을 위한 유의사항 및 승객 고지사항 및 동의사항</div>
        <div className ="sub2"> - 상기 차량 및 본 시범 서비스의 제어권은 동승한 세이프티 드라이버 및 (주)라이드플럭스에 있으며, 탑승이 이루어지는 동안 탑승자는 세이프티 드라이버에 지시를 준수해야 합니다. 세이프티 드라이버에게 위해를 가하는 언행을 행하는 경우, 자율주행 차량 운행에 방해가 되는 행동을 하는 경우, 차량 내부 시설물을 훼손하거나 훼손할 가능성이 있는 행동을 하는 경우 세이프티 드라이버 또는 (주)라이드플럭스는 해당 탑승자의 탑승을 거부할 수 있으며, 탑승자의 언행으로 인해 손해가 발생할 경우 탑승자는 법적 책임을 부담할 수 있습니다.</div>
        <div className ="sub2"> - 연구개발 및 시범 서비스 주행 안정성 확인을 위해 탑승 서비스를 제공하는 동안 차량 내부가 녹화되며, 녹화된 내용은 향후 당사의 연구 목적으로 활용될 수 있습니다. </div>
        <div className ="sub2"> - 시범 서비스 운행 중에는 반드시 안전벨트를 착용하여 주시기 바랍니다.</div>
        <div className ="sub2"> - 상기 차량의 운행과 관련된 일체의 자료는 당사의 영업비밀에 해당되어 탑승자가 임의의 목적으로 촬영∙녹음할 수 없으며, 이로 인한 손해가 발생할 경우 법적 책임을 부담할 수 있습니다. </div>
        <div className ="sub2"> - 세이프티 드라이버는 안전한 운행을 위해 수동으로 차량을 운행할 수 있습니다.</div>
        <div className ="sub2"> - 만 6세 이하의 아동은 안전상의 이유(카시트 미설치)로 시승이 제한되며, 만 6세 이상의미성년자는 법정대리인의 동의하에 법정대리인과 동승하여 시승이 가능합니다.</div>
        <div className ="sub1">2. 동의 거부 권리</div>
        <div className ="sub2"> - 탑승자는 본 안내에 따른 탑승 고지사항 및 동의사항에 거부하실 권리가 있습니다. 다만, 탑승 고지사항 및 동의사항에 거부할 경우 서비스 이용에 어려움이 있음을 알려드립니다. </div>
        <p>본인은 탑승에 따른 고지사항 및 동의사항을 숙지하였으며 이에 동의합니다.</p>
        <hr />
        <b>개인정보 수집∙이용 동의서</b>
        <p>(주)라이드플럭스는 [개인정보보호법]에 의거하여, 자율주행 자동차 시범 서비스 제공을 위해 아래와 같은 내용으로 개인정보를 수집하고 있으며, 탑승자가 제공한 모든 정보는 다음의 목적을 위해 활용하며, 하기 목적 이외의 용도로는 사용되지 않습니다.</p>
        <div className="sub1">1. 개인정보 수집 항목 및 수집∙이용 목적</div>
        <div className="sub2"> - 수집 항목 (필수항목)</div>
        <div className="sub3"> * 성명, 생년월일, 휴대전화번호, 위치정보 및 운행영상</div>
        <div className="sub2"> - 수집 및 이용 목적 </div>
        <div className="sub3"> * 시범서비스 제공</div>
        <div className="sub3"> * 사고 발생시 대응 및 보험처리</div>
        <div className="sub3"> * 도난방지</div>
        <div className="sub3"> * 그 외 민원처리</div>
        <div className="sub1">2. 개인정보 보유 및 이용기간</div>
        <p className="sub2"> - 수집∙이용 동의일로부터 1년</p>
        <div className="sub1">3. 동의 거부 권리</div>
        <p className="sub2"> - 탑승자는 본 안내에 따른 탑승 고지사항 및 동의사항에 거부하실 권리가 있습니다. 다만, 탑승 고지사항 및 동의사항에 거부할 경우 서비스 이용에 어려움이 있음을 알려드립니다. </p>
        <p>본인은 위 동의사항을 숙지하였으며 이에 동의합니다.</p>
        <hr />
        <b>[개인정보 제3자 제공에 대한 별도 동의 (선택사항) ]</b>
        <div className="sub2">① 개인정보를 제공받는 자 : 한국교통연구원 </div>
        <div className="sub2">② 개인정보를 제공받는 자의 개인정보 이용 목적 : 자율주행 서비스 연구 및 설문 목적</div>
        <div className="sub2">③ 제공하는 개인정보의 항목 : 성명, 전화번호</div>
        <div className="sub2">④ 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간 : 제공 후 1년</div>
        <div className="sub2">⑤ 동의를 거부할 수 있으나, 설문조사에 응하신 분들에게 답례품(기프티콘)을 발송해드릴 예정이며, 귀하의 개인정보는 설문조사 및 답례품 발송 외의 목적으로 활용되지 않습니다.</div>
    </div>
)

export const agreementShortTextWithOption = (
    <div>
        <div className="agree-info-title">동의사항에 관한 안내</div>
        <div className="agree-info-content">
            <div>• &nbsp; 안전을 위해 탑승 동안 <b>차량 내부가 녹화</b>되고 있습니다.</div>
            <div>• &nbsp; 상기 차량의 운행과 관련한 일체 자료는 당사의 영업비밀에 해당되므로, 탑승자는 차량 운행과 관련하여 <b>무단으로 녹음, 촬영 및 배포할 수 없습니다.</b></div>
            <div>• &nbsp; 탑승자의 안전을 위해 전문 교육을 받은 세이프티 드라이버 2명이 동승합니다.</div>
            <div>• &nbsp; 안전상의 이유로 미취학 아동은 탑승이 제한되며, 8세 이상의 미성년자는 법정대리인의 동의 하에 탑승이 가능합니다.</div>
            <div>• &nbsp; 서비스 제공 등을 위해 개인정보를 수집 및 이용합니다.</div>
            <div>• &nbsp; (선택) 자율주행 서비스 연구 및 설문 목적으로 개인정보가 제3자에 제공될 수 있습니다.</div>
        </div>
    </div>
)

export const AgreementShorTextComponent = ({
    className = "consent-form jumbotron",
    buttonTag = 'button',
    buttonWrapperProps = {className: ''},
    buttonProps = {className: "btn-consent-form"},
    ...props
}) => {

    const [open, setOpen] = React.useState(false);
    const toggle = React.useCallback(() => setOpen(open => !open), []);

    const btn = React.useMemo(() => {
        const text = "동의서 전문 확인하기"
        switch (buttonTag) {
            case 'button':
                return <Button 
                    {...buttonProps}
                    onClick={toggle} 
                >{text}</Button>
            case 'div' :
                return <div 
                    {...buttonProps}
                    onClick={toggle} 
                >{text}</div>
            default :
                return null;
        }
    }, [buttonTag, buttonProps, toggle]);

    return (
        <div {...props} className={className}>
            {agreementShortTextContent}
            <div {...buttonWrapperProps}>
                {btn}
            </div>
            <AgreementModal
                open={open}
                toggle={toggle}
            />
        </div>
    )
};

export const AgreementModal = ({
    open,
    toggle = () => {},
    headerProps = {},
    bodyProps = {},
    footerProps = {},
    ...props
}) => {


    return (
        <Modal {...props} isOpen={open} toggle={toggle} >
            <ModalHeader toggle={toggle} {...headerProps} >동의서 전문</ModalHeader>
            <ModalBody {...bodyProps} >{agreementText}</ModalBody>
            <ModalFooter {...footerProps} >
                <Button color="primary" onClick={toggle}>확인</Button>
            </ModalFooter>
        </Modal>
    )
}

export function getFirstPage(ride_location) {
    let start_loc = "제주공항";
    let end_loc = "쏘카 스테이션 제주";
    if (ride_location !== "airport") {
        start_loc = "쏘카 스테이션 제주";
        end_loc = "제주공항";
    }
    return (
        <div>
            <div style={{
                padding: "0",
                width: "100vw",
                height: "max(65vh, 125vw)",
                backgroundColor: "#4969BA",
                boxShadow: "0px 3px 6px #0000003B"
            }}>
                <p
                    style={{
                        paddingTop: "min(5vh, 5vw)",
                        paddingLeft: "5vw",
                        fontSize: "6vw",
                        fontWeight: "600",
                        color: "#FFFEFE",
                        textShadow: "0px 3px 6px #0000003B"
                    }}
                >
                    For The Safety,<br /> Beyond The Safety
                </p>
                <p style={{
                    margin: "0",
                    paddingTop: "min(3vh, 4vw)",
                    paddingLeft: "5vw",
                    paddingBottom: "0",
                    fontSize: "5vw",
                    fontWeight: "500",
                    color: "#1F1C2A"
                }}>
                    보다 안전한 자율주행 모빌리티
                </p>
                <div style={{ position: "relative" }}>
                    <div>
                        <img src='/RF_LOGO_WHITE.png'
                            style={{
                                margin: "0",
                                paddingTop: "0.5vh",
                                paddingLeft: "6vw",
                                width: "50vw"
                            }} alt="Smiley face" />
                        <br />
                        <p
                            style={{
                                paddingLeft: "6vw",
                                paddingTop: "1vh",
                                color: "#FBF9F9",
                                fontSize: "5vw"
                            }}>with <img src='/socar.png'
                                style={{
                                    height: "3vh"
                                }} alt="Smiley face" /></p>
                    </div>
                    <div style={{
                        position: "absolute",
                        top: "8vh"
                    }}>
                        <img src='/rideflux_car.png'
                            style={{
                                margin: "0",
                                paddingTop: "0",
                                paddingLeft: "30vw",
                                width: "90vw"
                            }} alt="Smiley face" />
                    </div>
                </div>
                <div style={{
                    paddingTop: "40vw",
                    paddingLeft: "5vw",
                    paddingBottom: "3vh"
                }}>
                    <div style={{
                        width: "90vw",
                        height: "15vw",
                        borderRadius: "8px",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 12px 16px #455B6314",
                        position: "relative"
                    }}>
                        <div
                            style={{
                                top: "3vw",
                                color: "#454F63",
                                margin: "0",
                                position: "absolute",
                                fontSize: "4.5vw",
                                textAlign: "center",
                                fontWeight: "600"
                            }}>
                            <span style={{ fontSize: "5vw", paddingLeft: "5vw", paddingRight: "5vw" }}><MdGpsFixed /></span>
                            <span style={{ fontWeight: "bold", paddingLeft: "3vw", paddingRight: "3vw", color: "#78849E" }}> {start_loc} </span>
                            <span style={{ color: "#78849E" }}><BsArrowRight /></span>
                            <span style={{ fontWeight: "bold", paddingLeft: "3vw", paddingRight: "3vw", color: "#454F63" }}>{end_loc}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>);
}

export function getUnavailablePage(ride_location, car_position, wait_status) {
    let start_loc = "제주공항";
    let end_loc = "쏘카 스테이션 제주";
    if (ride_location !== "airport") {
        end_loc = "제주공항";
        start_loc = "쏘카 스테이션 제주";
    }
    // TODO add 
    let info_based_on_status = (<div>
        <Alert color="danger" style={{ width: "85vw", marginLeft: "8.5vw", marginTop: "3vh", fontSize: "4vw" }}>신규 서비스 확장 및 신규 기술의 적용 <br/>준비로 인해 비정기적으로 운행 중입니다</Alert>
        <div style={{ color: "#454F63", fontWeight: "900", fontSize: "5vw", paddingLeft: "10vw", paddingTop: "1vh" }}>지금은 탑승 가능한 차량이 없습니다.</div>
        <div style={{ color: "#454F63", fontSize: "4vw", paddingLeft: "10vw", paddingTop: "2vh" }}>지금은 차량 운행이 불가하거나</div>
        <div style={{ color: "#454F63", fontSize: "4vw", paddingLeft: "10vw" }}>운영시간이 아닙니다.</div>
        <div style={{ color: "#454F63", fontSize: "4vw", paddingLeft: "10vw" }}>쏘카셔틀을 이용해 주세요</div>
    </div>);
    if (wait_status === "SOON_SERVICE") {
        info_based_on_status = (<div>
            <div style={{ color: "#454F63", fontWeight: "900", fontSize: "5vw", paddingLeft: "10vw", paddingTop: "3vh" }}>지금은 차량이 운행 중입니다.</div>
            <div style={{ color: "#454F63", fontSize: "4vw", paddingLeft: "10vw" }}>잠시 후 다시 시도해주세요.</div>
            <div style={{ paddingTop: "3vh", paddingLeft: "10vw", width: "90vw" }}>
                <div style={{ color: "#454F63", fontWeight: "900", fontSize: "4vw" }}>차량 현위치 확인하기</div>
                <MapContainer car_position={car_position} />
            </div>
        </div>);
    } else if (wait_status === "SERVICE") {
        info_based_on_status = (<div>
            <div style={{ color: "#454F63", fontWeight: "900", fontSize: "5vw", paddingLeft: "10vw", paddingTop: "3vh" }}>지금은 차량이 운행 중입니다.</div>
            <div style={{ color: "#454F63", fontSize: "3.5vw", paddingLeft: "10vw", paddingTop: "1vh" }}>계속해서 이용 가능한 차량이 없는 경우</div>
            <div style={{ color: "#454F63", fontSize: "3.5vw", paddingLeft: "10vw" }}>쏘카셔틀을 이용해 주세요</div>
            <div style={{ paddingTop: "3vh", paddingLeft: "10vw", width: "90vw" }}>
                <div style={{ color: "#454F63", fontWeight: "900", fontSize: "4vw" }}>차량 현위치 확인하기</div>
                <MapContainer car_position={car_position} />
            </div>
        </div>);
    }
    return (
        <div>
            <div style={{
                padding: "0",
                width: "100vw",
                height: "max(25vh, 55vw)",
                backgroundColor: "#4969BA",
                paddingTop: "5vh"
            }}>
                <div>
                    <div style={{ width: "20vw", float: "left", display: "inline-block", margin: "0", padding: "0", paddingTop: "1vh" }}>
                        <img src='/rideflux_car.png'
                            style={{
                                paddingLeft: "3vh",
                                width: "28vw"
                            }} alt="Smiley face" />
                    </div>
                    <div style={{ width: "75vw", float: "right", display: "inline-block", margin: "0", padding: "0", paddingTop: "1vh" }}>
                        <div style={{
                            paddingLeft: "8vw",
                            fontSize: "4.5vw",
                            fontWeight: "600",
                            color: "#1F1C2A"
                        }}>보다 안전한 자율주행 모빌리티
                            <div style={{
                                color: "#FBF9F9"
                            }}>
                                <img src='/RF_LOGO_WHITE.png'
                                    style={{
                                        height: "7vw"
                                    }} alt="Smiley face" />
                                <span>
                                    {' '} with
                                    <img src='/socar.png'
                                        style={{
                                            height: "4.5vw"
                                        }} alt="Smiley face" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    paddingTop: "25vw",
                    paddingBottom: "3vh",
                    paddingLeft: "5vw"
                }}>
                    <div style={{
                        width: "90vw",
                        height: "13vw",
                        borderRadius: "8px",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 12px 16px #455B6314",
                        position: "relative"
                    }}>
                        <div
                            style={{
                                top: "2.5vw",
                                color: "#454F63",
                                margin: "0",
                                position: "absolute",
                                fontSize: "4.5vw",
                                textAlign: "center",
                                fontWeight: "600"
                            }}>
                            <span style={{ fontSize: "5vw", paddingLeft: "5vw", paddingRight: "5vw" }}><MdGpsFixed /></span>
                            <span style={{ fontWeight: "bold", paddingLeft: "3vw", paddingRight: "3vw", color: "#78849E" }}> {start_loc} </span>
                            <span style={{ color: "#78849E" }}><BsArrowRight /></span>
                            <span style={{ fontWeight: "bold", paddingLeft: "3vw", paddingRight: "3vw", color: "#454F63" }}>{end_loc}</span>
                        </div>
                    </div>
                </div>
            </div>
            {info_based_on_status}
            <div style={{ color: "#454F63", fontSize: "3.5vw", fontWeight: "900", paddingLeft: "10vw", paddingTop: "3vh" }}>자율주행 셔틀 이용안내</div>
            <div style={{ width: "88vw", paddingLeft: "11vw", paddingTop: "1vh" }}>
                <Card body inverse style={{ backgroundColor: '#f5f5f5', margin: 0, padding: 5 }}>
                    <CardText>

                        <div style={{ color: "#454F63", fontSize: "3vw", paddingLeft: "3vw" }}><b>운영기간</b></div>
                        <div style={{ color: "#454F63", fontSize: "3vw", paddingLeft: "3.3vw" }}>2020년 5월 18일 ~ 별도 고지 시까지</div>
                        <div style={{ color: "#454F63", fontSize: "3vw", paddingLeft: "3vw", paddingTop: "0.5vh" }}><b>운영시간</b></div>
                        <div style={{ color: "#454F63", fontSize: "3vw", paddingLeft: "3.3vw" }}>평일 오전 8시 30분 ~ 오전 11시 30분</div>
                        <div style={{ color: "#454F63", fontSize: "3vw", paddingLeft: "3.3vw" }}>평일 오후 5시 30분 ~ 오후 8시 30분</div>
                        <div style={{ color: "#454F63", fontSize: "3vw", paddingLeft: "3vw", paddingTop: "0.5vh" }}>* 차량 점검 등 운영사의 사정으로 인해</div>
                        <div style={{ color: "#454F63", fontSize: "3vw", paddingLeft: "6.3vw" }}>사전고지 없이 일시 중단될 수 있습니다</div>
                    </CardText>
                </Card>
            </div>
            <div style={{ color: "#454F63", fontSize: "2.8vw", paddingLeft: "10vw", paddingTop: "8vh" }}>
                보다 안전한 차세대 이동수단<br />
                자율주행 모빌리티 서비스</div>
            <div style={{ color: "#454F63", fontSize: "2.8vw", paddingLeft: "10vw", paddingTop: "1vh" }}> 라이드플럭스가 만들어가고 있습니다.</div>
            <a href="https://rideflux.com"
                style={{ color: "#5185EE", fontSize: "2.8vw", paddingLeft: "10vw" }}>{"홈페이지 방문하기 >"} </a>
            <div style={{ paddingLeft: "38vw", paddingTop: "3vh", color: "lightgrey", fontSize: "3.5vw" }}>ⓒ Rideflux Inc.</div>
        </div >);
}

export function getMapImg(map_img_path) {
    return (<img src={map_img_path} style={{
        width: "90vw",
        paddingTop: "4vh",
        paddingBottom: "1vh",
        paddingLeft: "10vw"
    }} alt="Smiley face" />);
}

export function getInfoPage(call_location) {
    let arrival_location = "제주공항";
    let map_img_path = '/socarMap.png';
    if (call_location === "제주공항") {
        arrival_location = "쏘카 스테이션 제주";
        map_img_path = '/airportMap.png';
    } else {
        call_location = "쏘카 스테이션 제주";
    }
    return (
        <div>
            <div style={{
                padding: "0",
                width: "100vw",
                height: "max(25vh, 55vw)",
                backgroundColor: "#4969BA",
                paddingTop: "5vh"
            }}>
                <div>
                    <div style={{ width: "20vw", float: "left", display: "inline-block", margin: "0", padding: "0", paddingTop: "1vh" }}>
                        <img src='/rideflux_car.png'
                            style={{
                                paddingLeft: "3vh",
                                width: "28vw"
                            }} alt="Smiley face" />
                    </div>
                    <div style={{ width: "75vw", float: "right", display: "inline-block", margin: "0", padding: "0", paddingTop: "1vh" }}>
                        <div style={{
                            paddingLeft: "8vw",
                            fontSize: "4.5vw",
                            fontWeight: "600",
                            color: "#1F1C2A"
                        }}>보다 안전한 자율주행 모빌리티
                            <div style={{
                                color: "#FBF9F9"
                            }}>
                                <img src='/RF_LOGO_WHITE.png'
                                    style={{
                                        height: "7vw"
                                    }} alt="Smiley face" />
                                <span>
                                    {' '} with
                                    <img src='/socar.png'
                                        style={{
                                            height: "4.5vw"
                                        }} alt="Smiley face" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    paddingTop: "25vw",
                    paddingBottom: "3vh",
                    paddingLeft: "5vw"
                }}>
                    <div style={{
                        width: "90vw",
                        height: "13vw",
                        borderRadius: "8px",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 12px 16px #455B6314",
                        position: "relative"
                    }}>
                        <div
                            style={{
                                top: "2.5vw",
                                color: "#454F63",
                                margin: "0",
                                position: "absolute",
                                fontSize: "4.5vw",
                                textAlign: "center",
                                fontWeight: "600"
                            }}>
                            <span style={{ fontSize: "5vw", paddingLeft: "5vw", paddingRight: "5vw" }}><MdGpsFixed /></span>
                            <span style={{ paddingLeft: "3vw", paddingRight: "3vw", color: "#78849E" }}> {call_location} </span>
                            <span style={{ color: "#78849E" }}><BsArrowRight /></span>
                            <span style={{ paddingLeft: "3vw", paddingRight: "3vw", color: "#454F63" }}>{arrival_location}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ fontWeight: "900" }}>
                <div style={{ paddingLeft: "6vw", color: "#b30000", fontSize: "4.5vw", fontWeight: "400", paddingTop: "3vh" }}>예약완료 후
                    <span style={{ fontWeight: "900" }}> 5분</span> 이내 탑승하지 않을 시</div>
                <div style={{ paddingLeft: "6vw", color: "#b30000", fontSize: "4.5vw", fontWeight: "400" }}>예약이 취소됩니다. 탑승 장소로 이동해주세요.</div>
            </div>
            <div style={{ paddingTop: "5vh", paddingLeft: "10vw", color: "#454F63", fontSize: "4vw", fontWeight: "900" }}>탑승장소</div>
            {getMapImg(map_img_path)}
            <div style={{ paddingLeft: "38vw", paddingTop: "3vh", color: "lightgrey", fontSize: "3.5vw" }}>ⓒ Rideflux Inc.</div>
        </div >);
}

export function isUnderAge(birthdate, age = 19) {
    return (new Date() - new Date(birthdate)) / 31557600000.0 < age;
}

export function isChild(birthdate, age = 7) {
    return (new Date() - new Date(birthdate)) / 31557600000.0 < age;
}

export function calcETA(length, velocity=20){
    if(!!!length || length < 0) return undefined;
    return Math.ceil(Math.abs(length)/(velocity*1000/60));
}