import React from 'react';
import { FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';

const CustomTextInput1 = ({
    className = "",
    titleClassName = "",
    formtextClassName = "",
    formFeedbackClassName = "",
    InputClassName = "",
    type = 'text',
    title = "",
    infoText = "",
    invalidText="",
    id="text-input",
    onChange = () => {},
    ...props
}) => {
    


    return <FormGroup className={`custom-text-shape ${className}`} onChange={onChange}>
        {!!title.length > 0 ? 
            <Label className={`${titleClassName}`} >
                {title}
            </Label> : null
        }
        <Input className={`${InputClassName}`} type={type} id={id} {...props} />
        {!!infoText.length > 0 ? 
            <FormText className={`${formtextClassName}`}>
                {infoText}
            </FormText>: null
        }
        {!!invalidText.length > 0 ? 
            <FormFeedback invalid className={`${formFeedbackClassName}`}>
                {invalidText}
            </FormFeedback>: null
        }
    </FormGroup>
}

export default CustomTextInput1;
