export function deg2rad(deg) {
    return deg * (Math.PI/180)
  }
export function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  let R = 6371;
  let dLat = deg2rad(lat2-lat1);
  let dLon = deg2rad(lon2-lon1); 
  let a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  return R * c;
}
export function toStandardLocation (location) {
    if (Array.isArray(location)) {
        return {
            latitude: location[0],
            longitude: location[1],
        };
    }
    else if (typeof location === 'object' && !!location) {
        if (location.hasOwnProperty('x')) {
            return {
                latitude: location.y, longitude: location.x
            };
        }
        else if (location.hasOwnProperty('lat')){
            return {
                latitude: location.lat, longitude: location.lng !== undefined ? location.lng : location.lon
            };
        }
        else if (location.hasOwnProperty('latitude')){
            return location;
        }
    }
    throw "invalid location type";
}
export function toDBLocaleString(date_any_type) {
    let date;
    if (date instanceof Date) {
        date = date_any_type
    }
    else {
        date = new Date(date_any_type);
    }
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${String(date.getHours()).padStart(2,'0')}:${String(date.getMinutes()).padStart(2,'0')}:${String(date.getSeconds()).padStart(2,'0')}`
}