import React, { Children, createElement, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import ReactDOM  from 'react-dom'
import { addEventHandle, toLatLng } from "./Util";
import { KakaoMapContext } from "./KakaoMap";

let IDNumber = 0

const KakaoCustomOverlay = ({
    children: content,
    contentID,
    clickable,
    position,
    xAnchor,
    yAnchor,
    zIndex,

    draggable,

    altitude,
    range,

    onClick ,
    onMouseOver,
    onMouseOut,
    onRightClick,
    onDragStart,
    onDragEnd,
    onTrigger = [],
    visible = true,
    ...props
}) => {
    const { map, loaded } = useContext(KakaoMapContext);
    const { current: randomNum } = useRef(`${Math.random()}`.replace("0.", ""));
    const { current: realContentID } = useRef(`customOverlayContent_${contentID || randomNum}_${IDNumber++}`)

    const customOverlay = useMemo(() => {
        if (!!!map || !loaded || !window.kakao) {
            return null;
        }
        return new window.kakao.maps.CustomOverlay({
            map, clickable, content: `<div id=${realContentID} position="absolute"></div>`
        });
    }, [loaded, window.kakao, toLatLng, clickable, realContentID]);

    // const onMouseMove = useCallback((startPoint, customOverlay) => {
    //     return function (e) {
    //         if (e.preventDefault) {
    //             e.preventDefault();
    //         } else {
    //             e.returnValue = false;
    //         }
        
    //         const proj = map.getProjection();
    //         const deltaX = startPoint.x - e.clientX; 
    //         const deltaY = startPoint.y - e.clientY;
    //         const newPoint = new window.kakao.maps.Point(startPoint.overlayPoint.x - deltaX, startPoint.overlayPoint.y - deltaY);
    //         const newPos = proj.coordsFromContainerPoint(newPoint);
        
    //         // 커스텀 오버레이의 좌표를 설정합니다 
    //         customOverlay.setPosition(newPos);
    //     }
    // }, []);

    // const onMouseDown = useCallback((custom_overlay) => {
    //     if (!custom_overlay) {
    //         return (e) => {
    //             console.error("not loaded")
    //         }
    //     }
    //     return (e) => {
    //         if (e.preventDefault) {
    //             e.preventDefault();
    //         } else {
    //             e.returnValue = false;
    //         }
        
    //         const proj = map.getProjection(); 
    //         const overlayPos = custom_overlay.getPosition();
        
    //         // 커스텀오버레이에서 마우스 관련 이벤트가 발생해도 지도가 움직이지 않도록 합니다
    //         if (!window.kakao) {
    //             return;
    //         }
    //         window.kakao.maps.event.preventMap();
        
    //         // mousedown된 좌표를 설정합니다 
    //         let startX = e.clientX; 
    //         let startY = e.clientY;
        
    //         // mousedown됐을 때의 커스텀 오버레이의 좌표를
    //         // 지도 컨테이너내 픽셀 좌표로 변환합니다 
    //         let startOverlayPoint = proj.containerPointFromCoords(overlayPos);
        
    //         // document에 mousemove 이벤트를 등록합니다 
    //         addEventHandle(document, 'mousemove', onMouseMove({
    //             x: startX, y: startY, overlayPoint: startOverlayPoint
    //         }, custom_overlay));
    //     }
    // }, [onMouseMove]);

    useLayoutEffect(() => {
        if (customOverlay && position) {
            const p = toLatLng(position);
            if (p) {
                customOverlay.setPosition(p);
            }
        }
    }, [customOverlay, position, toLatLng]);

    useLayoutEffect(() => {
        if (customOverlay) {
            customOverlay.setVisible(visible);
        }
    }, [customOverlay, visible]);

    // 기존 setContent 부분
    // useLayoutEffect(() => {
    //     if (!customOverlay || !loaded || !content) {
    //         return;
    //     }
    //     customOverlay.setContent(content);
    // }, [customOverlay, content, loaded]);

    useLayoutEffect(() => {
        if (customOverlay) {
            customOverlay.setAltitude(altitude);
        }
    }, [customOverlay, altitude]);
    useLayoutEffect(() => {
        if (customOverlay && typeof(range) === 'number') {
            customOverlay.setRange(range);
        }
    }, [customOverlay, range]);
    useLayoutEffect(() => {
        if (customOverlay && typeof(zIndex) === 'number') {
            customOverlay.setZIndex(zIndex);
        }
    }, [customOverlay, zIndex]);

    // useLayoutEffect(() => {
    //     if (customOverlay) {
    //         addEventHandle(content, 'mousedown', onMouseDown);
    //         addEventHandle(document, 'mouseup', onMouseUp);
    //     }
    // }, [customOverlay, content, draggable]);

    useLayoutEffect(() => {
    },[]);

    useEffect(() => {
        return () => {
            if (customOverlay) {
                customOverlay.setMap(null);
            }
        }
    }, [customOverlay]);

    const contentElement = useMemo(() => {
        const element = document.createElement('div');
        element.id = realContentID;
        return element;
    }, [realContentID]);

    useLayoutEffect(() => {
        if (customOverlay && contentElement) {
            customOverlay.setContent(contentElement);
        }
    }, [customOverlay, contentElement]);

    return !!contentElement && content ? ReactDOM.createPortal(content, contentElement) : null;
};

export default KakaoCustomOverlay;