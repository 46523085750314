import axios from "axios";
import React, { useEffect, useMemo, useRef, useState, useContext, createContext, useCallback } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { withRouter } from "react-router-dom";
import { Button, Collapse, FormGroup, Label, Modal, ModalBody } from 'reactstrap';
import { AgreementShorTextComponent } from '../Component/Agreement';
import { isUnderAge } from "../Component/Common";
import CustomRadioButton1 from '../Component/CustomRadioButton1';
import CustomTextInput1 from '../Component/CustomTextInput1';

const RegisterInfo = createContext({
    agreement: false,
    personalInfo: false,
    name: undefined,
    birthDate: undefined,
    phone: undefined,
    survey: {
        address: null,
        purpose: null,
        know_path: null,
        is_related_job: null,
        related_job: null,
    },

    setAgreement: () => {},
    setPersonalInfo: () => {},
    setName: () => {},
    setBirthDate: () => {},
    setPhone: () => {},
});
const birthDateChecker = RegExp(/(\d{4})+[ -]?(0[1-9]|1[0-2])+[ -]?(0[1-9]|[12][0-9]|3[01])+/);
const phoneTest = RegExp(/(\d{3})-(\d{3,4})-(\d{3,})/);
const phoneChecker = RegExp(/(\d{0,3})?[- ]?(\d{3,4})?[- ]?(\d{0,4})?/);

const Register = ({
    history,
    location,
    ...props
}) => {
    const END_PAGE = 3;
    const closeEvent = new CustomEvent('registered', undefined);
    const [page, setPage] = useState(1);

    const [agreement, setAgreement] = useState(false);
    const [personalInfo, setPersonalInfo] = useState(false);

    const [name, setName] = useState(undefined);
    const [birthDate, setBirthDate] = useState(undefined);
    const [phone, setPhone] = useState(undefined);

    const [openCompletedModal, setOpenCompletedModal] = useState(false);

    const [survey, setSurvey] = useState({
        address: null,
        purpose: null,
        know_path: null,
        is_related_job: null,
        related_job: null,
    });

    const context = {
        agreement: agreement,
        personalInfo: personalInfo,
        name: name,
        birthDate: birthDate,
        phone: phone,
        survey: survey,
        setAgreement,
        setPersonalInfo,
        setName,
        setBirthDate,
        setPhone,
        setSurvey,
    };

    const registerUser = useCallback(async (e) => {
        if (!(agreement && personalInfo)) {
            console.error("agreement", agreement, "personalInfo", personalInfo)
            return;
        }

        const register_result = await axios.post("/api/tamra/user/register-user",{
            name: name,
            phone: phone,
            birthdate: birthDate,
            address: survey.address,
            purpose: survey.purpose,
            related_job: survey.is_related_job === 'yes' ? survey.related_job : undefined,
            know_path: survey.know_path,
        }).then(res => {
            return res.data;
        }).catch(err => {
            console.error(err);
            alert("제출에 실패했습니다.");
            return {
                success: false,
                msg: [undefined, ''],
            };
        });
        if (register_result.success) {
            setOpenCompletedModal(true);
        }
        else {
            const error_num = register_result.msg?.[0];
            if (error_num === 1062) {
                alert("이미 등록된 고객(휴대번호)입니다.\n추가 탑승 동의 없이 서비스를 이용하실 수 있습니다.");
            }
        }

    }, [agreement, personalInfo, name, birthDate, phone, survey]);

    const onClick = useCallback((event) => {
        if (page < END_PAGE) {
            setPage( p => p + 1);
        }
        else {
            registerUser(event);
        }
    }, [page, registerUser]);

    const goBack = useCallback((e) => {
        if (page === 1) {
            window.close();
        }
        else {
            setPage(page - 1);
        }
    }, [page, history]);

    const contents = useMemo(() => {
        switch (page) {
            case 1:
                return <Notification />
            case 2:
                return <PersonalInfo />
            case 3:
                return <Survey 
                />
        }
    }, [page]);

    const btn_disabled = useMemo(() => {
        let able = true;
        switch (page) {
            case 3:
                let is_valid_related_job = survey.is_related_job === 'yes' ? !!survey.related_job : !!survey.is_related_job;
                able = able && (!!survey.address && !!survey.purpose && !!survey.know_path && !!survey.is_related_job && is_valid_related_job);
            case 2:
                const birthDateCheck = birthDateChecker.test(birthDate) && !isUnderAge(birthDate);
                const phoneCheck = phoneTest.test(phone)
                able = able && (name && isNaN(name) && birthDateCheck && phoneCheck);
            case 1:
                able = able && (agreement && personalInfo);
            default  :
        }
        return !able;
    }, [page, agreement, personalInfo, name, birthDate, phone, survey]);

    const skip_button = useMemo(() => {
        if (page !== 3) {
            return null;
        }
        return (
            <div
                className='skip-button'
                onClick={registerUser}
            >
                건너뛰기
            </div>
        )
    }, [page, registerUser]);

    useEffect(() => {

    }, []);


    return (
    <RegisterInfo.Provider value={context} >
        <div className='font-SUIT tamra-register'>
            <AiOutlineLeft
                style={{cursor: 'pointer', marginBottom: "17px"}}
                onClick={goBack}
            />
            <Index 
                index={page}
                indexNumber={END_PAGE}
                />
            {/* <CustomRadioButton1 /> */}
            {contents}
            {skip_button}
            <Button className="tamra-confirm-button"
                disabled={btn_disabled}
                onClick={onClick}
            >
                {page !== END_PAGE ? "다음으로" : "제출하기"}
            </Button>
        </div>
        <Modal
            isOpen={openCompletedModal}
            centered 
            toggle={() => setOpenCompletedModal(open => !open)}
            className="font-SUIT tamra-register-modal"
        >
            <ModalBody>
                <img src="/check_circle.svg" />
                <div>
                최초 탑승 정보 등록이<br/>완료되었습니다
                </div>
                <Button onClick={() => {
                    setOpenCompletedModal(false);
                    window.dispatchEvent(closeEvent);
                }}>
                    확인
                </Button>
            </ModalBody>
        </Modal>
    </RegisterInfo.Provider>)
}

export default withRouter(Register);;


const Notification = () => {

    const { 
        agreement,
        setAgreement,
        personalInfo,
        setPersonalInfo,
    } = useContext(RegisterInfo);


    return <div className='wrapper'>
        <div className='title'>
            탑승 전, <br/>
            안내사항을 확인해주세요.
        </div>
        <AgreementShorTextComponent 
            className='agreement'
            modalProps={{
                headerProps: {visible: false},
                contentClassName: "tamra-agreement-modal-content",
                modalClassName: "font-SUIT tamra-agreement-modal",
            }}
        />
        <div className='custom-checkbox-shape'>
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    setAgreement(!agreement)}
                }
                active={agreement}
            >
                <div>
                    (필수) <br/>
                    동의사항을 숙지하였으며 이에 동의합니다.
                </div>
                <CustomRadioButton1 
                    type="checkbox"
                    checked={agreement}
                    readOnly
                />
            </Button>
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    setPersonalInfo(!personalInfo)}
                }
                active={personalInfo}
            >
                <div>
                    (필수) <br/>
                    개인정보 수집 및 이용에 동의합니다.
                </div>
                <CustomRadioButton1 
                    type="checkbox"
                    checked={personalInfo}
                    readOnly
                />
            </Button>
        </div>
    </div>;
}

const PersonalInfo = () => {

    const { 
        name,
        birthDate,
        phone,
        setName,
        setBirthDate,
        setPhone,
    } = useContext(RegisterInfo);

    const [validBirthDate, setValidBirthDate] = useState(false);
    const [invalidBirthDate, setInvalidBirthDate] = useState(false);
    const [underAge, setUnderAge] = useState(false);

    // const [validPhone, setValidPhone] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);

    const dateReplacer = useCallback((match, year, month, date, offset, string) => {
        return [year, month, date].join('-');
    },[]);
    const phoneReplacer = useCallback((match, first, second, third, offset, string) => {
        return [first, second, third].filter(a => !!a).join('-');
    },[]);

    useEffect(() => {
        setUnderAge(isUnderAge(birthDate, 14));
    }, [birthDate]);

    return <div className='wrapper'>
        <div className='title'>
            개인정보를 입력해주세요.
        </div>
        <div className="personal-info-box">
            <CustomTextInput1 
                onChange={(e) => {
                    setName(e.target.value)
                }}
                onBlur={() => setName((prev) => prev?.trim())}
                title={"이름"}
                placeholder={"이름을 입력해주세요"}
                invalid={!isNaN(name)}
                value={name}
                id={"name"}
            />
            <CustomTextInput1 
                className=''
                onChange={(e) => {
                    const value = e.target.value;
                    const test = birthDateChecker.test(value);

                    if (!value) {
                        setValidBirthDate(false);
                        setInvalidBirthDate(false);
                    }
                    else {
                        setValidBirthDate(test);
                        setInvalidBirthDate(!test);
                    }

                    setBirthDate(value.replace(birthDateChecker, dateReplacer));
                }}
                title={"생년월일"}
                placeholder={"생년월일을 입력해주세요"}
                infoText={"예시 : 2023 01 01"}
                valid={validBirthDate && !underAge}
                invalid={invalidBirthDate || underAge}
                invalidText={underAge ? "만 14세 미만의 미성년자는 차량 탑승시 현장에서 동의를 진행해주세요" : "생년월일을 확인해주세요 예시 : 2022-01-01"}
                id={"birthdate"}
                value={birthDate}
            />
            <CustomTextInput1 
                className=''
                onChange={(e) => {
                    const value = e.target.value;
                    const test = phoneTest.test(value);
                    const input_phone = value.replace(phoneChecker, phoneReplacer);
                    setPhone(input_phone);
                    setInvalidPhone(!test);
                }}
                title={"전화번호"}
                infoText={"*예약 완료 시 해당 번호로 문자가 발송됩니다"}
                placeholder={"전화번호를 입력해주세요"}
                invalid={invalidPhone}
                id={"phone"}
                value={phone}
            />
        </div>
    </div>;
};

const Survey = ({
    onSkip = () => {},
    ...props
}) => {
    const purpose = ["여행지 이동", "업무", "가족/친구 방문", "워케이션", "기타"];
    const know_path = ["인터넷", "지인 소개", "온라인 광고", "오프라인", "광고", "기타"];

    const { 
        survey,
        setSurvey,
    } = useContext(RegisterInfo);

    return <div className='wrapper'>
        <div className='title-wrapper'>
            <div className='title'>
                설문조사에 응답해주세요. <small>(선택)</small>
            </div>
            <div className='sub-title'>
                답변해주신 내용은<br/>
                더 나은 서비스 제공을 위해 활용됩니다.
            </div>
        </div>
        <div className="survey-box">
            <CustomTextInput1
                onChange={(e) => {
                    const value = e.target.value;
                    setSurvey(s => {
                        return {
                            ...s,
                            address: value,
                        }
                    });
                }}
                title={"1. 거주 도시"}
                placeholder={"시/군/구"}
                id={"adress"}
                value={survey.address}
                // valid={!!name}
            />
            <FormGroup onChange={(e) => {
                const value = e.target.value;
                setSurvey(s => {
                    return {
                        ...s,
                        purpose: value,
                    }
                });
            }}>
                <Label>2. 탑승 목적</Label>
                {purpose.map(purpose => 
                <CustomRadioButton1 
                    text={purpose}
                    name={'purpose'}
                    key={`purpose-${purpose}`}
                    id={`purpose-${purpose}`}
                    value={purpose}
                    checked={survey.purpose === purpose}
                />)}
            </FormGroup>
            <FormGroup onChange={(e) => {
                const value = e.target.value;
                setSurvey(s => {
                    return {
                        ...s,
                        know_path: value,
                    }
                });
            }}>
                <Label>3. 서비스를 알게 된 경로</Label>
                {know_path.map(path => 
                <CustomRadioButton1 
                    text={path}
                    key={`path-${path}`}
                    name={'know_path'}
                    id={`know_path-${path}`}
                    value={path}
                    checked={survey.know_path === path}
                />)}
            </FormGroup>
            <FormGroup>
                <Label>4. 자율주행 관련 업종 종사 여부</Label>
                <div className="button-group">
                    <Button 
                        value={'yes'}
                        active={survey.is_related_job === 'yes'}
                        onClick={(e) => {
                            const value = e.target.value;
                            setSurvey(s => {
                                return {
                                    ...s,
                                    is_related_job: value,
                                }
                            });
                        }}
                    >
                        예
                    </Button>
                    <Button 
                        value={'no'}
                        active={survey.is_related_job === 'no'}
                        onClick={(e) => {
                            const value = e.target.value;
                            setSurvey(s => {
                                return {
                                    ...s,
                                    is_related_job: value,
                                }
                            });
                        }}
                    >
                        아니오
                    </Button>
                </div>
                <Collapse isOpen={survey.is_related_job === 'yes'}>
                    <Label>4-1. 회사 이름을 입력해주세요.</Label>
                    <CustomTextInput1 
                        placeholder="회사 이름"
                        onChange={(e) => {
                            const value = e.target.value;
                            setSurvey(s => {
                                return {
                                    ...s,
                                    related_job: value,
                                };
                            });
                        }}
                        id={"company"}
                        value={survey.related_job}
                    />
                </Collapse>
            </FormGroup>
        </div>
    </div>;
};

const Index = ({
    className = '',
    style,
    index,
    indexNumber = 0,
}) => {

    const ref = useRef(null);

    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (ref?.current) {
            setWidth(ref.current.offsetHeight);
        }
    }, [ref]);

    return <div className={`tamra-index ${className}`} style={style} ref={ref}>
        {Array.from(Array(indexNumber)).map((_, idx) => <div
            className={index === idx + 1 ? 'selected' : ''}
            style={{width: width}}
            key={`index-${idx}`}
        >
            <span className=''>
            {idx + 1}
            </span>
            <div />
        </div>)}
    </div>
}
