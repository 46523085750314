import React from 'react';

const CustomRadioButton1 = ({
    className = "",
    labelClassName = "",
    type = 'radio',
    text = "",
    id="label",
    ...props
}) => {
    
    return <div className={`custom-radio-shape ${className}`}>
        <input className="" type={type} id={id} {...props} />
        <label className={labelClassName} htmlFor={id}>
            {text}
        </label>
    </div>
}

export default CustomRadioButton1;
