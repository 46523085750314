/*global kakao*/

import React, { Component } from "react";
import "../../style/SeogwipoMapContent.css"

class SeogwipoMiniMapContainer extends Component {
    constructor(props) {
        super(props);
        this.map = undefined;
        this.departure_marker = undefined;
        this.departure_image = undefined;
        this.destination_marker = undefined;
        this.destination_image = undefined;
        this.global_path = undefined;
        this.global_path_on_map = undefined;
        this.path = undefined;
        this.car_type = "ioniq";
        this.circles = [];
        this.boarding_path = [];
        this.map_changing = false;
    }
    getLatLng(p) {
        return new kakao.maps.LatLng(p[0], p[1]);
    }
    getCenter() {
        if (this.props.departure !== undefined && this.props.departure !== null &&
            this.props.destination !== undefined && this.props.destination !== null) {
            let lat = 0.5 * (this.props.departure[0] + this.props.destination[0]);
            let lng = 0.5 * (this.props.departure[1] + this.props.destination[1]);
            return new kakao.maps.LatLng(lat, lng);
        }
        else if (Array.isArray(this.props.initial_center) && this.props.initial_center.length > 1) {
            return new kakao.maps.LatLng(this.props.initial_center[0], this.props.initial_center[1]);
        }
        else {
            return new kakao.maps.LatLng(33.25335474788911, 126.50898282140602);
        }
    }
    getMarker(latlng, type, size) {
        var imageSrc = '/' + type + '.svg', // 마커이미지의 주소입니다    
            imageSize = new kakao.maps.Size(size,size);
        let imageOption = {offset: new kakao.maps.Point(size/2, size*0.9)};
        this.destination_image = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
        return new kakao.maps.Marker({
            position: this.getLatLng(latlng),
            image: this.destination_image,
            clickable: false,
        });
    }
    getCustomOverlay(latlng, src, txt, txt_className="", size=52){
        var imageSrc = '/' + src + '.svg';
        
        let content = document.createElement("div"), content_img = document.createElement("img"), content_txt = document.createElement("div");
        content.className = "custom-overlay-box"; 
        content_img.className="custom-overlay-img"; 
        content_txt.className = "custom-overlay-text-box "+txt_className;
        content.style.width = (size+"px");
        content_img.src = imageSrc; content_img.style.height = size+"px";
        content_txt.style.fontSize=(Math.ceil(size/3.5))+"px";
        content_txt.appendChild(document.createTextNode(txt));
        content.appendChild(content_img);
        content.appendChild(content_txt);

        return new kakao.maps.CustomOverlay({
            map: this.map,
            clickable: false,
            content: content,
            position: this.getLatLng(latlng),
            xAnchor: 0.5,
            yAnchor: 1,
        })
    }
    updateCustomOverlay(){

    }
    getArrow(depart, destination) {
        let data = [];
        data.push(this.getLatLng(depart));
        data.push(this.getLatLng(destination));
        return new kakao.maps.Polyline({
            path: data,
            strokeWeight: 2,
            strokeColor: "black",
            strokeOpacity: 0.7,
            strokeStyle: 'dash',
            endArrow: true
        })
    }
    getPath(arr, option){
        return new kakao.maps.Polyline({
            path: arr,
            ...option,
        });
    }
    setCenter(center) {
        this.map.setCenter(new kakao.maps.LatLng(center[0], center[1]));
    }
    drawCenter() {
        if (this.props.use_center) {
            let c = this.map.getCenter();
            this.center = [c.getLat(), c.getLng()];
            this.getCenter();
        }
        else{
            this.center = undefined
        }
        if (this.center !== undefined) {
            let center = new kakao.maps.LatLng(this.center[0], this.center[1]);
            if (this.center_marker === undefined) {
                this.center_marker = new kakao.maps.Marker({
                    position: center,
                    zIndex: 5,
                });
                this.center_marker.setMap(this.map);
            } else {
                this.center_marker.setPosition(center);
            }
        }
        else if(!this.props.use_center && this.center_marker !== undefined){
            this.center_marker.setMap(null);
            this.center_marker = undefined;
        }
    }
    drawCar() {
        if ((this.props.car_location === undefined || this.props.car_location === [0, 0, 0]) && this.car_marker !== undefined) {
            this.car_marker.setMap(null);
            this.car_marker = undefined;
        }
        else if (this.props.car_location !== null && this.props.car_location !== undefined) {
            let car_location = new kakao.maps.LatLng(this.props.car_location[0], this.props.car_location[1]);
            if (!!this.props.car_heading || this.props.car_heading === 0){
                let theta = this.props.car_heading
                if (this.car_marker === undefined) {
                    let img = document.createElement('img');
                    img.src = '/car_icon.svg';
                    img.alt = '/'+(!!this.props.car_type ? this.props.car_type : 'ioniq')+'.png';
                    img.style.width = '50px';
                    img.style.height = '50px';
                    img.style.transform = 'rotate(' + (90-theta) + 'deg)';
                    img.style.pointerEvents = "none";
    
                    this.car_marker = new kakao.maps.CustomOverlay({
                        map: this.map,
                        content: img,
                        position: car_location,
                        xAnchor: 0.5, // 컨텐츠의 x 위치
                        yAnchor: 0.5, // 컨텐츠의 y 위치
                    });
                } else {
                    if (!!this.car_marker.getContent){
                        let img = this.car_marker.getContent();
                        img.style.transform = 'rotate(' + (90-theta) + 'deg)';
                        this.car_marker.setPosition(car_location);
                    } else {
                        console.log("car_marker is not overlay")
                        this.car_marker.setMap(null);
                        this.car_marker = undefined;
                    }
                }
            }
            else{
                if (this.car_marker === undefined) {
                    var imageSrc = '/' + (this.props.car_type !== undefined ? this.props.car_type : 'ioniq') + '.png', // 마커이미지의 주소입니다    
                        imageSize = new kakao.maps.Size(35, 40);
                    let imageOption = { offset: new kakao.maps.Point(22.5, 40) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
                    this.car_image = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
                    this.car_marker = new kakao.maps.Marker({
                        position: car_location,
                        image: this.car_image
                    });
                    this.car_marker.setMap(this.map);
                    this.car_marker.setDraggable(false);
                } else {
                    if (!!!this.car_marker.getContent){
                        this.car_marker.setPosition(car_location);
                    } else{
                        console.log("car_marker is not marker")
                        this.car_marker.setMap(null);
                        this.car_marker = undefined;
                    }
                }
            }
        }
    }
    makeBoardingPath(dep, des, option)
    {
        if(dep !== undefined && des !== undefined){
            let options = {
                strokeWeight: 2,
                strokeColor: '#000000', 
                strokeOpacity: 1.0,
                strokeStyle: 'dashdot',
                ...option
            }
            let path = this.makePath([dep,des], options);
            return path;
        }
        return undefined;
    }
    drawCurrentBoardingPath(){
        if(this.props.boarding_point !== undefined && this.center !== undefined && this.boarding_path[0] === undefined){
            let p = this.getLatLng(this.props.boarding_point);
            let idx = this.start_marker === undefined ? 0 : 1;
            let color = {strokeColor: "#fec0c1"};
            this.boarding_path[0] = this.makeBoardingPath(this.getLatLng(this.center), p, color);
            this.boarding_path[0].setMap(this.map);
        }
        else if(this.props.boarding_point === undefined && this.boarding_path[0] !== undefined){
            this.boarding_path[0].setMap(null);
            this.boarding_path[0] = undefined;
        }
    }
    updateCurrentBoardingPath(){
        if(this.props.boarding_point !== undefined && this.center !== undefined && this.boarding_path[0] !== undefined && !this.map_changing){
            let idx = this.start_marker === undefined ? 0 : 1;
            let color = {strokeColor: "#fec0c1"};
            let p = this.getLatLng(this.props.boarding_point);
            this.boarding_path[0].setPath([this.getLatLng(this.center), p]);
            this.boarding_path[0].setOptions(color);
        }
    }
    makePath(arr, option)
    {
        if(arr !== undefined && arr.length !== 0){
            let path = new kakao.maps.Polyline({
                map: this.map,
                path: arr,
            });
            if(option !== undefined)
                path.setOptions(option);
            return path;
        }
        return undefined;
    }
    drawCircles(){
        if(this.props.circle_points !== undefined && this.circles.length === 0){
            let cnt = 0;
            for(let c of this.props.circle_points){
                cnt++;
                if(cnt % 3  !== 0){
                    continue;
                }
                let circle = new kakao.maps.Circle({
                    map: this.map,
                    center : new kakao.maps.LatLng(c[0],c[1]),
                    radius: 1,
                    strokeWeight: 0.5,
                    strokeColor: "#00EEEE",
                    strokeOpacity: 0.5,
                    strokeStyle: 'solid',
                    fillColor: '#00EEEE',
                    fillOpacity: 0.5 
                });
                this.circles.push(circle);
            }
        }
    }
    updateCarToDeparture(option){
        if(this.props.car_to_departure_path !== undefined && this.car_path !== undefined && !this.map_changing){
            let path = this.props.car_to_departure_path.map((val)=>{
                return this.getLatLng(val);
            });
            this.car_path.setPath(path);
            if(option !== undefined)
                this.car_path.setOptions(option);
        }
    }
    updateGlobalPath(option){
        if (this.global_path === this.props.global_path_list) {
            return;
        }
        if (!Array.isArray(this.props.global_path_list)) {
            if(this.global_path_on_map) {
                this.global_path_on_map.setMap(null);
                this.global_path_on_map = undefined;
            }
            if (!this.path) {
                this.path = this.getArrow(this.props.departure, this.props.destination);
                this.path.setMap(this.map);
            }
            return;
        }
        if (this.path) {
            this.path.setMap(null);
            this.path = undefined;
        }
        let default_option = {
            strokeWeight: 4,
            strokeColor: "black",
            strokeOpacity: 0.6,
            // ...this.props.,
        };
        this.global_path = this.props.global_path_list;
        let path = this.props.global_path_list.map((val)=>{
            return this.getLatLng(val);
        });
        if(this.global_path_on_map) {
            this.global_path_on_map.setPath(path);
        }
        else {
            this.global_path_on_map = this.getPath(path, option ? option : default_option);
            this.global_path_on_map.setMap(this.map);
        }
    }
    updateIdleState(){
        // this.drawCurrentBoardingLocation();
        this.updateCurrentBoardingPath();
        this.updateCarToDeparture();
        this.updateGlobalPath();
    }
    async updateBoardingLocation(){
        let loc = this.map.getCenter();
        await this.props.idleCallback([loc.getLat(), loc.getLng()]).then(()=>{
            this.updateIdleState();
        });
    }
    drawCarToDeparture(){
        if(this.props.car_to_departure_path !== undefined){
            let path = this.props.car_to_departure_path.map((val)=>{
                return this.getLatLng(val);
            });
            if(this.car_path === undefined){
                let option = {strokeColor : "#FF0000", strokeOpacity: 0.6};
                this.car_path = this.makePath(path, option);
            }
        }
        else if(this.props.car_to_departure_path === undefined && this.car_path !== undefined){
            this.car_path.setMap(null);
            this.car_path = undefined;
        }
    }
    componentDidMount() {
        const script = document.createElement("script");
        script.async = true;
        script.src =
            "https://dapi.kakao.com/v2/maps/sdk.js?appkey=44e205bb2875a6334dd7602d2e5c004d&autoload=false";
        document.head.appendChild(script);

        script.onload = () => {
            kakao.maps.load(() => {
                let container = document.getElementById("base_mini_map");
                let options = {
                    center: this.getCenter(),
                    level: 5
                };
                this.map = new window.kakao.maps.Map(container, options);
                this.drawCar();
                this.drawCenter();
                this.drawCircles();
                this.drawCarToDeparture();
                // this.drawCurrentBoardingPath();
                if (!!this.props.departure && !!this.props.destination) {

                    this.departure_marker = this.getMarker(this.props.departure, "departure",52).setMap(this.map);
                    if((this.props.ETA ?? -1) >= 0){
                        let eta_txt = this.props.ETA > 0 ? this.props.ETA+"분 소요" : "곧 도착";
                        this.destination_marker = this.getCustomOverlay(this.props.destination, "markers/arrival", eta_txt, "arrival", 52);
                    }
                    else{
                        this.destination_marker = this.getMarker(this.props.destination, "arrival",52).setMap(this.map);
                    }
                    
                    if(!!this.props.global_path_list){
                        this.updateGlobalPath();
                    }
                    else {
                        this.path = this.getArrow(this.props.departure, this.props.destination);
                        this.path.setMap(this.map);
                    }

                    let bounds = new kakao.maps.LatLngBounds();
                    bounds.extend(this.getLatLng(this.props.departure));
                    bounds.extend(this.getLatLng(this.props.destination));
                    this.map.setBounds(bounds);
                }


                kakao.maps.event.addListener(this.map, "center_changed", () => {
                    if (this.map !== undefined && this.props.use_center && this.props.centerChangeCallback!==undefined) {
                        if (this.zoom_center !== undefined) {
                            this.map.setCenter(this.zoom_center);
                            this.zoom_center = undefined;
                        }
                        this.center = [this.map.getCenter().getLat(), this.map.getCenter().getLng()];
                        this.props.centerChangeCallback(this.center);
                    }
                });
                kakao.maps.event.addListener(this.map, 'zoom_start', () => {
                    if (this.map !== undefined && this.props.use_center) {
                        if(this.zoom_center === undefined) 
                            this.zoom_center = this.map.getCenter();
                    }
                });
                kakao.maps.event.addListener(this.map, 'idle', () => {
                    this.map_changing = false;
                    if(this.map !== undefined && this.props.use_center && this.props.idleCallback !== undefined){
                        this.updateBoardingLocation();
                    }
                });
                kakao.maps.event.addListener(this.map, 'dragstart', () => {
                    this.map_changing = true;
                });
            });
        };
    }
    render() {
        if (this.map !== undefined) {
            this.drawCar();
            this.drawCenter();
            this.drawCarToDeparture();
            // this.drawCurrentBoardingPath();
            this.updateGlobalPath();
        }
        return (<div id="base_mini_map" className={"Seogwipo-MapContent mini "+(this.props.className??"")} 
        style={{
            opacity: this.props.opacity,
        }} />);
    }
}

export default SeogwipoMiniMapContainer;