import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from 'axios'
import { DropdownItem, DropdownToggle, DropdownMenu, ButtonDropdown } from "reactstrap";

const SelectCar = (props) => {
    const {
        selectedCar,
        selectCallback,
    } = props;

    const [cars, setCars] = useState([]);
    const [isCarDropDownOpen, setIsCarDropDownOpen] = useState(false);

    const carDropDownOnClick = useCallback((name) => {
        if (!!selectCallback)
            selectCallback(name);
    }, [selectCallback]);

    const car_items = useMemo(() => {
        return [...cars, undefined].map((name, idx) => {
            return <DropdownItem name={name} key={`car-dropdown-${idx}=${name}`} value={name} onClick={() => carDropDownOnClick(name)} >{name}</DropdownItem>
        });
    }, [cars, carDropDownOnClick]);

    useEffect(() => {
        async function wrapperFunction() {
            const _car_names = await axios.get("/api/get_cars")
                .then((res) => res.data.cars)
                .catch(_ => []);
            setCars(_car_names);
        }
        wrapperFunction();
    }, []);

    useEffect(() => {
        if (cars.length === 0 || !!selectedCar)
            return;
        if (!!selectCallback)
            selectCallback(cars[0]);
    }, [cars, selectedCar, selectCallback]);


    return <div style={{ textAlign: "center" }}>
        <ButtonDropdown direction="down" isOpen={isCarDropDownOpen} toggle={() => setIsCarDropDownOpen(open => !open)}>
            <DropdownToggle caret>
                {selectedCar}
            </DropdownToggle>
            <DropdownMenu name="cars" >
                {car_items}
            </DropdownMenu>
        </ButtonDropdown>
    </div>
}

export default SelectCar