
// TODO : This function is duplicated. So erase the original function in SeogwipoCommon.js.

export function deg2rad(deg) {
    return deg * (Math.PI/180)
}

export function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    let R = 6371;
    let dLat = deg2rad(lat2-lat1);
    let dLon = deg2rad(lon2-lon1); 
    let a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    return R * c;
}

export function inside(point, polygon) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html
    if(!!!point) return false;
    var x = point[0], y = point[1];

    var inside = false;
    for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i][0], yi = polygon[i][1];
        var xj = polygon[j][0], yj = polygon[j][1];

        var intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
};

export function getRandomPoint(
    polygon,
    {
        lat: {min: lat_min, max: lat_max},
        lng: {min: lng_min, max: lng_max},
        radius = 0.2,
        center = [],
    }
) {
    let rand_lat = Math.random() * (lat_max - lat_min) + lat_min;
    let rand_lng = Math.random() * (lng_max - lng_min) + lng_min;
    let from_center = Array.isArray(center) && center.length >= 2;
    let idx = 0;
    do
    {
        console.log([rand_lat, rand_lng], center)
        if (inside([rand_lat, rand_lng], polygon))
        {
            if (from_center) {
                console.log(getDistanceFromLatLonInKm(center[0],center[1], rand_lat, rand_lng), radius)
                if (getDistanceFromLatLonInKm(center[0],center[1], rand_lat, rand_lng) < radius) {
                    break;
                }
            }
            else {
                break;
            }
        }
        rand_lat = Math.random() * (lat_max - lat_min) + lat_min;
        rand_lng = Math.random() * (lng_max - lng_min) + lng_min;
        idx++;
    } while (idx < 1000)
    return [ rand_lat,rand_lng ];
}