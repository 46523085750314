import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { ButtonGroup, Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { inside } from "../../Component/PageCommon.js";
import { scienceParkValidServiceArea } from "../../../constants/ValidServiceArea.js";
import { useInterval } from "../../Hooks/Hooks.js"


const ScienceParkAdminPage = (props) => {
    const {
        history,
        location,
    } = props;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const {
        car_name
    } = query;
    const service_name = "SCIENCE_PARK";

    const [service, setService] = useState(false);
    const [trip, setTrip] = useState(false);
    const [carInfo, setCarInfo] = useState({
        car_timeout: true,
        car_location: undefined,
        passenger: [],
        trip_cnt: 0,
        total_trip_cnt: 0,
        current_service: "",
    });

    const [carName, setCarName] = useState(car_name);
    const [cars, setCars] = useState([]);
    const [isCarDropDownOpen, setIsCarDropDownOpen] = useState(false);
    const [isInside, setIsInside] = useState(false);

    const getCallState = useCallback(async () => {
        if (!!!carName)
            return;
        const res = await axios.get(`/api/${carName}/science/floating/getCallState`, {
            params: {
                isClient: false
            }
        }).catch(err => {
            return err.response;
        });
        if (res.statusText === 'OK') {
            const {
                car_timeout, car_location, service_on, passenger,
                trip_on, trip_cnt, total_trip_cnt, current_service,
            } = res.data;
            setCarInfo({
                car_timeout, car_location, trip_cnt, total_trip_cnt,
                current_service, passenger,
            });
            setService(service_on);
            setTrip(trip_on);
        }
        else {
            setCarInfo(info => {
                return {
                    ...info,
                    car_location: undefined,
                    trip_cnt: 0,
                    total_trip_cnt: 0,
                }
            });
            setService(false);
            setTrip(false);
        }
    }, [carName]);

    const disableService = useCallback(async () => {
        if (!!!carName)
            return;
        const res = await axios.put(`/api/${carName}/science/floating/disableService`)
            .catch(err => err.response);
        if (res.statusText === 'OK') {
            setService(false);
            setTrip(false);
        }
    }, [carName]);

    const ableService = useCallback(async () => {
        if (!!!carName)
            return;
        const res = await axios.put(`/api/${carName}/science/floating/ableService`)
            .catch(err => err.response);
        if (res.statusText === 'OK') {
            setService(true);
        }
    }, [carName]);

    const disableTrip = useCallback(async () => {
        if (!!!carName)
            return;
        const res = await axios.put(`/api/${carName}/science/floating/disableTrip`)
            .catch(err => err.response);
        if (res.statusText === 'OK') {
            setTrip(false);
        }
    }, [carName]);

    const ableTrip = useCallback(async () => {
        if (!!!carName)
            return;
        const res = await axios.put(`/api/${carName}/science/floating/ableTrip`)
            .catch(err => err.response);
        if (res.statusText === 'OK') {
            setTrip(true);
        }
    }, [carName]);

    const logoutBtn = useMemo(() => {
        const logout = (e) => {
            sessionStorage.removeItem("token");
            history.go(0);
        }
        return (<div
            style={{
                margin: "auto",
                width: "90%",
                padding: "10px 0",
                textAlign: "right",
                color: "black",
            }}
        >
            <Button sm={10} onClick={logout}>Log Out</Button>
        </div>);
    }, [history]);

    const carDropDownOnClick = useCallback((name) => {
        history.replace({
            search: `?car_name=${name}`
        });
    }, [history]);

    const car_items = useMemo(() => {
        return [...cars, undefined].map((name, idx) => {
            return <DropdownItem name={name} key={`car-dropdown-${idx}=${name}`} value={name} onClick={() => carDropDownOnClick(name)} >{name}</DropdownItem>
        });
    }, [cars, carDropDownOnClick]);

    const trip_count_content = useMemo(() => {
        return <span
            style={{ fontSize: "4vw", width: "40%", textAlign: "center" }}
        >
            {carName} 운행 횟수: {carInfo.trip_cnt}
            <br />
            총 운행 횟수: {carInfo.total_trip_cnt}
        </span>
    }, [carInfo, carName]);
    const service_button = useMemo(() => {
        return <Button
            style={{ width: "70vw", marginTop: service && isInside ? "1vh" : "5vh" }}
            size="md"
            color={service ? "danger" : "info"}
            onClick={service ? disableService : ableService}
        >
            {service ? "서비스 종료" : "서비스 시작"}
        </Button>
    }, [service, isInside, disableService, ableService]);
    const trip_button = useMemo(() => {
        return <Button
            style={{ width: "70vw", marginTop: "5vh" }}
            size="md"
            color={trip ? "warning" : "info"}
            onClick={trip ? disableTrip : ableTrip}
        >
            {trip ? "순환 운행 종료" : "순환 운행 시작"}
        </Button>
    }, [trip, disableTrip, ableTrip]);

    const content_info = useMemo(() => {
        const {
            car_timeout, car_location, current_service
        } = carInfo;
        const _inside = isInside;
        let text = '';
        let color = 'red';
        let service_show = service;
        if (!!current_service && service_name !== current_service)
            text = "다른 사용자 페이지에서 서비스 중이거나 서비스명이 일치하지 않습니다."
        else if (car_timeout || !!!car_location)
            text = "차량의 위치를 확인할 수 없어 서비스를 시작할 수 없습니다."
        else if (service) {
            if (_inside) {
                color = 'blue'
                text = trip ? "순환 노선을 주행 중입니다." : "";
            }
            else
                text = "차량이 운영구역을 벗어났습니다. 서비스를 중단 해주세요!"
        }
        else if (!car_timeout && !_inside)
            text = "차량이 운영구역 밖에 있어 서비스를 시작할 수 없습니다."
        else {
            text = "고객이 탑승을 할 수 없는 상태입니다."
            service_show = true;
        }

        return {
            text,
            color,
            service_show,
            trip_show: color === 'blue',
        }
    }, [carInfo, service, trip, service_name, isInside,]);

    useEffect(() => {
        async function wrapperFunction() {
            const _car_names = await axios.get("/api/get_cars")
                .then((res) => res.data.cars)
                .catch(_ => []);
            setCars(_car_names);
        }
        wrapperFunction();
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#F9DEDE";
    }, []);

    useEffect(() => {
        if (cars.length === 0 || !!car_name)
            return;
        history.replace({
            search: `?car_name=${cars[0]}`
        });

    }, [cars, history, car_name]);

    useEffect(() => {
        setCarName(car_name);
    }, [car_name]);

    useEffect(() => {
        setIsInside(inside(carInfo.car_location, scienceParkValidServiceArea));
    }, [carInfo]);

    useEffect(() => {
        getCallState();
    }, [getCallState]);

    useInterval(getCallState, 2000);
    // useInterval(getTripCnt, 2000);


    return <div style={{ height: "100%" }}>
        {logoutBtn}
        <div
            style={{
                margin: "auto",
                width: "70%",
                paddingTop: "5vh",
                textAlign: "center",
                color: "black",
            }}
        >
            <h1><b>- 첨단과기단지 운영자 페이지 - </b></h1>
            <br />
            <div style={{ textAlign: "center" }}>
                <ButtonDropdown direction="down" isOpen={isCarDropDownOpen} toggle={() => setIsCarDropDownOpen(open => !open)}>
                    <DropdownToggle caret>
                        {carName}
                    </DropdownToggle>
                    <DropdownMenu name="cars" >
                        {car_items}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
            <br />
            <div>
                {content_info.trip_show ? trip_count_content : null}
                <h2 style={{ color: content_info.color }}>
                    {content_info.text}
                    {content_info.trip_show ? trip_button : null}
                    {content_info.service_show ? service_button : null}
                </h2>
            </div>
        </div>
    </div >
}

export default withRouter(ScienceParkAdminPage);
