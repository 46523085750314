/*global kakao*/

import React, { Component } from "react";

class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.map = undefined;
        this.car_marker = undefined;
        this.station_marker = undefined;
        this.airport_marker = undefined;
        this.markerImage = undefined;
        this.markerImageStation = undefined;
        this.markerImageAirport = undefined;
    }
    componentDidMount() {
        const script = document.createElement("script");
        script.async = true;
        script.src =
            "https://dapi.kakao.com/v2/maps/sdk.js?appkey=44e205bb2875a6334dd7602d2e5c004d&autoload=false";
        document.head.appendChild(script);

        script.onload = () => {
            kakao.maps.load(() => {
                let car_location = new kakao.maps.LatLng(33.500699, 126.496796);
                if (this.props.car_position !== null && this.props.car_position !== undefined) {
                    car_location = new kakao.maps.LatLng(this.props.car_position[0], this.props.car_position[1]);
                }
                let container = document.getElementById("track_shuttle");
                let options = {
                    center: car_location,
                    level: 5
                };
                this.map = new window.kakao.maps.Map(container, options);
                var imageSrc = '/car_pin.png', // 마커이미지의 주소입니다    
                    imageSize = new kakao.maps.Size(25, 40);
                let imageOption = { offset: new kakao.maps.Point(12.5, 40) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
                this.markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);

                var imageSrcStation = '/station_pin.png', // 마커이미지의 주소입니다    
                    imageSizeStation = new kakao.maps.Size(30, 30);
                let imageOptionStation = { offset: new kakao.maps.Point(15, 30) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
                this.markerImageStation = new kakao.maps.MarkerImage(imageSrcStation, imageSizeStation, imageOptionStation);
                this.station_marker = new kakao.maps.Marker({
                    position: new kakao.maps.LatLng(33.494130, 126.491539),
                    image: this.markerImageStation
                });
                this.station_marker.setMap(this.map);

                var imageSrcAirport = '/airport_pin.png', // 마커이미지의 주소입니다    
                    imageSizeAirport = new kakao.maps.Size(20, 30);
                let imageOptionAirport = { offset: new kakao.maps.Point(10, 30) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
                this.markerImageAirport = new kakao.maps.MarkerImage(imageSrcAirport, imageSizeAirport, imageOptionAirport);

                this.airport_marker = new kakao.maps.Marker({
                    position: new kakao.maps.LatLng(33.504091, 126.492253),
                    image: this.markerImageAirport
                });
                this.airport_marker.setMap(this.map);

            });
        };
    }
    render() {
        if (this.map !== undefined) {
            let car_location = new kakao.maps.LatLng(33.500699, 126.496796);
            if (this.props.car_position !== null && this.props.car_position !== undefined) {
                car_location = new kakao.maps.LatLng(this.props.car_position[0], this.props.car_position[1]);
            }
            if (this.car_marker === undefined) {
                this.car_marker = new kakao.maps.Marker({
                    position: car_location,
                    image: this.markerImage
                });
                this.car_marker.setMap(this.map);
            } else {
                this.car_marker.setPosition(car_location);
            }
            this.map.panTo(car_location);
        }
        return (<div id="track_shuttle" style={{ height: "25vh", minHeight: "280px" }} />);
    }
}

export default MapContainer;
