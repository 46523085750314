/*global kakao*/

import React, { Component } from "react";
import { validServiceArea } from "./SeogwipoCommon"
import "../../style/SeogwipoMapContent.css"

class SeogwipoMapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roadview_open: false,
            cur_roadview_name: "",
        };
        this.use_center = false;
        this.initial_center = undefined;
        this.center = undefined;
        this.drag_end_point = undefined;
        this.map = undefined;
        this.roadview = undefined;
        this.roadview_client = undefined;
        this.area = undefined;
        this.path = [];
        this.arrow_number = [30,30,30,18,8,8];  //max zoom level is 14 
        this.arrow_list = [];
        this.center_marker = undefined;
        this.center_image = undefined;
        this.car_marker = undefined;
        this.car_image = undefined;
        this.car_eta_marker = undefined;
        this.my_marker = undefined;
        this.boarding_path = [undefined, undefined, undefined]; //[cur, dep, des]
        this.boarding_marker = [undefined, undefined, undefined]; //[cur, dep, des]
        this.boarding_image = undefined;
        this.boarding_image_color = ["#000", "#000"] // [dep, des]
        this.start_marker = undefined;
        this.start_image = undefined;
        this.end_marker = undefined;
        this.end_image = undefined;
        this.zoom_center = undefined;
        this.draggable = undefined;
        // this.car_type = "ioniq";
        // this.center_type = "departure";
        this.shuttle_stop_images = {deactive:[], active:[]};
        this.shuttle_stop_markers = [];
        this.shuttle_stop_markers_roadview = undefined;
        this.shuttle_stop_pano_id = new Object();
        this.shuttle_stop_info = undefined;
        this.map_id = "base_map";
        this.roadview_id = "base_roadview";
        this.map_changing = false;

        this.cur_roadview_marker = undefined;

        this.car_path = undefined;
        this.global_path = undefined;
        this.valid_roads = [];

        this.active_stop = -1;
        this.geocoder = undefined;
        this.service = undefined;
        this.leaving_marker = undefined;
    }
    getLatLng(p) {
        return new kakao.maps.LatLng(p[0], p[1]);
    }
    getCenter() {
        let center = new kakao.maps.LatLng(33.25335474788911, 126.50898282140602);
        if (!this.props.reset_map) {
            if (!!this.props.initial_center && this.props.initial_center !== this.initial_center) {
                this.initial_center = this.props.initial_center;
                center = new kakao.maps.LatLng(this.props.initial_center[0], this.props.initial_center[1]);
                if (!!this.map && this.props.use_center) {
                    this.map.setCenter(center);
                }
            } else if (!!this.center) {
                center = new kakao.maps.LatLng(this.center[0], this.center[1]);
            }
        } else {
            this.map.setCenter(center);
        }
        return center;
    }
    setCenter(p) {
        if(!!p){
            this.map.setCenter(this.getLatLng(p));
        }
    }
    setLevel(n) {
        if(!!this.map && this.map.getLevel() !== n) this.map.setLevel(n);
    }
    setBounds(p1, p2) {
        if(Array.isArray(p1) && Array.isArray(p2)){
            if(p1.includes(undefined) || p2.includes(undefined)) return;

            let ne = Array(2), sw = Array(2);
            if(p1[0] < p2[0]){
                ne[0] = p1[0]; sw[0] = p2[0];
            } else {
                ne[0] = p2[0]; sw[0] = p1[0];
            }
            if(p1[1] > p2[1]){
                ne[1] = p1[1]; sw[1] = p2[1];
            } else {
                ne[1] = p2[1]; sw[1] = p1[1];
            }
            this.map.setBounds(
                new kakao.maps.LatLngBounds(
                    this.getLatLng(sw), 
                    this.getLatLng(ne)
                ), 0, 50, 50
            );
            this.initial_center = this.map.getCenter();
        }
    }
    toggleRoadview(active){
        this.setState({
            roadview_open: active
        })
        this.roadview.relayout();
    }
    showInitialCenter(){
        if(this.map !== undefined){
            this.map.setLevel(5);
            this.map.setCenter(new kakao.maps.LatLng(33.25335474788911, 126.50898282140602));
        }
    }
    drawArea() {
        if (this.props.area_data !== null && this.props.area_data !== undefined && this.area === undefined) {
            var polygonPath = [];
            var polygonCoverage = []
            for (const xy of this.props.area_data) {
                polygonPath.push(new kakao.maps.LatLng(xy[0], xy[1]));
            }
            for (const xy of validServiceArea){
                polygonCoverage.push(new kakao.maps.LatLng(xy[0], xy[1]));
            }
            this.area = new kakao.maps.Polygon({
                path: [polygonCoverage, polygonPath], // 그려질 다각형의 좌표 배열입니다
                // strokeWeight: 3, // 선의 두께입니다
                // strokeColor: '#39DE2A', // 선의 색깔입니다
                strokeOpacity: 0.0, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                // strokeStyle: 'longdash', // 선의 스타일입니다
                fillColor: '#000', // 채우기 색깔입니다
                fillOpacity: 0.13 // 채우기 불투명도 입니다
            });
            this.area.setMap(this.map);
        }
    }
    toggleShuttleStop(){
        if((!!this.props.active_stop || this.props.active_stop === 0) && this.active_stop !== this.props.active_stop){
            if(this.active_stop > -1 && !!this.shuttle_stop_markers[this.active_stop])
                kakao.maps.event.trigger(this.shuttle_stop_markers[this.active_stop], 'deactive', this.active_stop);
            if(this.props.active_stop > -1 && !!this.shuttle_stop_markers[this.props.active_stop])
                kakao.maps.event.trigger(this.shuttle_stop_markers[this.props.active_stop], 'active', this.props.active_stop);
            this.active_stop = this.props.active_stop;
        }
    }
    drawShuttleStop() {
        if (!!this.props.shuttle_stop_list && this.shuttle_stop_markers.length === 0) {
            for (const shuttle_stop of this.props.shuttle_stop_list) {
                const shuttle_stop_idx = this.props.shuttle_stop_list.indexOf(shuttle_stop);
                let stop_position = this.getLatLng(shuttle_stop["location"]);
                let imageSize = new kakao.maps.Size(34, 54);
                let deactive_stop_image = new kakao.maps.MarkerImage('/stops/assets/stop'+(shuttle_stop_idx+1)+'.svg', imageSize);
                imageSize = new kakao.maps.Size(34*1.2, 54*1.2);
                let active_stop_image = new kakao.maps.MarkerImage('/stops/assets/bluestop'+(shuttle_stop_idx+1)+'.svg', imageSize);
                
                let stop_marker = new kakao.maps.Marker({
                    position: stop_position,
                    image: deactive_stop_image,
                    clickable: true,
                    zIndex: 1,
                });
                stop_marker.setTitle(shuttle_stop["description"]);

                kakao.maps.event.addListener(stop_marker, 'click', () => {
                    if(this.props.shuttle_stop_click_handler !== undefined){
                        this.props.shuttle_stop_click_handler(stop_marker.getTitle());
                    }
                });
                kakao.maps.event.addListener(stop_marker, 'active', (idx) => {
                    stop_marker.setImage(this.shuttle_stop_images["active"][idx]);
                    this.map.panTo(stop_marker.getPosition());
                });
                kakao.maps.event.addListener(stop_marker, 'deactive', (idx) => {
                    stop_marker.setImage(this.shuttle_stop_images["deactive"][idx]);
                });
                stop_marker.setMap(this.map);
                stop_marker.setDraggable(false);
                this.shuttle_stop_markers.push(stop_marker);
                this.shuttle_stop_images["deactive"].push(deactive_stop_image);
                this.shuttle_stop_images["active"].push(active_stop_image);
            }
        }
    }
    clickShuttleStopImg(marker_description){
        if(!!this.shuttle_stop_pano_id[marker_description] && !!this.roadview){
            let marker = this.shuttle_stop_markers_roadview[marker_description];
            this.roadview.setPanoId(this.shuttle_stop_pano_id[marker_description], marker.getPosition());
            this.cur_roadview_marker = marker;
        }
    }
    checkPanoId(marker_description){
        return Number(this.shuttle_stop_pano_id[marker_description]) === this.roadview.getPanoId();
    }
    updateViewpoint(marker_description){
        let marker = this.shuttle_stop_markers_roadview[marker_description];
        let projection = this.roadview.getProjection();
        let viewpoint = projection.viewpointFromCoords(marker.getPosition(), marker.getAltitude()-1);
        this.roadview.setViewpoint(viewpoint);
    }
    drawRoadviewStopMarker(){
        if (!!this.props.shuttle_stop_list && !!!this.shuttle_stop_markers_roadview && !!this.roadview){
            this.shuttle_stop_markers_roadview = new Object();
            this.shuttle_stop_info = new Object();
            for (const shuttle_stop of this.props.shuttle_stop_list) {
                let stop_position = this.getLatLng(shuttle_stop["roadview_location"]);
                let roadview_marker = new kakao.maps.Marker({
                    position: stop_position,
                    // zIndex: 5,
                    range: 50,
                    draggable: false,
                });
                roadview_marker.setTitle(shuttle_stop["description"]);
                let info = new kakao.maps.InfoWindow({
                    content: "<div class='roadview-custom-overlay'>" + shuttle_stop["description"] + "</div>",
                    removable: false,
                    range: 50, 
                });

                this.shuttle_stop_markers_roadview[shuttle_stop["description"]] = roadview_marker;
                this.shuttle_stop_info[shuttle_stop["description"]] = info;
            }
        }
    }
    drawPath(n = 7) {
        if (!!this.props.path_data && this.path.length === 0) {
            this.path = this.props.path_data.map((line_path)=>
                line_path.map((point)=>this.getLatLng(point))
            )
            this.updatePath(n);
        }
    }
    updatePath(n) {
        if(!!this.path && !!this.props.path_data){
            for(const path of this.arrow_list) path.setMap(null);
            this.arrow_list = [];

            let option = {
                strokeWeight: 5,
                strokeColor: '#3366ff', //'#006adf
                strokeOpacity: 1.0,
                strokeStyle: 'solid',
                endArrow: true
            }
            let divisor = parseInt(this.props.path_data.length / n), rest = this.props.path_data.length - n*divisor;
            let tmp_path = [];
            for(const i in this.path){
                tmp_path.push(this.path[i]);
                if(tmp_path.length === divisor + (rest > 0 ? 1:0)){
                    rest--;
                    this.arrow_list.push(new kakao.maps.Polyline({
                        map: this.map,
                        path: [...tmp_path],
                        ...option,
                    }));
                    tmp_path = [];
                }
            }
        }
    }
    drawCar() {
        if (!!this.props.car_location && !!this.map) {
            let car_location = new kakao.maps.LatLng(this.props.car_location[0], this.props.car_location[1]);
            if (!!this.props.car_heading || this.props.car_heading === 0){
                let theta = this.props.car_heading;
                if (!!!this.car_marker) {
                    let img = document.createElement('img');
                    img.src = '/car_icon.svg';
                    img.alt = '/'+(this.props.car_type !== undefined ? this.props.car_type : 'ioniq')+'.png';
                    img.style.transform = 'rotate(' + (90-theta) + 'deg)';
                    img.className = "car-marker "+ this.props.car_type;
    
                    this.car_marker = new kakao.maps.CustomOverlay({
                        map: this.map,
                        content: img,
                        position: car_location,
                        xAnchor: 0.5, // 컨텐츠의 x 위치
                        yAnchor: 0.5, // 컨텐츠의 y 위치
                    });
                } else {
                    if (!!this.car_marker.getContent){
                        let img = this.car_marker.getContent();
                        img.style.transform = 'rotate(' + (90-theta) + 'deg)';
                        this.car_marker.setPosition(car_location);
                    } else {
                        console.log("car_marker is not overlay")
                        this.car_marker.setMap(null);
                        this.car_marker = undefined;
                    }
                }
            }
            else{
                if (!!!this.car_marker) {
                    var imageSrc = '/' + (this.props.car_type !== undefined ? this.props.car_type : 'ioniq') + '.png', // 마커이미지의 주소입니다    
                        imageSize = new kakao.maps.Size(35, 40);
                    let imageOption = { offset: new kakao.maps.Point(22.5, 40) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
                    this.car_image = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
                    this.car_marker = new kakao.maps.Marker({
                        map: this.map,
                        position: car_location,
                        image: this.car_image,
                        clickable: false,
                    });
                    this.car_marker.setDraggable(false);
                } else {
                    if (!!!this.car_marker.getContent){
                        this.car_marker.setPosition(car_location);
                    } else{
                        console.log("car_marker is not marker")
                        this.car_marker.setMap(null);
                        this.car_marker = undefined;
                    }
                }
            }

            if((this.props.car_eta ?? -1) >= 0){
                //this.props.car_eta == null || this.props.car_eta == undefined ? -1 : this.props.car_eta;
                let eta_txt = this.props.car_eta > 0 ? this.props.car_eta+"분 소요" : "곧 도착";
                if(!!!this.car_eta_marker){
                    this.car_eta_marker = this.makeBoardingCustomOverlay(this.props.car_location,"markers/moving",eta_txt,"moving");
                    this.car_eta_marker.setMap(this.map);
                } else{
                    this.car_eta_marker.setPosition(this.getLatLng(this.props.car_location));
                    let content = this.car_eta_marker.getContent().getElementsByClassName("custom-overlay-text-box")?.[0];
                    content.textContent = eta_txt;
                }
            } else if(!!this.car_eta){
                this.car_eta.setMap(null);
                this.car_eta = undefined;
            }
        } else{
            if(!!this.car_marker){
                this.car_marker.setMap(null);
                this.car_marker = undefined;
                if(!!this.car_eta){
                    this.car_eta.setMap(null);
                    this.car_eta = undefined;
                }
            }
        }
    }
    drawStart() {
        if (this.props.start_location !== null && this.props.start_location !== undefined) {
            if(this.start_marker === undefined){
                let start_location = this.getLatLng(this.props.start_location);
                if(this.start_image === undefined){
                    var imageSrc = '/start.png', // 마커이미지의 주소입니다    
                        imageSize = new kakao.maps.Size(16, 16);
                    let imageOption = { offset: new kakao.maps.Point(8, 8) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
                    this.start_image = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
                }
                this.start_marker = new kakao.maps.Marker({
                    position: start_location,
                    image: this.start_image
                });
                this.start_marker.setMap(this.map);
                this.start_marker.setDraggable(false);
            }
        }
        else{
            if (this.start_marker !== undefined) {
                this.start_marker.setMap(null);
                this.start_marker = undefined;
            }
        }
    }
    drawEnd() {
        if (this.props.end_location !== null && this.props.end_location !== undefined) {
            if(this.end_marker === undefined){
                let end_location = this.getLatLng(this.props.end_location);
                if(this.end_image === undefined){
                    var imageSrc = '/end.png', // 마커이미지의 주소입니다    
                        imageSize = new kakao.maps.Size(16, 16);
                    let imageOption = { offset: new kakao.maps.Point(8, 8) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
                    this.end_image = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
                }   
                this.end_marker = new kakao.maps.Marker({
                    position: end_location,
                    image: this.end_image
                });
                this.end_marker.setMap(this.map);
                this.end_marker.setDraggable(false);
            }
        }
        else{
            if (this.end_marker !== undefined) {
                this.end_marker.setMap(null);
                this.end_marker = undefined;
            }
        }
    }
    drawCenter() {
        if (!!this.props.use_center) {
            let c = this.map.getCenter();
            this.center = [c.getLat(), c.getLng()];
            this.getCenter();
        } else{
            this.center = undefined;
        }
        if (this.center !== undefined) {
            let center = new kakao.maps.LatLng(this.center[0], this.center[1]);
            if (this.center_marker === undefined) {
                let imageSrc = '/location_pin.svg', 
                imageSize = new kakao.maps.Size(26, 26);
                this.center_image = new kakao.maps.MarkerImage(imageSrc, imageSize);
                this.center_marker = new kakao.maps.Marker({
                    image: this.center_image,
                    position: center,
                    zIndex: 5,
                    clickable: false,
                });
                this.center_marker.setMap(this.map);
            } else {
                this.center_marker.setPosition(center);
            }
        }
        else if(!this.props.use_center && this.center_marker !== undefined){
            this.center_marker.setMap(null);
            this.center_marker = undefined;
        }
    }
    drawMyLocation() {
        if(this.props.my_location !== undefined){
            let location = this.getLatLng(this.props.my_location);
            if(this.my_marker === undefined){
                var imageSrc = '/location_dot.svg', // 마커이미지의 주소입니다    
                    imageSize = new kakao.maps.Size(15, 15);
                let imageOption = { offset: new kakao.maps.Point(7.5, 7.5) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
                let img = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
                this.my_marker = new kakao.maps.Marker({
                    map: this.map,
                    position: location,
                    image: img,
                    opacity: 0.7,
                    clickable: false,
                });
                this.my_marker.setDraggable(false);
            }
            else {
                if(this.my_marker.getMap() === null)
                    this.my_marker.setMap(this.map);
                this.my_marker.setPosition(location);
            }
        }
        else{
            if(this.my_marker !== undefined){
                this.my_marker.setMap(null);
            }
        }

    }
    makePath(arr, option){
        if(!!arr){
            let path = new kakao.maps.Polyline({
                map: this.map,
                path: arr,
                strokeOpacity: 0.7,
            });
            if(option !== undefined)
                path.setOptions(option);
            return path;
        }
        return undefined;
    }
    makeBoardingPath(dep, des, option){
        if(dep !== undefined && des !== undefined){
            let options = {
                strokeWeight: 2,
                strokeColor: '#000000', 
                strokeOpacity: 1.0,//0.6,
                strokeStyle: 'shortdot',
                ...option
            }
            let path = this.makePath([dep,des], options);
            return path;
        }
        return undefined;
    }
    drawCurrentBoardingPath(){
        if(this.props.boarding_point !== undefined && this.center !== undefined && this.boarding_path[0] === undefined){
            let p = this.getLatLng(this.props.boarding_point);
            let idx = this.start_marker === undefined ? 0 : 1;
            let color = {strokeColor: this.boarding_image_color[idx]};
            this.boarding_path[0] = this.makeBoardingPath(this.getLatLng(this.center), p, color);
            this.boarding_path[0].setMap(this.map);
        }
        else if(this.props.boarding_point === undefined && this.boarding_path[0] !== undefined){
            this.boarding_path[0].setMap(null);
            this.boarding_path[0] = undefined;
        }
    }
    updateCurrentBoardingPath(){
        if(this.props.boarding_point !== undefined && this.center !== undefined && this.boarding_path[0] !== undefined && !this.map_changing){
            let idx = this.start_marker === undefined ? 0 : 1;
            let color = {strokeColor: this.boarding_image_color[idx]};
            let p = this.getLatLng(this.props.boarding_point);
            this.boarding_path[0].setPath([this.getLatLng(this.center), p]);
            this.boarding_path[0].setOptions(color);
        }
    }
    makeBoardingMarker(point, type, size=52){
        if(!!point){
            let marker;
            let position = this.getLatLng(point);
            // let theta = point[2];
            var imageSrc = '/'+type+'.svg', 
                imageSize = new kakao.maps.Size(size, size);
            let imageOption = {offset: new kakao.maps.Point(size*0.5, size*0.9)}
            this.boarding_image = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
            marker = new kakao.maps.Marker({
                map: this.map,
                position: position,
                image: this.boarding_image,
                clickable: false,
            });
            return marker;
        }
        return undefined;
    }
    makeBoardingCustomOverlay(latlng, src, txt, txt_className="", size=52, option={}){
        if(!!!latlng){
            return undefined;
        }
        let imageSrc = '/' + src + '.svg';
        let content = document.createElement("div"), content_img = document.createElement("img"), content_txt = document.createElement("div");
        content.className = "custom-overlay-box"; 
        content_img.className="custom-overlay-img"; 
        content_txt.className = "custom-overlay-text-box "+txt_className;
        content.style.width = (size+"px");
        content_img.src = imageSrc; content_img.style.height = size+"px";
        content_txt.style.fontSize=(Math.ceil(size/3.5))+"px";
        content_txt.appendChild(document.createTextNode(txt));
        content.appendChild(content_img);
        content.appendChild(content_txt);
        
        return new kakao.maps.CustomOverlay({
            clickable: false,
            content: content,
            position: this.getLatLng(latlng),
            xAnchor: 0.5,
            yAnchor: 1,
            ...option,
        })
    }
    drawCurrentBoardingLocation(){
        if(!!this.props.boarding_point){
            if(!!!this.boarding_marker[0]){
                this.boarding_marker[0] = this.makeBoardingMarker(this.props.boarding_point, this.props.center_type);
                this.boarding_marker[0].setZIndex(4);
                this.boarding_marker[0].setMap(this.map);
            }
            else{
                var imageSrc = '/' + this.props.center_type + '.svg', // 마커이미지의 주소입니다    
                    imageSize = new kakao.maps.Size(52, 52);
                this.boarding_image = new kakao.maps.MarkerImage(imageSrc, imageSize);
                this.boarding_marker[0].setPosition(this.getLatLng(this.props.boarding_point));
                this.boarding_marker[0].setImage(this.boarding_image);
            }
        }
        else if(!!!this.props.boarding_point && !!this.boarding_marker[0]){
            this.boarding_marker[0].setMap(null);
            this.boarding_marker[0] = undefined;
        }
    }
    drawBoardingLocations(){
        if(!!this.props.departure_location && !!!this.boarding_marker[1]){
            this.boarding_marker[1] = this.makeBoardingMarker(this.props.departure_location, "departure");

            if(this.props.start_location !== undefined){
                this.boarding_path[1] = this.makeBoardingPath(this.getLatLng(this.props.start_location), this.getLatLng(this.props.departure_location));
            }
        }
        else if(!!!this.props.departure_location && !!this.boarding_marker[1]){
            this.updateCurrentBoardingPath();
            this.boarding_marker[1].setMap(null);
            this.boarding_marker[1] = undefined;

            if(!!this.boarding_path[1]){
                this.boarding_path[1].setMap(null);
                this.boarding_path[1] = undefined;
            }
        }

        if(!!this.props.destination_location && !!!this.boarding_marker[2]){
            if(!!this.props.ETA){
                let eta_txt = this.props.ETA > 0 ? this.props.ETA+"분 소요" : "곧 도착";
                this.boarding_marker[2] = this.makeBoardingCustomOverlay(this.props.destination_location, "markers/arrival", eta_txt, "arrival");
            }else {
                this.boarding_marker[2] = this.makeBoardingMarker(this.props.destination_location, "arrival");
            }
            this.boarding_marker[2].setMap(this.map);

            if(!!this.props.end_location){
                this.boarding_path[2] = this.makeBoardingPath(this.getLatLng(this.props.end_location), this.getLatLng(this.props.destination_location));
            }
        }
        else if(!!!this.props.destination_location && !!this.boarding_marker[2]){
            this.updateCurrentBoardingPath();
            this.boarding_marker[2].setMap(null);
            this.boarding_marker[2] = undefined;

            if(!!this.boarding_path[2]){
                this.boarding_path[2].setMap(null);
                this.boarding_path[2] = undefined;
            }
        }
    }
    drawCarToDeparture(){
        if(this.props.car_to_departure_path !== undefined){
            let path = this.props.car_to_departure_path.map((val)=>{
                return this.getLatLng(val);
            });
            if(this.car_path === undefined){
                let option = {strokeColor : "#000", strokeWeight: 4.0, strokeOpacity: 0.6};
                this.car_path = this.makePath(path, option);
            }
        }
        else if(this.props.car_to_departure_path === undefined && this.car_path !== undefined){
            this.car_path.setMap(null);
            this.car_path = undefined;
        }
    }
    updateCarToDeparture(option){
        if(this.props.car_to_departure_path !== undefined && this.car_path !== undefined && !this.map_changing){
            let path = this.props.car_to_departure_path.map((val)=>{
                return this.getLatLng(val);
            });
            this.car_path.setPath(path);
            if(option !== undefined)
                this.car_path.setOptions(option);
        }
    }
    drawGlobalPath(){
        if(this.props.global_path_list !== undefined){
            let path = this.props.global_path_list.map((val)=>{
                return this.getLatLng(val);
            });
            if(this.global_path === undefined){
                let option = {strokeColor : "#000", strokeWeight: 4.0, strokeOpacity: 0.6};
                this.global_path = this.makePath(path,option);
            }
        }
        else if(this.props.global_path_list === undefined && this.global_path !== undefined){
            this.global_path.setMap(null);
            this.global_path = undefined;
        }
    }
    updateGlobalPath(option){
        if(this.props.global_path_list !== undefined && this.global_path !== undefined && !this.map_changing){
            let path = this.props.global_path_list.map((val)=>{
                return this.getLatLng(val);
            });
            this.global_path.setPath(path);
            if(option !== undefined)
                this.global_path.setOptions(option);
        }
    }
    drawLeavingMarker(){
        if(!!!this.map) return;
        if(!!this.props.leaving_location && (this.props.ETA ?? -1 >=0)){
            if(!!!this.leaving_marker){
                let eta_txt = this.props.ETA > 0 ? this.props.ETA+"분 소요" : "곧 도착";
                this.leaving_marker = this.makeBoardingCustomOverlay(this.props.leaving_location, "markers/leaving", eta_txt, "leaving");
                this.leaving_marker.setMap(this.map);
            } else {
                this.updateLeavingMarker();
            }
        } else if(!!!this.props.leaving_location && !!this.leaving_marker && !!this.props.ETA){
            this.leaving_marker.setMap(null);
            this.leaving_marker = undefined;
        } 
    }
    updateLeavingMarker(){
        if(!!!this.leaving_marker || !!!this.map) return;
        if((this.props.ETA ?? -1) < 0) return;
        //this.props.ETA == null || this.props.ETA == undefined ? -1 : this.props.ETA => if(-1 < 0) => return;
        let eta_txt = this.props.ETA > 0 ? this.props.ETA+"분 소요" : "곧 도착";
        let content = this.leaving_marker.getContent().getElementsByClassName("custom-overlay-text-box")?.[0];
        content.textContent = eta_txt;
        this.leaving_marker.setPosition(this.getLatLng(this.props.leaving_location));
    }
    drawRoad(){
        if(!!this.props.valid_roads){
            let option = {
                strokeWeight: 5,
                strokeColor: '#3366FF',
                strokeOpacity: 0.5,
                strokeStyle: 'solid', 
            }
            let path = this.props.valid_roads["lines"].map((point_list)=>
                 point_list.map((point)=> this.getLatLng(point)
                )
            );
            this.valid_roads.push(this.makePath(path, option));
            for(const center of this.props.valid_roads["circles"]){
                let circle = new kakao.maps.Circle({
                    map: this.map,
                    center : this.getLatLng(center),
                    radius: center[2],
                    ...option,
                }); 
                this.valid_roads.push(circle);
            }
        }
    }
    updateIdleState(){
        this.drawCurrentBoardingLocation();
        this.updateCurrentBoardingPath();
        this.updateCarToDeparture();
        this.updateGlobalPath();
    }
    async updateBoardingLocation(){
        let loc = this.map.getCenter();
        await this.props.idleCallback([loc.getLat(), loc.getLng()]).finally(()=>{
            this.updateIdleState();
        });
    }
    async getStopPanoIds(list){
        // wrapping function
        for await (const stop of list){
            let id = await this.getPanoId(this.getLatLng(stop["roadview_location"]));
            this.shuttle_stop_pano_id[stop["description"]] = id;
        }
    }
    getPanoId(location){
        //getPanoId의 비동기화
        return new Promise((rsv, rjt) => {
            this.roadview_client.getNearestPanoId(location, 20, (pano_id)=>{
                rsv(pano_id)
            });
        })
    }
    findAddress(p){
        if(!!!this.props.addressHandler || !!!this.map || !!!this.geocoder || !!!this.service) return;
        this.geocoder.coord2Address(p[1],p[0], (address, status)=>{
            if (status === kakao.maps.services.Status.OK) {
                // let address_name = address[0]["address"]["region_1depth_name"] +" "+ address[0]["address"]["region_2depth_name"];
                let address_name = address[0]["address"]["address_name"];
                if(!!address[0]["road_address"]){
                    address_name = address[0]["road_address"]["address_name"];
                }
                this.service.keywordSearch(address_name, (data, status)=>{
                    try{
                        if (!!!this.props.addressHandler) return;
                        if (status === kakao.maps.services.Status.OK) {
                            let place = data.find((elem)=> elem["category_group_code"] !== 'PK6');
                            if(!!place){
                                this.props.addressHandler(place["address_name"], place["place_name"]);
                                return;
                            }
                        } 
                        let region = address[0]["address"]["region_1depth_name"];
                        let name = address_name.slice(region.length).trim();
                        this.props.addressHandler(address_name, name);
                    }catch(e){
                        console.error(e)
                    }
                },{useMapCenter: true, sort:kakao.maps.services.SortBy.DISTANCE, radius: 100}); 
            }
        });
    }
    getAddress = (p) => {
        return new Promise((rsv, rjt) => {
            if(!!!this.map || !!!this.geocoder || !!!this.service){ 
                rjt(); 
                return;
            }
            try{
                this.geocoder.coord2Address(p[1],p[0], (address, status)=>{
                    if (status === kakao.maps.services.Status.OK) {
                        // let address_name = address[0]["address"]["region_1depth_name"] +" "+ address[0]["address"]["region_2depth_name"];
                        let address_name = address[0]["address"]["address_name"];
                        if(!!address[0]["road_address"]){
                            address_name = address[0]["road_address"]["address_name"];
                        }
                        this.service.keywordSearch(address_name, (data, status)=>{
                            let place = data.find((elem)=> elem["category_group_code"] !== 'PK6');
                            if (!!place) {
                                rsv(place["place_name"]);
                                return;
                            } 
                            let region = address[0]["address"]["region_1depth_name"];
                            let name = address_name.slice(region.length).trim();
                            rsv(name);
                            
                        },{location: this.getLatLng(p), sort:kakao.maps.services.SortBy.DISTANCE, radius: 100}); 
                    } else { rjt();}
                });
            }catch(e){
                console.error("coord2Address",e)
                rjt();
            }
        });
    }
    componentDidMount() {
        const script = document.createElement("script");
        script.async = true;
        script.src =
            "https://dapi.kakao.com/v2/maps/sdk.js?appkey=44e205bb2875a6334dd7602d2e5c004d&autoload=false&libraries=services";
        document.head.appendChild(script);
        
        script.onload = () => {
            kakao.maps.load(() => {
                if (document.getElementById(this.map_id)) {
                    let container = document.getElementById(this.map_id);
                    let options = {
                        center: this.getCenter(),
                        level: 5
                    };
                    this.map = new window.kakao.maps.Map(container, options);
                    this.geocoder = new kakao.maps.services.Geocoder();
                    this.service = new kakao.maps.services.Places();
                    this.service.setMap(this.map);
                    this.map.setDraggable(this.props.draggable);
                    this.drawMyLocation();
                    this.drawPath(this.arrow_number[this.map.getLevel() - 1]);
                    this.drawCarToDeparture();
                    this.drawGlobalPath();
                    this.drawStart();
                    this.drawEnd();
                    this.drawArea();
                    this.drawCar();
                    this.drawLeavingMarker();
                    this.drawCurrentBoardingPath();
                    this.drawBoardingLocations();
                    this.drawCurrentBoardingLocation();
                    this.drawShuttleStop();
                    this.drawRoad();

                    kakao.maps.event.addListener(this.map, "center_changed", () => {
                        if (this.map !== undefined && this.props.use_center) {
                            if (this.zoom_center !== undefined) {
                                this.map.setCenter(this.zoom_center);
                                // this.updateCurrentBoardingPath();
                                this.zoom_center = undefined;
                            }
                            this.center = [this.map.getCenter().getLat(), this.map.getCenter().getLng()];
                            this.props.centerChangeCallback(this.center);
                            this.drawCenter();
                        }
                    });
                    kakao.maps.event.addListener(this.map, 'zoom_start', () => {
                        this.map_changing = true;
                        if (this.map !== undefined && this.props.use_center) {
                            if(this.zoom_center === undefined) 
                                this.zoom_center = this.map.getCenter();
                        }
                    });
                    kakao.maps.event.addListener(this.map, 'idle', () => {
                        this.map_changing = false;
                        if(!!this.map && this.props.use_center && !!this.props.idleCallback){
                            this.updateBoardingLocation();
                            this.findAddress([this.map.getCenter().getLat(), this.map.getCenter().getLng()]);
                        }else if(!!this.map){
                            this.updateIdleState();
                        }
                    });
                    kakao.maps.event.addListener(this.map, 'dragstart', () => {
                        this.map_changing = true;
                    });
                    kakao.maps.event.addListener(this.map, 'click', (event) => {
                        if(this.props.map_click_handler !== undefined){
                            this.props.map_click_handler();
                        }
                    });
                    kakao.maps.event.addListener(this.map, 'zoom_changed', ()=> {
                        if(!!this.props.path_data){
                            let idx = this.map.getLevel() > this.arrow_number.length ? this.arrow_number.length - 1 : this.map.getLevel() - 1;
                            if(this.arrow_number[idx] !== this.arrow_list.length) {
                                this.updatePath(this.arrow_number[idx]);
                            }
                        }
                    });
                }
                if (document.getElementById(this.roadview_id) && !!this.props.shuttle_stop_list){
                    let roadview_container = document.getElementById(this.roadview_id);
                    this.roadview = new kakao.maps.Roadview(roadview_container);
                    this.roadview_client = new kakao.maps.RoadviewClient();
                    this.getStopPanoIds(this.props.shuttle_stop_list);
                    this.drawRoadviewStopMarker();

                    kakao.maps.event.addListener(this.roadview, 'init', ()=>{
                        for(const description in this.shuttle_stop_markers_roadview){
                            if(!!!this.shuttle_stop_markers_roadview[description].getMap()){
                                this.shuttle_stop_markers_roadview[description].setMap(this.roadview);
                                this.shuttle_stop_info[description].open(this.roadview,this.shuttle_stop_markers_roadview[description]);
                            }
                        }
                    });
                    kakao.maps.event.addListener(this.roadview, 'panoid_changed', ()=>{
                        if(!!this.cur_roadview_marker){
                            this.updateViewpoint(this.cur_roadview_marker.getTitle());
                            this.cur_roadview_marker = undefined;
                        }
                    });
                }
            });
        };
    }
    render() {
        if (this.map !== undefined) {
            if (this.props.draggable !== this.draggable) {
                this.draggable = this.props.draggable;
                this.map.setDraggable(this.props.draggable);
            }
            this.drawMyLocation();
            this.drawPath(this.arrow_number);
            this.drawCarToDeparture();
            this.drawGlobalPath();
            this.drawArea();
            this.drawLeavingMarker();
            this.drawStart();
            this.drawEnd();
            this.drawCar();
            this.drawLeavingMarker();
            this.drawCurrentBoardingPath();
            this.drawBoardingLocations();
            this.drawCenter();
            // this.drawCurrentBoardingLocation();
            this.toggleShuttleStop()
            this.drawShuttleStop();
        }
        if (this.props.map_id!==undefined){
            this.map_id = this.props.map_id;
        }
        return (
            <div className="Seogwipo-MapContent map_wrapper">
                <div id={this.map_id} style={{
                    display: this.props.roadview ? "none" : "block",
                    opacity: this.props.opacity,
                }} />
                <div id={this.roadview_id} style={{
                    display: this.props.roadview ? "block" : "none",
                }} />
            </div>
        );
    }
}

export default SeogwipoMapContainer;
