import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import { Button, DropdownItem, Dropdown, DropdownMenu, DropdownToggle, ButtonGroup, ButtonDropdown, } from "reactstrap";
import { inside, validServiceArea, shuttle_stop_list } from "./SeogwipoCommon.js";
import { drawPassengerList, isBoardedUnderAge } from "../../Component/PageCommon.js"

class ShuttleAdminPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            service_on: false,
            trip_on: false,
            passenger_info: [],
            next_shuttle_stop_id: undefined,
            dropdownOpen: false,
            selected_shuttle_stop_id: 0,
            num_passenger: 0,

            invitation_service_on: false,
            invitation: false,
        };
        this.shuttle_stop_list = shuttle_stop_list;
    }

    async getCallState() {
        await axios
            .get("/api/seogwipo/shuttle/getCallState", {
                params: {
                    isClient: false,
                    invitation: this.state.invitation
                }
            })
            .then(function (res) {
                if (res.statusText === "OK") {
                    return res.data;
                } else {
                    return {
                        car_location: undefined,
                        service_on: false,
                        invitation_service_on: false,
                        trip_on: false,
                        total_trip_cnt: 0,
                        next_shuttle_stop_id: undefined,
                    };
                }
            })
            .catch(function (error) {
                console.error("error while request GET to server");
                console.log(error);
                return {
                    car_location: undefined,
                    service_on: false,
                    invitation_service_on: false,
                    trip_on: false,
                    total_trip_cnt: 0,
                    next_shuttle_stop_id: undefined,
                };
            })
            .then((data) => {
                let cur = this.state.invitation ? data.private_service_on : data.service_on;
                this.setState({
                    car_timeout: data.car_timeout,
                    passenger_info: data.passenger,
                    car_location: data.car_location,
                    service_on: data.service_on,
                    invitation_service_on: data.private_service_on,
                    trip_on: data.trip_on,
                    total_trip_cnt: data.total_trip_cnt,
                    next_shuttle_stop_id: data.next_shuttle_stop_id,
                    cur_service: cur,
                    num_passenger: data.num_passenger
                });
            });
    }

    async getShuttleStops() {
        await axios.get("/api/seogwipo/shuttle/get-stops", {}
        ).then((res) => {
            this.shuttle_stop_list = res.data;
        }).catch((err) => {
            console.error(err);
        });
    }
    async disableService() {
        await axios
            .put("/api/seogwipo/shuttle/disableService")
            .then(function (res) {
                if (res.statusText === "OK") {
                } else {
                    alert("서비스 끄기에 실패했습니다.");
                }
            })
            .catch(function (error) {
                alert("서비스 끄기에 실패했습니다.");
            }).then((data) => {
                this.setState({
                    passenger_info: [],
                    service_on: false,
                    invitation_service_on: false,
                    cur_service: false,
                })
            }
            );
    }

    async ableService() {
        await axios
            .put("/api/seogwipo/shuttle/ableService", {
                invitation: this.state.invitation
            }).then(function (res) {
                if (res.statusText === "OK") {
                    return true;
                } else {
                    alert("서비스 켜기에 실패했습니다.");
                }
            }).catch(function (error) {
                alert("서비스 켜기에 실패했습니다.");
                return {
                    private_service_on: false,
                    service_on: false,
                }
            }).then((data) => {
                this.setState({
                    service_on: !this.state.invitation,
                    invitation_service_on: this.state.invitation,
                    cur_service: true,
                });
            });
    }

    async disableTrip() {
        await axios
            .put("/api/seogwipo/shuttle/disableTrip")
            .then(function (res) {
                if (res.statusText === "OK") {
                } else {
                    alert("순환 운행 종료 실패했습니다.");
                }
            })
            .catch(function (error) {
                alert("순환 운행 종료 실패했습니다.");
            }).then((data) => {
                this.setState({
                    trip_on: false
                })
            }
            );
    }

    async ableTrip() {
        await axios
            .put("/api/seogwipo/shuttle/ableTrip")
            .then(function (res) {
                if (res.statusText === "OK") {
                    return true;
                } else {
                    alert("순환 운행 실행 실패했습니다.");
                }
            }).catch(function (error) {
                alert("순환 운행 실행 실패했습니다.");
            }).then((data) => {
                this.setState({
                    trip_on: true
                })
            }
            );
    }

    async componentDidMount() {
        this.intervalId = await setInterval(() => this.getCallState(), 2000);
        await this.getShuttleStops();
        this.getCallState();
    }
    async componentWillUnmount() {
        if (this.intervalId !== undefined)
            clearInterval(this.intervalId);
    }

    async redirect(path) {
        clearInterval(this.intervalId);
        this.props.history.push(path);
    }

    async logout() {
        sessionStorage.removeItem("token");
        this.props.history.go(0);
    }

    getLogOutBtn() {
        return (<div
            style={{
                margin: "auto",
                width: "90%",
                padding: "10px 0",
                textAlign: "right",
                color: "black",
            }}
        >
            <Button sm={10} onClick={() => this.logout()}>Log Out</Button>
        </div>);
    }
    stopSelected(idx) {
        this.setState({
            selected_shuttle_stop_id: parseInt(idx)
        })
        this.toggle()
    }
    async sendSoundTrigger() {
        await axios.put("/api/seogwipo/shuttle/audio_guidance", {
            audio_guidance_code: 0
        }).then(function (res) {
            if (res.statusText === "OK") {
                return true;
            } else {
                alert("음성안내에 실패했습니다.");
            }
        }).catch(function (error) {
            alert("음성안내에 실패했습니다.");
        })
    }
    async setNextStop() {
        await axios.put("/api/seogwipo/shuttle/next_stop", {
            next_shuttle_stop_id: this.state.selected_shuttle_stop_id
        }).then(function (res) {
            if (res.statusText === "OK") {
                return true;
            } else {
                alert("정류장 설정에 실패했습니다.");
            }
        }).catch(function (error) {
            alert("정류장 설정에 실패했습니다.");
        }).then((data) => {
            let idx = this.state.selected_shuttle_stop_id + 1;
            if (this.shuttle_stop_list.length === idx) {
                idx = 0;
            }
            this.setState({
                selected_shuttle_stop_id: idx,
                num_passenger: 0
            })
        });
        this.getCallState();
    }
    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    getDropdownItem(idx, name, description) {
        return (<DropdownItem name={name} value={description} onClick={this.stopSelected.bind(this, idx)} >{description}</DropdownItem>)
    }

    getDropdown() {
        const { dropdownOpen } = this.state;
        let items = []
        for (const shuttle_stop_idx in this.shuttle_stop_list) {
            let shuttle_stop = this.shuttle_stop_list[shuttle_stop_idx];
            items.push(this.getDropdownItem(shuttle_stop_idx, shuttle_stop["name"], shuttle_stop["description"]));
        }
        let shuttle_stop_text = (<div />);
        if (this.state.next_shuttle_stop_id !== undefined) {
            shuttle_stop_text = (<h2 style={{ color: "blue" }}>{this.shuttle_stop_list[this.state.next_shuttle_stop_id]["description"]} 정류장으로 이동 중입니다.</h2>);
        }
        return (
            <div>
                <br />
                {shuttle_stop_text}
                <br />
                <ButtonGroup>
                    <ButtonDropdown direction="down" isOpen={dropdownOpen} toggle={this.toggle.bind(this)}>
                        <DropdownToggle caret>
                            {this.shuttle_stop_list[this.state.selected_shuttle_stop_id]["description"]}
                        </DropdownToggle>
                        <DropdownMenu name="shuttleStop" >
                            {items}
                        </DropdownMenu>
                    </ButtonDropdown>
                    <Button size="md" color="success" onClick={this.setNextStop.bind(this)}>정류장으로 출발</Button>
                </ButtonGroup>
                <br />
            </div>
        )
    }
    getNumPassenger() {
        let text = !!!this.state.num_passenger ?
            "현재 탑승 등록한 승객이 없습니다." : <>현재 <b>{this.state.num_passenger}</b>명이 탑승 등록 했습니다.</>
        return (
            <div style={{ marginTop: "1em" }}>
                {text}
            </div>
        )
    }

    isInvitation() {
        let link_txt = this.state.invitation ? "- 초대승객용 -" : "- 모든승객용 -";
        let btn_txt = this.state.invitation ? "모든 승객에게 서비스하기" : "초대 승객에게 서비스하기";
        return (
            <>
                <div style={{ fontSize: "1.8em", color: this.state.invitation ? "orange" : "#33f" }}>{link_txt}</div>
                <a href="#;" onClick={() =>
                    this.setState({
                        invitation: !this.state.invitation,
                        cur_service: !this.state.invitation ? this.state.invitation_service_on : this.state.service_on
                    })
                }>
                    {btn_txt}
                </a>
            </>
        );
    }
    render() {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#F9DEDE";
        const { cur_service, car_timeout, car_location, trip_on, total_trip_cnt, passenger_info } = this.state;

        let logoutBtn = this.getLogOutBtn();
        let contents = (<div />);
        let shuttle_stop_contents = (<div />);
        let num_registered_passenger = this.state.next_shuttle_stop_id !== undefined ? this.getNumPassenger() : null;
        let passengers = drawPassengerList(passenger_info);
        let warning_under_age = isBoardedUnderAge(passenger_info);

        if (cur_service) {
            if (inside(car_location, validServiceArea)) {
                if (trip_on) {
                    shuttle_stop_contents = this.getDropdown();
                    contents = (<div>
                        <h3>총 운행 횟수: {total_trip_cnt}
                            <h5>* 2021/8/3부터 집계</h5>
                        </h3>
                        <h2 style={{ color: "blue" }}>순환 노선을 주행 중입니다. </h2>
                        {shuttle_stop_contents}
                        <br/>
                        <Button size="md" color="info" onClick={this.sendSoundTrigger.bind(this)}>탑승객에게 음성안내</Button>
                        {num_registered_passenger}
                        <Button style={{ width: "70vw", marginTop: "2em" }} size="md" color="warning" onClick={this.disableTrip.bind(this)}>
                            순환 운행 종료
                        </Button>
                        <Button style={{ width: "70vw", marginTop: "0.75em" }} size="md" color="danger" onClick={this.disableService.bind(this)}>
                            서비스 종료
                        </Button>
                    </div>);
                } else {
                    contents = (<div>
                        <h3>
                            총 운행 횟수: {total_trip_cnt}
                            <h5>* 2021/8/3부터 집계</h5>
                        </h3>
                        <h2>
                            <Button style={{ width: "70vw", marginTop: "2em" }} size="md" color="info" onClick={this.ableTrip.bind(this)}>
                                순환 운행 시작
                            </Button>
                            <Button style={{ width: "70vw", marginTop: "0.75em" }} size="md" color="danger" onClick={this.disableService.bind(this)}>
                                서비스 종료
                            </Button>
                        </h2>
                    </div>);
                }
            } else {
                contents = (<div><h2 color="red">차량이 운영구역을 벗어났습니다. 서비스를 중단 해주세요! <Button style={{ width: "70vw", marginTop: "2em" }} size="md" color="danger" onClick={this.disableService.bind(this)}>서비스 종료</Button></h2></div>);
            }
        } else if (car_timeout || car_location === undefined) {
            contents = (<div><h2 style={{ color: "red" }}>차량의 위치를 확인할 수 없어 서비스를 시작할 수 없습니다.</h2></div>);
        } else if (!car_timeout && !inside(car_location, validServiceArea)) {
            contents = (<div><h2 style={{ color: "red" }}>차량이 운영구역 밖에 있어 서비스를 시작할 수 없습니다.</h2></div>);
        } else {
            contents = (<div><h2 style={{ color: "red" }}>고객이 탑승을 할 수 없는 상태입니다. <Button style={{ width: "70vw", marginTop: "2em" }} size="md" color="info" onClick={this.ableService.bind(this)}>서비스 시작</Button></h2></div>);
        }
        return (
            <div style={{ height: "100%" }}>
                {logoutBtn}
                <div
                    style={{
                        margin: "auto",
                        width: "70%",
                        paddingTop: "5vh",
                        textAlign: "center",
                        color: "black",
                    }}
                >
                    <h1><b>- 서귀포 셔틀 운영자 페이지 - </b></h1>
                    {this.isInvitation()}
                    <br />
                    <br />
                    {contents}
                    {cur_service ? warning_under_age : <div marginTop="2vh" />}
                    {passengers}
                </div>
            </div >
        );
    }
}
export default withRouter(ShuttleAdminPage);
