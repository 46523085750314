import React, { useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { randomAnnulusPoint } from 'random-location';
import { booleanPointInPolygon, polygon, point } from '@turf/turf'
import { Button } from "reactstrap";
import ReactSound from "react-sound";
import { useInterval } from "../../Hooks/Hooks.js";
import AdminTopBar from "../../Component/TopBar.js";
import LogoutButton from "../../Component/LogoutButton.js";
import SelectCar from "../../Component/SelectCar.js";
import KakaoMap from "../../Component/Map/KakaoMap.js";
import KakaoMarker from "../../Component/Map/KakaoMarker.js";
import KakaoCircle from "../../Component/Map/KakaoCircle.js";
import KakaoCustomOverlay from "../../Component/Map/KakaoCustomOverlay.js";
import '../../style/TestAdmin.css';
import LocationButton from "../../Component/LocationButton.js";

const MAX_RADIUS = 2000; // meter
const MIN_RADIUS = 300; //meter

const BRAND_NAME = "NEMORIDE";
const INITIAL_CENTER = {latitude: 33.450695558367116, longitude: 126.5665741715234};

const MODE_KEY = 'nemoride/goal/btn/mode';
const RANDOM_MODE = 'random';
const SELECT_MODE= 'select';
const MAXIMUM_ITER = 100;

const DESTINATION_SOUND_FILE = "../sound/doremisol.mp3";

const ODD = [
    { latitude: 33.46463, longitude: 126.57563},
    { latitude: 33.46408, longitude: 126.56367},
    { latitude: 33.46160, longitude: 126.55499},
    { latitude: 33.44822, longitude: 126.55467},
    { latitude: 33.43719, longitude: 126.56651},
    { latitude: 33.43748, longitude: 126.57615},
    { latitude: 33.44832, longitude: 126.58500},
    { latitude: 33.46463, longitude: 126.57563},
].map(({latitude, longitude}) => [longitude, latitude]);

const INIT_CAR_INFO = {
    car_timeout: true,
    current_service: "",
    pose: undefined,
    heading: undefined,
}

const NemoRideTestAdmin = (props) => {
    const {
        history,
        location,
    } = props;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const {
        car_name,
    } = query;

    const [carName, setCarName] = useState(car_name);
    const [carInfo, setCarInfo] = useState(INIT_CAR_INFO);
    const [serviceOn, setServiceOn] = useState(false);
    
    const [centerPosition, setCenterPosition] = useState(INITIAL_CENTER);
    const [adpPoint, setAdpPoint] = useState(undefined);
    const [mode, setMode] = useState(SELECT_MODE);

    const [soundStatus, setSoundStatus] = useState("STOPPED");

    const [isLoading, setIsLoading] = useState(false);
    const mapDragEndAbortController = useRef(undefined);
    const randomGoalAbortController = useRef(undefined);

    const getCarInfo = useCallback(async () => {
        if (!carName) {
            return;
        }
        try {
            const res = await axios.get(`/api/nemoride/get-car-data`, {
                params: {
                    isClient: false,
                    car_name: carName,
                }
            });
            const {
                data
            } = res;
            setCarInfo(data);
            setServiceOn(data.current_service?.toUpperCase() === BRAND_NAME);
        }
        catch (err) {
            const car_info = {...INIT_CAR_INFO};
            let service_on = false;
            if (!err.response) {
                console.error(err);
                return;
            }
            if (err.response.status === 404 | err.response.status === 400) {
                console.error(`${carName} 차량을 찾을 수 없습니다.`);
            }
            else if (err.response.status === 503) {
                console.error(`${carName} 차량 정보를 받을 수 없는 상태입니다..`);
                console.error(err);
            }
            else if (err.response.status === 403) {
                console.error(`${carName} 차량 서비스를 받을 수 없습니다.`);
                console.error(err);
                const service_name = err.response.data ?? "";
                car_info.car_timeout = false;
                car_info.current_service = service_name;
                service_on = true;
            }
            setCarInfo(car_info);
            setServiceOn(service_on);
        }
    }, [carName]);

    const selectCarCallback = useCallback((car_name) => {
        setCarName(car_name);
        history.replace({
            search: `?car_name=${car_name}`
        });
    },[history]);

    const getADP = useCallback(async (location_point, abortController) => {
        if (abortController.current) {
            abortController.current.abort('previous request canceled');
        }
        abortController.current = new AbortController();
        return await axios
            .post(
                "/adp/seogwipo/floating/get-point",
                {
                    ...location_point,
                    type: "AP",
                },
                { signal: abortController.current.signal }
            )
            .then(function (res) {
                abortController.current = undefined;
                if (res.statusText === "OK") {
                    return res.data;
                } else {
                    return undefined;
                }
            })
            .catch(function (e) {
                console.error("error while POST to adp");
                console.log(e);
            })
            .then((data) => {
                if (data) {
                    return {
                        latitude: data.start_latitude,
                        longitude: data.start_longitude,
                    };
                }
                return;
            });
    }, []);

    const registerRoamingTrip = useCallback(async (car_name, geo_point) => {
        const { latitude, longitude } = geo_point;
        const res = await axios.post('/api/nemoride/register-roaming-trip',
            { car_name: car_name, goal: { latitude, longitude } },
        )
        .catch((err) => {
            console.error("error while POST to adp");
            console.error(err);
            return err.response;
        })
        if (res.status === 200) {
            navigator.vibrate(500);
            setSoundStatus("PLAYING");
        }
        else {
            alert("목적지 전송에 실패했습니다.");
        }
    }, []);

    const setRandomGoal = async () => {
        if (!carInfo?.pose) {
            return;
        }
        let random_point = undefined;
        let inside_odd = false;
        let i = 0;
        do {
            random_point = randomAnnulusPoint({
                latitude: carInfo.pose.latitude,
                longitude: carInfo.pose.longitude,
            }, MIN_RADIUS, MAX_RADIUS);
            const random_point_turf = point([random_point.longitude, random_point.latitude]);
            inside_odd = booleanPointInPolygon(random_point_turf, polygon([ODD]));
            i++;
        } while(
            (!inside_odd) && i < MAXIMUM_ITER
        );
        if (!inside_odd) {
            alert(`${MAXIMUM_ITER}번 안에 random goal을 설정하는데 실패했습니다. 재시도 해주십시요.`);
            return;
        }

        const adp = await getADP(random_point, randomGoalAbortController);
        return adp;
    }

    const onCenterChange = useCallback((map, event) => {
        setCenterPosition(map.getCenter());
    }, []);

    const moveToCurrentCarLocation = useCallback(() => {
        if (carInfo.pose?.latitude && carInfo.pose?.longitude) {
            setCenterPosition(carInfo.pose);
        }
    }, [carInfo]);

    const changeToOppositeLocation = useCallback(async () => {
        setIsLoading(true);
        try {
            await axios.put("/api/nemoride/change-to-oppsite-location", { car_name: carName });
        }
        catch (err) {
            if (err.response?.data === 'too_far') {
                alert("건너편 목적지가 없는 목적지입니다.");
            }
            else if (err.response?.data === 'no_destination') {
                alert("설정된 목적지가 없어 건너편으로 목적지를 설정할 수 없습니다.");
            }
            else {
                alert("건너편으로 목적지를 설정하는데 실패했습니다.");
            }
        }
        setIsLoading(false);
    }, [carName]);

    useEffect(() => {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#DFDFDF";
        const stored_mode = localStorage.getItem(MODE_KEY);
        if ([RANDOM_MODE, SELECT_MODE].includes(stored_mode)) {
            setMode(stored_mode);
        }
    }, []);

    useEffect(() => {
        getCarInfo().then(data => {
            if (data && data.pose?.[0] && data.pose?.[1]) {
                setCenterPosition(data.pose);
            }
        })
    }, [carName, getCarInfo]);

    useInterval(getCarInfo, 2000);

    return (
        <div style={{ height: "100%", position: "relative" }}>
            <AdminTopBar>
                <LogoutButton history={history} />
                <Button
                    sm={10}
                    style={{
                        float: "left",
                    }}
                    onClick={() => {
                        const new_mode =
                            mode === RANDOM_MODE ? SELECT_MODE : RANDOM_MODE;
                        setMode(new_mode);
                        localStorage.setItem(MODE_KEY, new_mode);
                    }}
                >
                    {" "}
                    {mode === RANDOM_MODE ? "특정으로" : "랜덤으로"}
                </Button>
                <SelectCar
                    selectedCar={carName}
                    selectCallback={selectCarCallback}
                />
            </AdminTopBar>
            <div
                style={{
                    margin: "auto",
                    marginTop: "1rem",
                    textAlign: "center",
                    color: "black",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "90%",
                }}
            >
                <ReactSound
                    url={DESTINATION_SOUND_FILE}
                    playStatus={soundStatus}
                    onFinishedPlaying={() => {
                        setSoundStatus("STOPPED");
                    }}
                />
                <h1 className="test-admin title">
                    <b>네모라이드 테스트 페이지</b>
                </h1>
                {carInfo.car_timeout ? (
                    <div style={{ fontSize: "1.7rem", color: "red" }}>
                        차량 위치를 확인할 수 없습니다.
                    </div>
                ) : (!serviceOn) ? (
                    <div style={{ fontSize: "1.7rem", color: "red" }}>
                        네모라이드 서비스가 꺼져있습니다.
                    </div>
                ) : (carInfo.current_service.toUpperCase() !== BRAND_NAME) ? (
                    <div style={{ fontSize: "1.7rem", color: "red" }}>
                        {!carInfo.current_service ? "기사앱의 출근하기를 눌러주세요." : `${carInfo.current_service} 서비스가 활성화되어 있습니다.`}
                    </div>
                ) : carInfo.has_service ? (
                    <>
                    <div style={{ fontSize: "1.7rem", color: "red" }}>
                        현재 서비스 주행 중입니다. 빈차 주행 기능을 활성화 할 수 없습니다.
                    </div>
                    <div>
                        <Button
                            disabled={isLoading}
                            onClick={changeToOppositeLocation}
                        >
                            건너편으로 목적지 설정
                        </Button>
                    </div>
                    </>
                ) : (
                    <>
                        <Button
                            className={`push-button ${
                                mode === RANDOM_MODE
                                    ? ""
                                    : "test-admin-invisible"
                            }`}
                            color="primary"
                            onClick={async () => {
                                const goal = await setRandomGoal();
                                if (goal) {
                                    setAdpPoint(goal);
                                    setCenterPosition(goal);
                                    await registerRoamingTrip(carName, goal);
                                }
                            }}
                        >
                            랜덤 목적지 설정
                        </Button>
                        <Button
                            className={`push-button ${
                                mode === SELECT_MODE
                                    ? ""
                                    : "test-admin-invisible"
                            }`}
                            color={isLoading ? "secondary" : "success"}
                            onClick={async () => {
                                if (!adpPoint) {
                                    return;
                                }
                                setIsLoading(true);
                                await registerRoamingTrip(carName, adpPoint);
                                getCarInfo();
                                setIsLoading(false);
                            }}
                        >
                            {isLoading ? "전송 중.." : "목적지 전송"}
                        </Button>
                    </>
                )}
                <br />
                <div style={{position: "relative", width: "100%"}}>
                    <KakaoMap
                        className="test-admin map-containter"
                        center={centerPosition}
                        level={6}
                        onCenterChange={onCenterChange}
                        onDragEnd={async (map) => {
                            const kakao_latlng = map.getCenter();
                            const adp = await getADP(
                                {
                                    latitude: kakao_latlng.getLat(),
                                    longitude: kakao_latlng.getLng(),
                                },
                                mapDragEndAbortController
                            );
                            if (adp) {
                                setAdpPoint(adp);
                            }
                        }}
                    >
                        <KakaoMarker position={centerPosition} />
                        <KakaoCustomOverlay
                            position={carInfo?.pose}
                            contentID={"car_icon"}
                        >
                            <img
                                src="/car_icon=mini.png"
                                alt="car_icon"
                                onClick={(e) => e.preventDefault()}
                                style={{
                                    transform: `rotate(${
                                        (isNaN(carInfo.heading) || carInfo.heading === null) ? 90 : 180 - carInfo.heading
                                    }deg)`,
                                }}
                            />
                        </KakaoCustomOverlay>
                        <KakaoCircle
                            position={carInfo?.pose}
                            radius={MAX_RADIUS}
                            fillOpacity={0}
                            strokeWeight={3}
                            strokeColor={"#000000"}
                            strokeOpacity={1}
                            strokeStyle={"dash"}
                        />
                        <KakaoMarker
                            position={adpPoint}
                            image={{
                                src: "/marker=arrival.svg",
                                width: 48,
                                height: 48,
                                options: {
                                    offset: [24, 40],
                                },
                            }}
                        />
                    </KakaoMap>
                    <LocationButton 
                        wrapperClassName=""
                        className="tamra-location-button"
                        style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                            margin: "0",
                        }}
                        onClick={moveToCurrentCarLocation}
                    />
                </div>
                <div className="reactive-font" style={{ marginTop: "5px" }}>
                    updated at{" "}
                    <b>{new Date(carInfo?.updated_time).toLocaleString()}</b>
                </div>
            </div>
        </div>
    );
}
export default withRouter(NemoRideTestAdmin);
