import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { agreementTextWithOption, agreementShortTextWithOption, isChild , isUnderAge } from "../../Component/Common.js";
import { getSeogwipoBg, completedService } from "./../Seogwipo/SeogwipoCommon.js"
import { Jumbotron } from 'reactstrap';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import { FaArrowsAltH } from 'react-icons/fa'

class JungmoonShuttlePassenger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            service_on: false,
            isModalOpened: false,
            isAgreed: false,
            phoneValid1: true,
            birthdateValid1: true,

        };
    }
    async getCallState() {
        await axios.get("/api/jungmun/shuttle/getCallState", {
            params: {}
        }).then(function (res) {
            if (res.statusText === "OK") {
                return res.data;
            } else {
                return {
                    service_on: false,
                    car_location: undefined
                };
            }
        }).catch(function (error) {
            console.error("error while request GET to server");
            console.log(error);
            return {
                service_on: false,
                car_location: undefined
            };
        }).then((data) =>
            this.setState({
                ...this.state,
                service_on: data.service_on,
                car_location: data.car_location,
            })
        );
    }

    async componentDidMount() {
        this.intervalId = await setInterval(() => this.getCallState(), 2000);
        this.getCallState();
    }
    async componentWillUnmount(){
        if(this.intervalId !== undefined){
            clearInterval(this.intervalId);}
    }


    getCustomerInfo() {
        return (
            <div className="form-wrapper">
                <p className="Seogwipo">탑승객 정보 입력하기 </p>
                {this.getForm()}
            </div>
        );
    }
    getAgreePage() {
        let modal = this.getAgreementModal();
        let agreeText = this.getAgreeText();
        let agreeText2 = this.getAgreeText2();
        let agreeText3 = this.getAgreeText3();
        let confirmBtn = this.getConfirmBtn();
        return (
            <div>
                <p className="Seogwipo">탑승 전 안내사항</p>
                <div className = "consent-form">
                    <Jumbotron className = "consent-form jumbotron">
                        {agreementShortTextWithOption}
                        <Button className="btn-consent-form"
                            onClick={this.toggle} 
                        >동의서 전문 확인하기 </Button>
                    </Jumbotron>
                </div>
                {modal}
                <div className="agree-box">
                    {agreeText}
                    {agreeText2}
                    {agreeText3}
                </div>
                <div >
                    {confirmBtn}
                </div>
            </div>);
    }
    handleNameChange1 = e => {
        this.setState({ name1: e.target.value });
    }
    handleBirthChange1 = e => {
        this.setState({ birthdate1: e.target.value });
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            this.phoneInput1.focus();
        }
    }
    handlePhoneChange1 = e => {
        this.setState({ phone1: e.target.value });
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            e.target.blur();
        }
    }
    handlePhoneBlur1 = e => {
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            this.setState({
                phoneValid1: true
            });
        } else {
            this.setState({
                phoneValid1: false
            });
        }
    }

    handleBdayBlur1 = e => {
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            try {
                new Date(e.target.value);
                this.setState({
                    birthdateValid1: true,
                    birthdateUnderage1: isUnderAge(e.target.value)
                });
            } catch {
                this.setState({
                    birthdateValid1: false
                });
            }
        } else {
            this.setState({
                birthdateValid1: false
            });
        }
    }
    getBirthDateHelperText() {
        if (!this.state.birthdateValid1) {
            return '8자리를 년/월/일 순으로 입력해주세요'
        }
        if (this.state.birthdateUnderage1) {
            return '미성년자는 법적대리인의 서면 동의가 필요합니다. \n 제출 완료 후 차량 내 안전요원에게 안내 요청 부탁드립니다.'
        }
        return '';
    }
    getForm() {
        return (
            <div className="form">
                <TextField
                    required
                    id="passenger_name"
                    label="탑승자 성함"
                    fullWidth
                    onChange={this.handleNameChange1}
                    defaultValue={this.state.name1}
                />
                <NumberFormat
                    customInput={TextField}
                    format="####-##-##"
                    error={!this.state.birthdateValid1 || this.state.birthdateUnderage1}
                    helperText={this.getBirthDateHelperText()}
                    required
                    id="passenger_birthdate"
                    label="생년월일(YYYY-MM-DD)"
                    type="tel"
                    fullWidth
                    onChange={this.handleBirthChange1}
                    defaultValue={this.state.birthdate1}
                    onBlur={this.handleBdayBlur1}
                />
                <NumberFormat
                    customInput={TextField}
                    format="###-####-####"
                    error={!this.state.phoneValid1}
                    helperText={!this.state.phoneValid1 ? '11자리를 입력해주세요' : ' '}
                    inputProps={{ ref: input => this.phoneInput1 = input }}
                    required
                    id="passenger_phone"
                    label="휴대전화"
                    type="tel"
                    name="phone"
                    fullWidth
                    onChange={this.handlePhoneChange1}
                    defaultValue={this.state.phone1}
                    onBlur={this.handlePhoneBlur1}
                />
            </div>);
    }

    toggle = () => {
        this.setState({ isModalOpened: !this.state.isModalOpened });
    };
    getAgreementModal() {
        return (
            <Modal isOpen={this.state.isModalOpened} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>동의서 전문</ModalHeader>
                <ModalBody>{agreementTextWithOption}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>확인</Button>
                </ModalFooter>
            </Modal>
        );
    }
    getAgreeText() {
        let check_icon = (this.state.isAgreed ? <AiFillCheckCircle className= "checkbox fill"/> : <AiOutlineCheckCircle className="checkbox"/>);
        return (
            <Button id="agree-btn"
                onClick={() => { this.setState({ isAgreed: !this.state.isAgreed }) }}>{check_icon}
                 <span className={this.state.isAgreed ? "on": ""}>(필수) 동의사항을 숙지하였으며 이에 동의합니다.</span></Button>);
    }
    getAgreeText2() {
        let check_icon = (this.state.isAgreed2 ? <AiFillCheckCircle className= "checkbox fill"/> : <AiOutlineCheckCircle className="checkbox"/>);
        return (
            <Button id="agree-btn"
                onClick={() => { this.setState({ isAgreed2: !this.state.isAgreed2 }) }}>{check_icon} 
                <span className={this.state.isAgreed2 ? "on": ""}>(필수) 개인정보 수집 및 이용에 동의합니다.</span></Button>);
    }
    getAgreeText3() {
        let check_icon = (this.state.isAgreed3 ? <AiFillCheckCircle className= "checkbox fill"/> : <AiOutlineCheckCircle className="checkbox"/>);
        return (
            <Button id="agree-btn"
                onClick={() => { this.setState({ isAgreed3: !this.state.isAgreed3 }) }}>{check_icon} 
                <span className={this.state.isAgreed3 ? "on": ""}>(선택) 개인정보 제3자 제공에 동의합니다</span></Button>);
    }
    getConfirmBtn() {
        let passengerInfoSet = false;
        if (this.state.name1 !== undefined && this.state.birthdate1 !== undefined && this.state.phone1 !== undefined
            && this.state.birthdateValid1 && this.state.phoneValid1) {
            passengerInfoSet = true;
        }
        if (passengerInfoSet && this.state.isAgreed && this.state.isAgreed2 && !isChild(this.state.birthdate1)) {
            return (<Button className="press-btn Seogwipo" id = "able"
                    onClick={this.requestCall} form='passengerForm' >제출하기</Button>);
        } else {
            let warning_tex="";
            if (isChild(this.state.birthdate1)) {
                warning_tex = <div style={{ color: "red", textAlign: "center" }}>* 6세 이하는 탑승할 수 없습니다.</div>
            }
            return (<div>{warning_tex}
                        <Button className="press-btn Seogwipo" id = "disable"
                        form='passengerForm' disabled >제출하기</Button></div>);
        }
    }

    requestCall = event => {
        event.preventDefault();
        axios.put("/api/jungmun/shuttle/passenger",
            {
                'passenger':
                {
                    'name': this.state.name1, 'birthdate': this.state.birthdate1, 'phone': this.state.phone1,
                    'third_party': !!this.state.isAgreed3,
                },
            }).then(function (res) {
                if (res.statusText === "OK" && res.data.registered) {
                    alert("제출 완료되었습니다.");
                    // window.location.assign("/jungmun");
                    return {};
                } else {
                    alert("제출 실패하였습니다. 다시 제출해주세요.");
                    return {};
                }
            }).catch(function (error) {
                console.error("error while request GET to server");
                console.log(error);

                alert("제출 실패하였습니다. 다시 제출해주세요.");
                return {};
            });
        // ReactGA.event({
        //     category: 'User',
        //     action: 'Request Call'
        // });
    }

    render() {
        const { service_on, car_location } = this.state;

        if (service_on === true) {
            return (<div className="main">
                {getSeogwipoBg()}
                <div className="Seogwipo-Main-box agree-page">
                    <div className="Seogwipo-logo-box passenger">
                        <img className="RF-Logo" src="/RF_LOGO_BLUE.png" alt="/RF_LOGO_WHITE.png"/>
                    </div>
                    <div className="main-name "> 제주국제공항 <FaArrowsAltH className="arrow-alt-icon"/> 중문관광단지 </div>
                    <div className="sub-name "> 자율주행 모빌리티 서비스 </div>
                </div>
                <div className="Seogwipo-Main-round-box passenger">
                    {this.getCustomerInfo()}
                    <div className="line-divider"/>
                    {this.getAgreePage()}
                </div>
            </div>);
        } else {
            return (
                completedService("지금은 서비스 중이 아닙니다.", "서비스 예약 후 이용해주세요.", {display: false}, {className: "center"}, {className: "center"})
            );
        }
    }
}
export default withRouter(JungmoonShuttlePassenger);
