import axios from 'axios';
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Divider } from "@material-ui/core";

class Database extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active_tab: 0,
            car_data: undefined,
        };
    }

    async getData() {
        await axios.get("/api/admin/get-all-data")
        .then((res) => this.setState({ car_data: res.data.car_data }))
        .catch((error) => {
            console.error("error while request GET to server");
            console.log(error);
        });
    }

    async componentDidMount() {
        this.intervalId = setInterval(() => this.getData(), 2000);
        this.getData();
    }
    async componentWillUnmount() {
        if (this.intervalId !== undefined) {
            clearInterval(this.intervalId);
        }
    }

    getNav() {
        if (!!!this.state.car_data) return null;
        let items = [];
        let keys = Object.keys(this.state.car_data);
        for (const i in keys) {
            items.push(
                <NavItem key={"car " + i} style={{ marginRight: "-1px" }}>
                    <NavLink
                        key={"car link " + i}
                        style={
                            this.state.active_tab === Number(i) + 1
                                ? {
                                      fontWeight: "bold",
                                      color: "blue",
                                      backgroundColor: "#fff",
                                      borderColor:
                                          "#dee2e6 #fff #dee2e6 #dee2e6",
                                  }
                                : {}
                        }
                        className={classnames({
                            active: this.state.active_tab === Number(i) + 1,
                        })}
                        onClick={() => {
                            this.setState({ active_tab: Number(i) + 1 });
                        }}
                    >
                        {keys[i]}
                    </NavLink>
                </NavItem>
            );
        }
        return (
            <div
                style={{
                    height: "auto",
                    width: "auto",
                    minWidth: "180px",
                    float: "left",
                    borderRight: "1px solid #dee2e6",
                    textAlign: "center",
                }}
            >
                <div style={{ margin: "40px 0" }}>
                    <p>Data List</p>
                    <Divider style={{ color: "black", marginTop: "20px" }} />
                </div>
                <Nav
                    vertical
                    tabs
                    style={{
                        border: "0px solid",
                    }}
                >
                    {items}
                </Nav>
            </div>
        );
    }
    recursiveConstruct(data, key, depth = 0) {
        if (Array.isArray(data)) {
            const items = data.map((val, idx, arr) => {
                const result = this.recursiveConstruct(
                    val,
                    `${depth + 1}-depth-${key}-array-item-${idx}`,
                    depth + 1
                );
                return result;
            });
            return (
                <div key={`${depth + 1}-depth-${key}-array-wrapper`}>
                    {items.every((item) => typeof item !== "object")
                        ? items.join(", ")
                        : items}
                </div>
            );
        } else if (typeof data === "object") {
            if (!!!data) return null;

            return (
                <Table
                    striped
                    hover
                    key={`${key}-object`}
                    style={{ textAlign: "left" }}
                >
                    <tbody>
                        {Object.entries(data).map((val, idx) => {
                            return (
                                <tr key={`${key}-${idx}th-tr`}>
                                    <th
                                        style={{
                                            display: "inline",
                                            float: "left",
                                        }}
                                    >
                                        {val[0]}
                                    </th>
                                    <td
                                        style={{
                                            display: "inline",
                                            float: "left",
                                        }}
                                    >
                                        {" "}
                                        {this.recursiveConstruct(
                                            val[1],
                                            `${key}-obejct-${idx}th-td`,
                                            depth + (1)[1]
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            );
        } else {
            if (typeof data === "number") return data.toFixed(5);
            else if (typeof data === "boolean") return data ? "true" : "false";
            return data;
        }
    }
    getItem(car_name, car_data) {
        if (!!!this.state.car_data) {
            return (
                <div>
                    {" "}
                    {!!car_name && typeof car_name === "string"
                        ? car_name
                              .split("_")
                              .map(
                                  (str) =>
                                      str.charAt(0).toUpperCase() + str.slice(1)
                              ) + " is "
                        : ""}{" "}
                    No data{" "}
                </div>
            );
        }
        let items = Object.entries(car_data).map((data_arr, data_idx) => {
            let key = data_arr[0],
                data = this.recursiveConstruct(
                    data_arr[1],
                    `car-data-${data_idx}`
                );

            return (
                <tr key={data_idx + " at " + data_arr[0]}>
                    <th style={{ verticalAlign: "middle" }}>{key}</th>
                    <td style={{ verticalAlign: "middle" }}>{data}</td>
                </tr>
            );
        });
        return (
            <Table
                borderless
                striped
                hover
                style={{ textAlign: "center", fontSize: "14px" }}
            >
                <tbody>{items}</tbody>
            </Table>
        );
    }
    getItems() {
        if (!!!this.state.car_data) return null;
        let tab_items = [];
        let keys = Object.keys(this.state.car_data);
        for (const i in keys) {
            tab_items.push(
                <TabPane key={"car_name_" + keys[i]} tabId={Number(i) + 1}>
                    {this.getItem(keys[i], this.state.car_data[keys[i]])}
                </TabPane>
            );
        }
        return (
            <TabContent
                activeTab={this.state.active_tab}
                style={{ margin: "10vh 40px" }}
            >
                {tab_items}
            </TabContent>
        );
    }

    render() {
        return (
            <div
                className="main"
                style={{
                    display: "flex",
                }}
            >
                {this.getNav()}
                {this.getItems()}
            </div>
        );
    }
}
export default withRouter(Database);
