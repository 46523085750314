import React, { useState, useMemo, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Sound from "react-sound";
import { Button, Table } from "reactstrap";
import { tamraJungmunValidServiceArea } from "../../../constants/ValidServiceArea.js";
import { useGetCallState } from "../../Hooks/Hooks.js"
import LogoutButton from "../../Component/LogoutButton.js";
import { getRandomPoint, inside } from "../../../util/Util.js"
import SeogwipoMiniMapContainer from "../Seogwipo/SeogwipoMiniMapContent.js"
import SelectCar from "../../Component/SelectCar.js";
import { isUnderAge } from "../../Component/Common.js";
import AdminTopBar from "../../Component/TopBar.js";
import DarkModeButton from "../../Component/DarkModeButton.js";

const AREA_BOX = {
    lat: {min: 33.260907362542376, max: 33.23363520953888},
    lng: {min: 126.39251683367091, max: 126.4369934821072},
}

const LOCATION = "tamra";
const SERVICE = "jungmun";
const SERVICE_NAME = [LOCATION.toUpperCase(), SERVICE.toUpperCase()].join('_');

const TamraAdminPage = (props) => {
    const {
        history,
        location,
    } = props;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const {
        car_name
    } = query;


    const [carName, setCarName] = useState(car_name);
    const [viewPassengerInfo, setViewPassengerInfo] = useState(false);
    const [currentPath, setCurrentPath] = useState(undefined);

    const [carInfo, service, _, getCallState] = useGetCallState(carName, LOCATION, SERVICE, 2000, {
        isClient: false,
    });
    const [departureLocation, setDepartureLocation] = useState(undefined);
    const [destinationLocation, setDestinationLocation] = useState(undefined);

    const selectCarCallback = useCallback((car_name) => {
        setCarName(car_name);
        history.replace({
            search: `?car_name=${car_name}`
        });
    },[history]);

    const getGlobalPath = useCallback(async (departure, destination) => {
        if (departure === undefined || destination === undefined){
            console.error("undefined depature & destination set");
            return {};
        }
        const result = await axios.post(`/adp/${LOCATION}/${SERVICE}/get-path`, {
            departure: departure,
            destination: destination,
        }).then((res) => {
            const { data } = res;
            const { goal, recommended } = data;
            return {
                goal: !!data.goal,
                goal_location: [goal?.location.latitude, goal?.location.longitude, goal?.theta],
                goal_path: goal?.path.map(point => [point.latitude, point.longitude]),
                goal_path_length: goal?.length,
                recommendation: !!data.recommendation,
                recommendated_goal: [recommended?.location.latitude, recommended?.location.longitude, recommended?.theta],
                recommendated_path: recommended?.path.map(point => [point.latitude, point.longitude]),
                recommendated_length: recommended?.length,
            }
        }).catch((err) => {
            console.error("error while request GET to server");
            console.log(err);
            return {
                goal: false,
                goal_location: undefined,
                goal_path: undefined,
            }
        });
        return result;
    },[]);

    const ableCall = useCallback(async () => {
        await axios.put(`/api/${LOCATION}/${SERVICE}/ableCall`, { car_name: carName})
        .catch(function (error) {
            alert("호출 받기 활성화에 실패했습니다.");
        });
        getCallState();
    },[carName, getCallState]);

    const disableCall = useCallback(async () => {
        await axios.put(`/api/${LOCATION}/${SERVICE}/disableCall`, { car_name: carName})
        .catch(function (error) {
            alert("호출 받기 활성화에 실패했습니다.");
        });
        await getCallState();
    },[carName, getCallState]);

    const checkCall = useCallback(async () => {
        const result = await axios.put(`/api/${LOCATION}/${SERVICE}/checkCall`, { car_name: carName })
        .then((res) => res.statusText === "OK")
        .catch(function (error) {
            alert("예약 확인이 실패했습니다. 필요한 추가 작업은 없습니다.");
            return undefined;
        });
        if (result) {
            getCallState();
        }
    },[carName, getCallState]);

    const cancelCall = useCallback(async (service_ids) => {
        if(window.confirm("호출을 취소하시겠습니까?")){
            let result;
            if (Array.isArray(service_ids)) {
                const all_settled_list = await Promise.allSettled(service_ids.map(service_id => 
                    axios.put(`/api/${LOCATION}/${SERVICE}/cancelCall`,{
                        service_id: service_id,
                        car_name: carName,
                    })
                ));
                const cancel_failed_list = [];
                const msg_failed_list = [];
                for (const promise_result of all_settled_list) {
                    const {
                        status, value
                    } = promise_result;
                    if (status === 'rejected') {
                        cancel_failed_list.push(status);
                    }
                    else if (value.data?.result_code < 0) {
                        msg_failed_list.push(value.data);
                    }
                }
                if (cancel_failed_list.length) {
                    alert("예약 취소가 실패했습니다. 예약자에게 수동으로 연락이 필요할 수 있습니다.");
                }
                if (msg_failed_list.length) {
                    result = Math.min(...msg_failed_list.map(data => data.result_code));
                }
            }
            else {
                result = await axios.put(`/api/${LOCATION}/${SERVICE}/cancelCall`,{
                    service_id: service_ids,
                    car_name: carName,
                })
                .then((res) => res.data)
                .catch((error) => {
                    alert("예약 취소가 실패했습니다. 예약자에게 수동으로 연락이 필요할 수 있습니다.");
                    return undefined;
                });
            }
            if (result) {
                if (result.result_code < 0) {
                    alert("예약 문자 전송이 실패했습니다.");
                }
                else {
                    setViewPassengerInfo(false);
                }
                getCallState();
            }
        }
    },[carName, getCallState]);

    const addNoCustomerTrip = useCallback(async (polygon, AREA_BOX, center) => {
        const rand_point = getRandomPoint(polygon, {...AREA_BOX, center, radius: 2});
        const rand_goal_point = await axios.post(`/adp/${LOCATION}/${SERVICE}/get-point`,{
            latitude: rand_point[0],
            longitude: rand_point[1],
            type: "AP",
        }).then((res) => {
            return res.data;
        }).catch((e) => {
            console.error("error while POST to adp");
            console.log(e);
            return undefined;
        });
        if (!!rand_goal_point) {
            axios.put(`/api/${LOCATION}/${SERVICE}/addNoCustomerTrip`, {
                random_destination: [rand_goal_point.start_latitude, rand_goal_point.start_longitude],
                car_name: carName,
            }).catch(res => {
                alert("가상 목적지 설정이 실패하였습니다.");
            });
            getCallState();
        }
    },[carName, getCallState]);

    const endNoCustomerTrip = useCallback(async () => {
        await axios.put(`/api/${LOCATION}/${SERVICE}/endNoCustomerTrip`, { car_name: carName })
        .catch(function () {
                alert("가상 목적지 도착 완료 설정에 실패하였습니다.");
        });
        getCallState();
    },[carName, getCallState]);

    const addToCustomerTrip = useCallback(async () => {
        await axios.put(`/api/${LOCATION}/${SERVICE}/addToCustomerTrip`, { car_name: carName })
        .catch(function () {
            alert("탑승 장소로 이동 설정에 실패하였습니다.");
        });
        getCallState();
    },[carName, getCallState]);

    const endToCustomerTrip = useCallback(async () => {
        await axios.put(`/api/${LOCATION}/${SERVICE}/endToCustomerTrip`, { car_name: carName })
        .catch(function () {
            alert("탑승 장소 도착 완료 설정에 실패하였습니다.");
        });
        getCallState();
    },[carName, getCallState]);

    const addCustomerTrip = useCallback(async () => {
        await axios.put(`/api/${LOCATION}/${SERVICE}/addCustomerTrip`, { car_name: carName })
        .catch(function () {
            alert("손님탑승 설정이 실패하였습니다");
        });
        getCallState();
    },[carName, getCallState]);

    const endCustomerTrip = useCallback(async () => {
        await axios.put(`/api/${LOCATION}/${SERVICE}/endCustomerTrip`, { is_private: false, car_name: carName })
        .catch(function () {
            alert("목적지 도착 완료 설정에 실패하였습니다.");
        });
        setViewPassengerInfo(false);
        getCallState();
    },[carName, getCallState]);
    
    const passengerInfoTable = useMemo(() => {
        const { passenger_info } = carInfo;
        let passengerItems;
        let with_under_age = null;
        if (passenger_info) {
            if (Array.isArray(passenger_info)) {
                if (passenger_info.find(passenger => isUnderAge(passenger.birthdate))) {
                    with_under_age = (<div style={{ color: "red", textAlign: "center" }}>* 미성년자가 탑승했습니다. 법적대리인의 서면 동의를 받아주세요.</div>);
                }
                passengerItems = (passenger_info.reduce((prev, passenger, idx) => {
                    const person_info = Object.keys(passenger).map((item) => {

                        return <td key={`${item}-${idx}`}>{passenger[item]}</td>
                    })
                    prev.push(<tr className="table-light" style={{padding: 0}}>
                        {person_info}
                    </tr>);
                    return prev;
                }, []));
            }
        }
        else {
            passengerItems = <div />;
        }
        return (
            <div>
                {with_under_age}
                <Table bordered hover style={{ color: "black" }}>{passengerItems}</Table>
            </div>
        );
    },[carInfo])

    const map = useMemo(() => {
        const {
            car_location,
            current_service_departure,
            current_service_destination,
        } = carInfo;

        const car_type = carName?.split('_')[0] ?? 'ioniq';
        return (<div style={{ width: "100%", height: "20%", marginTop: "3vh" }}>
            <SeogwipoMiniMapContainer
                className="admin"
                initial_center={[33.24753061649211, 126.41578699870084]}
                departure={current_service_departure}
                destination={current_service_destination}
                draggable={true}
                opacity={"1.0"}
                car_location={car_location}
                car_type={car_type}
                use_center={false}
                global_path_list={currentPath}
            />
        </div>);
    },[carInfo, carName, currentPath]);
    
    const callInfoForm = useMemo(() => {
        const {
            req_time,
            current_service_departure_description,
            current_service_destination_description,
        } = carInfo;
        return viewPassengerInfo ? (
            <div style={{
                textAlign: "left",
                width: "90%"
            }}>
                <h6>[호출 시각] : {new Date(req_time).toLocaleString()}</h6>
                <h6>[호출 승객 정보] : </h6>
                {passengerInfoTable}
                <h6>[승차지] : {current_service_departure_description}</h6>
                <h6>[도착지] : {current_service_destination_description}</h6>
                {map}
            </div>)
        : null;
    },[carInfo, viewPassengerInfo, passengerInfoTable, map]);

    const newCallInfo = useMemo(() => {
        const {
            req_time,
            current_service_departure_description,
            current_service_destination_description,
        } = carInfo;
        return <div style={{
            textAlign: "left",
            width: "90%"
        }}>
            <Sound
                url="/sound/ring.mp3"
                playStatus="PLAYING"
                allow="autoplay"
            />
            <h4>새로운 호출이 있습니다.</h4>
            <br />
            <h6>[호출 시각] : {new Date(req_time).toLocaleString()}</h6>
            <h6>[호출 승객 정보] : </h6>
            {passengerInfoTable}
            <h6>[승차지] : {current_service_departure_description}</h6>
            <h6>[도착지] : {current_service_destination_description}</h6>
            {map}
        </div>
    },[carInfo, passengerInfoTable, map]);

    const adminForm = useMemo(() => {
        const {
            new_call, trip_description, call_available
        } = carInfo;
        let admin_form = null;
        if (new_call)
        {
            admin_form = newCallInfo; // getnewCallInfo
        }
        else if (call_available && trip_description === "trip_without_customer")
        {
            admin_form = (<div style={{ marginTop: "5vh" }}><h2>가상 목적지로 이동 중입니다.</h2></div>);
        }
        else if (!call_available && trip_description === "trip_to_customer") {
            admin_form = (<div style={{ marginTop: "5vh" }}><h2>탑승 장소로 이동 중입니다.</h2></div>);
        }
        else if (!call_available && trip_description === "trip_with_customer") {
            admin_form = (<div style={{ marginTop: "5vh" }}><h2>손님이 탑승하여 목적지로 이동 중입니다.</h2></div>);
        }
        else if (call_available) {
            admin_form = (<div>
                <h4>고객의 호출을 기다리고 있습니다.</h4>
                <h4>빈차 주행 클릭 시 가상 목적지가 설정됩니다.</h4>
                {callInfoForm}
            </div>);
        }
        else {
            admin_form = callInfoForm;
        }
        return admin_form
    },[carInfo, callInfoForm, newCallInfo]);
    
    const btns = useMemo(() => {
        const {
            new_call, trip_description, call_available, passenger_info,
            car_location, current_service_ids,
        } = carInfo;
        let buttons = null;
        if (new_call)
        {
            buttons = (<Button size="md" style={{ width: "50vw", marginTop: "0.5vh" }} color="info" onClick={checkCall}>차량 호출 확인 / 알림 소리 종료 </Button>);
        }
        else if (call_available && trip_description === "trip_without_customer")
        {
            buttons = (<Button size="md" style={{ width: "50vw", marginTop: "0.5vh" }} color="info" onClick={endNoCustomerTrip}>가상 목적지 도착 완료</Button>);
        }
        else if (!call_available && trip_description === "trip_to_customer") {
            buttons = (<Button size="md" style={{ width: "50vw", marginTop: "0.5vh" }} color="info" onClick={endToCustomerTrip}>탑승 장소 도착 완료</Button>);
        }
        else if (!call_available && trip_description === "trip_with_customer") {
            buttons = (<Button size="md" style={{ width: "50vw", marginTop: "0.5vh" }} color="info" onClick={endCustomerTrip}>목적지 도착 완료</Button>);
        }
        else if (call_available) {
            let prevCallInfoViewerBtn = null;
            if (passenger_info && !viewPassengerInfo) {
                prevCallInfoViewerBtn = (<Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="info" onClick={() => {setViewPassengerInfo(true)}}>이전 호출 정보 보기</Button>);
            } else if (viewPassengerInfo) {
                prevCallInfoViewerBtn = (<Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="info" onClick={() => {setViewPassengerInfo(false)}}>이전 호출 정보 닫기</Button>);
            }
            buttons = (<div>
                {prevCallInfoViewerBtn}
                <Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="info " onClick={() => addNoCustomerTrip(tamraJungmunValidServiceArea, AREA_BOX, car_location)}>빈차주행</Button>
                <Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="danger" onClick={() => disableCall()}>서비스 종료</Button>
            </div>);
        }
        else {
            buttons = (<div>
                <Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="info " onClick={addToCustomerTrip}>탑승장소로 이동</Button>
                <Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="success " onClick={addCustomerTrip}>손님탑승</Button>
                <Button style={{ width: "50vw", marginTop: "0.5vh" }} size="md" color="danger" onClick={() => cancelCall(current_service_ids)}>예약 취소</Button>
            </div>)
        }
        return buttons
    },[carInfo, viewPassengerInfo, checkCall, endCustomerTrip, endNoCustomerTrip, endToCustomerTrip, addCustomerTrip, addNoCustomerTrip, addToCustomerTrip, cancelCall, disableCall]);
    
    const body = useMemo(() => {
        const {
            car_timeout,
            car_location, current_service
        } = carInfo;
        let contents = null
        if (service) {
            contents = <div style={{ marginTop: "3vh" }}>
                {adminForm}
                <div style={{
                    zIndex: "1"
                }}>
                    {btns}
                </div>
            </div>;
        }
        else if (car_timeout || car_location === undefined) {
            contents = (<div style={{ marginTop: "5vh" }}><h2 style={{ color: "red" }}>차량의 위치를 확인할 수 없습니다.</h2></div>);
        }
        else if (!car_timeout && !inside(car_location, tamraJungmunValidServiceArea)) {
            contents = (<div style={{ marginTop: "5vh" }}><h2 style={{ color: "red" }}>차량이 운영구역 밖에 있어 서비스를 시작할 수 없습니다.</h2></div>);
        }
        else if (current_service && SERVICE_NAME !== current_service) {
            contents = (<div>
                <h3 style={{ color: "red" }}>다른 관리자 페이지에서 서비스 중이거나<br/>서비스명이 일치하지 않습니다.</h3>
                <h5 >현재 이용중인 서비스: {current_service}</h5>
            </div>);
        }
        else {
            contents = (
                <div style={{ marginTop: "5vh" }}>
                    <h2 style={{ color: "red" }}>고객이 호출을 할 수 없는 상태입니다.</h2>
                    <Button style={{ width: "50vw", marginTop: "1vh" }} size="md" color="info" onClick={() => {ableCall()}}>서비스 시작</Button>
                </div>);
        }
        return contents
    },[carInfo, adminForm, btns, service]);


    useEffect(() => {
        const bodyElt = document.querySelector("body");
        bodyElt.style.backgroundColor = "#DFDFDF";
    },[]);

    useEffect(() => {
        const {
            current_service_departure, current_service_destination
        } = carInfo;
        if (
            !Array.isArray(departureLocation) || 
            (Array.isArray(current_service_departure) && !current_service_departure.every((point, idx) => point === departureLocation[idx]))
        ) {
            setDepartureLocation(current_service_departure);
        }
        if (
            !Array.isArray(destinationLocation) || 
            (Array.isArray(current_service_destination) && !current_service_destination.every((point, idx) => point === destinationLocation[idx]))
        ) {
            setDestinationLocation(current_service_destination);
        }
    }, [carInfo]);

    useEffect(() => {
        if (!departureLocation || !destinationLocation) {
            return;
        }
        getGlobalPath(departureLocation, destinationLocation)
        .then(data => {
            const {
                goal,
                goal_path,
            } = data;
            if (!goal) {
                return;
            }
            setCurrentPath(goal_path);
        });
    }, [departureLocation, destinationLocation, getGlobalPath]);

    useEffect(() => {
        const {
            new_call
        } = carInfo;
        setViewPassengerInfo(new_call || viewPassengerInfo);
    },[carInfo, getGlobalPath]);

    return <div style={{ height: "100%" }}>
        <AdminTopBar>
            <LogoutButton history={history}/>
            <DarkModeButton />
        </AdminTopBar>
        <div
            style={{
                margin: "auto",
                width: "70%",
                paddingTop: "5vh",
                textAlign: "center",
                color: "black",
            }}
        >
            <h1><b>- Tamra 중문 운영자 페이지 - </b></h1>
            <SelectCar 
                selectedCar={carName}
                selectCallback={selectCarCallback}
            />
            {body}
        </div>
    </div >
}

export default withRouter(TamraAdminPage);
