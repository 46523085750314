import React, { Component } from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import { getNoService, getPressBtn, getGPSBtn, inside, validServiceArea,
    shuttle_stop_list, shuttle_service_txt, shuttle_path } from "./SeogwipoCommon.js";
import SeogwipoMapContainer from "./SeogwipoMapContent.js";
import { Carousel, CarouselItem, CarouselControl, } from 'reactstrap';
import { IoIosArrowBack } from "react-icons/io"
import { MdLocationOn } from "react-icons/md"
import '../../style/SeogwipoShuttle.css';

class SeogwipoShuttle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stops: undefined,
            ETAs: [],
            service_on: false,
            car_location: undefined,
            car_heading: undefined,
            shuttlePath: undefined,
            my_location: undefined,
            next_shuttle_stop_id: -1,
            activeIndex: -1,
            animating: false, setAnimating: false,
            roadview: false,
            cur_roadview_name: "",
        };
        this.is_invitation = false;
        this.shuttle_stop_list = shuttle_stop_list;
        this.next_shuttle_stop_toggle = false;
        this.map_container = React.createRef();
        this.watch_id = undefined;
        this.geo_permission = undefined;
        this.unlisten = undefined;
    }
    async getCallState() {
        await axios.get("/api/seogwipo/shuttle/getCallState", {
            params: {isClient: true, invitation: this.is_invitation}
        }).then(function (res) {
            if (res.statusText === "OK") {
                return res.data;
            } else {
                return {
                    service_on: false,
                    car_location: undefined,
                    car_heading: undefined,
                };
            }
        }).catch(function (error) {
            console.error("error while request GET to server", error);
            return {
                service_on: false,
                car_location: undefined,
                car_heading: undefined,
            };
        }).then((data) =>{
            if(this.state.next_shuttle_stop_id !== data.next_shuttle_stop_id){
                this.next_shuttle_stop_toggle = true;
            }
            this.setState({
                ...this.state,
                service_on: data.service_on,
                car_location: data.car_location,
                car_heading: data.car_heading,
                next_shuttle_stop_id: data.next_shuttle_stop_id,
            })
        });
        if(this.next_shuttle_stop_toggle){
            this.updateCarouselItems();
        }
    }
    // async getShuttlePath(){
    //     await axios.get("/api/seogwipo/shuttle/get-path",{}
    //     ).then((res) => {
    //         if(res.statusText === "OK"){
    //             this.setState({
    //                 shuttlePath: res.data
    //             });
    //             return;
    //         }
    //         else{
    //             console.error(res.statusText);
    //         }
    //     }).catch((e) => {
    //         console.error("error while request Path to server. \n", e);
    //         return;
    //     });
    // }
    async getShuttleStops(){
        await axios.get("/api/seogwipo/shuttle/get-stops",{}
        ).then((res)=>{
            this.shuttle_stop_list = res.data;
        }).catch((err)=>{
            console.error(err);
        });
    }

    async getMyPosition(){
        if(!!this.watch_id) return;
        this.watch_id = navigator.geolocation.watchPosition((position)=>{
            this.geo_permission = 'granted';
            this.setState({
                ...this.state,
                my_location: [position.coords.latitude, position.coords.longitude]
            });
        }, (error)=>{
            this.watch_id = undefined;
            this.geo_permission = 'denied';
            this.setState({
                ...this.state,
                my_location: undefined,
            });
        });
    }

    async componentDidMount() {
        if(this.props.match.path.endsWith('/roadview') && !this.state.roadview){
            this.props.history.replace(this.props.match.path.replace("/roadview",""));
        }
        if(this.props.match.path.startsWith("/seogwipo/invitation/shuttle")){
            this.is_invitation = true;
        }

        await this.getShuttleStops();
        this.intervalId = await setInterval(() => {
            this.getCallState();
        }, 2000);
        this.getCallState();
        this.getMyPosition();
        // this.getShuttlePath();
        this.unlisten = this.props.history.listen(location => {
            this.setState({
                ...this.state, 
                roadview: location.state?.roadview ?? false,
                cur_roadview_name : location.state?.cur_roadview_name ?? "",
            })
        });
        this.state.stops = this.shuttle_stop_list.map((stop, idx) => {
            return (<CarouselItem   
                        onExiting={()=> this.state.setAnimating = true}
                        onExited={()=> this.state.setAnimating = false}
                        key = {`${stop.description}-${idx}`}>
                            {this.getCarouselItem(stop)}
                    </CarouselItem>);
        });
        try{
            navigator.permissions.query({name:'geolocation'})
            .then((permission_status) => {
                this.geo_permission = permission_status.state;
                permission_status.addEventListener("change", (e)=>{
                    if(!!!this.watch_id) this.getMyPosition();
                    this.geo_permission = e.target.state;
                })
            });
        } catch (e){
             console.error("Permission query is not supported by IOS.")
        }
    }
    async componentWillUnmount(){
        if(this.intervalId !== undefined)
            clearInterval(this.intervalId);
        navigator.geolocation.clearWatch(this.watch_id);
        if(!!this.unlisten)
            this.unlisten();
    }

    async updateCarouselItems(){
        let new_stops = this.shuttle_stop_list.map((stop, idx) => {
            return (<CarouselItem   
                        onExiting={()=> this.state.setAnimating = true}
                        onExited={()=> this.state.setAnimating = false}
                        key = {`${stop.description}-${idx}`}>
                            {this.getCarouselItem(stop)}
                    </CarouselItem>);
        });
        this.setState({
            stops: new_stops
        });
        this.next_shuttle_stop_toggle = false;
    }

    shuttleStopClickHandler(stop_description){
        let stop_id = this.shuttle_stop_list.findIndex((elem) => elem.description === stop_description);
        this.setState({
            activeIndex: stop_id,
        })
    }
    mapClickHandler(){
        this.setState({
            activeIndex: -1,
        })
    }
    imgClickHandler(img_description){
        if(!this.state.roadview){
            this.setState({roadview: true, cur_roadview_name: img_description});
            if(!(this.props.match.path === "/seogwipo/shuttle/roadview")){
                this.props.history.push('./shuttle/roadview',{roadview: true, cur_roadview_name: img_description});
            }
            if(!!this.map_container.current){
                this.map_container.current.clickShuttleStopImg(img_description);
            }
        }
    }
    roadveiwPinHandler(){
        if(!!this.map_container.current){
            if(this.map_container.current.checkPanoId(this.state.cur_roadview_name)){
                this.map_container.current.updateViewpoint(this.state.cur_roadview_name);
            }else {
                this.map_container.current.clickShuttleStopImg(this.state.cur_roadview_name);
            }
        }
    }
    toggleRoadview(select){
        this.setState({roadview: select});
        this.props.history.goBack();
    }
    gpsClickHandler(){
        this.getMyPosition();
        if(!!this.map_container.current && this.geo_permission === 'granted'){
            this.map_container.current.setCenter(this.state.my_location);
        }

    }
    getETA(current_stop_id){
        if(this.state.next_shuttle_stop_id < 0) return this.state.next_shuttle_stop_id;
        let result = current_stop_id - this.state.next_shuttle_stop_id;
        if(!!!result && result !== 0) return -1;
        return result < 0 ? result + this.shuttle_stop_list.length : result;
    }
    nextCarousel = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.shuttle_stop_list.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({
            activeIndex: nextIndex,
        })
      }
    
    previousCarousel = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.shuttle_stop_list.length - 1 : this.state.activeIndex - 1;
        this.setState({
            activeIndex: nextIndex,
        })
    }

    goToIndex = (newIndex) => {
        if (this.state.animating) return;
        this.setState({
            setActiveIndex: newIndex,
        })
    }
    getRideBtn(){
        return (
            getPressBtn("탑승 대기하기", this.redirect.bind(this))
        );
    }
    getInfoCard(){
        return (
            <div className="Carousel">
                <div className="Card-Item" id="no_stop">
                    <div className="title">  
                        {/* <AiOutlineInfoCircle className="Info-Icon"/>  */}
                        <img className="Info-Icon" src="/info.svg"/>
                        <span> 운행정보</span></div>
                    <hr className="solid"></hr>
                    <div className="table">
                        <div className="table-row">
                            <div className="table-cell table-pivot">
                                차량번호
                            </div>
                            <div className="table-cell table-content">
                                임 4179
                            </div>
                        </div>
                        <div className="table-row">
                            <div className="table-cell table-pivot">
                                운행요원 번호
                            </div>
                            <div className="table-cell table-content">
                                010-5765-8039
                            </div>
                        </div>
                    </div>
                    {/* {this.getRideBtn()} */}
                </div>
            </div>
        );
    }
    getCarouselItem(stop){
        let idx = this.shuttle_stop_list.indexOf(stop);
        let eta = this.getETA(idx);
        return (
            <div className="Card-Item">
                <div style={{display:"flex", flexDirection:"column", flexWrap:"wrap"}}>
                    <div className="stop-number">
                        정류장 {idx+1}
                    </div>
                    <div className="stop-name">
                        {stop.description}
                    </div>
                    <div className="ETA-box"> 
                        <div className="ETA">
                            {eta < 0 ? "운행 준비중 입니다." : "도착예정"}
                        </div>
                        <div className="ETA-info">
                            {eta < 0 ? "" : (eta === 0 ? "잠시 후 도착 예정 입니다." : eta+" 정류장 전입니다.")}
                        </div>
                    </div>
                    <div className="stop-img-wrapper">
                        <img className="stop-img" src={"/stops/pictures/"+stop.name+".jpg"} onError={(e)=>{
                            // e.target.onerror = null; 
                            e.target.style.display="none";
                        }} onClick={this.imgClickHandler.bind(this, stop.description)}/>
                        <img className="roadview-icon" src="/streetview.svg" />
                    </div>
                </div>
                {/* {this.getRideBtn()} */}
            </div>
        );
    }
    getCarousel(){
        return(
            <Carousel className="Carousel"
                interval = {false}
                slide = {false}
                activeIndex={this.state.activeIndex}
                next={this.nextCarousel}
                previous={this.previousCarousel}>
                {this.state.stops}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previousCarousel} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.nextCarousel} />
            </Carousel>
        );
    }
    getRoadviewPinButton(){
        return(
            <div className="roadview-pin-btn" onClick={this.roadveiwPinHandler.bind(this)}>
                <MdLocationOn />
            </div>
        );
    }
    redirect(){
        this.props.history.push('/seogwipo/shuttle/passenger');
    }
    render() {
        const { service_on, car_location, car_heading, my_location, activeIndex, roadview, cur_roadview_name } = this.state;
        if (service_on === true && car_location !== undefined && inside(car_location, validServiceArea)) {
            if(!roadview){
                return (
                    <div className="main">
                        <SeogwipoMapContainer
                            ref={this.map_container}
                            draggable={true}
                            roadview={roadview}
                            my_location={this.geo_permission === "granted" ? my_location : undefined}
                            opacity={"1.0"}
                            path_data={shuttle_path}
                            car_location={car_location}
                            car_heading={car_heading}
                            shuttle_stop_list={this.shuttle_stop_list}
                            active_stop={activeIndex}
                            shuttle_stop_click_handler = {this.shuttleStopClickHandler.bind(this)}
                            map_click_handler = {this.mapClickHandler.bind(this)}
                            car_type="pacifica" />
                        <div className="Card-Item-wrapper">
                            {getGPSBtn(this.gpsClickHandler.bind(this))}
                            {activeIndex < 0 ? this.getInfoCard() : this.getCarousel()}
                        </div>
                    </div>);
            } else{
                return (
                    <div className="main">
                        <SeogwipoMapContainer
                            ref={this.map_container}
                            draggable={true}
                            roadview={roadview}
                            my_location={this.geo_permission === "granted" ? my_location : undefined}
                            opacity={"1.0"}
                            path_data={shuttle_path}
                            car_location={car_location}
                            car_heading={car_heading}
                            shuttle_stop_list={this.shuttle_stop_list}
                            active_stop={activeIndex}
                            car_type="pacifica" />
                        <div className={"roadview-top"+(roadview ? " on" : "")}>
                            <div className="roadview-back-btn" > 
                            <IoIosArrowBack onClick={()=>this.toggleRoadview(false)} />
                            </div>
                            <div className="roadview-description">
                                {cur_roadview_name}
                            </div>
                        </div>
                        {this.getRoadviewPinButton()}
                    </div>);
            }
        } else {
            return (getNoService(shuttle_service_txt));
        }
    }
}
export default withRouter(SeogwipoShuttle);
