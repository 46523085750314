import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useInterval } from "./Hooks";

/**
 * 어드민페이지 등 내부에서만 사용하는 페이지에서 state를 받아오는 api를 호출하는 훅.
 * @param {string} middleUrl - /api/{middleUrl}/get-admin-state
 * @param {number} delay - api 호출 주기
 * @param {string} car_name - 차량 이름
 * @returns {[object, function]} - [carInfo, getState]
 */
const useGetAdminState = (middleUrl, delay, car_name) => {
    const [carInfo, setCarInfo] = useState({});

    const getState = useCallback(async () => {
        const res = await axios
            .get(`/api/${middleUrl}/get-admin-state?car_name=${car_name}`)
            .catch((err) => {
                return err.response;
            });
        if (res && res.statusText === "OK") {
            setCarInfo(res.data);
        }
    }, [car_name, middleUrl]);

    useEffect(() => {
        getState();
    }, [getState]);

    useInterval(getState, delay);

    return [carInfo, getState];
};
export default useGetAdminState;
