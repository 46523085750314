import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader,
    Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Collapse } from 'reactstrap';
import { AgreementShorTextComponent } from '../../Component/Agreement';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const phone_pattern = RegExp(/(\d{0,3})?[- ]?(\d{0,4})?[- ]?(\d{0,4})?/);

export const TamraPassengerForm = ({
    startLocation,
    endLocation,
    reservationTime = new Date(),
    reservation = true,
    onSubmit = () => {},
    max_passenger = 2,
    initialPassengerNumber = 0,
    getEstimatedSelectedCar = () => {},
    ...props
}) => {
    const [passengerNumber, setPassengerNumber] = useState(initialPassengerNumber);
    const [passengers, setPassengers] = useState([]);
    const [validPassengers, setValidPassengers] = useState([]);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

    const [isDuplicatedPhone, setIsDuplicatedPhone] = useState(false);
    const [childrenNumber, setChildrenNumber] = useState(0);
    const [childrenDropdownIsOpen, setChildrenDropdownIsOpen] = useState(false);
    
    const [openReservation, setOpenReservation] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);

    const toggleReservation = useCallback(() => {
        setOpenReservation(open => !open);
    }, []);
    const toggleRegister = useCallback(() => {
        setOpenRegister(open => !open);
    }, []);

    const changePassengerNumberChecker = useCallback((newPassengerCount) => {
        let alreadyCheckedPhoneCount = 0
        for (let i = 0; i < passengers.length; i++) {
            if (i < newPassengerCount) continue
            if (passengers[i]) alreadyCheckedPhoneCount += 1
        }

        const changeConfirm = alreadyCheckedPhoneCount > 0 && window.confirm(
            `탑승인원을${newPassengerCount}명으로 변경할 경우 기존에 확인된 ${alreadyCheckedPhoneCount}명의 전화번호를 다시 입력해야합니다. 
            그래도 변경하시겠습니까?`
        )
        
        if (alreadyCheckedPhoneCount === 0 || changeConfirm) setPassengerNumber(newPassengerCount)
    }, [passengers]);

    const passengerDropdown = (
        <Dropdown
            isOpen={dropdownIsOpen}
            toggle={() => setDropdownIsOpen((prev) => !prev)}
        >
            <DropdownToggle caret>
                {passengerNumber === 0 ? `탑승 인원을 선택하세요. (최대 탑승 인원: ${max_passenger}명)` : `${passengerNumber}명`}
            </DropdownToggle>
            <DropdownMenu>
                {Array.from(Array(max_passenger)).map((_, idx) => (
                    <DropdownItem key={`passenger_${idx + 1}`} onClick={() => changePassengerNumberChecker(idx + 1)}>{idx + 1}명</DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
    
    const childrenDropdown = (
        <Dropdown
            isOpen={childrenDropdownIsOpen}
            toggle={() => setChildrenDropdownIsOpen((prev) => !prev)}
        >
            <DropdownToggle caret>
                {`${childrenNumber}명`}
            </DropdownToggle>
            <DropdownMenu>
                {passengerNumber > 1 && Array.from(Array(passengerNumber - 1)).map((_, idx) => (
                    <DropdownItem key={`children_btn_${idx + 1}`} onClick={() => setChildrenNumber(idx + 1)}>{idx + 1}명</DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    )

    useEffect(() => {
        const passenger_num = childrenNumber ? Math.max(passengerNumber - childrenNumber, 1) : passengerNumber;
        if (childrenNumber) {
            setChildrenNumber(prev => Math.min(passengerNumber - 1, prev));
        }
        setPassengers(old_passengers => {
            return Array.from(Array(passenger_num)).map((_, idx) => {
                return old_passengers[idx];
            });
        });
    }, [passengerNumber, childrenNumber]);

    useEffect(() => {
        setIsDuplicatedPhone(new Set(passengers).size !== passengers.length)
        getEstimatedSelectedCar()
    }, [passengers]);
    
    return <div className='tamra-form'>
        <div className='title'>
        탑승자 정보 입력
        </div>
        <div className={max_passenger < 5 ? 'select-passenger-num' : ''}>
            탑승객 수
            <div className="select-num">
                {max_passenger < 5
                    ? Array.from(Array(max_passenger)).map((_, idx) => {
                        return <Button
                            className={`${idx+1 === passengerNumber ? 'selected' : ''}`}
                            key={`passenger-button-${idx}`}
                            onClick={() => {
                                if (idx + 1 === passengerNumber) {
                                    changePassengerNumberChecker(0);
                                }
                                else {
                                    changePassengerNumberChecker(idx+1);
                                }
                            }}
                            style={{padding: '15px 10px'}}
                        >
                            {idx+1}명
                        </Button>})
                    : passengerDropdown}
            </div>
            {passengerNumber < 2? 
                undefined :
                <>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={childrenNumber > 0}
                            onChange={() => {setChildrenNumber((prev) => !childrenNumber ? prev || 1 : 0)}}
                        />
                    }
                    label={<span >어린이 동승 여부(만 6세~만 13세)</span>}
                />
                {passengerNumber > 2 && <Collapse className="enable-children" isOpen={childrenNumber > 0}>
                    <div>어린이 수를 선택해 주세요</div>
                    <div className="select-num">
                        {max_passenger < 5
                            ? Array.from(Array(passengerNumber - 1)).map((_, idx) => {
                                return <Button
                                    className={`${idx+1 === childrenNumber ? 'selected' : ''}`}
                                    key={`children-button-${idx}`}
                                    onClick={() => {
                                        if (idx + 1 === childrenNumber) {
                                            setChildrenNumber(0);
                                        }
                                        else {
                                            setChildrenNumber(idx+1);
                                        }
                                    }}
                                    style={{padding: '10px'}}
                                >
                                    {idx+1}명
                                </Button>})
                            : childrenDropdown}
                    </div>
                </Collapse>}
                </>
            }
        </div>
        <div className='tamra-phone-input-box'>
            {Array(passengerNumber).length ? <>
            전화번호
            <div>
                {Array.from(Array(passengerNumber)).map((_, idx) => {
                    const is_child = idx >= passengerNumber - childrenNumber;
                    return <MobilitPhoneInput
                        key={`phone-input-${idx}`}
                        disabled={childrenNumber > 0 && is_child}
                        onConfirm={(phone) => {
                            setPassengers(old_passengers => {
                                const new_passengers = [...old_passengers];
                                new_passengers[idx] = phone;
                                return new_passengers;
                            })
                        }}
                        onCheck={(valid) => {
                            setOpenRegister(!valid);
                        }}
                        onDuplicate={(value) => passengers.includes(value)}
                    />
                })}
            </div>
            </>
            : null}
        </div>
        <div className='agreement-box'>
            <AgreementShorTextComponent 
                className='agreement'
                buttonTag='div'
                buttonProps={{className: 'link'}}
                modalProps={{
                    headerProps: {visible: false},
                    contentClassName: "tamra-agreement-modal-content",
                    modalClassName: "font-SUIT tamra-agreement-modal",
                }}
            />
        </div>
        <Button
            className="tamra-confirm-button"
            disabled={
                isDuplicatedPhone ||
                passengers.length === 0 ||
                !passengers.every(passenger => !!passenger)}
            onClick={() => {
                toggleReservation();
                getEstimatedSelectedCar();
            }}
        >
            {reservation ? "차량 예약" : "차량 호출"}
        </Button>
        <Modal
            isOpen={openReservation} toggle={toggleReservation}
            className=""
            wrapClassName="tamra-modal"
            contentClassName="modal-container"
            centered
        >
            <ModalHeader className="modal-header">{reservation ? "예약" : "호출"} 내용을 확인해주세요.</ModalHeader>
            <ModalBody className="tamra-information-box">
                <div>
                    <div>경로</div>
                    <div>{startLocation?.description} {">"} {endLocation?.description}</div>
                </div>
                <div>
                    <div>탑승 인원</div>
                    <div>{passengerNumber}인</div>
                </div>
                <div>
                    <div>{reservation ? '탑승' : '호출'} 시각</div>
                    <div>{reservationTime.getMonth() + 1}월 {reservationTime.getDate()}일 {String(reservationTime.getHours()).padStart(2,'0')}시 {String(reservationTime.getMinutes()).padStart(2,'0')}분</div>
                </div>
                <div className='list'>
                    <div>휴대폰 번호</div>
                    <div className="phones">
                        {passengers.map((passenger, idx) => <div
                            key={`phone-${idx}`}
                        >
                            {passenger}
                        </div>)}
                        {childrenNumber ? `외 ${childrenNumber}명` : null}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter >
                <Button onClick={toggleReservation}>
                    수정
                </Button>
                <Button onClick={(e) => {
                    e.preventDefault();
                    onSubmit(passengers, passengerNumber);
                }}>
                    {reservation ? "예약하기" : "호출하기"}
                </Button>
            </ModalFooter>
            
        </Modal>
        <TamraRegisterModal
            open={openRegister}
            toggle={toggleRegister}
        />
    </div>
}


const MobilitPhoneInput = ({
    onConfirm = () => {},
    onCheck = () => {},
    onDuplicate = () => {},
    disabled = false,
}) => {

    const [phone, setPhone] = useState('');
    const [checked, setChecked] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [duplicated, setDuplicatated] = useState(false);

    const checkValid = useCallback(async (phone) => {
        const pattern = new RegExp(/[0-9]{3}-[0-9]{4}-[0-9]{4}/);
        if (!pattern.test(phone)) {
            setInvalid(true);
            return false;
        }
        const is_valid = await axios.get("/api/check-users", {
            params: {phones: [phone]}
        }).then(res => {
            return !!res.data.verified;
        }).catch(err => false);
        setInvalid(!is_valid);
        setChecked(is_valid);
        if (typeof(onConfirm) === 'function') {
            onConfirm(is_valid ? phone : undefined);
        }
        return is_valid

    }, [onConfirm]);

    const onClickCheck = useCallback(async (phone) => {
        let is_duplicated = onDuplicate(phone);
        setDuplicatated(is_duplicated);
        if (is_duplicated) {
            return;
        }
        const result = await checkValid(phone);
        onCheck(result);

    }, [checkValid, onCheck, onDuplicate]);

    const onClickInit = useCallback(e => {
        setPhone('');
        setChecked(false);
        onConfirm('');
    }, [onConfirm]);

    const phoneReplacer = useCallback((match, arg1, arg2, arg3, offset, string) => {
        return [arg1, arg2, arg3].filter(a => !!a).join('-');
    }, [phone_pattern]);

    useEffect(() => {
        if (disabled) {
            setPhone('');
            setChecked(false);
            setInvalid(false);
            setDuplicatated(false);
        }
    }, [disabled]);

    return <Form 
        onSubmit={(e) => {
            e.preventDefault();
        }}
    >
        <FormGroup className={`tamra-phone-input ${phone.length > 0 ? '' : 'empty'}`} >
            <div className={`input-box ${(phone.length > 0 && !invalid && !checked) ? 'shadow' : ''} `}>
                <Input
                    className={''}
                    invalid={invalid || duplicated}
                    valid={checked}
                    type="tel" //pattern="[0-9]{3}[ -]{1}[0-9]{4}[ -]{1}[0-9]{4}"
                    placeholder={"전화번호를 입력해주세요"}
                    readOnly = {disabled || checked}
                    onChange = {(e) => {
                        const value = e.target.value;
                        const phone_number = value.replace(phone_pattern, phoneReplacer);
                        setPhone(phone_number);
                        setInvalid(false);
                        setChecked(false);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            if (!checked && phone.length > 0) {
                                onClickCheck(phone);
                            }
                        }
                    }}
                    value={disabled ? "어린이는 현장에서 탑승 동의 필요" : phone}
                />
                <Button
                    className={(checked) ? 'checked' : ''}
                    disabled={phone.length === 0}
                    onClick={(e) => {
                        if (checked) {
                            onClickInit(e);
                        }
                        else {
                            onClickCheck(phone);
                        }
                    }}
                >
                    {checked ? '초기화' : '확인'}
                </Button>
            </div>
            {checked ?
            <FormFeedback className="feedback valid" >
                확인되었습니다.
            </FormFeedback> : null
            }
            {invalid ?
            <FormFeedback className="feedback invalid" >
                등록되지 않은 번호입니다. 번호를 확인해주세요.
            </FormFeedback> : null
            }
            {duplicated ?
            <FormFeedback className="feedback invalid" >
                중복된 번호입니다. 번호를 확인해주세요.
            </FormFeedback> : null
            }
        </FormGroup>
    </Form>
}

const TamraRegisterModal = ({
    open,
    toggle = ()=>{},
    url = '/register',
}) => {
    return <Modal
        isOpen={open} toggle={toggle}
        className="register"
        wrapClassName="tamra-modal"
        contentClassName="modal-container register"
        centered
    >
        <ModalBody >
            <div >
                <div className="text">
                    <p>등록되지 않은 전화번호</p>입니다.<br/>
                    최초 탑승 정보를 등록해주세요.
                </div>
                <div className="link" onClick={toggle}>
                    다른 번호 입력하기
                </div>
            </div>
            <Button
                onClick={() => {
                    const register_page = window.open(url, '_blank');
                    register_page.addEventListener('registered', (e) => {
                        register_page.close();
                        toggle();
                    });
                }}
            >
                최초 탑승 정보 등록
            </Button>
        </ModalBody>
    </Modal>
}