import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { AiOutlineForm } from "react-icons/ai";
import "../../style/Tamra.css"
import { useInterval } from "../../Hooks/Hooks";
import { IoIosArrowForward } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import ToastMsg from '../../Component/ToastMsg.js';
import 'react-toastify/dist/ReactToastify.css';

const DELAY = 5 * 1000;

function TamraMain ({
    history,
    location,
    ...props
}) {
    
    const [carpoolRemainCars, setCarpoolRemainCars] = useState(0);
    const [jungmunRemainCars, setJungmunRemainCars] = useState(0);
    const [notifications, setNotifications] = useState([]);

    const goTo = useCallback((url, state={}) => {
        let path_name = url;
        let search = undefined;
        if (typeof url === "object") {
            path_name = url.pathname;
            search = url.search;
        }
        if (path_name.startsWith('./')) {
            path_name = `${location.pathname}/${path_name.slice(2)}`;
        }
        else if (!path_name.startsWith('/')){
            path_name = [...location.pathname.split('/').slice(0,-1), path_name].join('/');
        }
        history.push({
            pathname: path_name,
            search
        }, state);
    },[history, location]);

    const getAvailableCar = useCallback(async () => {
        const {
            TAMRA_CARPOOL: tamra_carpool_cars,
            TAMRA_JUNGMUN: tamra_jungmun_cars,
        } = await axios.get(`/api/available-car`, {
            params: {service_name: ['TAMRA_CARPOOL', 'TAMRA_JUNGMUN']}
        })
        .then (res => res.data)
        .catch(err => {
            console.log(err);
            return {
                TAMRA_CARPOOL: [],
                TAMRA_JUNGMUN: [],
            }
        });

        setCarpoolRemainCars(tamra_carpool_cars.length);
        setJungmunRemainCars(tamra_jungmun_cars.length);
    }, []);

    useLayoutEffect(() => {
        getAvailableCar();
    }, [getAvailableCar]);
    useInterval(getAvailableCar, DELAY);

    useEffect(() => {
        async function fn() {
            const current_notifications = await axios.get("/api/get-current-notification",{
                params: {
                    service_name: "TAMRA"
                }
            })
            .then(res => res.data)
            .catch(err => []);
            setNotifications(current_notifications);
        }
        fn();
    }, []);

    useEffect(() => {
        notifications.forEach(notification => {
            const converted_description = notification.description.split('\n').reduce((acc, cur, idx) => {
                acc.push(cur);
                acc.push(<br key={`notification-${notification.id}-${idx}`}/>);
                return acc
            }, []);
            try {
                toast[notification.type](
                <ToastMsg key={`notification-${notification.id}`}>
                    {converted_description}
                </ToastMsg>, {
                    
                });
            }
            catch (err) {
                console.error("Invalid notification type:", notification.type);
            }
            
        });
    }, [notifications]);

    return (
    <>
    <ToastContainer
        style={{minWidth: 'fit-content'}}
        position='top-right'
        hideProgressBar={true}
        autoClose={5000}
        pauseOnFocusLoss
    />
    <div className="font-SUIT tamra-main">
        <div>
            <div className="wrapper">
                <div className="tamra-main-title " >
                    <span>탐나는 자율주행</span>
                    <img 
                        src="/tamra_logo=horizon.svg"
                    />
                    <div className="service-info-link">
                        <span onClick={() => {window.open('https://ridefluxservice.notion.site/0b6060cc884c4d2d891d12ef953ce793', '_blank');}}>
                            서비스 소개<IoIosArrowForward className="arrow-forward" />
                        </span>
                    </div>
                </div>
                <div className="tamra-main-select-wrapper">
                    <SelectBox
                        service_name="shuttle"
                        title="해안도로 순환형 합승 서비스"
                        text="가볍게 해안도로 한 바퀴"
                        remainCars={carpoolRemainCars}
                        carImgSrc="/image_solati_sideview.png"
                        carImgStyle={{
                            transform: `scale(2)`,
                            position: 'absolute',
                            left: '-40px'
                        }}
                        showRemainCars={true}
                        onClick={e => {
                            if (carpoolRemainCars === 0) {
                                alert("현재 서비스 가능한 잔여 차량이 없습니다.");
                            }
                            goTo("/tamra/carpool")
                        }}
                    />
                    <SelectBox
                        service_name="jungmun"
                        title="중문관광단지 구역형 서비스"
                        text="숙소 문 앞까지 빠르게"
                        color="blue"
                        remainCars={jungmunRemainCars}
                        carImgSrc="/image_ioniq_sideview.png"
                        carImgStyle={{
                            transform: `scale(2)`,
                            position: 'absolute',
                            left: '-35px'
                        }}
                        onClick={e => {
                            if (jungmunRemainCars === 0) {
                                alert("현재 서비스 가능한 잔여 차량이 없습니다.");
                            }
                            goTo("/tamra/jungmun")
                        }}
                    />
                    <SelectBox
                        service_name="luggage"
                        title="제주공항 ↔ 중문관광단지 짐배송 서비스"
                        text="짐 걱정 없이 가뿐하게"
                        color="pink"
                        carImgSrc="/image_solati_sideview.png"
                        carImgStyle={{
                            transform: `scale(2)`,
                            position: 'absolute',
                            left: '-40px'
                        }}
                        showRemainCars={false}
                        onClick={e => window.location.assign("https://zimcarry.net/")}
                    />
                </div>
            </div>
        </div>
    </div>
    <div className="font-SUIT tamra-main-footer">
        <div className="service-time-info">
            <div>
                <span><b>이용시간</b></span>
                <span>평일 10:30 ~ 18:30</span>
                <span>*Break Time: 13:15 ~ 14:30</span>
            </div>
        </div>
        <div
            className="register-box"
            onClick={() => {
                const register_page = window.open('/register', '_blank');
                register_page.addEventListener('registered', (e) => {
                    register_page.close();
                });
            }}
        >
            <div>
                <div>
                    서비스 이용이 처음이신가요?
                </div>
                <div>
                    최초 탑승정보 등록하기
                </div>
            </div>
            <AiOutlineForm/>
        </div>
    </div>
    </>)
};

export default withRouter(TamraMain);

const SelectBox = ({
    service_name="shuttle",
    title="서비스 명입니다",
    text="서비스 catch phrase",
    color="green",
    remainCars=0,
    showRemainCars=true,
    carImgSrc,
    carImgStyle,
    onClick=() => {}
}) => {
    return (
        <div className="tamra-main-select"
            onClick={onClick}
        >
            <img
                src={`/tamra=${service_name.toLowerCase()}_ill.svg`}
            />
            <div className={`box ${color}`}>
                <div>
                    <span>{title}</span>
                    <span>{text} {showRemainCars ? <span>{!!remainCars ? `잔여차량 ${remainCars}대` : "잔여차량이 없습니다."}</span> : null}</span>
                </div>
                <ArrowIcon />
            </div>
            <CarIcon src={carImgSrc} imgStyle={carImgStyle} />
        </div>
    )
}

const CarIcon = ({
    src = "/ioniq_front_view.png",
    imgStyle
}) => {
    return (
        <div className="car-img" style={{position: 'absolute'}}>
            <img
                src={src}
                style={imgStyle}
            />
        </div>
    )
}
const ArrowIcon = ({
    src = "/tamra=right_arrow.svg",
    onClick = () => {}
}) => {
    return (
        <img 
            className="arrow-icon"
            src={src} 
            onClick={onClick}
        />
    );
}