import React from "react";
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

export const DropdownButton = (props) => {
    const { dropdownName, direction, isOpen, toggleHandler, children } = props;

    return (
        <ButtonDropdown
            direction={direction || "down"}
            isOpen={isOpen}
            toggle={toggleHandler}
        >
            <DropdownToggle caret>{dropdownName}</DropdownToggle>
            <DropdownMenu>{children}</DropdownMenu>
        </ButtonDropdown>
    );
};

export const DropdownItems = (props) => {
    const { items, clickHandler } = props;
    return items.map((item, idx) => (
        <DropdownItem
            key={`${item}_${idx}`}
            name={item}
            value={item}
            onClick={() => clickHandler(item)}
        >
            {item}
        </DropdownItem>
    ));
};
