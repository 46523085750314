import React, { Component } from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import {
    Button,
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Jumbotron
} from "reactstrap";
import { TextField } from '@material-ui/core';
import "../../../App.css";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { BsExclamationCircleFill } from "react-icons/bs";
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import { agreementText, agreementShortText, getFirstPage, getUnavailablePage } from "../../Component/Common.js";

class ReservationStation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            server_on: false,
            call_available: true,
            location: 'station',
            request_result: 'none',
            isModalOpened: false,
            numPassenger: 0,
            isAgreed: false,
            phoneValid1: true,
            phoneValid2: true,
            birthdateValid1: true,
            birthdateValid2: true,
            selectAlert: false,
            car_location: [0, 0, 0],
            car_status: 'idle',
            service_on: false
        };
        this.page_location = "station";
    }
    async getCallState() {
        await axios.get("/api/getCallState", {
            params: {
                isClient: true
            }
        }).then(function (res) {
            if (res.statusText === "OK") {
                return res.data;
            } else {
                return {
                    server_on: false,
                    call_available: false,
                    location: 'airport'
                };
            }
        }).catch(function (error) {
            console.error("error while request GET to server");
            console.log(error);
            return {
                server_on: false,
                call_available: false,
                location: 'airport'
            };
        }).then((data) =>
            this.setState({
                ...this.state,
                server_on: data.server_on,
                call_available: data.call_available,
                location: data.current_location,
                status_location: data.status_location,
                car_location: data.car_location,
                car_status: data.car_status,
                service_on: data.service_on
            })
        );
    }

    async componentDidMount() {
        this.intervalId = await setInterval(() => this.getCallState(), 2000);
        this.getCallState();
    }
    async componentWillUnmount(){
        if(this.intervalId !== undefined){
            clearInterval(this.intervalId);}
    }

    redirect(path) {
        if (this.state.name2 === undefined || this.state.name2 === null) {
            sessionStorage.setItem("passenger_name", this.state.name1);
        } else {
            sessionStorage.setItem("passenger_name", this.state.name1 + "," + this.state.name2);
        }
        sessionStorage.setItem("call_location", "쏘카 스테이션");
        sessionStorage.setItem("req_time", this.state.requested_datetime);
        clearInterval(this.intervalId);
        this.props.history.push(path);
    }

    requestCall = event => {
        event.preventDefault();
        axios.post("/api/call_request",
            {
                'location': 'station',
                'passenger_info':
                {
                    'name1': this.state.name1, 'birthdate1': this.state.birthdate1, 'phone1': this.state.phone1,
                    'name2': this.state.name2, 'birthdate2': this.state.birthdate2, 'phone2': this.state.phone2
                }
            }).then(function (res) {
                if (res.statusText === "OK" && res.data.requested) {
                    return { path: "/confirm", requested_datetime: res.data.req_time };
                } else {
                    this.setState({ request_result: "none" });
                    return { path: "/station", requested_datetime: new Date() };
                }
            }).catch(function (error) {
                console.error("error while request GET to server");
                console.log(error);
                return { path: "/station", requested_datetime: new Date() };
            }).then((data) =>
                this.setState({
                    ...this.state, request_result: data.path, requested_datetime: data.requested_datetime
                })
            ).catch(
                function (error) {
                    console.log(error);
                }
            );

        // ReactGA.event({
        //     category: 'User',
        //     action: 'Request Call'
        // });
    }
    getInitialInfo() {
        return (
            <div>
                <div style={{ margin: "0", paddingTop: "2.5vh", paddingLeft: "7vw" }}>
                    <span style={{ color: "#F8061E", fontSize: "7vw" }}>
                        <BsExclamationCircleFill />
                    </span>
                    <span style={{ color: "#454F63", fontWeight: "900", fontSize: "5vw" }}> 호출 전에 확인해 주세요!</span>
                </div>
                <div style={{ width: "95vw", paddingLeft: "12vw", color: "#030000", fontWeight: "600", paddingTop: "2vh", fontSize: "4vw" }}>
                    <div ><span style={{ color: "#b30000", fontWeight: "900", paddingRight: "2vw" }}><AiOutlineDoubleRight /></span>캐리어 1개 이하의 1인 또는</div>
                    <div style={{ paddingLeft: "5vw", paddingBottom: "1vh" }}>큰짐이 없는 2인만 탑승 가능합니다</div>
                    <div style={{ paddingBottom: "1vh" }}><span style={{ color: "#b30000", fontWeight: "900", paddingRight: "2vw" }}><AiOutlineDoubleRight /></span>미성년자는 탑승이 불가합니다.</div>
                    <div style={{ paddingBottom: "1vh" }}><span style={{ color: "#b30000", fontWeight: "900", paddingRight: "2vw" }}><AiOutlineDoubleRight /></span>승차장소 확인 후 호출을 계속해주세요.</div>
                    <div style={{ paddingLeft: "6vw", color: "#b30000", fontSize: "3.7vw", fontWeight: "400" }}>예약완료 후 5분 내 탑승하지 않을 시</div>
                    <div style={{ paddingLeft: "6vw", color: "#b30000", fontSize: "3.7vw", fontWeight: "400" }}>예약이 취소됩니다.</div>
                </div>
                <img src='/socarMap.png' style={{
                    width: "90vw",
                    paddingTop: "3vh",
                    paddingLeft: "10vw"
                }} alt="Smiley face" />
            </div>
        )
    }
    getCustomerInfo() {
        let btn;
        if (this.state.numPassenger === 1) {
            btn = (
                <div style={{ width: "80vw", paddingLeft: "10vw" }}>
                    <Row>
                        <Col xs="6">
                            <Button style={{ backgroundColor: "#4969BA", borderRadius: "3vw", lineHeight: "min(3.5vw, 2.5vh)", width: "38vw", borderColor: "#4969BA", color: "white", height: "min(20vw, 25vh)", fontSize: "min(5vw, 3vh)" }}
                                onClick={this.updateNumPassenger1}>
                                <b>1명</b><br /><br />
                                <span style={{ fontSize: "min(3.5vw, 2.5vh)" }}>캐리어 1개까지<br />
                                가능합니다</span><br />
                            </Button>
                        </Col>
                        <Col xs="6" style={{ paddingLeft: "6vw" }}>
                            <Button outline style={{ borderRadius: "3vw", lineHeight: "min(3.5vw, 2.5vh)", width: "38vw", borderColor: "#4969BA", color: "#4969BA", height: "min(20vw, 25vh)", fontSize: "min(5vw, 3vh)" }}
                                onClick={this.updateNumPassenger2}>
                                <b>2명</b><br /><br />
                                <span style={{ fontSize: "min(3.5vw, 2.5vh)" }}>백팩, 작은짐만<br />
                                가능합니다</span><br />
                            </Button>
                        </Col>
                    </Row>
                </div>);
        } else if (this.state.numPassenger === 2) {
            btn = (
                <div style={{ width: "80vw", paddingLeft: "10vw" }}>
                    <Row>
                        <Col xs="6">
                            <Button outline style={{ borderRadius: "3vw", lineHeight: "min(3.5vw, 2.5vh)", width: "38vw", borderColor: "#4969BA", color: "#4969BA", height: "min(20vw, 25vh)", fontSize: "min(5vw, 3vh)" }}
                                onClick={this.updateNumPassenger1}>
                                <b>1명</b><br /><br />
                                <span style={{ fontSize: "min(3.5vw, 2.5vh)" }}>캐리어 1개까지<br />
                                가능합니다</span><br />
                            </Button>
                        </Col>
                        <Col xs="6" style={{ paddingLeft: "6vw" }}>
                            <Button style={{ backgroundColor: "#4969BA", borderRadius: "3vw", lineHeight: "min(3.5vw, 2.5vh)", width: "38vw", borderColor: "#4969BA", color: "white", height: "min(20vw, 25vh)", fontSize: "min(5vw, 3vh)" }}
                                onClick={this.updateNumPassenger2}>
                                <b>2명</b><br /><br />
                                <span style={{ fontSize: "min(3.5vw, 2.5vh)" }}>백팩, 작은짐만<br />
                                가능합니다</span><br />
                            </Button>
                        </Col>
                    </Row>
                </div>);
        } else {
            btn = (
                <div style={{ width: "80vw", paddingLeft: "10vw" }}>
                    <Row>
                        <Col xs="6">
                            <Button outline style={{ borderRadius: "3vw", lineHeight: "min(3.5vw, 2.5vh)", width: "38vw", borderColor: "#4969BA", color: "#4969BA", height: "min(20vw, 25vh)", fontSize: "min(5vw, 3vh)" }}
                                onClick={this.updateNumPassenger1}>
                                <b>1명</b><br /><br />
                                <span style={{ fontSize: "min(3.5vw, 2.5vh)" }}>캐리어 1개까지<br />
                                가능합니다</span><br />
                            </Button>
                        </Col>
                        <Col xs="6" style={{ paddingLeft: "6vw" }}>
                            <Button outline style={{ borderRadius: "3vw", lineHeight: "min(3.5vw, 2.5vh)", width: "38vw", borderColor: "#4969BA", color: "#4969BA", height: "min(20vw, 25vh)", fontSize: "min(5vw, 3vh)" }}
                                onClick={this.updateNumPassenger2}>
                                <b>2명</b><br /><br />
                                <span style={{ fontSize: "min(3.5vw, 2.5vh)" }}>백팩, 작은짐만<br />
                                가능합니다</span><br />
                            </Button>
                        </Col>
                    </Row>
                </div>);
        }
        let f1 = this.getForm();
        let f2 = this.getForm2();
        return (
            <div>
                <p style={{ color: "#454F63", fontWeight: "900", fontSize: "5vw", paddingLeft: "4vw", paddingTop: "3vh" }}>승객 정보 입력하기</p>
                {btn}
                {f1}
                {this.state.numPassenger === 2 ? f2 : <div />}
            </div>
        );
    }
    getAgreePage() {
        let modal = this.getAgreementModal();
        let agreeText = this.getAgreeText();
        let agreeText2 = this.getAgreeText2();
        let confirmBtn = this.getConfirmBtn();
        return (
            <div>
                <p style={{ color: "#454F63", fontWeight: "900", fontSize: "5vw", paddingLeft: "4vw", paddingTop: "3vh" }}>탑승 전 안내사항</p>
                <div style={{ width: "95vw", paddingLeft: "5vw" }}>
                    <Jumbotron>
                        {agreementShortText}
                        <Button onClick={this.toggle} style={{
                            margin: "0",
                            padding: "0",
                            border: "none",
                            color: "blue",
                            backgroundColor: "transparent",
                            textDecoration: "underline",
                            fontSize: "min(4vw, 3vh)"
                        }}>동의서 전문 확인하기 > </Button>
                    </Jumbotron>
                </div>
                {modal}
                <div style={{ paddingTop: "1vh" }} />
                <div style={{ width: "95vw", paddingLeft: "5vw" }}>
                    {agreeText}
                    {agreeText2}
                </div>
                <div style={{ paddingTop: "5vh", paddingBottom: "5vh", width: "75vw", paddingLeft: "25vw" }}>
                    {confirmBtn}
                </div>
            </div>);
    }
    checkPassengerNumSelect = e => {
        if (this.state.numPassenger === 0) {
            this.setState({ selectAlert: true });
        }
    }
    handleNameChange1 = e => {
        this.setState({ name1: e.target.value });
    }
    handleBirthChange1 = e => {
        this.setState({ birthdate1: e.target.value });
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            this.phoneInput1.focus();
        }
    }
    handlePhoneChange1 = e => {
        this.setState({ phone1: e.target.value });
        if (this.state.numPassenger === 1
            && !e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            e.target.blur();
        } else if (this.state.numPassenger === 2 && !e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            this.nameInput2.focus();
        }
    }
    handleNameChange2 = e => {
        this.setState({ name2: e.target.value });
    }
    handleBirthChange2 = e => {
        this.setState({ birthdate2: e.target.value });
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            e.target.blur();
            // this.phoneInput2.focus();
        }
    }
    handlePhoneChange2 = e => {
        this.setState({ phone2: e.target.value });
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            e.target.blur();
        }
    }
    updateNumPassenger1 = e => {
        this.setState({ numPassenger: 1, selectAlert: false });
    }
    updateNumPassenger2 = e => {
        this.setState({ numPassenger: 2, selectAlert: false });
    }

    handlePhoneBlur1 = e => {
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            this.setState({
                phoneValid1: true
            });
        } else {
            this.setState({
                phoneValid1: false
            });
        }
    }
    handlePhoneBlur2 = e => {
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            this.setState({
                phoneValid2: true
            });
        } else {
            this.setState({
                phoneValid2: false
            });
        }
    }

    handleBdayBlur1 = e => {
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            try {
                new Date(e.target.value);
                this.setState({
                    birthdateValid1: true
                });
            } catch{
                this.setState({
                    birthdateValid1: false
                });
            }
        } else {
            this.setState({
                birthdateValid1: false
            });
        }
    }
    handleBdayBlur2 = e => {
        if (!e.target.value.includes(" ")
            && e.target.value !== null
            && e.target.value !== undefined
            && e.target.value !== "") {
            try {
                new Date(e.target.value);
                this.setState({
                    birthdateValid2: true
                });
            } catch{
                this.setState({
                    birthdateValid2: false
                });
            }
        } else {
            this.setState({
                birthdateValid2: false
            });
        }
    }
    getForm() {
        let a = (<div style={{ color: "red", fontSize: "min(4vw, 3vh)", paddingTop: "2vw" }}>인원을 먼저 선택해주세요!</div>)
        return (
            <div style={{ paddingTop: "min(5vh, 1vw)", width: "90vw", paddingLeft: "5vw" }}>
                {this.state.selectAlert ? a : <div />}
                <TextField
                    required
                    id="passenger_name"
                    label="탑승자1 성함"
                    fullWidth
                    onChange={this.handleNameChange1}
                    onFocus={this.checkPassengerNumSelect}
                    defaultValue={this.state.name1}
                    helperText={' '}
                    disabled={this.state.selectAlert}
                />
                <NumberFormat
                    customInput={TextField}
                    format="####-##-##"
                    error={!this.state.birthdateValid1}
                    helperText={!this.state.birthdateValid1 ? '8자리를 년/월/일 순으로 입력해주세요' : ' '}
                    required
                    id="passenger_birthdate"
                    label="생년월일(YYYY-MM-DD)"
                    type="tel"
                    fullWidth
                    onChange={this.handleBirthChange1}
                    onFocus={this.checkPassengerNumSelect}
                    defaultValue={this.state.birthdate1}
                    onBlur={this.handleBdayBlur1}
                    disabled={this.state.selectAlert}
                />
                <NumberFormat
                    customInput={TextField}
                    format="###-####-####"
                    error={!this.state.phoneValid1}
                    helperText={!this.state.phoneValid1 ? '11자리를 입력해주세요' : ' '}
                    // getInputRef={(el) => (this.phoneInput1 = el)} 
                    inputProps={{ ref: input => this.phoneInput1 = input }}
                    required
                    id="passenger_phone"
                    label="휴대전화"
                    type="tel"
                    name="phone"
                    fullWidth
                    onChange={this.handlePhoneChange1}
                    onFocus={this.checkPassengerNumSelect}
                    defaultValue={this.state.phone1}
                    onBlur={this.handlePhoneBlur1}
                    disabled={this.state.selectAlert}
                />
                <div style={{ fontSize: "min(3vw, 2vh)", color: "grey" }}>예약 완료 시 안내 문자가 발송됩니다.</div>
            </div>);
    }

    getForm2() {
        return (
            <div style={{ paddingTop: "min(5vh, 1vw)", width: "90vw", paddingLeft: "5vw" }}>
                <TextField
                    inputProps={{ ref: input => this.nameInput2 = input }}
                    required
                    id="passenger_name2"
                    label="탑승자2 성함"
                    fullWidth
                    defaultValue={this.state.name2}
                    onChange={this.handleNameChange2}
                    helperText={' '}
                />
                <NumberFormat
                    customInput={TextField}
                    format="####-##-##"
                    error={!this.state.birthdateValid2}
                    helperText={!this.state.birthdateValid2 ? '8자리를 년/월/일 순으로 입력해주세요' : ' '}
                    required
                    id="passenger_birthdate2"
                    label="생년월일(YYYY-MM-DD)"
                    type="tel"
                    fullWidth
                    onChange={this.handleBirthChange2}
                    defaultValue={this.state.birthdate2}
                    onBlur={this.handleBdayBlur2}
                    disabled={this.state.selectAlert}
                />
            </div>);
    }

    toggle = () => {
        this.setState({ isModalOpened: !this.state.isModalOpened });
    };
    getAgreementModal() {
        return (
            <Modal isOpen={this.state.isModalOpened} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>동의서 전문</ModalHeader>
                <ModalBody>{agreementText}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>확인</Button>
                </ModalFooter>
            </Modal>
        );
    }
    getAgreeText() {
        let check_icon = (this.state.isAgreed ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />);
        return (
            <Button style={{
                margin: "0",
                padding: "0",
                border: "none",
                color: "black",
                backgroundColor: "transparent",
                fontSize: "min(4vw, 3vh)"
            }} onClick={() => { this.setState({ isAgreed: !this.state.isAgreed }) }}>{check_icon} 동의사항을 숙지하였으며 이에 동의합니다.</Button>);
    }
    getAgreeText2() {
        let check_icon = (this.state.isAgreed2 ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />);
        return (
            <Button style={{
                margin: "0",
                padding: "0",
                border: "none",
                color: "black",
                backgroundColor: "transparent",
                fontSize: "min(4vw, 3vh)"
            }} onClick={() => { this.setState({ isAgreed2: !this.state.isAgreed2 }) }}>{check_icon} 개인정보 수집 및 이용에 동의합니다.</Button>);
    }
    getConfirmBtn() {
        let passengerInfoSet = false;
        if (this.state.numPassenger === 1) {
            if (this.state.name1 !== undefined && this.state.birthdate1 !== undefined && this.state.phone1 !== undefined
                && this.state.birthdateValid1 && this.state.phoneValid1) {
                passengerInfoSet = true;
            }
        } else if (this.state.numPassenger === 2) {
            if (this.state.name1 !== undefined && this.state.birthdate1 !== undefined && this.state.phone1 !== undefined
                && this.state.name2 !== undefined && this.state.birthdate2 !== undefined
                && this.state.birthdateValid1 && this.state.phoneValid1 && this.state.birthdateValid2) {
                passengerInfoSet = true;
            }
        }
        if (passengerInfoSet && this.state.isAgreed && this.state.isAgreed2) {
            return (<Button onClick={this.requestCall} form='passengerForm' color="primary" style={{ width: "100%", fontSize: "min(4vw, 3vh)" }}>호출하기</Button>);
        } else {
            return (<Button form='passengerForm' disabled style={{ width: "100%", fontSize: "min(4vw, 3vh)" }}>호출하기</Button>);
        }
    }

    render() {
        const { server_on, call_available, location, request_result, car_location, status_location, service_on, car_status } = this.state;
        let firstPage = getFirstPage(this.page_location);
        let initialInfoPage = this.getInitialInfo();
        let customerInfoPage = this.getCustomerInfo();
        let agreePage = this.getAgreePage();
        if (server_on && call_available && location === 'station' && request_result === 'none') {
            return (
                <div>
                    {firstPage}
                    {initialInfoPage}
                    {customerInfoPage}
                    {agreePage}
                </div >);
        } else if (request_result !== 'none') {
            this.redirect(request_result);
            return (<div></div>);
        } else {
            let wait_status = "NOT_SERVICE";
            if (service_on) {
                if (car_status === "driving" && status_location === "airport") {
                    wait_status = "SOON_SERVICE";
                } else {
                    wait_status = "SERVICE"
                }
            }
            let unavailablePage = getUnavailablePage(this.page_location, car_location, wait_status);
            return (
                <div>
                    {/* {firstPage}
                    {initialInfoPage}
                    {customerInfoPage}
                    {agreePage} */}
                    {unavailablePage}
                </div >);
        }
    }
}
export default withRouter(ReservationStation);
