import React, { Component } from 'react';
import { Jumbotron } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import SeogwipoMapContainer from "./Service/Seogwipo/SeogwipoMapContent.js";

const Seogwipo_Service_End = new Date(2023,1,1);

class Home extends Component {
    render() {
        return (
        <div className="main">
            <SeogwipoMapContainer draggable={false} opacity={"0.5"} />
            <div
                style={{
                    width: "100vw",
                    textAlign: "center",
                    paddingTop: "20vh",
                    paddingLeft: "2.5vw",
                    float: "center",
                    position: "absolute",
                    zIndex: "1"
                }}>
                <Jumbotron style={{ margin: "0", width: "95vw", backgroundColor: "#646464", opacity: "0.8", color: '#FFFFFF', textAlign: "center"}}>
                    <span style={{
                        display: "inline-block", verticalAlign: "middle", lineHeight: "max(5vh, 5vw)",
                        fontWeight: '900',
                        fontSize: "3vh"
                    }}>이용하실 서비스를 선택해 주세요.</span>
                    <br/>
                    <br/>
                    <Link to="/tamra" style={{
                            color: '#FFFFFF',
                            fontWeight: '900',
                            fontSize: "2.5vh",
                            textDecoration: "underline"
                        }}>
                    <span>탐라자율차 페이지로 이동</span>
                    </Link>
                    <br/>
                    <Link to="/sangsang" style={{
                            color: '#FFFFFF',
                            fontWeight: '900',
                            fontSize: "2.5vh",
                            textDecoration: "underline"
                        }}>
                    <span>상상자율차 페이지로 이동</span>
                    </Link>
                </Jumbotron>
            </div>
        </div>
        );
    }
}
export default withRouter(Home);
