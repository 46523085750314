import { useCallback, useEffect, useState, useRef } from 'react'
import axios from 'axios';


export function useGetCallState(carName, Location, Service, delay, {
    isClient = true
}) {
    const [carInfo, setCarInfo] = useState({
        car_name: carName,
        car_pose: {
            pose: {
                latitude: 0,
                longitude: 0,
            },
            heading: 0,
        },
        vehicle_info: {
            id: '',
            maximum_occupancy: 0,
        }
    });
    const [isServiceOn, setIsServiceOn] = useState(false);
    const [isTripOn, setIsTripOn] = useState(false);

    const getCallState = useCallback(async () => {
        if (!!!carName)
            return;
        const res = await axios.get(`/api/${Location}/${Service}/getCallState`, {
            params: {
                car_name: carName,
            }
        }).catch(err => {
            return err.response;
        });
        if (res && res.statusText === 'OK') {
            const { service } = res.data;
            // res.data.car_name = carName;
            setCarInfo(() => res.data);
            setIsServiceOn(!!service);
            setIsTripOn(!!service?.trip_id);
        }
        else {
            setCarInfo(info => ({
                ...info,
                car_name: carName,
                car_pose: {
                    pose: {
                        latitude: 0,
                        longitude: 0,
                    },
                    heading: 0,
                },
                vehicle_info: {
                    id: '',
                    maximum_occupancy: 0,
                }
            }));
            setIsServiceOn(false);
            setIsTripOn(false);
        }
        return res?.data;
    },[carName, Location, Service, isClient]);

    useEffect(() => {
        getCallState();
    }, [getCallState]);

    useInterval(getCallState, delay);
    return [carInfo, isServiceOn, isTripOn, getCallState];
}

export function useGetCarState(delay, Location, Service) {
    const [carInfo, setCarInfo] = useState({});
    
    const getCallState = useCallback(async () => {
        const res = await axios.get(`/api/${Location}/${Service}/get-state`).catch(err => {
            return err.response;
        });
        if (res && res.statusText === 'OK') {
            setCarInfo(res.data);
        }
        else {
            setCarInfo({});
        }
        return res?.data;
    }, [Location, Service]);

    useEffect(() => {
        getCallState();
    }, [getCallState]);

    useInterval(getCallState, delay);
    return [carInfo, getCallState];
}

export function useGetState(carName, delay) {
    const [carInfo, setCarInfo] = useState({
        car_timeout: true,
        pose: {
            latitude: undefined,
            longitude: undefined,
        },
        passenger: [],
        trip_cnt: 0,
        total_trip_cnt: 0,
        current_service: "",
    });
    const [service, setService] = useState(false);
    const [trip, setTrip] = useState(false);

    const getCallState = useCallback(async () => {
        if (!!!carName) {
            return;
        }
        const res = await axios.get(`/api/admin/getState`, {
            params: {car_name: carName}
        })
        .catch(err => err.response)

        if (res && res.status === 200) {
            const { service_on, trip_on } = res.data;
            res.data.car_name = carName;
            setCarInfo(() => res.data);
            setService(service_on);
            setTrip(trip_on);
        }
        else {
            setCarInfo(info => ({
                ...info,
                car_name: carName,
                pose: {
                    latitude: undefined,
                    longitude: undefined,
                },
                trip_cnt: 0,
                total_trip_cnt: 0,
                car_timeout: true,
            }));
            setService(false);
            setTrip(false);
        }
        return res?.data;
    },[carName]);

    useEffect(() => {
        getCallState();
    }, [getCallState]);

    useInterval(getCallState, delay);
    return [carInfo, service, trip, getCallState];
}

export function useInterval(callback, delay)
{
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        let id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}

export function usePosition(onoff) {
    const [position, setPosition] = useState({});
    const [error, setError] = useState(null);
    
    const onChange = ({coords}) => {
        setPosition({
            latitude: coords.latitude,
            longitude: coords.longitude,
            accuracy: coords.accuracy,
            heading: coords.heading,
        });
    };
    const onError = (error) => {
        setError(error.message);
    };

    useEffect(() => {
        if(!!!onoff) {
            return;
        }
        const geo = navigator.geolocation;
        if (!geo) {
            setError('Geolocation is not supported');
            return;
        }
        geo.getCurrentPosition(onChange, onError);
    }, [onoff]);

    useEffect(() => {
        if(!!!onoff) {
            return;
        }
        const geo = navigator.geolocation;
        if (!geo) {
            setError('Geolocation is not supported');
            return;
        }
        let watcher = geo.watchPosition(onChange, onError);
        return () => geo.clearWatch(watcher);
    }, [onoff]);
    return {...position, error};
}