import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { KakaoCustomOverlay, KakaoMap, KakaoMarker, KakaoPolyline } from "../../Component/Map/Kakao";
import { usePosition } from "../../Hooks/Hooks";
import { Button } from "reactstrap";
import TamraCancelModal from "./TamraCancelModal";
import LocationButton from "../../Component/LocationButton";
import { UnavailablePage } from "./TamraUnavailable";
import { PathGraph } from "./TamraShuttlePath";
import { toStandardLocation } from "../../Util/Common";

const NOT_FOUND = 'not found';
const CANCELD = 'CANCEL';
const SERVED = 'SERVED';
const EXPIRED = 'EXPIRED';
const INIT_BOUNDS = {
    bound : [{lng: 126.44718426116032, lat: 33.4887548039607}, {lng: 126.51668431338587, lat: 33.529292347712946}],
    padding: [0, 0]
};

function TamraShuttleReservation ({
    history,
    location,
    ...props
}) {
    const myLocation = usePosition(true);

    const [reservationId, setReservationId] = useState(0);
    const [reservationTime, setReservationTime] = useState(null);
    const [status, setStatus] = useState('');

    const [startStop, setStartStop] = useState();
    const [endStop, setEndStop] = useState();
    const [startStopId, setStartStopId] = useState(-1);
    const [endStopId, setEndStopId] = useState(-1);
    const [passengerNumber, setPassengerNumber] = useState(0);
    const [stops, setStops] = useState([]);

    const [openCancelModal, setOpenCancelModal] = useState(false);

    const [center, setCenter] = useState([33.505796045397226, 126.48513102168431]);
    const [bounds, setBounds] = useState(INIT_BOUNDS);

    const [carName, setCarName] = useState('');
    const [carInfo, setCarInfo] = useState({});

    const [shuttleGraph, setShuttleGraph] = useState();
    const [shuttlePath, setShuttlePath] = useState([]);

    const onCancel = useCallback(() => {
        axios.put("/api/tamra/shuttle/cancel-reservation",{
            id: reservationId
        })
        .then(res => {
            const success = res.data;
            setStatus(s => success ? CANCELD : s);
            setOpenCancelModal(false);
        })
        .catch(err => {
            alert("Fail to cancel the reservation.");
        });
    }, [reservationId]);

    const findStopInfo = useCallback((stop_id) => {
        return stops.find(stop => stop?.id === stop_id);
    }, [stops]);


    useLayoutEffect(() => {
        async function wrapper() {
            const [{value: shuttle_stops}, {value: shuttle_path}] = await Promise.allSettled([
                axios.get("/api/tamra/shuttle/get-stops")
                .then(res => res.data).catch(err => {
                    console.error(err);
                    return [];
                }),
                axios.get("/api/tamra/shuttle/get-path")
                .then(res => res.data).catch(err => {
                    console.error(err);
                    return {geometry: []};
                }),
            ]);

            setStops(shuttle_stops);
            const path_edges_info = shuttle_path.map(path => {
                const {
                    from, to, bidirection
                } = JSON.parse(path.json_data);
                return {
                    path: path.geometry.flat(),
                    from, to, bidirection
                }
            });
            const graph = new PathGraph(path_edges_info);
            setShuttleGraph(graph);
        }
        wrapper();
    }, []);

    useLayoutEffect(() => {
        async function wrapper() {
            const { id: encrypted_id } = Object.fromEntries(new URLSearchParams(location.search));

            const reservation_info = await axios.get("/api/tamra/shuttle/get-reservation",{
                params: { encrypted_id }
            })
            .then(res => res.data[0])
            .catch(err => {
                console.error(err);
                return;
            });
            if (reservation_info === undefined) {
                setStatus(NOT_FOUND)
                return;
            }
            const {
                passenger_num, start_stop_id, end_stop_id, status, start_datetime, car_name,
                id: reservation_id
            } = reservation_info;

            setReservationId(reservation_id);

            setStatus(status);
            const reservation_time = new Date(start_datetime);
            setReservationTime(reservation_time);
            setCarName(car_name);
            if (status !== CANCELD && status !== EXPIRED) {
                setPassengerNumber(passenger_num);
                setStartStopId(start_stop_id);
                setEndStopId(end_stop_id);
            }
        }
        wrapper();
    }, [location]);

    useEffect(() => {
        async function wrapper() {
            if (!!!carName) {
                return;
            }
            const car_info = await axios.get("/api/tamra/get-car-info",{
                params: {car_name: carName}
            })
            .then(res => res.data).catch(err => console.error(err));
            if (car_info) {
                setCarInfo(car_info);
            }
        }
        wrapper();
    }, [carName]);

    useEffect(() => {
        const start_stop = findStopInfo(startStopId);
        if (start_stop) {
            setStartStop(start_stop);
        }
        else {
            console.error("Cannot find startStop. Id:", startStopId);
        }
    }, [startStopId, findStopInfo]);
    useEffect(() => {
        const end_stop = findStopInfo(endStopId);
        if (end_stop) {
            setEndStop(end_stop);
        }
        else {
            console.error("Cannot find startStop. Id:", endStopId);
        }
    }, [endStopId, findStopInfo]);

    useEffect(() => {
        if (startStop && endStop && shuttleGraph) {
            setShuttlePath(shuttleGraph.getPath(startStop.order, endStop.order, true).path);
        }
    }, [startStop, endStop, shuttleGraph, stops]);

    useEffect(() => {
        if (shuttlePath.length > 0) {
            const lat_lng_min_max = shuttlePath.flat().reduce((prev, location) => {
                const standard_location = toStandardLocation(location);
                prev.max_latitude = Math.max(standard_location.latitude, prev.max_latitude);
                prev.max_longitude = Math.max(standard_location.longitude, prev.max_longitude);
                prev.min_latitude = Math.min(standard_location.latitude, prev.min_latitude);
                prev.min_longitude = Math.min(standard_location.longitude, prev.min_longitude);
                return prev;
            },{
                max_latitude: -9999,
                max_longitude: -9999,
                min_latitude: 9999,
                min_longitude: 9999,
            });
            setBounds({
                bound : [
                    {lng: lat_lng_min_max.min_longitude, lat: lat_lng_min_max.min_latitude},
                    {lng: lat_lng_min_max.max_longitude, lat: lat_lng_min_max.max_latitude}
                ],
                padding: [32, 32]
            })
        }
    }, [shuttlePath]);

    return (
    status === CANCELD ? 
    <UnavailablePage
        history={history}
        black={true}
    >
        예약이<br/>
        취소되었습니다
    </UnavailablePage> :
    status === EXPIRED ?
    <UnavailablePage
        history={history}
        black={true}
    >
        만료된 <br/>
        예약입니다 <br/>
        {}
    </UnavailablePage>
    : status === NOT_FOUND ?
    <UnavailablePage
        history={history}
        black={true}
    >
        Not Found: 404 <br/>
        올바르지 않은 경로입니다
    </UnavailablePage> 
    : status === SERVED ?
    <UnavailablePage
        history={history}
    >
        이용해 주셔서 <br/>
        감사합니다.
    </UnavailablePage>
    : <div 
        className="font-SUIT" 
        style={{position:"relative", height: "100%", display:"flex", flexDirection:"column-reverse"}}
    >
        <div className="tamra-bottom-box relative">
            <LocationButton
                className="tamra-location-button"
                onClick={()=>{
                    if (myLocation.latitude !== undefined && myLocation.longitude !== undefined) {
                        setCenter([myLocation.latitude, myLocation.longitude]);
                    }
                }}
            />
            <div className="reservation-title" style={{flexDirection: 'row', justifyContent:'space-between'}}>
                <div>예약이 완료되었습니다. <img src="/three_dot.svg"/></div>
            </div>
            <div className="line-divider" />
            <div className="info-box">
                <div className="tamra-information-box">
                    <div>
                        <div>
                        경로
                        </div>
                        <div>
                        {startStop?.description ?? "출발 정류장"}
                        {" > "}
                        {endStop?.description ?? "도착 정류장"}
                        </div>
                    </div>
                    <div>
                        <div>탑승 인원</div>
                        <div>{passengerNumber}인</div>
                    </div>
                    {!!reservationTime ? 
                    <div>
                        <div>탑승 시각</div>
                        <div>{reservationTime.getMonth() + 1}월 {reservationTime.getDate()}일 {reservationTime.getHours()}시 {reservationTime.getMinutes()}분</div>
                    </div> : null
                    }
                    <div>
                        <div>차량 번호</div>
                        <div>{carInfo.id}</div>
                    </div>
                    <div className='list'>
                        <div>문의</div>
                        <div className="phones">
                            064-748-8039
                        </div>
                    </div>
                </div>
            </div>
            <Button onClick={(e) => {
                setOpenCancelModal(true)
            }}>
            예약 취소
            </Button>
        </div>
        <KakaoMap
            width="100%"
            style={{flexGrow: 1}}
            center={center}
            bounds={bounds}
            level={6}
        >
            <KakaoMarker 
                position={startStop?.location}
                image={{
                    src: '/get_on=blue.svg',
                    width: 48,
                    height: 48,
                    options: {
                        offset: [24, 40]
                    }
                }}
                title={"승차"}
            />
            <KakaoMarker 
                position={endStop?.location}
                image={{
                    src: '/get_off=blue.svg',
                    width: 48,
                    height: 48,
                    options: {
                        offset: [24, 40]
                    }
                }}
                title={"하차"}
            />
            <KakaoCustomOverlay position={myLocation} contentID="mylocation">
                <img src="/mylocation=direction.svg" style={{transform: `rotate(${90 - (myLocation.heading ?? 90)}deg)`}} />
            </KakaoCustomOverlay>
            <KakaoPolyline 
                path={shuttlePath}
                strokeColor="#1261FB"
                strokeWeight={5}
            />
        </KakaoMap>
        <TamraCancelModal
            isReservation={true}
            isOpen={openCancelModal}
            centered={true}
            unmountOnClose={false}
            
            toggle={() => {setOpenCancelModal(p => !p)}}
            onCancel={onCancel}
        />
    </div>
    )
};


export default withRouter(TamraShuttleReservation);