import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { toLatLng } from "./Util";
import { KakaoMapContext } from "./KakaoMap";


const KakaoPolygon = ({
    path,
    
    strokeWeight,
    strokeColor,
    strokeOpacity,
    strokeStyle,
    fillColor,
    fillOpacity,
    strokeOptions, 

    zIndex,
    onClick ,
    onMouseOver,
    onMouseOut,
    onMouseMove,
    onMouseDown,
    onTrigger = [],
    visible = true,
    ...props
}) => {
    
    const { map, loaded } = useContext(KakaoMapContext);
    
    const polygon = useMemo(() => {
        if (!!!map || !loaded || !window.kakao) {
            return null;
        }
        
        return new window.kakao.maps.Polygon({
            map,
        });
    }, [loaded]);

    useLayoutEffect(() => {
        if (polygon) {
            polygon.setMap(visible ? map : null);
        }
    }, [visible]);
    useLayoutEffect(() => {
        if (polygon && !!!strokeOptions) {
            polygon.setOptions({
                strokeWeight: strokeWeight,
                strokeColor: strokeColor,
                strokeOpacity: strokeOpacity,
                strokeStyle: strokeStyle,
                fillColor: fillColor,
                fillOpacity: fillOpacity,
            });
        }
    }, [polygon, strokeWeight, strokeColor, strokeOpacity, strokeStyle, strokeOptions]);
    useLayoutEffect(() => {
        if (polygon && !!strokeOptions) {
            polygon.setOptions(strokeOptions);
        }
    }, [polygon, strokeOptions]);
    useLayoutEffect(() => {
        if (polygon) {
            let latlng_path = [];
            if (Array.isArray(path) && path.every(p => !!p)) {
                latlng_path = path.map(point => {
                    if (Array.isArray(point) && typeof(point[0]) !== 'number') {
                        return point.map(p => toLatLng(p));
                    }
                    else {
                        return toLatLng(point)
                    }
                });
            }
            polygon.setPath(latlng_path);
        }
    }, [polygon, path, toLatLng]);
    useLayoutEffect(() => {
        if (polygon && typeof(zIndex) === 'number') {
            polygon.setZIndex(zIndex);
        }
    }, [polygon, zIndex]);

    useLayoutEffect(() => {

    },[]);

    useEffect(() => {
        return () => {
            if (polygon) {
                console.log("Destroy kakao polyline");
                polygon.setMap(null);
            }
        }
    }, [polygon]);

    const onEvent = useCallback((polygon, func) => {
        return function (mouseEvent) {
            func(mouseEvent, polygon);
        }
    }, []);
    const onTriggerEvent = useCallback((polygon, callback) => {
        return function (data) {
            callback(data, polygon);
        }
    }, []);

    useLayoutEffect(() => {
        if (!polygon || !onClick || typeof(onClick) !== 'function') {
            return;
        }
        const on_click_func = onEvent(polygon, onClick);
        window.kakao.maps.event.addListener(polygon, 'click', on_click_func);
        return () => {
            window.kakao.maps.event.removeListener(polygon, 'click', on_click_func);
        }
    }, [polygon, onClick, onEvent]);
    useLayoutEffect(() => {
        if (!polygon || !onMouseOver || typeof(onMouseOver) !== 'function') {
            return;
        }
        const on_mouseover_func = onEvent(polygon, onMouseOver);
        window.kakao.maps.event.addListener(polygon, 'mouseover', on_mouseover_func);
        return () => {
            window.kakao.maps.event.removeListener(polygon, 'mouseover', on_mouseover_func);
        }
    }, [polygon, onMouseOver, onEvent]);
    useLayoutEffect(() => {
        if (!polygon || !onMouseOut || typeof(onMouseOut) !== 'function') {
            return;
        }
        const on_mouseout_func = onEvent(polygon, onMouseOut);
        window.kakao.maps.event.addListener(polygon, 'mouseout', on_mouseout_func);
        return () => {
            window.kakao.maps.event.removeListener(polygon, 'mouseout', on_mouseout_func);
        }
    }, [polygon, onMouseOut, onEvent]);
    useLayoutEffect(() => {
        if (!polygon || !onMouseMove || typeof(onMouseMove) !== 'function') {
            return;
        }
        const on_mousemove_func = onEvent(polygon, onMouseMove);
        window.kakao.maps.event.addListener(polygon, 'mouseout', on_mousemove_func);
        return () => {
            window.kakao.maps.event.removeListener(polygon, 'mouseout', on_mousemove_func);
        }
    }, [polygon, onMouseMove, onEvent]);
    useLayoutEffect(() => {
        if (!polygon || !onMouseDown || typeof(onMouseDown) !== 'function') {
            return;
        }
        const on_mousedown_func = onEvent(polygon, onMouseDown);
        window.kakao.maps.event.addListener(polygon, 'mouseout', on_mousedown_func);
        return () => {
            window.kakao.maps.event.removeListener(polygon, 'mouseout', on_mousedown_func);
        }
    }, [polygon, onMouseDown, onEvent]);
    useLayoutEffect(() => {
        if (!polygon || !onTrigger.length) {
            return;
        }

        onTrigger.forEach(trigger_obj => {
            window.kakao.maps.event.addListener(polygon, trigger_obj.type, onTriggerEvent(polygon, trigger_obj.callback));
        });
        return () => {
            onTrigger.forEach(trigger_obj => {
                window.kakao.maps.event.removeListener(polygon, trigger_obj.type, onTriggerEvent(polygon, trigger_obj.callback));
            });
        }
    }, [polygon, onTrigger]);
    return null;
};

export default KakaoPolygon;