import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { AiOutlineForm } from "react-icons/ai";
import "../../style/Tamra.css"
import { useInterval } from "../../Hooks/Hooks";
import { IoIosArrowForward } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import ToastMsg from '../../Component/ToastMsg.js';
import 'react-toastify/dist/ReactToastify.css';

const DELAY = 5 * 1000;
const LOCATION = 'sangsang'
const CARPOOL = 'carpool'
const CARPOOL_SERVICE_NAME = `${LOCATION}_${CARPOOL}`.toUpperCase()

const SangsangMain = ({ history, location }) => {
    const [remainCars, setRemainCars] = useState(0);
    const [notifications, setNotifications] = useState([]);

    const goTo = useCallback((url, state={}) => {
        let path_name = url;
        let search = undefined;
        if (typeof url === "object") {
            path_name = url.pathname;
            search = url.search;
        }
        if (path_name.startsWith('./')) {
            path_name = `${location.pathname}/${path_name.slice(2)}`;
        }
        else if (!path_name.startsWith('/')){
            path_name = [...location.pathname.split('/').slice(0,-1), path_name].join('/');
        }
        history.push({
            pathname: path_name,
            search
        }, state);
    },[history, location]);

    const getAvailableCar = useCallback(async () => {
        const {
            [CARPOOL_SERVICE_NAME]: sangsang_shuttle_cars,
        } = await axios.get(`/api/available-car`, {
            params: {service_name: [CARPOOL_SERVICE_NAME]}
        })
        .then (res => res.data)
        .catch(err => {
            console.log(err);
            return {
                [CARPOOL_SERVICE_NAME]: [],
            }
        });

        setRemainCars(sangsang_shuttle_cars.length);
    }, []);

    useLayoutEffect(() => {
        getAvailableCar();
    }, [getAvailableCar]);
    useInterval(getAvailableCar, DELAY);

    useEffect(() => {
        async function fn() {
            const current_notifications = await axios.get("/api/get-current-notification",{
                params: {
                    service_name: "SANGSANG"
                }
            })
            .then(res => res.data)
            .catch(err => []);
            setNotifications(current_notifications);
        }
        fn();
    }, []);

    useEffect(() => {
        notifications.forEach(notification => {
            const converted_description = notification.description.split('\n').reduce((acc, cur, idx) => {
                acc.push(cur);
                acc.push(<br key={`notification-${notification.id}-${idx}`}/>);
                return acc
            }, []);
            try {
                toast[notification.type](
                <ToastMsg key={`notification-${notification.id}`}>
                    {converted_description}
                </ToastMsg>, {
                    
                });
            }
            catch (err) {
                console.error("Invalid notification type:", notification.type);
            }
            
        });
    }, [notifications]);

    return (
    <>
    <ToastContainer
        style={{minWidth: 'fit-content'}}
        position='top-right'
        hideProgressBar={true}
        autoClose={5000}
        pauseOnFocusLoss
    />
    <main className="font-SUIT tamra-main">
        <div>
            <div className="wrapper">
                <div className="tamra-main-title " >
                    <img 
                        src="/sangsang_logo.png"
                        style={{width: '300px'}}
                    />
                    <div className="service-info-link">
                        <span onClick={() => {window.open('https://ridefluxservice.notion.site/96e7b453c80f41af8ca0a86a5cbfdb99', '_blank');}}>
                            서비스 소개<IoIosArrowForward className="arrow-forward" />
                        </span>
                    </div>
                </div>
                <div className="tamra-main-select-wrapper">
                    <SelectBox
                        service_name="carpool"
                        title="정부세종청사 순환형 서비스"
                        text="차량 호출하기"
                        color="blue"
                        remainCars={remainCars}
                        carImgSrc="/sangsang-card.png"
                        carImgStyle={{
                            width: '343px',
                        }}
                        showRemainCars={true}
                        onClick={e => {
                            if (!remainCars) {
                                alert('현재 서비스 가능한 잔여 차량이 없습니다.');
                            }
                            goTo(`/${LOCATION}/${CARPOOL}`)
                        }}
                    />
                </div>
            </div>
        </div>
    </main>
    <footer className="font-SUIT tamra-main-footer" style={{backgroundColor: '#fff'}}>
        <div
            className="register-box"
            onClick={() => {
                const register_page = window.open('/register', '_blank');
                register_page.addEventListener('registered', (e) => {
                    register_page.close();
                });
            }}
            style={{
                justifyContent: 'flex-end',
                backgroundColor: 'transparent',
                color: '#707990',
                width: '300px',
                maxWidth: '90%',
                margin: '0 auto',
                padding: 0
            }}
        >
            <div>
                <div style={{color: '#707990'}}>
                    서비스 이용이 처음이신가요?
                </div>
                <div>
                    최초 탑승정보 등록하기
                </div>
            </div>
            <div style={{backgroundColor: '#3e3276', padding: '5px', borderRadius: '15px', marginLeft: '10px'}}>
                <AiOutlineForm  color="white" />
            </div>
        </div>
        <div
            className="service-time-info"
            style={{
                backgroundColor: '#3e3276',
                width: '300px',
                maxWidth: '90%',
                color: '#fff',
                borderRadius: '20px',
                margin: '0 auto 30px',
            }}
        >
            <div>
                <span><b>이용시간</b></span>
                <span>평일 10:00 ~ 17:00</span>
                <span>*Break Time: 13:15 ~ 14:45</span>
            </div>
        </div>
    </footer>
    </>)
};

export default withRouter(SangsangMain);

const SelectBox = ({
    service_name="shuttle",
    title="서비스 명입니다",
    text="서비스 catch phrase",
    color="green",
    remainCars=0,
    showRemainCars=true,
    carImgSrc,
    carImgStyle,
    onClick=() => {}
}) => {
    return (
        <div
            className="tamra-main-select"
            onClick={onClick}
        >
            <img
                src={`/sangsang_card.png`}
                style={{width: '300px', height: 'auto'}}
            />
            <div className={`box ${color}`}>
                <div>
                    <span>{title}</span>
                    <span>{text} {showRemainCars ? <span>{!!remainCars ? `잔여차량 ${remainCars}대` : "잔여차량이 없습니다."}</span> : null}</span>
                </div>
                <ArrowIcon />
            </div>
            {/* <CarIcon src={carImgSrc} imgStyle={carImgStyle} /> */}
        </div>
    )
}

const CarIcon = ({
    src = "/ioniq_front_view.png",
    imgStyle
}) => {
    return (
        <div className="car-img" style={{position: 'absolute'}}>
            <img
                src={src}
                style={imgStyle}
            />
        </div>
    )
}
const ArrowIcon = ({
    src = "/tamra=right_arrow.svg",
    onClick = () => {}
}) => {
    return (
        <img 
            className="arrow-icon"
            src={src} 
            onClick={onClick}
        />
    );
}