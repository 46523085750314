import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Button } from "reactstrap";

const DarkModeButton = (props) => {
    // const {
        
    // } = props;
    
    const [darkMode, setDarkMode] = useState(localStorage.getItem('dark-mode')=== 'on');

    const toggle = useCallback(() => {
        setDarkMode(o => !o);
    }, []);

    useLayoutEffect(() => {
        if (darkMode) {
            document.body.classList.add('dark-mode');
            localStorage.setItem('dark-mode', "on");
        }
        else {
            document.body.classList.remove('dark-mode');
            localStorage.setItem('dark-mode', "off");
        }
    }, [darkMode]);

    return (
    <Button
        sm={10}
        style={{
            float: 'left'
        }}
        onClick={toggle}
    >
        {darkMode ? "Light Mode" : "Dark Mode"}
    </Button>
    );
}

export default DarkModeButton;